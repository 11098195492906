<template>
  <div class="com-point-left-mask top" :style="{ top: `${topOffset}px` }" v-if="!hidden && points.activePoints > 0">
    我的{{ globalPointName }}<span>{{ points.activePoints }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PointNoticeMask',
  props: {
    /**
     * 滚动到某个位置隐藏该组件，当为0时则不隐藏
     */
    hiddenOffset: {
      type: Number,
      default: 0
    },

    topOffset: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      hidden: false
    }
  },

  computed: {
    ...mapState({
      points: (state) => state.user.points
    })
  },

  created() {},
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },

  methods: {
    handleScroll(e) {
      if (this.hiddenOffset === 0) {
        return
      }

      const scrollTop =
        (e.target.documentElement && e.target.documentElement.scrollTop) || (e.target.body && e.target.body.scrollTop)
      // console.log(scrollTop)

      if (scrollTop > 200) {
        this.hidden = true
      } else {
        this.hidden = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.com-point-left-mask {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 999;
  left: 0;
  // width: 100%;
  padding: 5px 10px;

  color: #ffffff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
</style>
