// 不需要更新用户积分的路由
const KEEP_POINTS_ROUTES = [
  '/payindex',
  '/customService1',
  '/ordersuccess',
  '/myorder3',
  '/leftFirst',
  '/activity/brand',
  '/activity/timeLimit',
  '/activity/returnScore'
]

export { KEEP_POINTS_ROUTES }
