<template>
  <div v-if="pId" :class="className" class="mx2">
    <van-sticky
      v-if="sticky"
      :id="stickyId"
      @scroll="onStickyScroll"
      v-track:exposure
      :track-params="JSON.stringify(getEventInfo('首页商品瀑布流tab'))"
    >
      <TabFilter
        :style-tab-title="{
          fontSize: '16px',
          ...(tabFilterTitleColor ? { color: tabFilterTitleColor } : {})
        }"
        :tabs="groupTypeIds"
        :in-active-style="{ background: 'transparent' }"
        :style-active-subtitle="styleActiveSubtitle"
        :style-wrap="{ 'background-color': isStickyFixed ? tabFilterBgColor : 'transparent' }"
        @on-tab-change="onTabChange"
      />
    </van-sticky>

    <div v-else v-track:exposure :track-params="JSON.stringify(getEventInfo('首页商品瀑布流tab'))">
      <TabFilter
        :style-tab-title="{
          fontSize: '16px',
          ...(tabFilterTitleColor ? { color: tabFilterTitleColor } : {})
        }"
        :style-tab="{ width: '84px', height: '64px' }"
        :has-normal-bubble-bg="true"
        :tabs="groupTypeIds"
        :in-active-style="{ background: 'transparent' }"
        :style-active-subtitle="styleActiveSubtitle"
        :style-wrap="{ 'background-color': isStickyFixed ? tabFilterBgColor : 'transparent', padding: 0 }"
        @on-tab-change="onTabChange"
      />
    </div>

    <div class="my2"></div>
    <GoodsWaterfallList
      ref="waterfallGoodsList"
      name="首页推荐分类"
      :p-id="pId"
      :type="groupTypeIds[activeTabIndex].groupTypeId"
    />
  </div>
</template>

<script>
import GoodsWaterfallList from './GoodsWaterfallList'
import TabFilter from './TabFilter'
import GoodsNormalItem from './ListItem/GoodsNormalItem'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'
import xEnum from 'base/utils/enum'

const itemMap = {
  point: GoodsNormalItem
}

export default {
  name: 'HomeRecommendGoods',
  components: { TabFilter, GoodsWaterfallList },
  props: {
    name: {
      type: String,
      default: '未知'
    },

    iconName: {
      type: String,
      default: 'icongouwu'
    },

    iconColor: {
      type: String,
      default: '#E15658'
    },

    title: {
      type: String,
      default: '猜你喜欢'
    },

    className: {
      type: String,
      default: ''
    },

    groupTypeIds: {
      type: Array,
      default: () => []
    },

    sticky: {
      type: Boolean,
      default: false
    },

    enableAutoScroll: {
      type: Boolean,
      default: true
    },

    tabFilterBgColor: {
      type: String,
      default: 'white'
    },

    tabFilterTitleColor: {
      type: String,
      default: null
    },

    styleActiveSubtitle: {
      type: Object,
      default: () => {}
    },

    styleLoadingText: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      goodsList: [],
      loading: false,
      tabs: this.renderBrandElement({ id: 'guessYourFavor.tabs', type: 'object' }),
      activeTabIndex: 0,
      isStickyFixed: false,
      goodsItem: itemMap[this.renderBrandElement({ id: 'guessYourFavor.listItemType' }) || 'point']
    }
  },

  computed: {
    pId() {
      return this.$store.state.appState.pId
    },

    stickyId() {
      return `stickyZone-${this.name}`
    }
  },

  mounted() {
    if (this.pId) {
      // this.onTabChange(0, false);
    }
  },

  methods: {
    // async fetchGoodsList(type) {
    //   try{
    //     this.loading = true
    //     const response = await getGoodList({
    //       pId: this.pId,
    //       type,
    //     })

    //     this.goodsList = response.data ? response.data.goodsList : []
    //   } catch (err) {
    //     console.log(err);
    //   }

    //   this.loading = false
    // },
    onClickGoodsListMore() {
      this.$emit('click-more')
    },

    loadGoodsList(dataList) {
      dataList.forEach((item) => {
        this.goodsList.push(item)
      })
    },

    // eslint-disable-next-line no-unused-vars
    onTabChange(index, pageIndex = 1, scrollToTop = true) {
      this.activeTabIndex = index
      this.goodsList = []
      this.$refs.waterfallGoodsList.resetOnloadSetting()

      // 首页资源位-商品瀑布流tab
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hp_place_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_hp_page',
        params: {
          place_type: `商品组标题：${this.groupTypeIds[index].title}`,
          place_name: '首页商品瀑布流tab',
          place_addr: index,
          place_detail: this.groupTypeIds[index].groupTypeId
        }
      })
    },

    // eslint-disable-next-line no-unused-vars
    onStickyScroll({ scrollTop, isFixed }) {
      this.isStickyFixed = isFixed
    },

    resetOnloadSetting() {
      this.$refs.waterfallGoodsList.resetOnloadSetting()
    },

    getEventInfo(addr) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.HOME_PAGE_SOURCE_PLACE_EP.value,
        eventInfo: {
          place_type: '无',
          place_name: addr,
          place_addr: '无'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.com-point-notice-mask {
  .t_Img {
    display: inline-block;
    vertical-align: middle;
    width: 0.6rem;
    margin-left: 0.5rem;
    margin-right: 0.3rem;
  }
  .t_txt {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 2rem);
    font-size: 0.5rem;
    color: #ffffff;
  }

  &.top {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
</style>
