<template>
  <div class="com-home-swipe-container pt1 pb1">
    <img class="advert-bg" src="~base/assets/img/home_header_bottom.png" />
    <div class="px2">
      <van-swipe class="my-swipe" :autoplay="3000" :loop="true" indicator-color="white">
        <van-swipe-item
          v-for="(item, index) in swipeItems"
          :key="item.id"
          @click="swipeClick(item, index)"
          v-track:exposure
          :track-params="JSON.stringify(getEventInfo(item, index))"
        >
          <ImageThemed image-class-name="sbImg1" :src="item.img" style="border-radius: 11px; overflow: hidden" />
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
/**
 * 首页-轮播banner
 */
import { mapState } from 'vuex'
import ImageThemed from '../components/ImageThemed.vue'
import xEnum from 'base/utils/enum'
import utils from 'base/utils'
import { getAdvertiseConfig } from 'base/api'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'HomeSwipe',
  components: { ImageThemed },
  props: {},

  data() {
    return {
      swipeItems: []
    }
  },

  computed: {
    ...mapState({
      isInWechat: (state) => state.appState.isInWechat
    })
  },

  created() {
    this.getAdvertiseConfig()
  },

  mounted() {},
  destroyed() {},

  methods: {
    to(route) {
      this.$router.push(route)
    },

    async getAdvertiseConfig() {
      const resp = await getAdvertiseConfig({ advType: 'indexTopAdvert', subType: process.env.VUE_APP_BRANCH_ENUM })
      resp.data.forEach((item) => {
        item.businessKey = JSON.parse(item.businessKey)
      })
      const swipeItems = _.sortBy(resp.data, (o) => {
        return o.businessKey.index
      })
      this.swipeItems = swipeItems
    },

    async toMiniProgram(source) {
      const mpScheme = await this.$store.dispatch('getMpUrlScheme', source)
      location.href = mpScheme
    },

    swipeClick(item, index) {
      if (!item.businessKey) return
      const isWebUrl = utils.isWebUrl(item.businessKey.path)
      let placeType = ''
      let placeDetail = ''
      switch (item.urlType) {
        case xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.value:
          this.to({ path: `/activity/brand?pageId=${item.businessKey.pageId}` })
          placeDetail = item.businessKey.pageId
          placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.getText()
          break
        case xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.value:
          this.to({ path: `/goodsProfile/${item.businessKey.goodsId}`, query: { type: item.businessKey.type } })
          placeDetail = item.businessKey.goodsId
          placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.getText()
          break
        case xEnum.ENUM_ADVERTISE_URL_TYPE.LINK.value:
          if (isWebUrl) {
            window.location.href = item.businessKey.path
          } else {
            this.to({ path: item.businessKey.path })
          }
          placeDetail = item.businessKey.path
          placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.LINK.getText()
          break
        case xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.value:
          this.toMiniProgram(item.businessKey)
          placeDetail = item.businessKey
          placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.getText()
          break
        default:
          placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.NONE.getText()
          break
      }

      // 首页资源位-顶部banner
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hp_place_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_hp_page',
        params: {
          place_type: placeType,
          place_name: '首页顶部banner位置',
          place_addr: index,
          place_detail: placeDetail,
          module_name: item.businessKey?.title
        }
      })
    },

    getEventInfo(item, index) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.HOME_PAGE_SOURCE_PLACE_EP.value,
        eventInfo: {
          place_type: xEnum.ENUM_ADVERTISE_URL_TYPE.toText(item.urlType),
          place_name: '首页顶部banner位置',
          place_addr: index
        }
      }
    }
  }
}
</script>
<style lang="less">
.com-home-swipe-container {
  .van-swipe__indicator {
    width: 12px;
    height: 3px;
    border-radius: 2px;
  }
}
</style>
<style lang="less" scoped>
.com-home-swipe-container {
  position: relative;

  .advert-bg {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .my-swipe {
    border-radius: 11px;
  }
}
</style>
