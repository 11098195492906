<template>
  <transition name="fade">
    <div v-if="visible" class="mask" @touchmove.prevent>
      <div class="dialog-content flex flex-column justify-center items-center">
        <img width="103.5" height="64.5" src="~base/assets/img/get_coupon_success.png" />
        <div class="title">领取成功</div>
        <div class="subtitle">正在跳转中...</div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { IconFont } from 'components'

// const screenWidth = window.screen.width
// const widthHeightRatio = 5 / 6

export default {
  name: 'GetCouponDialog',
  // components: { IconFont },
  props: {},
  data() {
    return {
      visible: true
    }
  },

  computed: {},
  created() {
    setTimeout(() => {
      this.hide()
      this.onClose()
    }, this.duration || 1000)
  },

  methods: {
    closeDialog() {
      this.visible = false
    },

    hide() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog-content {
    width: 160px;
    height: 160px;
    background-color: white;
    border-radius: 16px;

    .title {
      color: #333;
      font-size: 18px;
    }

    .subtitle {
      color: #888;
      font-size: 15px;
    }
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.5s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
}

.fade-leave {
  opacity: 1;
}
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}
.fade-leave-to {
  opacity: 0;
}
</style>
