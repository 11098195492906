<template>
  <div class="com-infinite-list" :style="{ paddingTop: list.length && isFromTextMessageUrl ? '33px' : '0' }">
    <slot name="header"></slot>
    <slot name="empty" v-if="(!list || list.length < 1) && !loading"></slot>
    <!-- <div v-if="list.length && isFromTextMessageUrl" class="focus-tip" @click="toPointCenter">
      <div class="flex items-center">
        <div class="mr1">去看看</div>
        <IconFont size="12px" icon="iconpageleft" color="#ff9090" />
      </div>
    </div> -->
    <virtual-list
      :item-style="{ width: '100%' }"
      wrap-class="virtual-list-wrap"
      :data-key="'orderId'"
      :data-sources="list"
      :data-component="itemComponent"
      :extra-props="{ onClickItem }"
      :keeps="list.length"
    />
    <slot name="loading" v-if="loading"></slot>
    <div class="center mt2">
      <div
        :class="`${styleMoreBtn || 'more-btn'} flex items-center justify-center`"
        :style="styleMoreBtn"
        v-show="hasMore"
        @click="loadMore(false)"
      >
        <span class="fs16 mr1">{{ moreText }}</span>
        <IconFont size="18px" icon="icondown" color="#333333"></IconFont>
      </div>
    </div>
  </div>
</template>

<script>
import { IconFont } from 'components'
import VirtualList from 'vue-virtual-scroll-list'
import { mapState } from 'vuex'
import { trackUserEvent } from 'base/utils/rp'
import xEnum from 'base/utils/enum'

export default {
  name: 'InfiniteList',
  components: { IconFont, VirtualList },
  props: {
    styleWrap: {
      type: Object,
      default: () => {}
    },

    itemComponent: {
      type: Object,
      default: () => <div></div>
    },

    loadMore: {
      type: Function,
      default: () => {}
    },

    list: {
      type: Array,
      default: () => []
    },

    pageSize: {
      type: Number,
      default: () => 10
    },

    hasMore: {
      type: Boolean,
      default: () => true
    },

    appending: {
      type: Boolean,
      default: () => false
    },

    recordScrollY: {
      type: Function,
      default: () => {}
    },

    styleMoreBtn: {
      type: Object,
      default: () => {}
    },

    loading: {
      type: Boolean,
      default: () => false
    },

    from: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      currPage: 1,
      isFromTextMessageUrl: false
    }
  },

  computed: {
    ...mapState({
      phoneCode: (state) => state.user.phoneCode,
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId
    }),

    moreText() {
      return '查看更多'
    }
  },

  created() {
    if (this.phoneCode) {
      this.isFromTextMessageUrl = true
    }
  },

  methods: {
    onClickItem(source) {
      this.$emit('click-item', source)
    },

    toPointCenter() {
      this.$router.push({ path: '/primary1', query: this.$route.query })
      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        webUrl: window.location.href
      }
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.MYORDER_TOPBANNER_TO_POINTCENTER.value,
        eventInfo
      })
    }
  }
}
</script>
<style lang="less">
.com-goods-list {
  .virtual-list-wrap {
    padding: 0 !important;
    margin: 0 auto;
    position: relative;
  }
}
</style>
<style lang="less" scoped>
.com-infinite-list {
  margin: 0 auto;

  .more-btn {
  }
}
.focus-tip {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 20px);
  height: 46px;
  background-image: url('https://img.bj0yx.com/892929373634560');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 10px;
  transform: translateY(calc(-100% + 14px));
  & > div {
    color: #ff9090;
    font-size: 14px;
    margin: -15px 10px 0 0;
    box-sizing: border-box;
  }
}
</style>
