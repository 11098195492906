<template>
  <div
    class="com-goods-price-banner-point flex flex-column justify-center"
    :style="{ 'background-image': `url(${renderBrandElement({ id: 'priceBanner.tencentAdBg', type: 'image' })})` }"
  >
    <div class="white fs12">{{ priceDesc }}</div>
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <div class="white mr2">
          <span class="fs16">￥</span><span class="fs23">{{ good.goodsDetailEx.originalPrice }}</span>
        </div>
        <div class="price-info flex items-center">
          <div class="point">{{ renderBrandElement({ id: 'priceBanner.couponDesc' }) }}</div>
          <div class="price">{{ `${Number(good.goodsDetailEx.price)}` }}</div>
        </div>
      </div>
      <div class="white fs16 bold mb1">{{ couponDesc }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import { ImageThemed, IconFont } from 'components'

const chinaMobilePIdMap = ['857699249553408', '857699265806336', '857699284680704']

export default {
  filters: {
    sails: function (value) {
      return _.replace(value, '兑换', '抢购')
    }
  },

  // components: { IconFont, ImageThemed },

  props: {
    good: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId
    }),

    couponDesc() {
      const flag = chinaMobilePIdMap.find((item) => {
        return item === this.pId
      })
      if (flag) {
        return '移动用户体验券'
      } else {
        return this.renderBrandElement({ id: 'headerInfo.couponDesc' })
      }
    },

    priceDesc() {
      return this.pId === '857471818137600' ? '指导价' : '原价'
    }
  },

  created() {},
  beforeDestroy() {},

  methods: {}
}
</script>
<style lang="less" scoped>
.com-goods-price-banner-point {
  height: 60px;
  line-height: 1;
  background-color: #e93829;
  padding: 0 12px;
  box-sizing: border-box;
  position: relative;
  background-size: no-repeat;
  background-size: 100% 100%;

  .price-info {
    background-color: white;
    padding: 6px;
    border-radius: 99px;
  }

  .hot {
    color: #fff;
  }
  .point {
    color: #ff252e;
  }
  .price-reduce {
    width: 35%;
    color: #fff;
    text-align: right;
    font-size: 12px;
  }
  .price {
    font-size: 15px;
    color: #ff252e;
  }

  .tag {
    background-color: #fff;
    font-size: 12px;
    color: #e93829;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    position: absolute;
    top: 13px;
  }

  .original-price {
    color: #fff;
    font-weight: 400;
  }
}
</style>
