import Vue from 'vue'
import PaySuccessfulDialog from './PaySuccessfulDialog'

const Customized = Vue.extend(PaySuccessfulDialog)

export const paySuccessfulDialog = (option) => {
  const instance = new Customized({
    data() {
      return {
        onClick: typeof option.onClick === 'function' ? option.onClick : () => {}
      }
    }
  }).$mount()
  // console.log(instance.$el);
  document.body.appendChild(instance.$el)
}
