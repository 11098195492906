<template>
  <div class="com-goods-image-gallery">
    <van-swipe
      ref="swipe"
      class="top-swipe"
      indicator-color="#f44"
      :autoplay="videoPlaying ? 0 : 3000"
      :touchable="!videoPlaying"
      :show-indicators="!videoPlaying"
    >
      <van-swipe-item @click="onClickArea('商品图轮播')" v-for="(image, index) in bannerImgList" :key="index">
        <van-image v-show="!videoPlaying" style="display: block" :src="image" :lazy-load="true" />
        <div class="video-controls" v-show="!videoPlaying && index == 0" v-if="videoUrl" @click="playVideo"></div>
        <tp-video
          ref="videoPlayer"
          class="video-player"
          v-if="videoUrl && index == 0"
          v-show="videoPlaying"
          :src="videoUrl"
          @on-player-play="videoPlaying = true"
          @on-player-pause="videoPlaying = false"
        >
        </tp-video>
        <div v-if="!videoPlaying && index == 0" style="position: relative">
          <img class="banner_float" src="~/base/assets/img/goodsDetail/bannerFloat2.png" />
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { trackEvent } from 'base/utils/rp'
const TpVideo = () => import('components/TpVideo')

export default {
  components: { TpVideo },
  props: {
    bannerImgList: {
      type: Array,
      default: () => []
    },

    videoUrl: {
      type: String,
      default: null
    },

    goodsDetail: {
      type: Object,
      default: () => {}
    },

    groupType: {
      type: Number,
      default: 19
    }
  },

  data() {
    return {
      videoPlaying: false,
      delayer: null
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    bannerImgList(newVal, oldVal) {
      if (newVal) {
        this.delayer = setTimeout(() => {
          this.$refs.swipe.resize()
        }, 500)
      }
    }
  },

  created() {},
  mounted() {},
  beforeDestroy() {
    if (this.delayer) {
      clearTimeout(this.delayer)
    }
  },

  methods: {
    onClickArea(name, action) {
      trackEvent({
        category: `点击-详情页-${name}`,
        action: action
      })
    },

    playVideo() {
      this.onClickArea('视频播放')
      this.$refs.videoPlayer[0].player.play()
    }

    // getEventInfo(index) {
    //   return {
    //     eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_PROFILE_TOP_PICTURE_EP.value,
    //     eventInfo: {
    //       pic_index: index,
    //       goodsId: this.goodsDetail.goodsId,
    //       goodsPrice: this.goodsDetail.price,
    //       goodsGroupId: this.groupType,
    //       goodsName: this.goodsDetail.goodsName,
    //       goodsCategoryId: this.goodsDetail.categoryId
    //     }
    //   }
    // }
  }
}
</script>
<style lang="less">
.com-goods-image-gallery {
  .video-controls {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 2.4rem;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .video-controls:before {
    position: absolute;
    top: 50%;
    left: 52%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: '';
    width: 0;
    height: 0;
    border-top: 0.48rem solid transparent;
    border-left: 0.8rem solid #fff;
    border-bottom: 0.48rem solid transparent;
  }
}
</style>
<style lang="less" scoped>
.com-goods-image-gallery {
  .banner_float {
    position: absolute;
    z-index: 2;
    bottom: 40px;
    width: 40%;
  }

  .top-swipe {
    height: 100vw;
  }
}
</style>
