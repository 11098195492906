<template>
  <div class="copy-right" :style="styleWrap">
    <p class="nogg" style="font-weight: 700; margin-bottom: 10px">@本活动解释权归{{ mall }}所有</p>
    <a href="https://beian.miit.gov.cn/" class="nogg block black" style="margin-bottom: 0px; padding-bottom: 10px">{{
      record
    }}</a>
    <p v-if="showProtocol" class="nogg" style="margin-bottom: 0px; padding-bottom: 10px" @click="toProtocol">
      《隐私协议》
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CopyRightThemed',
  props: {
    styleWrap: {
      type: Object,
      default: () => ({
        color: '#666'
      })
    },

    showProtocol: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      mall: this.renderBrandElement({ id: 'global.mall' }),
      record: this.renderBrandElement({ id: 'global.icp' })
    }
  },

  computed: {
    ...mapGetters({
      appState: 'appState'
    })
  },

  created() {},
  mounted() {},
  destroyed() {},

  methods: {
    toProtocol() {
      this.$router.push({ path: '/protocol' })
    }
  }
}
</script>
<style lang="less" scoped>
.copy-right {
  // margin-top: 10px;
  margin-top: 1.5rem;

  .nogg {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    font-size: 0.5rem;
  }
}
</style>
