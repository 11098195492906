<template>
  <div
    class="contact-card-container flex justify-between items-center bg-white rounded-large px2"
    :class="editable && !addressInvalid && 'readonly'"
  >
    <div v-if="editable && addressInvalid" class="py1">
      <van-form @submit="onSubmit">
        <van-field
          class="field"
          :error="false"
          v-model="form.receiverName"
          required
          label="收货人姓名"
          placeholder="收货人姓名"
          :rules="[{ required: true, message: '请输入收货人姓名' }]"
        />
        <van-field
          class="field"
          v-model="form.phone"
          required
          type="tel"
          label="收货人手机号码"
          placeholder="收货人手机号码"
          :rules="[
            { required: true, message: '请输入收货人手机号码' },
            { pattern: /^1[3-9][0-9]\d{8}$/, message: '手机号码格式错误' }
          ]"
        />
        <van-field
          class="field"
          readonly
          clickable
          required
          v-model="form.address"
          label="省市区县"
          autosize
          type="textarea"
          rows="1"
          placeholder="点击选择省市区县"
          :rules="[{ required: true, message: '请选择省市区县' }]"
          @click="areaPopup = true"
        />

        <van-field
          class="field"
          v-model="form.addressDetail"
          required
          label="详细地址"
          autosize
          type="textarea"
          rows="1"
          placeholder="小区楼栋/乡村名称"
          :rules="[{ required: true, message: '请输入小区楼栋/乡村名称' }]"
        />
      </van-form>
    </div>
    <div v-if="!addressInvalid" class="flex-auto py1_5">
      <div class="flex-auto">
        <div class="flex items-end">
          <div class="fs16 bold color_title">{{ receiverName }}</div>
          <div class="ml1 fs16 bold color_title">{{ phone }}</div>
        </div>
        <div class="mt1 fs14 text-ellipsis text-[#808080]">
          {{ `${addressProvince}${addressCity}${addressArea}${addressDetail}` }}
        </div>
      </div>
    </div>
    <div v-if="editable && !addressInvalid" @click="onEdit" class="flex-none">
      <IconFont size="24px" icon="iconbianji" color="#999999"></IconFont>
      <!-- <div class="center" style="width: 60px;">
      <div style="color: #4F81BC;">编辑</div>
    </div> -->
    </div>

    <van-overlay :show="showEditForm" @click="showEditForm = false">
      <div class="addressDiv" @click.stop>
        <div class="header pt3 px2">
          <div class="mb1 flex items-center justify-between">
            <div class="flex items-center">
              <div class="dot bg_primary mr1"></div>
              <div class="title fs17 color_black_222">修改收货地址</div>
            </div>
            <div
              class="flex items-center max-h-[21px] px-[12px] py-[3px] text-12 text-white bg-[#087EF4] rounded-[28px]"
              @click="importAlipayAddress"
              v-if="isInAliPay"
            >
              导入支付宝地址
            </div>
          </div>
          <div class="flex justify-between items-center" v-if="isInWechat" @click="importWxAddress">
            <div class="flex items-center">
              <van-image width="24" height="24" src="https://img.bj0yx.com/890935459708928" />
              <div class="ml1 title fs14 color_black_333">导入微信地址</div>
            </div>
            <IconFont size="12px" icon="iconpageleft" color="#333333"></IconFont>
          </div>
        </div>

        <van-form class="px1" @submit="onSubmit">
          <van-field
            class="field"
            v-model="form.receiverName"
            required
            label="收货人姓名"
            placeholder="收货人姓名"
            :rules="[{ required: true, message: '请输入收货人姓名' }]"
          />
          <van-field
            class="field"
            v-model="form.phone"
            required
            type="tel"
            label="手机号码"
            placeholder="收货人手机号码"
            :rules="[
              { required: true, message: '请输入收货人手机号码' },
              { pattern: /^1[3-9][0-9]\d{8}$/, message: '手机号码格式错误' }
            ]"
          />
          <van-field
            class="field"
            :class="{ 'express-fee-not-validated': isExpressFeeNotEqual }"
            readonly
            clickable
            required
            v-model="form.address"
            label="省市区县"
            autosize
            type="textarea"
            rows="1"
            placeholder="点击选择省市区县"
            :rules="[{ required: true, message: '请选择省市区县' }]"
            @click="areaPopup = true"
          />

          <van-field
            class="field"
            v-model="form.addressDetail"
            required
            label="详细地址"
            autosize
            type="textarea"
            rows="1"
            placeholder="详细地址"
            :rules="[{ required: true, message: '请输入详细地址' }]"
          />

          <div class="mt3"></div>

          <van-button
            class="btn bg_primary"
            round
            block
            type="danger"
            size="large"
            native-type="submit"
            :loading="isUpdateAddressLoading"
            loading-text="修改地址中..."
            >保存
          </van-button>
        </van-form>
      </div>
    </van-overlay>

    <van-popup v-model="areaPopup" position="bottom">
      <van-area title="选择省市区县" :area-list="areaList" @confirm="areaConfirmFn" @cancel="areaPopup = false" />
    </van-popup>
  </div>
</template>

<script>
import { updateAddress } from 'base/api'
import { mapMutations, mapState } from 'vuex'
import { IconFont } from 'components'
import areaList from 'base/utils/area'
import { Toast } from 'vant'
import { getAddressCode, waitFor } from 'base/utils'
import wechatSdk from 'base/utils/wechatJssdk'

export default {
  components: { IconFont },
  props: {
    editable: {
      type: Boolean,
      default: true
    },

    orderId: {
      type: String,
      default: null
    },

    receiverName: {
      type: String,
      default: '未填写'
    },

    phone: {
      type: String,
      default: '未填写'
    },

    addressProvince: {
      type: String,
      default: '未填写'
    },

    addressCity: {
      type: String,
      default: '未填写'
    },

    addressArea: {
      type: String,
      default: '未填写'
    },

    addressDetail: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      showEditForm: false,
      isUpdateAddressLoading: false,
      areaList: areaList,
      areaPopup: false,
      form: {},
      addressInvalid: false, //地址是否有效
      isExpressFeeNotEqual: false // 两个地址的运费是否相等，由后端判断
    }
  },

  computed: {
    ...mapState({
      isInAliPay: (state) => state.appState.isInAliPay,
      isInWechat: (state) => state.appState.isInWechat,
      wechatSdkReject: (state) => state.appState.wechatSdkReject
    }),

    pId() {
      return this.$store.state.appState.pId
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    addressDetail: function (newVal, oldVal) {
      this.checkAddressInvalid(newVal)
    },

    // eslint-disable-next-line no-unused-vars
    showEditForm: function (newVal, oldVal) {
      this.$emit('on-toggle-edit-form', newVal)
    },

    'form.address': function () {
      this.isExpressFeeNotEqual = false
    }
  },

  created() {
    this.checkAddressInvalid(this.addressDetail)
  },

  mounted() {},

  methods: {
    ...mapMutations({
      setkeySettlement: 'SETKEY_SETTLEMENT'
    }),

    initForm() {
      this.form = {
        receiverName: this.receiverName,
        phone: this.phone,
        addressProvince: this.addressProvince,
        addressCity: this.addressCity,
        addressArea: this.addressArea,
        address: `${this.addressProvince} ${this.addressCity} ${this.addressArea}`,
        addressDetail: this.addressDetail
      }
    },

    onEdit() {
      this.showEditForm = true
      this.initForm()
    },

    areaConfirmFn(values) {
      this.areaPopup = false
      this.areaVlues = values
      this.form.address = values.map((item) => item.name).join(' ')
      this.form.addressProvince = values[0].name
      this.form.addressCity = values[1].name
      this.form.addressArea = values[2].name
      this.form.addressCode = values[2].code
    },

    async checkContact() {
      return await this.onSubmit(null, true)
    },

    checkValidInFrontend() {
      const { receiverName, phone, addressProvince, addressCity, addressArea, addressDetail } = this.form
      return (
        receiverName &&
        phone &&
        addressProvince &&
        addressProvince !== '所属省份' &&
        addressCity &&
        addressCity !== '所属地级市' &&
        addressArea &&
        addressArea !== '所属区县' &&
        addressDetail
      )
    },

    async onSubmit(e, silent = false) {
      // console.log('contact card submit', this.orderId)
      // return true
      if (this.isUpdateAddressLoading) {
        return false
      }

      this.isUpdateAddressLoading = true

      if (
        !this.form.receiverName.trim() ||
        this.form.receiverName == '' ||
        !this.form.phone ||
        this.form.phone == '' ||
        !this.form.addressProvince ||
        this.form.addressProvince == '' ||
        this.form.addressProvince == '所属省份' ||
        !this.form.addressCity ||
        this.form.addressCity == '' ||
        this.form.addressCity == '所属地级市' ||
        !this.form.addressArea ||
        this.form.addressArea == '' ||
        this.form.addressArea == '所属区县' ||
        !this.form.addressDetail.trim() ||
        this.form.addressDetail == ''
      ) {
        this.isUpdateAddressLoading = false
        Toast('请完善收货信息哟~')
        return false
      }
      /* if (!this.form.receiverName || this.form.receiverName == "") {
        this.isUpdateAddressLoading=false;
        Toast.fail("收货人姓名填写错误，不能为空，也不可包含特殊字符")
        return false
      } */

      if (this.form.receiverName.length > 20) {
        this.isUpdateAddressLoading = false
        Toast('收货人姓名不能超过20个字符')
        return false
      }

      /* if (!this.form.phone || this.form.phone == "") {
        this.isUpdateAddressLoading=false;
        Toast.fail("手机号码不能为空")
        return false
      } */

      const re = /^1[3-9][0-9]\d{8}$/
      if (this.form.phone.length != 11 || !re.test(this.form.phone)) {
        this.isUpdateAddressLoading = false
        Toast('请填写有效的手机号码')
        return false
      }

      /* if (
        !this.form.addressProvince ||
        this.form.addressProvince == "" ||
        this.form.addressProvince == "所属省份"
      ) {
        this.isUpdateAddressLoading=false;
        Toast.fail("请选择所属省份")
        return false
      } */

      /* if (
        !this.form.addressCity ||
        this.form.addressCity == "" ||
        this.form.addressCity == "所属地级市"
      ) {
        this.isUpdateAddressLoading=false;
        Toast.fail("请选择所属地级市")
        return false
      } */

      /* if (
        !this.form.addressArea ||
        this.form.addressArea == "" ||
        this.form.addressArea == "所属区县"
      ) {
        this.isUpdateAddressLoading=false;
        Toast.fail("请选择所属区县")
        return false
      } */

      /* if (!this.form.addressDetail || this.form.addressDetail == "") {
        this.isUpdateAddressLoading=false;
        Toast.fail("您填写详细地址无效，不可为空，也不可包含特殊字符")
        return false
      } */

      if (this.form.addressDetail.length > 200) {
        this.isUpdateAddressLoading = false
        Toast('详细地址过长不能超过200个字符')
        return false
      }

      if (this.noEmoji(this.form.receiverName) == '') {
        this.isUpdateAddressLoading = false
        Toast('收货人姓名不能为特殊字符,请重新输入')
        return false
      }

      if (this.noEmoji(this.form.addressDetail) == '') {
        this.isUpdateAddressLoading = false
        Toast('详细地址不能为特殊字符,请重新输入')
        return false
      }

      if (this.noEmoji(this.form.receiverName).trim().length > 20) {
        this.isUpdateAddressLoading = false
        Toast('收货人姓名过长,请重新输入')
        return false
      }

      if (this.noEmoji(this.form.addressDetail).trim().length > 200) {
        this.isUpdateAddressLoading = false
        Toast('详细地址过长,请重新输入')
        return false
      }

      this.form.receiverName = this.noEmoji(this.form.receiverName).trim()
      this.form.addressDetail = this.noEmoji(this.form.addressDetail).trim()
      if (!this.form.addressCode) {
        this.form.addressCode =
          getAddressCode(this.form.addressProvince, this.form.addressCity, this.form.addressArea) || 0
      }

      try {
        const response = await updateAddress({
          ...this.form,
          orderId: this.orderId,
          projectId: this.pId
        })

        this.isUpdateAddressLoading = false
        if (response.code === 200) {
          this.cookie.set('receiverName', this.form.receiverName, { expires: 30 })
          this.cookie.set('phone', this.form.phone, { expires: 30 })
          this.cookie.set('addressProvince', this.form.addressProvince, { expires: 30 })
          this.cookie.set('addressCity', this.form.addressCity, { expires: 30 })
          this.cookie.set('addressArea', this.form.addressArea, { expires: 30 })
          this.cookie.set('addressDetail', this.form.addressDetail, { expires: 30 })
          if (this.form.addressCode) {
            this.cookie.set('addressCode', this.form.addressCode, { expires: 30 })
          }
          this.$emit('on-change', this.form)
          if (!silent) {
            Toast.success('地址提交成功')
          }
          this.showEditForm = false
          return true
        } else if (response.code === 501) {
          this.isExpressFeeNotEqual = true
          return false
        } else {
          Toast.fail('修改失败：' + response.message)
          return false
        }
      } catch (err) {
        this.isUpdateAddressLoading = false
      }
    },

    noEmoji(str, s) {
      if (!str) {
        return ''
      }

      const reg =
        /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (str.match(reg)) {
        return str.replace(reg, s || '')
      }
      return str
    },

    checkAddressInvalid(addressDetail) {
      if (!addressDetail || addressDetail === '') {
        console.log(addressDetail)
        this.addressInvalid = true
      } else {
        this.addressInvalid = false
      }
    },

    importWxAddress() {
      if (!this.isInWechat) return
      if (!this.wechatSdkReject) {
        Toast('暂未开放，请手动输入收货地址')
      } else {
        wechatSdk.openAddress().then(async (res) => {
          this.form = {
            receiverName: res?.userName,
            addressProvince: res?.provinceName,
            addressCity: res?.cityName,
            addressArea: res?.countryName,
            addressDetail: res?.addressDetailInfoNew,
            phone: res?.telNumber,
            address: `${res?.provinceName} ${res?.cityName} ${res?.countryName}`,
            addressCode: getAddressCode(res?.provinceName, res?.cityName, res?.countryName) || 0
          }
          await waitFor(500)
          if (!this.form.addressCode) {
            Toast.fail('请重新选择省市区')
            this.form.addressProvince = ''
            this.form.addressCity = ''
            this.form.addressArea = ''
            this.form.address = ''
            this.form.addressCode = 0
          }
        })
      }
    },

    importAlipayAddress() {
      // eslint-disable-next-line no-undef
      if (!am) {
        Toast('暂不支持导入，请手动输入收货地址')
      } else {
        // eslint-disable-next-line no-undef
        am.selectAddress((res) => {
          this.formatAlipayAddress(res)
        })
      }
    },

    formatAlipayAddress(data) {
      // this.form = Object.assign({}, this.form, {
      //   receiverName: data?.fullname,
      //   phone: data?.mobilePhone,
      //   addressProvince: data?.prov,
      //   addressCity: data?.city,
      //   addressArea: data?.area,
      //   addressCode: data?.addressCode,
      //   addressDetail: data?.address,
      //   address: `${data?.prov} ${data?.city} ${data?.area}`
      // })

      this.$set(this.form, 'receiverName', data?.fullname)
      this.$set(this.form, 'phone', data?.mobilePhone)
      this.$set(this.form, 'addressProvince', data?.prov)
      this.$set(this.form, 'addressCity', data?.city)
      this.$set(this.form, 'addressArea', data?.area)
      this.$set(this.form, 'addressCode', data?.addressCode)
      this.$set(this.form, 'addressDetail', data?.address)
      this.$set(this.form, 'address', `${data?.prov} ${data?.city} ${data?.area}`)
      this.$forceUpdate()

      // this.form.receiverName = data?.fullname
      // this.form.phone = data?.mobilePhone
      // this.form.addressProvince = data?.prov
      // this.form.addressCity = data?.city
      // this.form.addressArea = data?.area
      // this.form.addressCode = data?.addressCode
      // this.form.addressDetail = data?.address
      // this.form.address = `${data?.prov} ${data?.city} ${data?.area}`
    }
  }
}
</script>
<style lang="less">
.contact-card-container {
  .van-overlay {
    z-index: 999;
  }
}
</style>

<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';

.contact-card-container {
  position: relative;
  overflow: hidden;

  &.readonly::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: -webkit-repeating-linear-gradient(
      135deg,
      #ff6c6c 0,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #1989fa 0,
      #1989fa 45%,
      transparent 0,
      transparent 50%
    );
    background: repeating-linear-gradient(
      -45deg,
      #ff6c6c 0,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #1989fa 0,
      #1989fa 45%,
      transparent 0,
      transparent 50%
    );
    background-size: 80px;
    content: '';
  }

  .addressDiv {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 90%;
    background-color: #fff;
    border-radius: 10px;
    min-height: 200px;
    height: fit-content;
    padding: 0 10px 10px 10px;

    .header {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      position: relative;
      // background-color: #e0e0e0;

      .dot {
        .rem(width, 6px);
        .rem(height, 18px);
        .rem(border-radius, 3px);
      }

      .addressClose {
        position: absolute;
        right: 10px;
        top: 20px;
      }
      .addressClose img {
        width: 20px;
      }
    }

    .field {
      padding: 15px 20px;
    }

    .btn {
      margin: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      .rem(width, 240px);
      .rem(height, 40px);
      border: 0;
    }
  }
}
::v-deep .express-fee-not-validated {
  .van-field__value {
    position: relative;
    &::before {
      content: '暂不支持修改，新地址会导致运费变化';
      color: #ff6c6c;
      position: absolute;
      bottom: -16px;
      left: 0;
      text-align: end;
      width: max-content;
      font-size: 12px;
    }
  }
  .van-field__control {
    color: #ff6c6c;
  }
}
</style>
