<template>
  <div class="announce-bar bg-white mx2 br10">
    <div class="color_title">
      变动通知：尊敬的{{ brand }}商城会员，您账户累计的{{ globalPointName }}即将全部过期作废！
    </div>
    <div class="flex items-start justify-between mt1 bold">
      <div class="color_6c">账户余额：</div>
      <div class="content" style="color: #ff0000">{{ points.activePoints }}{{ globalPointName }}</div>
    </div>
    <div class="flex items-start justify-between mt1">
      <div class="color_6c">有效期：</div>
      <div class="content">24小时后失效</div>
    </div>
    <div class="flex items-start justify-between mt1">
      <div class="color_6c">92700{{ globalPointName }}：</div>
      <div class="content">扫地机器人、周六福宫铃同心手链</div>
    </div>
    <div class="flex items-start justify-between mt1">
      <div class="color_6c">71900{{ globalPointName }}：</div>
      <div class="content">8K无人机、护肤九件套</div>
    </div>
    <div class="flex items-start justify-between mt1">
      <div class="color_6c">49900{{ globalPointName }}：</div>
      <div class="content">6瓶王子酒、儿童定位智能手表</div>
    </div>
    <div class="flex items-start justify-between mt1">
      <div class="color_6c">29000{{ globalPointName }}：</div>
      <div class="content">夏季凉感藤席三件套、蚕丝空调被</div>
    </div>
    <div class="flex items-start justify-between mt1">
      <div class="color_6c">更多好礼：</div>
      <div class="content">
        五粮液、周六福、创维、希尔顿等大牌； 积分还可换购家电，数码，美妆，手表， 酒水等，还有更多大牌好礼↓↓↓
      </div>
    </div>
    <div class="flex justify-between items-center mt1" @click="toPointCenter">
      <div class="btn flex items-center justify-center detail">{{ globalPointName }}中心</div>
      <div class="btn flex items-center justify-center go">
        <span class="mx2">立即换购</span>
        <IconFont size="12px" icon="iconpageleft"></IconFont>
      </div>
    </div>
    <div @click="toPointCenter">
      <img src="https://img.bj0yx.com/888405348581376" alt="立即换购" class="my2 br10" />
    </div>
    <div class="lineBox">
      <div class="line"></div>
    </div>

    <div class="flex items-center justify-between" @click="toPointCenter">
      <span>查看详情</span>
      <IconFont icon="iconpageleft" color="#656565"></IconFont>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IconFont } from 'components'
// import { trackEvent, trackUserEvent } from 'base/utils/rp'

export default {
  components: {
    IconFont
  },

  data() {
    return {
      brand: this.renderBrandElement({ id: 'global.brand' })
    }
  },

  computed: {
    ...mapState({
      points: (state) => state.user.points
    })
  },

  methods: {
    toPointCenter() {
      this.$router.push({ path: '/primary' })
    }
  }
}
</script>

<style lang="less" scoped>
.lineBox {
  position: relative;
  .line {
    margin: 20px 12px;
    background-color: #f2f2f2;
    height: 2px;
  }
  .line:after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background: #f2f2f2;
    border-radius: 100%;
    right: -15px;
    top: 50%;
    transform: translate(50%, -50%);
  }

  .line:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background: #f2f2f2;
    border-radius: 100%;
    left: -15px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.announce-bar {
  padding: 15px;
}
.color_6c {
  color: #6c6d71;
}
// .mt12px {
//   margin-top: 12px;
// }
.content {
  width: 9.4rem;
  color: #1e1e1e;
}
.btn {
  width: 6.28rem;
  height: 46px;
  border-radius: 5px;
  font-size: 16px;
}
.detail {
  border: 1px solid #f32728;
  color: #f32728;
}
.go {
  background-color: #f32728;
  color: #fff;
}
</style>
