import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import appState from './modules/appState'
import buryState from './modules/bury'
import orderList from 'base/store/modules/orderList'
import statistics from 'base/store/modules/statistics'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    appState,
    orderList,
    statistics,
    buryState
  },
  getters
})

export default store
