<template>
  <van-dialog :show="visible" class="wx-subscribe-dialog" :show-confirm-button="false">
    <div class="panel py3">
      <p class="title mb1 fs16">{{ title }}</p>
      <van-image
        class="wechat-touch-identify"
        @click="previeWxPubImage"
        width="150"
        height="150"
        :src="pubImage"
      ></van-image>
      <p class="mt1" v-if="isInWechat">长按识别上方二维码关注公众号</p>
      <div v-else>
        <div class="mt1">
          复制公众号名称<b>「{{ pubName }}」</b>
        </div>
        <div class="mb2">在微信中搜索并关注</div>
        <div class="add-quick-btn bg_primary white fs14" @click="copyWxPubAndOpenWechat">复制并打开微信</div>
      </div>
    </div>
    <div class="close-btn" @click="close">
      <van-icon size="40" name="close" />
    </div>
  </van-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { eventBus } from 'base/utils/eventBus'
// import wechatSdk from 'base/utils/wechatJssdk'

export default {
  name: 'QrcodeGuideDialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },

    title: {
      type: String,
      default: () => '关注公众号'
    },

    pubName: {
      type: String,
      default: () => ''
    },

    pubImage: {
      type: String,
      default: () => ''
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      isInWechat: (state) => state.appState.isInWechat
    })
  },

  created() {},

  methods: {
    close() {
      this.$emit('update:visible', false)
    },

    previeWxPubImage() {
      // eslint-disable-next-line no-unused-vars
      const imageUrl = `${window.location.origin}${this.pubImage}`
      // wechatSdk.previewImage(imageUrl, [imageUrl])
    },

    copyWxPubAndOpenWechat() {
      eventBus.$emit('copy-to-clipboard', {
        content: this.pubName,
        silent: false,
        onSuccess: () => {
          console.log('copy success')
          location.href = 'weixin://'
        }
      })
    }
  }
}
</script>
<style lang="less">
.wx-subscribe-dialog {
  width: 260px;
  color: #85591f;
  background-color: rgba(0, 0, 0, 0);

  .van-dialog__header {
    padding: 15px !important;
    // font-size: 20px;
    // font-weight: 500;
    // color: #c22b2d;
  }
  .van-dialog__content {
    padding-bottom: 15px;
  }
  .panel {
    text-align: center;
    font-size: 13px;
    background-color: #fdf1d7;
    border-radius: 10px;

    .title {
      font-weight: 500;
    }

    .p {
      margin-bottom: 5px;
    }
  }

  .close-btn {
    text-align: center;
    margin: auto;
    margin-top: 20px;
    color: white;
  }

  .add-quick-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 35px;
    margin: auto;
    border-radius: 20px;
    text-align: center;
    // background-image: linear-gradient(-90deg, #FFE875, #FFD349);
    // color: #5A4B00;
    text-decoration: none;
  }
}
</style>

<style lang="less" scoped></style>
