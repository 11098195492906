<template>
  <div class="progress-container">
    <div class="progress-item" :style="{ width: percentage + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percentage: {
      // 当前进度（即进度条宽度）
      type: Number,
      default: 0
    }
  },

  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.progress-container {
  width: 100%;
  height: 15px;
  background-color: #fee5ba;
  border-radius: 15px;
  position: relative;
}
.progress-item {
  position: absolute;
  height: 15px;
  border-radius: 15px;
  background: linear-gradient(to right, #f84606, #ff9501);
}
</style>
