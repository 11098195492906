<template>
  <transition name="fade">
    <div v-if="visible" @click="clickItem" class="notify-con p2">
      <div class="header flex items-center justify-between">
        <div class="title flex items-center">
          <img class="logo" :src="logo" alt="" />
          <div class="fs14 ml1" style="color: #666666">消息通知</div>
        </div>
        <div class="fs13">现在</div>
      </div>
      <div class="fs15 mt2 content flex justify-between">
        <div class="desc flex items-center">
          <div>{{ returnDialogOption.content }}</div>
        </div>
        <div class="btn mt1 flex items-center justify-center white bg_primary">立即领取</div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from '@/store'

export default {
  name: 'PaySuccessfulDialog',
  data() {
    return {
      logo: this.renderBrandElement({ id: 'global.logo', type: 'image' }) || require(process.env.VUE_APP_GLOBAL_LOGO),
      visible: false,
      delayer: null
    }
  },

  computed: {
    returnDialogOption() {
      return store.state.appState.orderSuccessfulDialogOption
    }
  },

  mounted() {
    this.visible = true
    this.delayer = setTimeout(() => {
      this.hide()
    }, this.returnDialogOption.duration)
  },

  destroyed() {
    clearTimeout(this.delayer)
  },

  methods: {
    hide() {
      this.visible = false
    },

    clickItem() {
      this.hide()
      this.onClick()
    }
  }
}
</script>

<style lang="less" scoped>
.notify-con {
  width: 96%;
  height: 95px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 5px #999;
  position: fixed;
  top: 10px;
  left: 2%;
  .logo {
    width: 25px;
    height: 25px;
  }
  .content {
    .btn {
      width: 75px;
      height: 30px;
      border-radius: 15px;
    }
  }
  .desc {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.fade-enter {
  opacity: 0;
  top: -30px;
}
.fade-enter-active {
  transition: all 0.3s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
  top: 10px;
}

.fade-leave {
  opacity: 1;
  top: 10px;
}
.fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.fade-leave-to {
  opacity: 0;
  top: -30px;
}
</style>
