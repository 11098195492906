<template>
  <div class="com-goods-price-banner-point flex flex-column justify-center">
    <div class="white fs12">原价</div>
    <div class="flex items-center">
      <div class="white mr2">
        <span class="fs16">￥</span><span class="fs23">{{ good.goodsDetailEx.originalPrice }}</span>
      </div>
      <div class="price-info flex items-center">
        <div class="point">中奖券后价￥</div>
        <div class="price">{{ `${Number(good.goodsDetailEx.price)}` }}</div>
      </div>
    </div>
  </div>
</template>
<script>
// import { ImageThemed, IconFont } from 'components'

export default {
  filters: {
    sails: function (value) {
      return _.replace(value, '兑换', '抢购')
    }
  },

  // components: { IconFont, ImageThemed },

  props: {
    good: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  created() {},
  beforeDestroy() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.com-goods-price-banner-point {
  height: 60px;
  line-height: 1;
  background-color: #e93829;
  padding: 0 12px;
  box-sizing: border-box;
  position: relative;
  background-size: no-repeat;
  background-size: 100% 100%;
  background-image: url('~base/assets/img/goodsDetail/priceBanner_drawPrize.png');

  .price-info {
    background-color: white;
    padding: 6px;
    border-radius: 99px;
  }

  .hot {
    // width: 35%;
    // text-align: right;
    color: #fff;
  }
  .point {
    color: #ff252e;
    // margin-left: 45px;
  }
  .price-reduce {
    width: 35%;
    color: #fff;
    text-align: right;
    font-size: 12px;
  }
  .price {
    font-size: 15px;
    color: #ff252e;
  }

  .tag {
    // margin-bottom: -3px;
    background-color: #fff;
    font-size: 12px;
    color: #e93829;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    position: absolute;
    top: 13px;
  }

  .original-price {
    color: #fff;
    font-weight: 400;
  }
}
</style>
