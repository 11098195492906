<template>
  <div v-if="isShow" class="deal-recent mx2 px2 py2">
    <div
      v-if="activityType !== 'drawPrize' && activityType !== 'payOnDeliveryFreeReceive'"
      class="count-down-container flex justify-between items-center mb2"
    >
      <div>
        <div v-if="activityType === 'singleProduct'" class="bold fs20">用户评价</div>
        <div v-else class="count-down-container-title color_title bold">
          厂家直供，仅剩<span class="color_primary"> {{ goods.goodsDetailEx.stockNum }} </span>件
        </div>
      </div>
      <!-- <div
        v-if="activityType === 'payOnDelivery' || activityType === 'singleProduct'"
        class="flex fs15 mb2"
        style="color: #666666"
      >
        数量有限，先到先得
      </div> -->
      <div v-if="timeLimitSwitch" class="flex">
        <div class="flex items-center">
          <IconFont size="14px" icon="iconnaozhong" color="#df4b2e" class="mr1"></IconFont>
          <span class="mr1 title color_primary fs12">限时兑换</span>
        </div>
        <CountDown
          class="count-down"
          bg-color="#FFCCCC"
          colon-color="#E54635"
          font-color="#FFFFFF"
          :block-style="{ background: '#E54635', padding: '1px 3px' }"
          :colon-style="{ margin: '0 1px' }"
          :end-time="countDownEnd"
        />
      </div>
    </div>
    <div>
      <van-swipe
        ref="swipe"
        :show-indicators="false"
        :autoplay="3000"
        :vertical="true"
        :touchable="false"
        v-if="visible"
        style="width: 100%; margin: 0 auto; padding: 0 0"
        :style="{ height: activityType === 'singleProduct' ? '5.8rem' : '3.4rem' }"
      >
        <van-swipe-item v-for="(item, index) in sourceList" :key="index" style="height: auto">
          <div
            v-if="activityType === 'singleProduct'"
            class="com-deal-recent"
            style="height: 140px; margin-bottom: 5px"
          >
            <div style="font-size: 0.56rem" class="deep_black flex flex-column">
              <div class="flex items-center">
                <img :src="item[0].userLogo" class="userIcon mr2" alt="" />
                <span class="fs17 bold">{{ item[0].userName }}</span>
              </div>
              <div style="height: 35px" class="flex items-center">
                <span class="deal-news ml4 text-ellipsis fs15">{{ item[0].content }}</span>
              </div>
            </div>
            <div style="font-size: 0.56rem" class="deep_black flex flex-column">
              <div class="flex items-center">
                <img :src="item[1].userLogo" class="userIcon mr2" alt="" />
                <span class="fs17 bold">{{ item[1].userName }}</span>
              </div>
              <div style="height: 35px" class="flex items-center">
                <span class="deal-news ml4 text-ellipsis fs15">{{ item[1].content }}</span>
              </div>
            </div>
          </div>
          <div v-else class="com-deal-recent" style="height: 3.4rem">
            <div style="font-size: 0.56rem" class="deep_black flex justify-between items-center">
              <div class="flex items-center">
                <img :src="item[0].avatars" class="userIcon mr2 flex-none" alt="" />
                <div class="fs12">
                  <span class="deal-news">{{ item[0].desc }}{{ purchaseWord }}了该商品</span>
                  <span v-if="activityType !== 'drawPrize'">
                    ,省了{{ Number(goods.goodsDetailEx.originalPrice) - Number(goods.goodsDetailEx.price) }}元
                  </span>
                </div>
              </div>
              <div v-if="activityType !== 'drawPrize'" @click="toBuy" class="newestBuy fs14">
                去{{ purchaseWordSub }}
              </div>
            </div>
            <div style="font-size: 0.56rem" class="deep_black flex justify-between items-center mt2">
              <div class="flex items-center">
                <img :src="item[1].avatars" class="userIcon mr2 flex-none" alt="" />
                <div class="fs12">
                  <span class="deal-news">{{ item[1].desc }}{{ purchaseWord }}了该商品</span>
                  <span v-if="activityType !== 'drawPrize'">
                    ,省了{{ Number(goods.goodsDetailEx.originalPrice) - Number(goods.goodsDetailEx.price) }}元
                  </span>
                </div>
              </div>
              <div v-if="activityType !== 'drawPrize'" @click="toBuy" class="newestBuy fs14">
                去{{ purchaseWordSub }}
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import mock from 'base/utils/mock'
import { trackEvent, trackUserEvent } from 'base/utils/rp'
import { IconFont, CountDown } from 'components'
import xEnum from 'base/utils/enum'
import { mapState } from 'vuex'

export default {
  name: 'DealRecent',
  components: { IconFont, CountDown },
  props: {
    goods: {
      type: Object,
      default: () => {}
    },

    activityType: {
      type: String,
      default: () => 'point'
    },

    goodsCommentList: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      buyInfoList: [],
      visible: true,
      countDownEnd: dayjs().endOf('day'),
      delayer: null
    }
  },

  computed: {
    ...mapState({
      timeLimitSwitch: (state) => state.appState.goodsProfileTimeLimitSwitch
    }),

    isShow() {
      const id = this.renderBrandElement({ id: 'global.directDeal' }) // console.log(11111111111, id)
      if (id == 'false') return false
      else return true
    },

    purchaseWord() {
      if (this.activityType === 'point') {
        return this.globalPointName + this.globalExchangeWord
      } else if (this.activityType === 'drawPrize') {
        return `以原价￥${this.goods.goodsDetailEx.originalPrice}购买`
      } else if (this.activityType === 'payOnDelivery') {
        return '购买'
      } else {
        return '兑换'
      }
    },

    purchaseWordSub() {
      if (this.activityType === 'payOnDelivery') {
        return '使用'
      } else {
        return this.globalExchangeWord
      }
    },

    goodsCommentDescList() {
      return _.chunk(this.goodsCommentList, 2)
    },

    sourceList() {
      return this.activityType === 'singleProduct' ? this.goodsCommentDescList : this.buyInfoList
    },

    eventInfo() {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_HEAT_EP.value,
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_EXPOSE.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: this.goods.goodsDetailEx.goodsId,
          goodsPrice: this.goods.goodsDetailEx.price,
          score: this.goods.goodsDetailEx.score,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      }
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    buyInfoList(newVal, oldVal) {
      // if (newVal) {
      if (this.isShow && newVal) {
        this.delayer = setTimeout(() => {
          this.$refs.swipe.resize()
        }, 500)
      }
    }
  },

  created() {},
  mounted() {
    this.buyInfoList = _.chain(Array(10))
      .map(() => {
        const familyName = mock.familyNames[_.random(mock.familyNames.length - 1)]
        const phoneNumberStart = mock.phoneNumbersStart[_.random(mock.phoneNumbersStart.length - 1)]
        const phone = `${phoneNumberStart}****${_.random(1000, 9999)}`
        const second = _.random(1, 12)
        const userIcon = mock.avatars[_.random(mock.avatars.length - 1)]
        return {
          avatars: userIcon,
          desc: `${familyName}**（${phone}）在${second}分钟前`
        }
      })
      .chunk(2)
      .value()
  },

  destroyed() {
    if (this.delayer) {
      clearTimeout(this.delayer)
    }
  },

  methods: {
    toBuy() {
      trackEvent({
        category: `点击-详情页-去兑换按钮`,
        action: '总数'
      })
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_HEAT_BUY_CLICK.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: this.goods?.goodsId,
          goodsPrice: this.goods?.price,
          score: this.goods?.score,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      })
      this.$emit('click-buy')
    }
  }
}
</script>
<style lang="less" scoped>
.deal-recent {
  background-color: #fff;
  border-radius: 10px;
}
.com-deal-recent {
  .userIcon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  .newestBuy {
    width: 28%;
    padding: 3px 5px;
    text-align: center;
    background-color: #e54635;
    border-radius: 20px;
    box-sizing: border-box;
    color: #fff;
    align-self: center;
  }
  .deal-news {
    // display: inline-block;
    width: 80%;
    padding-right: 5px;
    box-sizing: border-box;
  }
  .count-down-container {
    // background-color: #bc121a;
    // border-radius: 5px;
    padding: 0 10px 10px 10px;
    margin-bottom: 0.6rem;
    box-sizing: border-box;
    &-title {
      font-weight: bold;
      font-size: 16px;
    }
    .title {
      font-size: 15px;
      font-family: 'PingFangSC-Medium', 'PingFang SC Medium', 'PingFang SC', sans-serif;
      font-weight: 500;
    }
  }
}
</style>
