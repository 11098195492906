<template>
  <div class="Layout">
    <!-- <FloatAdThemed /> -->
    <keep-alive>
      <router-view />
    </keep-alive>
    <div @click="openQuickApp" v-if="showBanner" class="add-to-desktop">
      <div class="flex items-center justify-start">
        <img class="logo" :src="imgLogo" />
        <div>点击添加图标到桌面，享百亿补贴!</div>
      </div>
      <div @click.prevent="onCloseBanner" class="close-btn">
        <img :src="imgClose" />
      </div>
    </div>
    <FootBarThemed v-if="showFootBar"></FootBarThemed>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import distribute from '@/utils/distribute'
import { FootBarThemed } from 'widgets'
import imgLogo from 'base/assets/img/logo.png'
import imgClose from 'base/assets/img/close.png'
import brand from 'base/brands'

export default {
  name: 'Layout',
  components: {
    FootBarThemed
  },

  data() {
    return {
      imgLogo,
      imgClose,
      quickPidPair: null
    }
  },

  computed: {
    ...mapGetters({
      appState: 'appState',
      userId: 'userId'
    }),

    ...mapState({
      boolOpen: (state) => state.appState.boolOpen
    }),

    showFootBar() {
      if (this.$route.path === '/centerPageA' || this.$route.path === '/centerPageB' || !this.boolOpen) return false
      if (brand.renderBrandElement({ id: 'global.showTabbar' }) !== true) return false
      return true
    },

    showBanner() {
      const appState = this.appState
      return !appState.isInQuickApp && !!this.quickPidPair && appState.isAudited && appState.bottomBannerVisible
    }
  },

  created() {
    const { pId } = this.appState
    // 底部跳转的白名单，使用直拉的白名单。
    const quickPidPair = distribute.isNeedBottomWakeQuickApp(pId)
    this.quickPidPair = quickPidPair
  },

  methods: {
    ...mapMutations({
      updateAppState: 'UPDATE_APP_STATE'
    }),

    async openQuickApp() {
      if (!this.quickPidPair) {
        return
      }
      distribute.openQuickAppAndAddToDesktop(`底部横幅-快应用`, this.userId, this.quickPidPair.LAND_PID)
    },

    onCloseBanner(e) {
      e.stopPropagation()
      this.updateAppState({
        key: 'bottomBannerVisible',
        value: false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.add-to-desktop {
  position: fixed;
  bottom: 70px;
  left: 8px;
  right: 8px;
  height: 40px;
  z-index: 1000;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 35px;
  // background-image: linear-gradient(180deg, #4AB3FF, #1A8EE9);
  background-image: linear-gradient(90deg, #4ab3ff, #1a8ee9);
  color: white;
  .logo {
    width: 35px;
    height: 35px;
    margin-right: 4px;
  }
  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border: 1px solid white;
    border-radius: 50%;
    img {
      width: 8px;
      height: 8px;
    }
  }
}
</style>
