<template>
  <div class="flex justify-between items-center px3">
    <!-- <IconTag icon="icontubiao-" text="假一赔十" size="12px" iconSize="16px" color="#A27F67"></IconTag>
    <IconTag icon="icontui" text="七天退换" size="12px" iconSize="16px" color="#A27F67"></IconTag>
    <IconTag icon="iconyou" text="全场包邮" size="12px" iconSize="16px" color="#A27F67"></IconTag>
    <IconTag icon="iconxiaoshi" text="24小时发货" size="12px" iconSize="16px" color="#A27F67"></IconTag> -->

    <IconTag
      v-for="item in sourceList"
      :key="item.text"
      :icon="item.iconClass"
      :text="item.text"
      :size="item.fontSize + 'px'"
      :icon-size="item.iconSize + 'px'"
      :color="item.color"
    />
  </div>
</template>

<script>
import { IconTag } from 'components'
export default {
  name: 'ServiceIntro',
  components: { IconTag },
  props: {
    sourceList: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {}
  }
}
</script>

<style lang="less" scoped></style>
