<template>
  <div
    @click="onClick"
    class="flex flex-col rounded-[4px] overflow-hidden bg-[#FFFFFF]"
    :style="{ ...itemStyle }"
    v-track:exposure
    :track-params="JSON.stringify(eventInfo)"
  >
    <img :src="source.imgUrl" style="aspect-ratio: 1/1" alt="" srcset="" />
    <div class="flex flex-col px-[10px] py-[5px] gap-[6px]">
      <div class="text-[14px] van-ellipsis">{{ source.goodsName }}</div>
      <!-- <div class="text-[12px] text-[#999999] line-through">￥{{ source.originalPrice }}</div> -->
    </div>
    <div class="flex px-[10px] pb-[10px]">
      <!-- <div class="py-[10px] bg-[#F6822E] text-white w-[60%] flex gap-[4px]">
        <div class="">
          <span class="text-[12px]">￥</span>
          <span class="text-[14px]">{{ source.price }}</span>
        </div>
        <div
          class="flex items-center justify-center min-w-[60px] rounded-[32px] bg-white text-[#F6652E] text-[10px] gap-[2px]"
        >
          <span class="">优惠</span>
          <span class="">{{ (source.originalPrice - source.price).toFixed(0) }}</span>
        </div>
      </div> -->
      <div class="py-[10px] bg-[#DE3317] text-white text-[14px] w-full font-semibold text-center rounded-[4px]">
        {{ btnText }}
      </div>
    </div>
  </div>
</template>

<script>
import { trackUserEvent, webfunnyTrack } from 'base/utils/rp'
// import { ImageThemed } from 'components'
import xEnum from 'base/utils/enum'
import { mapState } from 'vuex'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'
import utils from 'base/utils'

export default {
  name: 'GoodsNormalItem',
  props: {
    itemStyle: {
      type: Object,
      default: () => {}
    },

    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: () => 0
    },

    type: {
      type: Number,
      default: () => 1
    },

    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    onClickItem: {
      type: Function,
      default: () => {}
    },

    currGoodsId: {
      type: String,
      default: () => ''
    },

    btnText: {
      type: String,
      default: '免费领取'
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId,
      phone: (state) => state.user.phone,
      openid: (state) => state.user.openid,
      unionId: (state) => state.user.unionId,
      webFingerPrint: (state) => state.user.webFingerPrint,
      showGoodPrice: (state) => state.appState.showGoodPrice
    }),

    saleDesc() {
      return _.replace(this.source.saleDesc, '兑换热度', '兑换热度')
    },

    eventInfo() {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_CARD_EP.value,
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_EXPOSE.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: this.source.id,
          goodsPrice: this.source.price,
          score: this.source.score,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          index: (this.source.i + 1) * (this.source.j + 1) - 1,
          goodsName: this.source.goodsName,
          goodsIndex: `${this.source.j}`,
          goodsIndexVertical: `${this.source.i}`,
          goodsCategoryId: this.source.categoryId
        }
      }
    }
  },

  methods: {
    onClick() {
      const { source, index } = this
      if (this.currGoodsId === source.id) {
        utils.scroll('#descDetail', 300)
        return
      }
      this.$router.push({
        path: `/goodsProfile/${source.id}?type=${this.type}&activityType=payOnDelivery&pId=${this.pId}`
      })

      this.onClickItem(source, index)

      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        goodsName: source.goodsName,
        goodsPrice: this.source.price,
        score: this.source.score,
        goodsIndex: index,
        goodsGroupId: this.type,
        webUrl: window.location.href
      }
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_CARD_CLICK.value,
        eventInfo
      })

      webfunnyTrack({
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_CLICK.value,
        eventType: 'goodsClick',
        eventInfo
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: source?.score,
          goods_group_type: this.type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: `${this.source.j}`,
          goods_index_vertical: `${this.source.i}`,
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_source_waterfall: '商品瀑布流',
          goods_category_id: source.categoryId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';
.btn {
  width: 100%;
  height: 30px;
  border-radius: 6px;
  color: white;
  background: linear-gradient(to right, rgb(255, 36, 0), rgb(254, 160, 5));
  font-family: 'PingFangSC-Medium';
}
.container {
  width: 100%;
  display: inline-block;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  box-shadow: 0px 0px 6px rgba(128, 128, 128, 0.2);
  background: #fff;
  overflow: hidden;

  .cornerImg {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 0.72rem;
    width: auto;
  }

  .In_c_Img {
    display: block;
    width: 100%;
    .rem(height, 175px);
  }
  .dw_img {
    .rem(width, 95px);
    .rem(height, 95px);
  }
  .name {
    padding-top: 0.32rem;
    max-width: 160px;
    text-align: left;
  }
  .recommend-intro {
    max-width: 160px;
    color: #999999;
    .item {
      border-right: 1px solid;
    }
    .item:last-child {
      border-right: 0;
      padding-right: 0;
    }
    .item:first-child {
      padding-left: 0;
    }
  }

  .in_c_I_p {
    display: inline-block;
    vertical-align: middle;
  }

  .in_c_I_p1 {
    width: 100%;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
  .goods-info {
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .goods-desc {
      margin-top: 0.16rem;
      color: #808080;
      flex-direction: row-reverse;
      .sale-desc {
        margin-right: 0.16rem;
      }
      .original-price {
        margin-left: 0.16rem;
        text-decoration: line-through;
      }
    }
    .point {
      font-size: 16px;
    }
    .goods-tag {
      max-width: 165px;
      .item {
        height: 18px;
        line-height: 18px;
        box-sizing: border-box;
      }
      .item:last-child {
        margin-right: 0;
      }
      .tag1 {
        padding: 1px 4px 0 4px;
        background: rgba(229, 70, 53);
        margin-right: 4px;
      }
      .tag2 {
        padding: 0 4px;
        border: 1px solid rgba(229, 70, 53, 0.5);
      }
    }
    .goods-bottom {
      margin-top: 4px;
    }
  }
}
</style>
