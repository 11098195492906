<template>
  <div class="wrapper-con">
    <div class="more-purchase-card-con mx2 mt2 px-[10px] pt-[28px] pb-[10px] relative">
      <div v-if="goods.length > 1 && changeable" class="absolute flex items-center gap-1 right-[10px] top-[5px] z-10">
        <van-image width="12px" height="12px" :radius="4" src="https://img.bj0yx.com/892935502036992" fit="cover" />
        <div class="text-[12px] text-[#EB713A]" @click="changeCurrentIndex">换一换</div>
      </div>
      <div
        class="flex justify-between items-center"
        @click="openDetails"
        v-track:exposure
        :track-params="JSON.stringify(getEventInfo())"
        :key="`${goodsSpec.goodsId}_${goodsSpec.id}`"
      >
        <div class="flex gap-[10px]">
          <van-image width="65px" height="65px" :radius="4" :src="goodsSpec.goodsImg" fit="cover" />
          <div class="flex flex-col justify-between w-[200px]">
            <van-skeleton round :loading="false" :row="3" :row-width="[160, 130, 180]">
              <div class="text-[14px] text-[#5B1907] two-line-ellipsis">{{ goodsSpec.goodsName }}</div>
              <div class="text-[12px] text-[#BDA098]">规格：{{ goodsSpec.goodsSpecName }}</div>
              <div class="flex items-end">
                <span class="text-[14px] text-[#E54635]" v-if="isCostPoints"> {{ goodsSpec.costPoints }} 积分 + </span>
                <span class="text-[12px] text-[#E54635] mr-[10px]">{{ goodsSpec.salePrice }}元</span>
                <span class="text-[12px] text-[#BDA098] line-through">{{ goodsSpec.originalPrice }}元</span>
              </div>
            </van-skeleton>
          </div>
        </div>
        <div @click.stop>
          <van-checkbox :disabled="loading" v-model="selected" checked-color="#E54635" @change="selectChanged" />
        </div>
      </div>
    </div>
    <div class="detail-action-sheet">
      <van-action-sheet v-model="detailsShow" :closeable="false">
        <div
          class="details-wrapper flex flex-col items-center relative bg-[#f2f2f2] max-h-[80vh] pt-[15px] px-[10px] gap-[13px]"
        >
          <div class="title">
            <van-image width="78px" height="18px" src="https://img.bj0yx.com/892941160153089" fit="cover" />
          </div>
          <van-image
            width="30px"
            height="30px"
            src="https://img.bj0yx.com/892941160153088"
            fit="cover"
            class="absolute top-[10px] right-[10px]"
            @click="detailsShow = false"
          />
          <div class="details-con w-full flex flex-col pb-[20px] gap-[10px] max-h-[80vh] overflow-y-scroll">
            <img class="w-[calc(100vw - 20px)] rounded-[10px]" :src="goodsSpec.bannerImgList[0]" fit="cover" />
            <div class="flex flex-col justify-between gap-[4px] w-full bg-white rounded-[10px] p-[10px]">
              <div class="flex justify-between">
                <div class="flex items-end text-[#E54635]">
                  <span class="text-[18px]" v-if="isCostPoints">{{ goodsSpec.costPoints }}{{ pointsName }}</span>
                  <span class="text-[12px]" v-if="isCostPoints">+</span>
                  <span class="text-[12px]">{{ goodsSpec.salePrice }}元</span>
                </div>
                <div class="flex items-center gap-[2px]">
                  <van-image width="12px" height="12px" src="https://img.bj0yx.com/892941673955328" fit="cover" />
                  <div class="text-[12px] text-[#999999]">{{ goodsSpec.saleDesc }}</div>
                </div>
              </div>
              <div class="text-[14px] text-[#333333] font-bold two-line-ellipsis">{{ goodsSpec.goodsName }}</div>
              <div class="text-[14px] text-[#999999]">加购规格：{{ goodsSpec.goodsSpecName }}</div>
            </div>
            <div class="flex flex-col">
              <div class="flex justify-center text-[14px] text-[#4d4d4d] my-[10px]">— 商品详情 —</div>
              <img
                v-for="item in extractImgAttributes(goodsSpec.goodsDescription)"
                :key="item.src"
                :src="item.src"
                :style="item.style"
              />
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import { Skeleton, ActionSheet } from 'vant'
import xEnum from 'base/utils/enum'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'MorePurchaseCard',
  components: { [Skeleton.name]: Skeleton, [ActionSheet.name]: ActionSheet },
  props: {
    goods: {
      type: Array,
      default: () => []
    },

    changeable: {
      type: Boolean,
      default: false
    },

    mainProductInfo: {
      type: Object,
      default: () => {}
    },

    pointsName: {
      type: String,
      default: '积分'
    },

    orderId: {
      type: String,
      default: ''
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selected: false,
      detailsShow: false,
      currentIndex: 0,
      goodsEpArr: []
    }
  },

  computed: {
    isCostPoints() {
      // TODO
      return this.goods[this.currentIndex]?.costPoints > 0
    },

    goodsSpec() {
      return this.goods[this.currentIndex]
    }
  },

  created() {},

  mounted() {},
  methods: {
    lazyLoader(dom) {
      const regExp = new RegExp(/(<img )([^>]*)(src=")([^"]*")([^>]*)(>)/, 'g')
      dom = dom.replace(regExp, function (match, p1, p2, p3, p4, p5, p6) {
        return p1 + p2 + 'data-src="' + p4 + p5 + p6
      })
      return dom
    },

    openDetails() {
      this.detailsShow = true

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_orderconfirm_ssmgoods_clk',
        eventType: EventType.Click,
        pageName: 'ducuecmc_orderconfirm_page',
        params: {
          goods_category_id: this.goodsSpec?.categoryId,
          ssmsource_goods_id: this.mainProductInfo.goodsId,
          ssmsource_goods_name: this.mainProductInfo.goodsName,
          ssmsource_goods_category_id: this.mainProductInfo.goodsVo?.categoryId,
          ssmsource_goods_group_type: this.mainProductInfo.goodGroupType,
          ssmsource_display_price: this.mainProductInfo.price,
          ssmsource_display_original_price: this.mainProductInfo.originalPrice,
          ssmsource_sku_id: this.mainProductInfo.goodsSpecId,
          goods_name: this.goodsSpec.goodsName,
          goods_group_type: -1,
          order_id: this.orderId,
          goods_id: this.goodsSpec.goodsId,
          display_price: this.goodsSpec.salePrice,
          display_original_price: this.goodsSpec.originalPrice,
          sku_id: this.goodsSpec.id,
          sku_name: this.goodsSpec.goodsSpecName
        }
      })
    },

    selectChanged(value) {
      if (value) {
        this.$emit('selected', this.goodsSpec)
      } else {
        this.$emit('selected', null)
      }
    },

    changeCurrentIndex() {
      if (this.currentIndex === this.goods.length - 1) {
        this.currentIndex = 0
      } else {
        this.currentIndex++
      }
      this.selected = false
      this.$emit('selected', null)
    },

    extractImgAttributes(string) {
      const pattern = /<img\s+style="([^"]*)"\s+src="([^"]*)"\s*>/g
      const matches = string.match(pattern)
      const imgObjects = []
      if (matches) {
        for (let i = 0; i < matches.length; i++) {
          const match = matches[i]
          const style = match.match(/style="([^"]*)"/)[1]
          const src = match.match(/src="([^"]*)"/)[1]
          const imgObject = {
            src: src,
            style: style
          }
          imgObjects.push(imgObject)
        }
      }
      return imgObjects
    },

    clearSelected() {
      this.selected = null
      this.$emit('selected', null)
    },

    getEventInfo() {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.CONVENIENT_BUY_GOODS_EP.value,
        eventInfo: {
          ssmsource_goods_id: this.mainProductInfo.goodsId,
          ssmsource_goods_name: this.mainProductInfo.goodsName,
          ssmsource_goods_category_id: this.mainProductInfo.goodsVo.categoryId,
          ssmsource_goods_group_type: this.mainProductInfo.goodGroupType,
          ssmsource_display_price: this.mainProductInfo.price,
          ssmsource_display_original_price: this.mainProductInfo.originalPrice,
          ssmsource_sku_id: this.mainProductInfo.goodsSpecId,
          goodsName: this.goodsSpec.goodsName,
          goodsGroupId: -1,
          goodsCategoryId: this.goodsSpec.categoryId,
          orderId: this.orderId,
          goodsId: this.goodsSpec.goodsId,
          goodsPrice: this.goodsSpec.salePrice,
          display_original_price: this.goodsSpec.originalPrice,
          goodsSpecId: this.goodsSpec.id,
          skuName: this.goodsSpec.goodsSpecName
        }
      }
    }
  }
}
</script>

<style lang="less">
.more-purchase-card-con {
  .van-skeleton {
    padding: 0;
  }
  .van-skeleton__row:not(:first-child) {
    margin-top: 8px;
  }
}
</style>

<style lang="less" scoped>
.more-purchase-card-con {
  background: left top / 100px no-repeat url(https://img.bj0yx.com/896607065735168),
    linear-gradient(to bottom, #fff1e4 0%, #fffbfa 30%, #ffffff 100%);
  border-radius: 8px;
}
.rotate-refresh {
  transform: rotate(360deg);
  transition: all 0.8s ease-in-out;
  transform-origin: center center;
  animation-fill-mode: forwards;
}
.two-line-ellipsis {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
