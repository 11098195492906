<template>
  <div class="com-container">
    <img :class="imageClassName" :width="width" :height="height" :src="imageSrc" />
  </div>
</template>

<script>
export default {
  name: 'ImageThemed',
  props: {
    src: {
      type: String,
      default: () => null
    },

    width: {
      type: [Number, String],
      default: () => '100%'
    },

    height: {
      type: [Number, String],
      default: () => '100%'
    },

    imageClassName: {
      type: String,
      default: () => ''
    },

    imageId: {
      type: String,
      default: () => null
    }
  },

  data() {
    return {}
  },

  computed: {
    imageSrc() {
      if (this.imageId) {
        return this.renderBrandElement({ id: this.imageId, type: 'image' })
      } else {
        return this.src
      }
    }
  },

  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.com-container {
}
</style>
