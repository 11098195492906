<template>
  <div class="activity-area">
    <div class="base-info flex items-center">
      <div class="left-part flex flex-column flex-auto pl2 justify-center items-start bg-DF2E24">
        <div class="price flex items-end">
          ￥
          <div class="original-price flex items-center">{{ Number(good.goodsDetailEx.price) }}</div>
          <div class="final-price">￥{{ Number(good.goodsDetailEx.originalPrice) }}</div>
        </div>
        <div>下单全额返 支付金额等价返还</div>
      </div>
      <div class="right-part relative flex flex-column justify-center items-center bg-DF2E24">
        <img :src="imgHint" />
      </div>
    </div>
  </div>
</template>

<script>
import { trackEvent } from 'base/utils/rp'
// import { IconFont, CountDown } from 'components'
import imgHint from 'base/assets/img/returnScore/detailHint.jpg'

export default {
  name: 'ReturnScore',
  // components: { IconFont, CountDown },
  filters: {
    sails: function (value) {
      return _.replace(value, '兑换', '当前')
    }
  },

  props: {
    good: { type: Object, default: new Object() }
  },

  data() {
    return {
      imgHint
    }
  },

  computed: {},

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {
    onClickArea(name, action) {
      trackEvent({
        category: `点击-详情页-${name}`,
        action: action
      })
    }
  }
}
</script>
<style></style>
<style lang="less" scoped>
.base-info {
  height: 60px;
  color: white;
  font-size: 13px;
  .left-part {
    height: 100%;
    .price {
      height: 30px;
      .original-price {
        height: 30px;
        font-size: 26px;
      }
      .final-price {
        padding: 0 6px;
        text-decoration: line-through;
      }
    }
  }
  .right-part {
    height: 100%;
    width: 30%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.desc {
  font-size: 16px;
  padding: 9px;
  .hint {
    font-size: 14px;
    border-radius: 3px;
    padding: 1px 3px;
  }
}
.label {
  border: 1px solid #ff6a7e;
  border-radius: 3px;
  font-size: 12px;
  color: #ff6a7e;
}
.sales {
  font-size: 12px;
}
.third-line {
  margin: 9px 9px 0 9px;
  padding-bottom: 9px;
  color: #333333;
  font-size: 13px;
  border-bottom: 1px solid #eee;
  .activity-name {
    margin-left: 2px;
    margin-right: 10px;
    color: #dc0000;
  }
}
.stock {
  padding: 9px;
  font-size: 13px;
  .title {
    margin-right: 15px;
    color: #929292;
  }
  .buy-limit {
    padding: 1px 3px;
  }
}
</style>
