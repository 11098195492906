<template>
  <transition name="fade">
    <div v-if="visible" class="mask" @click="onClickBtn" @touchmove.prevent>
      <div class="dialog-content">
        <div class="text bold absolute" v-if="isPresentAgain">
          再次获得
          <span>{{ morePoints }}</span>
          分
        </div>
        <div class="text bold absolute" v-if="!isPresentAgain && !pointTemplateImg">
          获得<span>{{ activePoints }}</span>
          分
        </div>
        <img class="image" :src="dislogOptions.bgImage" />

        <div id="close" class="close-btn" @click="closeDialog">
          <van-icon size="30" name="close" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { IconFont } from 'components'
import brand from 'base/brands'
import store from '@/store'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

const screenWidth = window.screen.width
const widthHeightRatio = 5 / 6

export default {
  name: 'PresentPointDialog',
  // components: { IconFont },
  props: {},
  data() {
    return {
      visible: true,
      imageWidth: screenWidth * (300 / 320),
      imageHeight: (screenWidth * (300 / 320)) / widthHeightRatio,
      textOffset: 165 * (screenWidth / 320),
      fontSize: 16 * (screenWidth / 320),
      pointTemplateImg: this.renderBrandElement({ id: 'global.presentPointDialog', type: 'img' }),
      morePoints: store.state.appState.morePoints,
      activePoints: store.state.user.points.activePoints
    }
  },

  computed: {
    dislogOptions() {
      if (!this.isPresentAgain) {
        return {
          bgImage: this.pointTemplateImg || 'https://img.bj0yx.com/875173617598464'
        }
      } else {
        return {
          title: `恭喜再次获得${this.point}`,
          subTitle: `${this.pointName}可${this.globalExchangeWord}商品`,
          bgImage: 'https://img.bj0yx.com/875173617598464'
        }
      }
    },

    pointName() {
      try {
        return (
          this.$store.state.appState.themeConfig['global.pointName'] ||
          brand.renderBrandElement({ id: 'global.pointName' })
        )
      } catch (err) {
        console.log(err)
        return '积分'
      }
    }
  },

  created() {},
  methods: {
    closeDialog() {
      this.visible = false
      if (this.isPresentAgain) {
        this.onClick()
      }
    },

    hide() {
      this.visible = false
    },

    onClickBtn(e) {
      // 积分弹窗
      const getPointType = this.isPresentAgain ? '过期再次赠送积分' : '赠送积分'
      const pointValue = this.isPresentAgain ? this.morePoints : this.activePoints
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_points_windows_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          point_value: pointValue,
          get_point_type: getPointType
        }
      })

      // console.log(e)
      this.hide()

      // 点击关闭按钮不触发后续逻辑
      if (e.target.id !== 'close' && e.target.parentNode.id !== 'close') {
        this.onClick()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;

  .dialog-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 80px;
    .image {
      width: 100%;
    }
    .text {
      font-family: PingFang SC;
      font-size: 20px;
      color: #fff;
      left: 50%;
      transform: translate(-50%, 0);
      top: 80vw;
    }
    .point {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      color: #fefbe9;
    }

    .close-btn {
      text-align: center;
      margin: auto;
      color: white;
    }

    .close-dialog {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 5px;
    }
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.5s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
}

.fade-leave {
  opacity: 1;
}
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}
.fade-leave-to {
  opacity: 0;
}
</style>
