import dayjs from 'dayjs'
import { trackEvent } from 'base/utils/rp'
import { mapState } from 'vuex'
import { IconFont, CountDown, centerWineDialog, NoticeBar, BackTop } from 'components'
import xEnum from 'base/utils/enum'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

import {
  GuessYourFavorThemed,
  GoodsListThemed,
  VanGoodsListThemed,
  GoodsWaterfallList,
  PointNoticeMaskThemed,
  TabFilter,
  CopyRightThemed,
  ActivityFootBarThemed,
  GoodsGalleryThemed,
  DealBubbleThemed,
  SuspendShareThemed,
  PointCenterBanner
} from 'widgets'
import utils from 'base/utils'

// const GOODS_LIST_PAGE_SIZE = 20

export default {
  name: 'indexNew',
  components: {
    GoodsListThemed,
    VanGoodsListThemed,
    GoodsWaterfallList,
    IconFont,
    CountDown,
    PointNoticeMaskThemed,
    TabFilter,
    GuessYourFavorThemed,
    CopyRightThemed,
    ActivityFootBarThemed,
    GoodsGalleryThemed,
    DealBubbleThemed,
    SuspendShareThemed,
    PointCenterBanner,
    BackTop,
    NoticeBar
  },
  props: {
    enableActivityTabbar: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      xEnum,
      activeTabIndex: 0,
      countDownEnd: dayjs().endOf('day'),
      goodsList: [],
      goodsRateList: {},
      goodsReturnList: {},
      goodsLoveList: {},
      orderList: [],
      loading: false
    }
  },
  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId,
      mallNotice: (state) => state.appState.mallNotice,
      mallNoticeOpacity: (state) => state.appState.mallNoticeOpacity
    }),
    type() {
      return this.tabs[this.activeTabIndex].groupTypeId
    }
  },
  mounted() {
    if (!this.renderBrandElement({ id: 'global.enableCenterWineDialog', type: 'boolean' })) {
      return
    }

    const centerWineDialogIsShowed = window.localStorage.getItem('centerWineDialogIsShowed')
    if (!centerWineDialogIsShowed) {
      centerWineDialog({
        onClick: () => {
          this.$router.push({
            path: `/goodsProfile/856024558862336?type=15&activityType=timeLimit&%3Ftag=13654322345&pId=${this.pId}`
          })
        }
      })
    }
  },

  methods: {
    goodClick(goods, type, module, index) {
      this.$store.dispatch('enterGoodsProfile', {
        module,
        index,
        type,
        goods
      })

      this.$router.push({
        path: `/goodsProfile/${goods.id}`,
        query: {
          type: type
        }
      })
    },
    async onClickGoodsListMore() {
      try {
        await this.fetchGoodsList(this.tabs[0].groupTypeId)
        this.$refs.tabFilter.changeTab(0)
        this.activeTabIndex = 0
        utils.scroll('#stickyZone', 150)
      } catch (err) {
        console.log(err)
      }
    },
    toOrdersuccess(orderInfo) {
      this.$router.push({
        name: 'ordersuccess',
        query: { orderId: orderInfo.orderId },
        params: { from: 'index' }
      })
    },
    toCustomService() {
      trackEvent({
        category: '点击-jf中心-联系客服'
      })
      this.$router.push({
        path: '/customService1',
        query: {
          location: window.location.href
        }
      })
    },
    // getGoodsRateList() {
    //   let params = { pId: this.pId };
    //   params.type = xEnum.ENUM_GOODS_GROUP_TYPE.RATE_GROUP.value
    //   getGoodList(params).then((response) => {
    //     if (response.code === 200) {
    //       this.goodsRateList = response.data;
    //       let goodsList = [];
    //       response.data.goodsList.forEach((a, b) => {
    //         if (b < 9) {
    //           goodsList.push(a);
    //         }
    //       });
    //       this.goodsRateList.goodsList = goodsList;
    //     }
    //   });
    // },
    loadGoodsList(dataList) {
      dataList.forEach((item) => {
        this.goodsList.push(item)
      })
    },
    // async fetchGoodsList(type, pageIndex) {
    //   try{
    //     this.loading = true
    //     const response = await getPagingGoodsList({
    //       pId: this.pId,
    //       userId: this.userId,
    //       type,
    //       pageIndex,
    //       pageSize: GOODS_LIST_PAGE_SIZE,
    //     })
    //     this.goodsList = response.data ? response.data.list : []
    //     this.loading = false
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },

    // eslint-disable-next-line no-unused-vars
    onTabChange(index, pageIndex = 1, scrollToTop = true) {
      // 每次切换tab都要将finished和pageIndex重置
      if (this.$refs.waterfallGoodsList) {
        this.$refs.waterfallGoodsList.resetOnloadSetting()
      }

      this.activeTabIndex = index
      if (scrollToTop) {
        utils.scroll('#stickyZone', 150, 'body', 0)
      }

      // 积分页资源位-商品瀑布流tab
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_points_center_place_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_points_center_page',
        params: {
          place_type: `商品组标题：${this.tabs[index].title}`,
          place_name: '积分页商品瀑布流tab',
          place_addr: index,
          place_detail: this.tabs[index].groupTypeId
        }
      })
    },
    clickSearch() {
      this.$router.push({ path: '/goodsSearch' })
    }
  }
}
