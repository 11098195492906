import { render, staticRenderFns } from "./PurchaseCardCosmetic.vue?vue&type=template&id=2b7cc0ce&scoped=true&"
import script from "./PurchaseCardCosmetic.vue?vue&type=script&lang=js&"
export * from "./PurchaseCardCosmetic.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseCardCosmetic.vue?vue&type=style&index=0&id=2b7cc0ce&prod&lang=less&"
import style1 from "./PurchaseCardCosmetic.vue?vue&type=style&index=1&id=2b7cc0ce&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b7cc0ce",
  null
  
)

export default component.exports