<template>
  <div class="waterfall-container">
    <!-- 给组件一个最小高度来撑开页面，实现：在有tab分类的情况下，点击tab吸顶 -->
    <div class="" v-if="!loading">
      <waterfall
        ref="waterfall"
        :goods-list="goodsList"
        :finished="finished"
        @load-more="onLoad"
        style="min-height: 700px"
      >
        <template slot-scope="{ source }">
          <div
            class="adv-img"
            v-if="source?.img"
            @click="onClickAdv(source)"
            v-track:exposure
            :track-params="JSON.stringify(getEventInfo(source))"
          >
            <img :src="source?.img" alt="" />
          </div>
          <GoodsNormalItem
            v-else
            ref="goodsItem"
            :source="source"
            :type="type"
            :on-click-item="onClickItem"
            :page-source="pageSource"
            :item-style="{ width: 'calc((100% - 3%))' }"
          />
        </template>
      </waterfall>
      <!-- 下拉加载的loading -->
      <div class="flex justify-center items-center my2" v-show="pullLoading && !finished">
        <van-loading size="24px">商品加载中...</van-loading>
      </div>
    </div>
    <!-- 整个商品列表加载的loading -->
    <div v-else class="loading-container flex flex-column items-center">
      <img width="100" style="margin-top: 10vh" :src="renderBrandElement({ id: 'goodsList.loading', type: 'image' })" />
      <span class="loading-container-text" :style="styleLoadingText">更多超值商品加载中</span>
    </div>
    <div class="flex justify-center items-center mt2" v-show="finished" style="color: #969799">我也是有底线的喔~</div>
  </div>
</template>

<script>
import _ from 'lodash'
import { getPagingGoodsList } from 'base/api'
import xEnum from 'base/utils/enum'
import GoodsNormalItem from 'widgets/ListItem/GoodsNormalItem'
import Waterfall from 'widgets/Waterfall'
import utils from 'base/utils'
import { mapState } from 'vuex'
import { trackEvent } from 'base/utils/rp'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

const GOODS_LIST_PAGE_SIZE = 20

export default {
  components: { GoodsNormalItem, Waterfall },
  props: {
    goodsItem: {
      type: String,
      default: 'default'
    },

    name: {
      type: String,
      default: () => 'VanGoodsListThemed'
    },

    type: {
      type: Number,
      default: () => 1
    },

    pId: {
      type: String,
      default: () => ''
    },

    styleWrap: {
      type: Object,
      default: () => {}
    },

    styleLoadingText: {
      type: Object,
      default: () => {}
    },

    // goodsList 放在组件内部维护
    // goodsList: {
    //   type: Array,
    //   default: () => []
    // },
    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    // 推文页中只需读取前12个商品，此处添加参数
    goodsListPageSize: {
      type: Number,
      default: () => 20
    },

    isCopyPromotionArticle: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      scrollTop: true,
      pageIndex: 1,
      goodsList: [],
      // 下拉加载loading
      pullLoading: false,
      // 整个商品列表加载的loading 默认为true，刚进来加载商品时让loading显示出来
      loading: true,
      finished: false,
      // 是否开始无限滚动
      startInfinity: false,
      // 瀑布流有插入广告
      hasAdv: false,
      // 是否为第一页数据
      isFirstPage: false
    }
  },

  computed: {
    // 是否开启无限滚动
    isInfinityList() {
      return (
        (this.name === '首页tab分类' ||
          this.name === '首页推荐分类' ||
          this.name === '今日超值兑换-主题1' ||
          this.name === '猜你喜欢') &&
        this.renderBrandElement({ id: 'primary.goodsRepeat', type: 'boolean' })
      )
    },

    ...mapState({
      cornerImg: (state) => state.appState.cornerImg,
      userId: (state) => state.user.userId,
      feedAdvert: (state) => state.appState.feedAdvert
    })
  },

  watch: {},

  created() {
    this.goodsList = []
    this.onLoad()
  },

  methods: {
    renderCornerTags(goodsList) {
      // 根据当前商品组类型type找出对应的商品角标
      const cornerObj = this.cornerImg.find((item) => {
        return item.type == this.type
      })
      if (cornerObj && cornerObj.cornerImg) {
        // 对goodsList每一项扩展cornerImg属性
        goodsList.forEach((item) => {
          item.cornerImg = cornerObj.cornerImg
        })
      }
    },

    /**
     * 通过后台配置的 [显示序号]index，计算出该瀑布流广告应该插入到哪一页数据的哪一位
     * 用index除以pageSize，商即为哪一页，余数即为哪一位
     */
    computedPositionByAdvIndex(index) {
      const coordinates = utils.divisionCalculate(Number(index), GOODS_LIST_PAGE_SIZE)
      // 页数应该等于商数+1
      coordinates[0] += 1
      return coordinates
    },

    renderFeedAdvertisements(goodsList) {
      // 根据当前商品组类型type找出对应的瀑布流广告
      const feedAdv = this.feedAdvert.filter((item) => {
        return item?.businessKey.type == this.type
      })
      feedAdv.forEach((item) => {
        const advertisementsPosition = this.computedPositionByAdvIndex(item.businessKey.index)
        // 广告位于哪一页
        item.businessKey.insertPageIndex = advertisementsPosition[0]
        // 广告位于哪一个
        item.businessKey.insertItemIndex = advertisementsPosition[1]

        if (feedAdv.length !== 0 && goodsList.length !== 0) {
          // 若广告所携带的 insertPageIndex 与页码匹配则将其插入到商品列表对应位置
          if (this.pageIndex - 1 === item.businessKey.insertPageIndex) {
            goodsList.splice(item.businessKey.insertItemIndex - 1, 0, item)
          }
        }
      })
    },

    onLoad() {
      // type通过props传进来,有可能出现在调用onLoad时type还未更新的情况,所以通过定时器包裹
      const timer = setTimeout(async () => {
        if (!this.type || this.pullLoading) return

        this.isFirstPage = this.pageIndex === 1
        this.pullLoading = true

        if (this.isCopyPromotionArticle && this.pageIndex > 1) {
          this.pullLoading = false
          return
        }
        const resp = await getPagingGoodsList({
          pId: this.pId,
          userId: this.userId,
          type: this.type,
          pageIndex: this.pageIndex++,
          pageSize: this.goodsListPageSize
        })
        if (resp.code === 200) {
          let tempList = []
          if (this.name === '品牌专场' && this.isFirstPage) {
            tempList = _.drop(resp.data.list, 3)
          } else {
            tempList = resp.data.list
          }

          // 根据列表数据渲染角标
          this.renderCornerTags(tempList)

          // 只有在没有开始无限滚动 才去插入并渲染瀑布流广告位
          if (!this.startInfinity) {
            this.renderFeedAdvertisements(tempList)
          }

          if (this.startInfinity) {
            tempList = _.shuffle(tempList)
          }

          this.goodsList = tempList
          this.loading = false
          this.pullLoading = false
        }
        const totalPageNum = Math.ceil(resp.data?.total / GOODS_LIST_PAGE_SIZE)
        this.finished = totalPageNum === this.pageIndex - 1
        if (this.isInfinityList && this.finished) {
          this.startInfinity = true
          this.pageIndex = 1
          this.finished = false
        }
        clearTimeout(timer)
      }, 0)
      // console.log('loadmore')
    },

    scroll() {
      // console.log('scroll')
    },

    // eslint-disable-next-line no-unused-vars
    async resetOnloadSetting(isNeed = true) {
      this.goodsList = []
      this.finished = false
      this.startInfinity = false
      this.pageIndex = 1
      this.loading = true
      this.hasAdv = false
      // 每次切tab都要重置掉子组件内部记录已经渲染个数的变量
      this.$refs.waterfall?.restRenderCounts()
      this.onLoad()
    },

    onClickItem(source, index) {
      this.$store.dispatch('enterGoodsProfile', {
        module: this.name,
        index,
        type: this.type,
        goods: source
      })
      this.$emit('click-item', source, this.type)
    },

    async onClickAdv(source) {
      trackEvent({
        category: `点击-瀑布流-${source.type}广告图点击`
      })

      if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.value) {
        const mpScheme = await this.$store.dispatch('getMpUrlScheme', source.businessKey)
        this.reportEvent(source, '拉起小程序', source.businessKey)
        location.href = mpScheme
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.value) {
        this.reportEvent(
          source,
          '跳转活动页',
          JSON.stringify({ path: '/activity/brand', query: { pageId: source.businessKey.pageId } })
        )
        this.$router.push({ path: '/activity/brand', query: { pageId: source.businessKey.pageId } })
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.value) {
        this.reportEvent(source, '跳转详情页', {
          path: `/goodsProfile/${source.businessKey.goodsId}`,
          query: { type: source.businessKey.type }
        })
        this.$router.push({
          path: `/goodsProfile/${source.businessKey.goodsId}`,
          query: { type: source.businessKey.type }
        })
      } else {
        const isWebUrl = utils.isWebUrl(source.businessKey.path)
        if (isWebUrl) {
          this.reportEvent(source, '跳转外链', source.businessKey.path)
          window.location.href = source.businessKey.path
        } else {
          this.reportEvent(source, '跳转路由页', { path: source.businessKey.path })
          this.$router.push({ path: source.businessKey.path })
        }
      }
    },

    reportEvent(source, linkType, detail) {
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_advertise_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: source?.score,
          goods_group_type: this.type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: `${source.j}`,
          goods_index_vertical: `${source.i}`,
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_source_waterfall: '商品瀑布流',
          goods_category_id: source.categoryId,
          place_type: linkType,
          place_detail: detail
        }
      })
    },

    getEventInfo(source) {
      let placeType = ''
      let detail = ''
      if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.value) {
        placeType = '拉起小程序'
        detail = JSON.stringify(source.businessKey)
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.value) {
        placeType = '跳转活动页'
        detail = JSON.stringify({ path: '/activity/brand', query: { pageId: source.businessKey.pageId } })
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.value) {
        placeType = '跳转详情页'
        detail = JSON.stringify({
          path: `/goodsProfile/${source.businessKey.goodsId}`,
          query: { type: source.businessKey.type }
        })
      } else {
        const isWebUrl = utils.isWebUrl(source.businessKey.path)
        if (isWebUrl) {
          placeType = '跳转外链'
          detail = source.businessKey.path
        } else {
          placeType = '跳转路由页'
          detail = { path: source.businessKey.path }
        }
      }
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_ADV_EP.value,
        pageName: 'dacuecmc_all_page',
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: source.id,
          goodsPrice: source.price,
          score: source.score,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          index: (source.i + 1) * (source.j + 1) - 1,
          goodsName: source.goodsName,
          goodsIndex: `${source.j}`,
          goodsIndexVertical: `${source.i}`,
          goodsCategoryId: source.categoryId,
          place_type: placeType,
          place_detail: detail
        }
      }
    }
  }
}
</script>

<style lang="less">
.vue-waterfall-column {
  display: flex;
  flex-wrap: wrap;
  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }
}
</style>
<style lang="less" scoped>
.waterfall-container {
  .loading-container-text {
    // trick改法: 必须撑满一页，否则吸顶 sticky 的 tab 切换的时候，会自动往上滚动。
    padding-bottom: 80vh;
    color: #797979;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 25vh;
  }
  .adv-img {
    margin-bottom: 8px;
    img {
      width: calc(97%);
      height: auto;
      border-radius: 8px;
    }
  }
}
</style>
