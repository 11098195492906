<template>
  <div
    class="com-container flex flex-wrap m2"
    v-track:exposure
    :track-params="JSON.stringify(getEventInfo('首页瓷片位置'))"
  >
    <div
      v-for="(item, index) in tileData"
      :key="index"
      @click="tileClick(item, index)"
      class="col-6 activity"
      :class="item.class"
    >
      <div class="flex items-center">
        <div class="title fs15 mr1">{{ item.businessKey.title }}</div>
        <div class="meta">
          <div v-if="item.businessKey.title === entryPointTitle" class="point flex items-center px1">
            <IconFont
              v-if="renderBrandElement({ id: 'homeActivity.metaShowPoint' })"
              size="18px"
              icon="iconjifen2"
              color="white"
            ></IconFont>
            <div class="flex-none">
              {{ renderBrandElement({ id: 'homeActivity.metaText' })
              }}{{ renderBrandElement({ id: 'homeActivity.metaShowPoint' }) ? `${points.activePoints}` : '' }}
            </div>
          </div>
          <div v-if="item.businessKey.title === '限时秒杀'">
            <CountDown bg-color="#d92e21" colon-color="#d92e21" :end-time="countDownEnd"></CountDown>
          </div>
          <div v-if="item.businessKey.title === '限量免费送'" class="flex">
            <img height="18" src="~/base/assets/img/home_entrance/gift.png" />
          </div>
        </div>
      </div>
      <div class="subtitle mb2">{{ item.businessKey.subTitle }}</div>
      <div class="flex justify-between">
        <div class="image mr1">
          <img :src="item.img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IconFont, CountDown } from 'components'
import xEnum from 'base/utils/enum'
import utils from 'base/utils'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'HomeTileActivity',
  components: { IconFont, CountDown },
  props: {
    tileData: {
      type: Array,
      default: () => {}
    }
  },

  data() {
    return {
      entryPointTitle: this.renderBrandElement({ id: 'homeActivity.title' }),
      countDownEnd: dayjs().endOf('day')
    }
  },

  computed: {
    ...mapState({
      points: (state) => state.user.points,
      isInWechat: (state) => state.appState.isInWechat
    })
  },

  methods: {
    to(route) {
      this.$router.push(route)
    },

    async toMiniProgram(source) {
      const mpScheme = await this.$store.dispatch('getMpUrlScheme', source)
      location.href = mpScheme
    },

    tileClick(item, index) {
      let placeType = ''
      let placeDetail = ''
      const isWebUrl = utils.isWebUrl(item.businessKey.path)
      switch (item.urlType) {
        case xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.value:
          this.to({ path: `/activity/brand?pageId=${item.businessKey.pageId}` })
          placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.getText()
          placeDetail = item.businessKey.pageId
          break
        case xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.value:
          this.to({ path: `/goodsProfile/${item.businessKey.goodsId}`, query: { type: item.businessKey.type } })
          placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.getText()
          placeDetail = item.businessKey.goodsId
          break
        case xEnum.ENUM_ADVERTISE_URL_TYPE.LINK.value:
          if (isWebUrl) {
            window.location.href = item.businessKey.path
          } else {
            this.to({ path: item.businessKey.path })
          }
          placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.LINK.getText()
          placeDetail = item.businessKey.path
          break
        case xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.value:
          this.toMiniProgram(item.businessKey)
          placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.getText()
          placeDetail = item.businessKey
          break
        default:
          break
      }

      // 首页资源位-瓷片
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hp_place_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_hp_page',
        params: {
          place_type: placeType,
          place_name: '首页瓷片位置',
          place_addr: index,
          place_detail: placeDetail,
          module_name: item.businessKey?.title
        }
      })
    },

    getEventInfo(addr) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.HOME_PAGE_SOURCE_PLACE_EP.value,
        eventInfo: {
          place_type: '无',
          place_name: addr,
          place_addr: '无'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.com-container {
  background-color: white;
  border-radius: 12px;
  border: 1px solid #eeeeee;

  .activity {
    padding: 10px;
    box-sizing: border-box;
    border-color: #eeeeee;

    // border: 1px dashed #E8E8E8;
    &.border-bottom {
      border-bottom: 1px solid #eeeeee;
    }

    &.border-top {
      border-top: 1px solid #eeeeee;
    }

    &.border-right {
      border-right: 1px solid #eeeeee;
    }

    &.border-left {
      border-left: 1px solid #eeeeee;
    }

    .title {
      font-weight: 500;
    }

    .subtitle {
      color: #7b7b7b;
      font-size: 12px;
    }

    .meta {
      .live {
        width: 57px;
        border-radius: 20px;
        color: white;
        background: linear-gradient(90deg, #438eff 0%, #61bbfd 100%);
        font-size: 11px;
      }
    }

    .point {
      border-radius: 20px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      // background-color: #ca0000;
      background: linear-gradient(90deg, #fb7845 0%, #ffbb5b 100%);
    }

    .bargain {
      padding: 2px 15px;
      border-top-left-radius: 35px;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      background-color: #cc3a21;
    }

    .image {
      width: 100%;
      padding-bottom: 50%;
      position: relative;

      img {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        position: absolute;
      }
    }
    .live-pic {
      width: 100%;
      height: 17px;
      img {
        margin: 0 auto;
        width: 95%;
        height: 100%;
      }
    }
  }
}
</style>
