<template>
  <div @click="onClick" class="container">
    <van-image class="In_c_Img" :src="source.imgUrl" :lazy-load="true">
      <template v-slot:loading>
        <van-loading type="spinner" />
      </template>
    </van-image>
    <img v-if="source.stockNum <= 0" class="dw_img" src="~base/assets/img/dw.png" />
    <div class="goods-info flex flex-column pt2">
      <div class="name text-ellipsis">{{ source.goodsName }}</div>
      <div class="flex items-end">
        <div class="color_primary">
          <!-- <IconFont size="14px" icon="iconjifen" color="#FFA202"></IconFont> -->
          <span class="point">{{ source.costPoints }}{{ globalPointName }}</span>
          <span class="orginal-price">+{{ source.price }}元</span>
        </div>
        <!-- <div class="price">￥{{ source.originalPrice }}</div> -->
      </div>
      <!-- <div class="goods-info-price">
        <div class="sale-desc">{{ source.saleDesc }}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { IconFont } from 'components'
import xEnum from 'base/utils/enum'
import { Dialog } from 'vant'

export default {
  name: 'GoodsNormalItem',
  // components: { IconFont },
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: 0
    },

    type: {
      type: Number,
      default: 1
    },

    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    onClickItem: {
      type: Function,
      default: () => {}
    }
  },

  methods: {
    onClick() {
      const { source } = this

      if (source.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        })
        return
      }

      this.$store.dispatch('enterGoodsProfile', {
        module: '福利中心-jf换购',
        index: this.index,
        type: this.type,
        goods: this.source
      })
      this.$router.push({
        path: `/goodsProfile/${source.id}`,
        query: {
          type: this.type,
          pageSource: this.pageSource
        }
      })

      this.onClickItem(source)
    }
  }
}
</script>

<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';

.container {
  width: 100%;
  display: inline-block;
  // box-shadow: 0px 0px 4px 1px #e4e4e4;
  border-radius: 8px;
  position: relative;
  // padding-bottom: 10px;
  background: #fff;

  .In_c_Img {
    display: block;
    width: 100%;
  }

  .in_c_I_p {
    display: inline-block;
    vertical-align: middle;
  }

  .in_c_I_p1 {
    width: 100%;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
  .goods-info {
    // height: 85px;
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-left: 7px;
    padding-right: 7px;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .point {
      font-size: 12px;
    }

    .orginal-price {
      font-size: 12px;
    }

    .price {
      text-decoration: line-through;
      font-size: 12px;
      color: #888888;
    }
  }

  .goods-info-price {
    display: flex;
    justify-content: space-between;

    .price {
      font-size: 12px;
      color: #222;
      text-decoration: line-through;
    }

    .sale-desc {
      font-size: 12px;
      color: #ff3600;
    }
  }
}
</style>
