<template>
  <div class="activity-footbar-container">
    <van-tabbar
      v-model="footBarActive"
      :safe-area-inset-bottom="true"
      :active-color="activeColor"
      :inactive-color="inactiveColor"
      @change="onTabChange"
    >
      <van-tabbar-item
        v-for="(item, index) in tabbarList"
        :key="index"
        :to="{ path: item.path, query: $route.query }"
        :name="item.path"
        @click="onTabClick(item, index)"
      >
        <span>{{ item.text }}</span>
        <template #icon="">
          <!-- <img :src="props.active ? item.icon.active : item.icon.active" /> -->
          <IconFont size="27px" :icon="item.icon"></IconFont>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
import { trackEvent } from 'base/utils/rp'
import { IconFont } from 'components'
import utils from 'base/utils'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'ActivityFootBar',
  components: { [Tabbar.name]: Tabbar, [TabbarItem.name]: TabbarItem, IconFont },
  props: {
    // footBarActive: { type : Number, default: 0}
    /**
     *  {path: '/home', text: '首页', icon: 'iconshouye'},
     */
    midTabbar: {
      type: Object,
      default: () => {}
    },

    activeColor: {
      type: String,
      default: '#1989fa'
    },

    inactiveColor: {
      type: String,
      default: '#7d7e80'
    },

    labelColor: {
      type: String,
      default: () => '#ff2841'
    }
  },

  data() {
    return {
      footBarActive: this.$route.path,
      tabbarList: [
        { path: '/home', text: '首页', icon: 'iconshouye' },
        this.midTabbar,
        {
          path: '/primary',
          text: this.renderBrandElement({ id: 'primary.tabbarName' }),
          icon: this.renderBrandElement({ id: 'primary.tabbarIcon' })
        }
      ]
    }
  },

  created() {},
  updated() {
    this.footBarActive = this.$route.path
  },

  methods: {
    onTabChange(path) {
      const tabIndex = this.tabbarList.findIndex((tab) => tab.path === path)
      const tab = this.tabbarList[tabIndex]
      const tabName = tab && tab.text
      trackEvent({
        category: `点击-底部导航-${tabName}`
      })
    },

    onTabClick(item, index) {
      if (index === 1) {
        utils.scroll('body', 150)
      }

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_bottom_tabbar_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          tab_name: item.text,
          module_name: this.midTabbar.text
        }
      })
    }
  }
}
</script>

<style lang="less">
.activity-footbar-container {
  .van-hairline--top-bottom {
    z-index: 999;
  }
  .van-tabbar--fixed {
    padding: 5px 0px;
  }
  .van-tabbar-item__icon img {
    height: 27px;
  }
}
</style>
<style lang="less" scoped>
.activity-footbar-container {
  background: #eee;
}
</style>
