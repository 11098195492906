<template>
  <div class="com-goods-gallery mt2" v-if="goodsList.length">
    <van-swipe indicator-color="#D9D9D9" show-indicators loop width="100%">
      <van-swipe-item v-for="(goods, index) in goodsList" :key="index" class="grid grid-cols-3 gap-1 justify-between">
        <div
          class="flex justify-center goods-item"
          v-for="(goodsItem, itemIndex) in goods"
          :key="itemIndex"
          @click="goodClick(goodsItem, itemIndex, index)"
          v-track:exposure
          :track-params="JSON.stringify(getEventInfo(goodsItem, itemIndex, index))"
        >
          <div>
            <div class="relative flex justify-center items-center">
              <img :src="goodsItem.imgUrl" alt="" />
              <img v-if="goodsItem.stockNum <= 0" class="dw_img" src="~base/assets/img/dw.png" />
            </div>
            <div class="van-ellipsis fs14 mt1 goods-name">{{ goodsItem.goodsName }}</div>
            <div class="flex fs13 color_primary">{{ goodsItem.price }}元</div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
  <div v-else>暂无推荐</div>
</template>

<script>
import { mapState } from 'vuex'
import { Dialog } from 'vant'
import { getRecommendGoods } from 'base/api'
import xEnum from 'base/utils/enum'
import { trackUserEvent } from 'base/utils/rp'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  props: {
    goodsGroupType: {
      type: Number,
      default: xEnum.ENUM_GOODS_GROUP_TYPE.RATE_GROUP.getValue()
    }
  },

  data() {
    return {
      goodsList: [],
      itemType: this.renderBrandElement({ id: 'goodsGallery.itemType' }),
      recommendGoodsSource: {
        goodsId: '',
        goodsName: ''
      }
    }
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId
    })
  },

  created() {
    // this.reload()
  },

  methods: {
    goodClick(goods, index, swipeIndex) {
      if (goods.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        })
        return
      }
      this.$router.push({
        path: `/goodsProfile/${goods.id}`,
        query: {
          type: this.goodsGroupType,
          activityType: this.itemType
        }
      })

      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: goods.id,
        score: goods?.score,
        goodsIndex: index,
        goodsGroupId: this.goodsGroupType,
        webUrl: window.location.href
      }
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_RECOMMEND_CLICK.value,
        eventInfo
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: goods?.score,
          goods_group_type: this.goodsGroupType,
          goods_name: goods.goodsName,
          goods_id: goods.id,
          goods_index: index,
          display_price: goods.price,
          display_original_price: goods.originalPrice,
          goods_recommend_source: this.recommendGoodsSource.goodsId,
          goods_recommend_source_goods_name: this.recommendGoodsSource.goodsName,
          recommend_swiper_index: swipeIndex,
          goods_source_waterfall: '商品详情页-推荐',
          goods_category_id: goods.categoryId
        }
      })
    },

    reload(id, sourceGoodsName) {
      const params = {
        pId: this.pId,
        goodsId: id,
        userId: this.userId || null
      }
      this.recommendGoodsSource = {
        goodsId: id,
        goodsName: sourceGoodsName
      }
      getRecommendGoods(params).then((response) => {
        if (response.code === 200) {
          this.goodsList = _.chain(response.data).slice(0, 12).chunk(6).value()
        }
        if (this.goodsList.length) {
          this.$emit('show-goods-gallery', true)
        }
      })
    },

    getEventInfo(goods, index, swipeIndex) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_RECOMMEND_CARD_EP.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: goods.id,
          score: goods?.score,
          goodsIndex: index,
          goodsGroupId: this.goodsGroupType,
          webUrl: window.location.href,
          goodsPrice: goods.price,
          goodsName: goods.goodsName,
          goodsRecommendSource: this.recommendGoodsSource.goodsId,
          goodsRecommendSourceGoodsName: this.recommendGoodsSource.goodsName,
          recommendSwipeIndex: swipeIndex,
          goodsCategoryId: goods.categoryId
        }
      }
    }
  }
}
</script>

<style lang="less">
.com-goods-gallery {
  .van-swipe__indicators {
    bottom: 0;
  }
  .dw_img {
    width: 70px;
    height: 70px;
    top: unset !important;
    bottom: unset !important;
  }
}
</style>
<style lang="less" scoped>
.goods-item {
  & img {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 5px;
  }
  .goods-name {
    max-width: 100px;
    text-align: left;
  }
}
.com-goods-gallery {
  /*排行榜*/

  .P_ul_li {
    width: 93.5%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    margin: 10px auto 0;
    border-radius: 5px;
    font-size: 0;
    background-color: #fff;
  }

  .In_c_r_Img1 {
    width: 20%;
    position: absolute;
    top: 0;
    left: 5px;
    z-index: 1;
  }

  .In_c_r_p {
    color: #555;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .In_c_r_p1 {
    color: #d92e21;
  }
  .In_c_r_p2 {
    font-size: 0.6rem;
  }
  .In_c_r_p3 {
    font-size: 0.5rem;
  }
  .In_c_r_p4 {
    color: #666666;
  }
}
</style>
