<template>
  <div
    class="time-limit-item flex p2"
    @click="onClick"
    v-track:exposure
    :track-params="JSON.stringify(getEventInfo(source, index))"
  >
    <van-image class="left-part flex-none" :src="source.imgUrl" :lazy-load="true">
      <template v-slot:loading>
        <van-loading type="spinner" />
      </template>
    </van-image>
    <div class="hint absolute">等价全额返还</div>
    <div class="right-part flex flex-column justify-between flex-auto ml2">
      <div class="description">{{ source.goodsName }}</div>
      <div class="sales flex items-center">
        <img class="icon" src="~base/assets/img/returnScore/flame.png" />
        {{ source.saleDesc | sails }}
      </div>
      <div class="bottom-part flex justify-end">
        <div
          class="buy-btn flex items-center px2 py1 white animate__animated animate__infinite animate__pulse animate__fast"
        >
          等价全返<span style="text-decoration: line-through">￥{{ Number(source.price).toFixed(2) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import xEnum from 'base/utils/enum'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'GoodsReturnScoreItem',
  filters: {
    sails: function (value) {
      value = _.replace(value, '兑换', '当前')
      return value
    }
  },

  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: () => 0
    },

    type: {
      type: Number,
      default: () => 1
    },

    onClickItem: {
      type: Function,
      default: () => {}
    }
  },

  methods: {
    onClick() {
      const { source, index } = this

      if (source.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        })
        return
      }

      this.$router.push({
        path: `/goodsProfile/${source.id}`,
        query: {
          type: this.type,
          activityType: 'returnScore'
        }
      })

      this.onClickItem(source, index)

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: source?.score,
          goods_group_type: this.type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: index,
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_source_waterfall: '等价全额返',
          goods_category_id: source.categoryId
        }
      })
    },

    getEventInfo(goods, index) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_RETURN_SCORE_LIST_EP.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: goods.id,
          score: goods?.score,
          goodsIndex: index,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          goodsPrice: goods.price,
          goodsName: goods.goodsName,
          goodsCategoryId: goods.categoryId
        }
      }
    }
  }
}
</script>

<style lang="less">
.time-limit-item {
  .left-part {
    img {
      border-radius: 6px;
    }
  }
}
</style>
<style lang="less" scoped>
@-webkit-keyframes mySlideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }
}
@keyframes mySlideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }
}
.time-limit-item {
  position: relative;
  font-size: 14px;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  .left-part {
    width: 120px;
    height: 120px;
  }
  .hint {
    padding: 2px 5px;
    bottom: var(--space-2);
    left: var(--space-2);
    border-radius: 0 6px 0 0;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    background-color: #dc1a21;
  }
  .right-part {
    .description {
      font-size: 16px;
      // margin-bottom: 7px;
      color: #000;
      font-weight: 400;
      letter-spacing: 1px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .sales {
      position: relative;
      width: fit-content;
      padding: 2px 6px 2px 24px;
      border-radius: 10px;
      background-color: #dc1a21;
      color: white;
      font-size: 12px;
      .icon {
        position: absolute;
        left: -2px;
        bottom: -1px;
        width: 24px;
        height: 30px;
        color: #dc1a21;
      }
    }
    .bottom-part {
      font-size: 12px;
      .buy-price {
        font-size: 20px;
      }
      .buy-btn {
        font-size: 14px;
        line-height: 18px;
        border-radius: 15px;
        background: #dc1a21;
      }
    }
  }
}
</style>
