<template>
  <div class="com-goods-list" :style="styleWrap">
    <slot name="header"></slot>
    <div v-if="_loading" class="loading-container flex flex-column items-center">
      <!-- <van-loading class="loading-container-block" type="spinner" style="margin-top:15px;"/> -->
      <img width="100" style="margin-top: 10vh" :src="renderBrandElement({ id: 'goodsList.loading', type: 'image' })" />
      <span class="loading-container-text" :style="styleLoadingText">更多超值商品加载中</span>
    </div>
    <div v-if="!_loading">
      <div v-if="useVirtualList">
        <virtual-list
          :item-class="cellPerRow !== 1 ? 'In_c_div' : ''"
          :item-style="{ width: cellPerRow === 1 ? '100%' : `calc((100% - 3%) / ${cellPerRow})`, ...itemStyle }"
          wrap-class="virtual-list-wrap"
          :data-key="dataKey"
          :data-sources="goodsList"
          :data-component="goodsItem"
          :extra-props="{ onClickItem, pId, type, pageSource, keyWords, showRankTag }"
          :keeps="goodsList.length"
        />
      </div>
      <div v-else>
        <DirectRenderItem
          v-for="item in goodsList"
          :key="item[dataKey]"
          :source="item"
          :data-component="goodsItem"
          :extra-props="{ onClickItem, pId, type, pageSource, keyWords }"
          :class="cellPerRow !== 1 ? 'In_c_div' : ''"
          :style="{ width: cellPerRow === 1 ? '100%' : `calc((100% - 3%) / ${cellPerRow})`, ...itemStyle }"
        >
        </DirectRenderItem>
      </div>
      <div
        v-show="goodsList.length % 2 && cellPerRow !== 1 && showPlaceholderItem"
        @click="onClickMore"
        class="more-goods"
        :style="{ 'background-image': `url(${renderBrandElement({ id: 'goodsList.moreItemImg', type: 'image' })})` }"
      ></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// import { IconFont } from 'components'
import GoodsNormalItem from 'widgets/ListItem/GoodsNormalItem'
import VirtualList from 'vue-virtual-scroll-list'
import xEnum from 'base/utils/enum'

const DirectRenderItem = Vue.component('DirectRenderItem', {
  functional: true,
  // eslint-disable-next-line vue/require-prop-types
  props: ['key', 'source', 'dataComponent', 'extraProps', 'class', 'style'],
  render(createElement, context) {
    // context.props = {
    //   ...context.props,
    //   ...context.props.extraProps,
    //   ...context.data,
    //   style: context.props.itemStyle,
    //   class: context.props.itemClass,
    // }
    return createElement(context.props.dataComponent, context, context.props.children)
  }
})

export default {
  name: 'GoodsListThemed',
  components: { VirtualList, DirectRenderItem },
  props: {
    itemStyle: {
      type: Object,
      default: () => {}
    },

    dataKey: {
      type: String,
      default: () => 'id'
    },

    name: {
      type: String,
      default: () => 'GoodsListThemed'
    },

    type: {
      type: Number,
      default: () => 1
    },

    pId: {
      type: String,
      default: () => ''
    },

    styleWrap: {
      type: Object,
      default: () => {}
    },

    styleLoadingText: {
      type: Object,
      default: () => {}
    },

    goodsList: {
      type: Array,
      default: () => []
    },

    goodsItem: {
      type: Object,
      default: () => GoodsNormalItem
    },

    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    // 列表每行有多少个单元
    cellPerRow: {
      type: Number,
      default: () => 2
    },

    loading: {
      type: Boolean,
      default: false
    },

    keyWords: {
      type: String,
      default: () => ''
    },

    showRankTag: {
      type: Boolean,
      default: () => false
    },

    useVirtualList: {
      type: Boolean,
      default: true
    },

    showPlaceholderItem: {
      type: Boolean,
      default: () => true
    }
  },

  data() {
    return {
      scrollTop: true
    }
  },

  computed: {
    _loading() {
      // 不能用 !this.goodsList判断，因为this.goodsList外面传进来的值默认永远为[]
      // return !this.goodsList || this.loading
      return this.loading
    }
  },

  created() {},
  destroyed() {
    clearTimeout(this.delayer)
  },

  methods: {
    onClickItem(source, index) {
      this.$store.dispatch('enterGoodsProfile', {
        module: this.name,
        index,
        type: this.type,
        goods: source
      })

      this.$emit('click-item', source, this.type)
    },

    onClickMore() {
      const path = this.renderBrandElement({ id: 'goodsList.moreItemPath' })
      if (this.$route.path !== path) {
        this.$router.push({ path, params: { scrollTop: this.scrollTop } })
      }

      this.$emit('click-more')
    }
  }
}
</script>
<style lang="less">
.com-goods-list {
  .loading-container {
    .loading-container-block {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 25vh;
    }
    .loading-container-text {
      // trick改法: 必须撑满一页，否则吸顶 sticky 的 tab 切换的时候，会自动往上滚动。
      padding-bottom: 80vh;
      color: #797979;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 25vh;
    }
  }
  .virtual-list-wrap {
    padding: 0 !important;
    margin: 0 auto;
  }

  .In_c_div {
    display: inline-block;
    vertical-align: top;
  }

  .In_c_div:nth-child(odd) {
    margin-right: 3%;
  }
}
</style>
<style lang="less" scoped>
.com-goods-list {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  .more-goods {
    // 85px + 5px的margin top
    @goodsInfoHeight: 119px;
    width: 48.5%;
    height: calc(48.5vw * 0.95 + @goodsInfoHeight);
    display: inline-block;
    // box-shadow: 0px 0px 4px 1px #e4e4e4;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    margin-bottom: 8px;
    position: absolute;
    right: 0;
    bottom: 0;
    padding-bottom: 10px;
    background: #fff;
    text-align: center;
    color: #7f7f7f;
    box-sizing: border-box;
    background-size: 100% 100%;
    p:nth-child(2) {
      margin-top: 25px;
    }

    // background-size: cover;
  }
}
</style>
