<template>
  <div @click="clearMask" v-if="visible" class="wx-mask">
    <img :src="shareImage" alt="" />
  </div>
</template>

<script>
export default {
  name: 'WechatShareMask',
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },

    shareImage: {
      type: String,
      default: () => require('base/assets/img/wxShareMask.png')
    }
  },

  data() {
    return {}
  },

  methods: {
    clearMask() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="less" scoped>
.wx-mask {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  color: #fff;
  img {
    width: 100%;
  }
}
</style>
