<template>
  <video-player
    class="video-player-box"
    ref="videoPlayer"
    :options="playerOptions"
    :playsinline="true"
    custom-event-name="customstatechangedeventname"
    @play="onPlayerPlay($event)"
    @pause="onPlayerPause($event)"
    @ended="onPlayerEnded($event)"
    @waiting="onPlayerWaiting($event)"
    @playing="onPlayerPlaying($event)"
    @loadeddata="onPlayerLoadeddata($event)"
    @timeupdate="onPlayerTimeupdate($event)"
    @canplay="onPlayerCanplay($event)"
    @canplaythrough="onPlayerCanplaythrough($event)"
    @statechanged="playerStateChanged($event)"
    @ready="playerReadied"
  >
  </video-player>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
// Similarly, you can also introduce the plugin resource pack you want to use within the component
// import 'some-videojs-plugin'
export default {
  components: { VideoPlayer: videoPlayer },
  props: {
    src: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'video/mp4'
    },

    poster: {
      type: String,
      default: ''
    },

    // https://docs.videojs.com/tutorial-options.html#width
    width: {
      type: Number,
      default: window.innerWidth
    },

    height: {
      type: Number,
      default: window.innerWidth
    },

    autoplay: {
      type: Boolean,
      default: false
    },

    controls: {
      type: Boolean,
      default: true
    },

    muted: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },

    playerOptions() {
      return {
        // videojs options
        muted: this.muted,
        // language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: this.type,
            src: this.src
          }
        ],

        poster: this.poster,
        width: this.width,
        height: this.height,
        autoplay: this.autoplay,
        controls: this.controls
      }
    }
  },

  mounted() {},

  methods: {
    // listen event
    onPlayerPlay(player) {
      this.$emit('on-player-play', player)
    },

    onPlayerPause(player) {
      // console.log('player pause!', player)
      this.$emit('on-player-pause', player)
    },

    // eslint-disable-next-line no-unused-vars
    onPlayerEnded(player) {
      // console.log('player pause!', player)
    },

    // eslint-disable-next-line no-unused-vars
    onPlayerWaiting(player) {
      // console.log('player pause!', player)
    },

    // eslint-disable-next-line no-unused-vars
    onPlayerPlaying(player) {
      // console.log('player pause!', player)
    },

    // eslint-disable-next-line no-unused-vars
    onPlayerLoadeddata(player) {
      // console.log('player pause!', player)
    },

    // eslint-disable-next-line no-unused-vars
    onPlayerTimeupdate(player) {
      // console.log('player pause!', player)
    },

    // eslint-disable-next-line no-unused-vars
    onPlayerCanplay(player) {
      // console.log('player pause!', player)
    },

    // eslint-disable-next-line no-unused-vars
    onPlayerCanplaythrough(player) {
      // console.log('player pause!', player)
    },

    // eslint-disable-next-line no-unused-vars
    // or listen state event

    // eslint-disable-next-line no-unused-vars
    playerStateChanged(playerCurrentState) {},

    // player is ready
    // eslint-disable-next-line no-unused-vars
    playerReadied(player) {
      // you can use it to do something...
      // player.[methods]
    }
  }
}
</script>
