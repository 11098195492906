<template>
  <div class="w-screen bg-[#D4372E] flex justify-between items-center p-[10px]">
    <div class="flex flex-col text-white">
      <div class="text-[14px]">补贴价</div>
      <div class="flex items-center text-white">
        <div class="text-[14px]">￥</div>
        <div class="text-[28px]">{{ good.goodsDetailEx.price }}</div>
      </div>
    </div>
    <div class="flex items-center text-[#D4372E] rounded-[18px] px-[18px] py-[3px] bg-white mt-[10px] mr-[50px]">
      <span class="text-[14px] mr-[3px]">秒杀价</span>
      <span class="text-[12px]">￥</span>
      <span class="text-[18px]">{{ originalPrice }}</span>
    </div>
    <div class="h-[100%] flex flex-col items-center">
      <div class="fs12 count-down-title white mb1">距离秒杀结束</div>
      <count-down
        :end-time="virtualCountDown"
        bg-color="#F2C3C0"
        colon-color="#F2C3C0"
        font-color="#D4372E"
        :block-style="{ fontSize: '14px' }"
      ></count-down>
    </div>
  </div>
  <!-- <div
    class="com-goods-price-banner-point flex flex-wrap"
    :style="{ 'background-image': `url(${renderBrandElement({ id: 'goodsProfile.priceBannerBg', type: 'image' })})` }"
  >
    <div class="price-info mt1 flex items-start">
      <p class="" style="color: #fff">
        <span class="point fs24">{{ good.goodsDetailEx.costPoints }}</span>
        <span class="price">
          <span class="fs24">{{ globalPointName }}</span>
          <span class="">+{{ good.goodsDetailEx.price }}元</span>
          <span v-if="good.goodsSpecList?.length > 1" class="fs13" style="margin-left: 3px">起</span>
        </span>
        <span class="original-price opacity07">￥{{ good.goodsDetailEx.originalPrice }}</span>
      </p>
    </div>
    <div class="flex justify-between items-center exchange-hot mb1">
      <div class="hot flex flex-none items-center fs12 opacity07">{{ saleDesc }}</div>
      <div class="price-reduce opacity07">
        {{ globalPointName }}已为您节省{{
          Number(good.goodsDetailEx.originalPrice) - Number(good.goodsDetailEx.price)
        }}元
      </div>
    </div>
  </div> -->
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import { CountDown } from 'components'
// import { ImageThemed, IconFont, CountDown } from 'components'

export default {
  components: { CountDown },
  props: {
    good: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      timer: undefined,
      countDownEnd: dayjs().endOf('d'),
      dateTime: new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1),
      virtualCountDown: dayjs().endOf('day')
    }
  },

  computed: {
    ...mapState({
      marketingBlockAreaFlag: (state) => state.appState.marketingBlockAreaFlag
    }),

    saleDesc() {
      return _.replace(this.good.goodsDetailEx.saleDesc, '兑换', this.globalExchangeWord)
    },

    originalPrice() {
      return this.virtualCoupon?.name === '红包'
        ? Number(this.good.goodsDetailEx.price + Number(this.virtualCoupon.value)).toFixed(2)
        : this.good.goodsDetailEx.originalPrice
    }
  },

  created() {},
  beforeDestroy() {},

  methods: {}
}
</script>
<style lang="less" scoped>
.com-goods-price-banner-point {
  height: 60px;
  line-height: 1;
  background-color: #e93829;
  padding: 0 12px;
  box-sizing: border-box;
  position: relative;
  background-size: no-repeat;
  background-size: 100% 100%;
  .price-info {
    width: 100%;
  }
  .exchange-hot {
    width: 100%;
  }
  .hot {
    color: #fff;
  }
  .point {
    color: #fff;
  }
  .price-reduce {
    color: #fff;
    font-size: 12px;
  }
  .price {
    font-size: 15px;
    color: #fff;
  }

  .tag {
    background-color: #fff;
    font-size: 12px;
    color: #e93829;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    position: absolute;
    top: 13px;
  }

  .original-price {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    text-decoration: line-through;
    margin-left: 5px;
  }
}
</style>
