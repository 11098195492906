<template>
  <div class="select-payment-container relative">
    <van-radio-group v-model="payment">
      <div
        class="pay"
        v-if="!appState.isInAliPay && enableWxPay"
        @click="onChange(xEnum.ENUM_PAYMENT_TYPE.WX.getValue())"
      >
        <div class="payBox">
          <img class="payImg" src="~base/assets/img/weixin2.png" />
          <ul class="payTxt">
            <li class="payt1 flex items-center">
              微信支付
              <span class="fs12" style="color: #e54635">(推荐)</span>
              <div>
                <span class="color_tips fs12">安全方便</span>
              </div>
            </li>
          </ul>
          <ul class="paytj">
            <van-radio
              :name="xEnum.ENUM_PAYMENT_TYPE.WX.getValue()"
              checked-color="#e00"
              style="float: right"
            ></van-radio>
          </ul>
        </div>
      </div>
      <div
        class="pay payjt2"
        id="aliPayDiv"
        v-if="!appState.isInWechat && enableAliPay"
        @click="onChange(xEnum.ENUM_PAYMENT_TYPE.ALI.getValue())"
      >
        <div class="payBox">
          <img class="payImg" src="~base/assets/img/zfb2.png" />
          <ul class="payTxt">
            <li class="payt1">
              支付宝支付&nbsp;
              <div>
                <font class="color_tips fs12">支持花呗分期</font>
              </div>
            </li>
          </ul>
          <ul class="paytj">
            <!--                    <li class="payj1">推荐</li>-->
            <!-- <img class="payj2" src="~base/assets/img/radio1.png" @click="radio = '2'"> -->
            <van-radio
              :name="xEnum.ENUM_PAYMENT_TYPE.ALI.getValue()"
              checked-color="#e00"
              style="float: right"
            ></van-radio>
          </ul>
        </div>
      </div>

      <!-- <div class="pay payjt2" id="aliPayDiv" v-else>
        <div class="payBox">
          <img class="payImg" src="~base/assets/img/zfb_hui.png" />
          <ul class="payTxt">
            <li class="payt1" style="color: #999">
              支付宝支付&nbsp;
              <span class="payf2">活动商品暂不支持支付宝</span>
            </li>
          </ul>
          <ul class="paytj">
            <van-radio
              :name="xEnum.ENUM_PAYMENT_TYPE.ALI.getValue()"
              checked-color="#e00"
              style="float: right"
              disabled
            ></van-radio>
          </ul>
        </div>
      </div> -->
      <div
        v-if="appState.isInAliPay || appState.isInWechat"
        class="p2 fs12 flex items-center justify-center color_tips"
      >
        <span v-if="appState.isInAliPay">微信</span>
        <span v-if="appState.isInWechat">支付宝</span>
        <span>支付，请复制链接后在浏览器打开</span>
      </div>
    </van-radio-group>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xEnum from 'base/utils/enum'

export default {
  props: {
    enableWxPay: {
      type: Boolean,
      default: false
    },

    enableAliPay: {
      type: Boolean,
      default: false
    },

    enableArrivePay: {
      type: Boolean,
      default: false
    },

    originalPrice: {
      type: Number,
      default: 0
    },

    goodsTotalAmount: {
      type: Number,
      default: 0
    },

    costPoints: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      xEnum,
      ENUM_PAYMENT_TYPE: xEnum.ENUM_PAYMENT_TYPE,
      payment: xEnum.ENUM_PAYMENT_TYPE.WX.getValue(),
      showArrivePay: false
    }
  },

  computed: {
    ...mapGetters({
      appState: 'appState'
    }),

    ...mapState({
      isInAliPay: (state) => state.appState.isInAliPay
    })
  },

  watch: {
    enableWxPay: {
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        const isAlipay = navigator.userAgent.toLowerCase().match(/aliapp/i) == 'aliapp'
        if (newVal && !isAlipay) {
          this.payment = xEnum.ENUM_PAYMENT_TYPE.WX.getValue()
          this.$emit('on-change', this.payment)
        } else {
          this.payment = xEnum.ENUM_PAYMENT_TYPE.ALI.getValue()
          this.$emit('on-change', this.payment)
        }
      },

      deep: true,
      immediate: true
    },

    isInAliPay: {
      handler(newVal) {
        if (newVal) {
          this.payment = xEnum.ENUM_PAYMENT_TYPE.ALI.getValue()
          this.$emit('on-change', this.payment)
        }
      },

      deep: true,
      immediate: true
    }
  },

  created() {
    // if (this.appState.isInAliPay) {
    //   this.payment = xEnum.ENUM_PAYMENT_TYPE.ALI.getValue()
    //   this.$emit('on-change', this.payment)
    // }
  },

  methods: {
    onChange(payment) {
      this.payment = payment
      this.$emit('on-change', payment)
    }
  }
}
</script>
<style lang="less" scoped>
.select-payment-container {
  /*支付方式*/
  .title {
    width: 95%;
  }
  .pay {
    padding: 10px 0;
    border-bottom: 1px dashed #e0e0e0;
  }
  .pay:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 5px;
  }
  .payBox {
    width: 100%;
    margin: 0 auto;
  }
  .payImg {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
  }
  .payTxt {
    width: calc(100% - 90px);
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  .payt1 {
    color: #4d4d4d;
    display: inline-block;
    font-size: 14px;
    // font-weight: 900;
  }
  .payf1 {
    color: #e00000;
    font-size: 0.5rem;
    font-weight: normal;
  }
  .payf2 {
    color: #999;
    font-size: 0.5rem;
    font-weight: normal;
  }
  .payt2 {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    font-size: 10px;
    text-align: center;
    line-height: 20px;
    height: 20px;
    background-color: #e00000;
    border-radius: 15px;
    color: #fff;
  }
  .paytj {
    width: 30px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
  }
  .payj1 {
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .payj2 {
    width: 18px;
    display: inline-block;
    vertical-align: middle;
  }
  .ohterpay {
    text-align: center;
  }
  .otherpay1 {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.55rem;
    color: #191919;
  }
  .otherpayImg {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    margin-left: 5px;
  }
}
</style>
