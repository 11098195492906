<template>
  <!-- <svg
    class="icon"
    aria-hidden="true"
    :style="{
      width: width || size,
      height: height || size,
      // ios 上面，color 只有此处才生效。安卓是下面的 use 上加，就可以生效。
      color
    }"
  >
    <use :xlink:href="`#${icon}`" :style="{ color }"></use>
  </svg> -->
  <div
    :class="'iconfont ' + icon"
    :style="{
      fontSize: size,
      // ios 上面，color 只有此处才生效。安卓是下面的 use 上加，就可以生效。
      color
    }"
  ></div>
</template>

<script>
export default {
  name: 'IconFont',
  props: {
    msg: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: ''
    },

    icon: {
      type: String,
      default: ''
    },

    width: {
      type: String,
      default: ''
    },

    height: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: '14px'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.iconfont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
