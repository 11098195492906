<template>
  <div class="com-goods-list" :style="styleWrap">
    <slot name="header"></slot>
    <div>
      <VanList
        v-model="pullLoading"
        :finished="finished"
        loading-text="商品加载中..."
        finished-text="我也是有底线的~"
        @load="onLoad"
        class="flex flex-wrap justify-between"
      >
        <div v-if="goodsItem == 'returnScore'" style="width: 100%">
          <GoodsReturnScoreItem
            v-for="(item, index) in goodsList"
            :key="index"
            :source="item"
            :index="index"
            :type="type"
            :on-click-item="onClickItem"
          />
        </div>
        <div v-if="goodsItem == 'timeLimit'" style="width: 100%">
          <GoodsTimeLimitItem
            v-for="(item, index) in goodsList"
            :key="index"
            :source="item"
            :index="index"
            :type="type"
            :on-click-item="onClickItem"
          />
        </div>
        <div v-if="goodsItem == 'default'" class="flex flex-wrap justify-between">
          <GoodsNormalItem
            v-for="(item, index) in goodsList"
            :key="index"
            :source="item"
            :index="index"
            :type="type"
            :on-click-item="onClickItem"
            :page-source="pageSource"
            :item-style="{ width: 'calc((100% - 3%) / 2)' }"
          />
        </div>

        <div v-if="goodsItem === 'payOnDelivery'" class="grid grid-cols-2 gap-[16px]">
          <PayOnDeliveryItem
            v-for="(item, index) in goodsList"
            :key="index"
            :source="item"
            :index="index"
            :type="type"
            btn="免费领取"
          />
        </div>
        <!-- <div v-show="goodsList.length % 2 && showPlaceholderItem && goodsItem == 'default'" @click="onClickMore" class="more-goods"
          :style="{'background-image': `url(${renderBrandElement({id: 'goodsList.moreItemImg', type: 'image'})})`}">
        </div> -->
      </VanList>
      <!-- <div v-if="useVirtualList">
        <virtual-list
          :item-class="cellPerRow !== 1 ? 'In_c_div' : ''"
          :item-style="{width: cellPerRow === 1 ? '100%' : `calc((100% - 3%) / ${cellPerRow})`, ...itemStyle}"
          wrap-class="virtual-list-wrap"
          :data-key="dataKey"
          :data-sources="goodsList"
          :data-component="goodsItem"
          :extra-props="{ onClickItem, pId, type, pageSource, keyWords, showRankTag }"
          :keeps="goodsList.length"
        />
      </div>
      <div v-else>
        <DirectRenderItem v-for="item in goodsList" :key="item[dataKey]" :source="item"
          :data-component="goodsItem"
          :extra-props="{ onClickItem, pId, type, pageSource, keyWords }"
          :class="cellPerRow !== 1 ? 'In_c_div' : ''"
          :style="{width: cellPerRow === 1 ? '100%' : `calc((100% - 3%) / ${cellPerRow})`, ...itemStyle}"
        >
        </DirectRenderItem>
      </div>
      <div v-show="goodsList.length % 2 && cellPerRow !== 1 && showPlaceholderItem" @click="onClickMore" class="more-goods"
        :style="{'background-image': `url(${renderBrandElement({id: 'goodsList.moreItemImg', type: 'image'})})`}">
      </div> -->
      <div v-show="pullLoading" class="loading-container flex flex-column items-center">
        <img
          width="100"
          style="margin-top: 10vh"
          :src="renderBrandElement({ id: 'goodsList.loading', type: 'image' })"
        />
        <span class="loading-container-text" :style="styleLoadingText">更多超值商品加载中</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import { IconFont } from 'components'
import { GoodsNormalItem } from 'widgets'
import GoodsTimeLimitItem from 'widgets/ListItem/GoodsTimeLimitItem'
import GoodsReturnScoreItem from 'widgets/ListItem/GoodsReturnScoreItem'
import PayOnDeliveryItem from 'widgets/ListItem/PayOnDeliveryItem'

import xEnum from 'base/utils/enum'
import utils from 'base/utils'
import { getPagingGoodsList } from 'base/api'
import _ from 'lodash'

const GOODS_LIST_PAGE_SIZE = 20

export default {
  name: 'VanGoodsListThemed',
  components: { GoodsNormalItem, GoodsTimeLimitItem, GoodsReturnScoreItem, PayOnDeliveryItem },
  props: {
    itemStyle: {
      type: Object,
      default: () => {}
    },

    dataKey: {
      type: String,
      default: () => 'id'
    },

    goodsItem: {
      type: String,
      default: 'default'
    },

    name: {
      type: String,
      default: () => 'VanGoodsListThemed'
    },

    type: {
      type: Number,
      default: 1
    },

    pId: {
      type: String,
      default: ''
    },

    styleWrap: {
      type: Object,
      default: () => {}
    },

    styleLoadingText: {
      type: Object,
      default: () => {}
    },

    goodsList: {
      type: Array,
      default: () => []
    },

    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    // 列表每行有多少个单元
    // cellPerRow: {
    //   type: Number,
    //   default: () => 2
    // },
    loading: {
      type: Boolean,
      default: false
    },

    // keyWords: String,
    // showRankTag: {
    //   type: Boolean,
    //   default: () => false
    // },
    // useVirtualList: {
    //   type: Boolean,
    //   default: true
    // },
    showPlaceholderItem: {
      type: Boolean,
      default: () => true
    }
  },

  data() {
    return {
      scrollTop: true,
      pageIndex: 1,
      tempList: [],
      pullLoading: false,
      finished: false,
      startInfinity: false
    }
  },

  computed: {
    ...mapState({
      userId: (state) => state.user.userId
    }),

    _loading() {
      // 不能用 !this.goodsList判断，因为this.goodsList外面传进来的值默认永远为[]
      // return !this.goodsList || this.loading
      return this.loading
    },

    isInfinityList() {
      return (
        (this.name === '首页tab分类' ||
          this.name === '首页推荐分类' ||
          this.name === '今日超值兑换-主题1' ||
          this.name === '猜你喜欢') &&
        this.renderBrandElement({ id: 'primary.goodsRepeat', type: 'boolean' })
      )
    }
  },

  created() {},
  destroyed() {
    clearTimeout(this.delayer)
  },

  methods: {
    onLoad() {
      // type通过props传进来,有可能出现在调用onLoad时type还未更新的情况,所以通过定时器包裹
      const timer = setTimeout(async () => {
        if (!this.type) return
        const resp = await getPagingGoodsList({
          pId: this.pId,
          userId: this.userId,
          type: this.type,
          pageIndex: this.pageIndex++,
          pageSize: GOODS_LIST_PAGE_SIZE
        })
        if (resp.code === 200) {
          let tempList = resp.data.list
          // 如果是无限加载列表，参数重置时打乱商品顺序
          if (this.startInfinity) {
            tempList = _.shuffle(tempList)
          }
          this.tempList = tempList
          this.$emit('load-goods-list', this.tempList)
          this.pullLoading = false
        }
        this.finished = resp.data.list.length < GOODS_LIST_PAGE_SIZE
        // 商品列表到底后重置参数
        if (this.isInfinityList && this.finished) {
          this.startInfinity = true
          this.pageIndex = 1
          this.finished = false
        }
        clearTimeout(timer)
      }, 0)
    },

    // isNeed用来判断页面需不需要手动调onLoad函数
    resetOnloadSetting(isNeed = true) {
      this.finished = false
      this.startInfinity = false
      this.pageIndex = 1
      this.pullLoading = true
      if (this.pullLoading && isNeed) {
        this.onLoad()
      }
    },

    onClickItem(source, index) {
      this.$store.dispatch('enterGoodsProfile', {
        module: this.name,
        index,
        type: this.type,
        goods: source
      })

      this.$emit('click-item', source, this.type)
    },

    onClickMore() {
      const path = this.renderBrandElement({ id: 'goodsList.moreItemPath' })
      if (this.$route.path !== path) {
        this.$router.push({ path, params: { scrollTop: this.scrollTop } })
      } else {
        utils.scroll('#stickyZone', 150)
      }

      this.$emit('click-more')
    }
  }
}
</script>
<style lang="less">
.van-list__loading,
.van-list__finished-text {
  width: 100%;
}
.com-goods-list {
  .loading-container {
    .loading-container-block {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 25vh;
    }
    .loading-container-text {
      // trick改法: 必须撑满一页，否则吸顶 sticky 的 tab 切换的时候，会自动往上滚动。
      padding-bottom: 80vh;
      color: #797979;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 25vh;
    }
  }
  .virtual-list-wrap {
    padding: 0 !important;
    margin: 0 auto;
  }

  .In_c_div {
    display: inline-block;
    vertical-align: top;
  }

  .In_c_div:nth-child(odd) {
    margin-right: 3%;
  }
}
</style>
<style lang="less" scoped>
.com-goods-list {
  position: relative;
  .more-goods {
    // 85px + 5px的margin top
    @goodsInfoHeight: 119px;
    width: 48.5%;
    height: calc(48.5vw * 0.95 + @goodsInfoHeight);
    display: inline-block;
    // box-shadow: 0px 0px 4px 1px #e4e4e4;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    margin-bottom: 8px;
    position: absolute;
    right: 0;
    bottom: 50px;
    padding-bottom: 10px;
    background: #fff;
    text-align: center;
    color: #7f7f7f;
    box-sizing: border-box;
    background-size: 100% 100%;
    p:nth-child(2) {
      margin-top: 25px;
    }

    // background-size: cover;
  }
}
</style>
