import { getOrderList, getUserWhitePhoneIsExist } from 'base/api'
import xEnum from 'base/utils/enum'
// import { Toast, Dialog } from 'vant'
// eslint-disable-next-line no-unused-vars
import router from '@/router'

function formatOrder(list) {
  return _.map(list, (order) => {
    const hideScoreList = [
      xEnum.ENUM_GOODS_GROUP_TYPE.RETURN_POINTS_GROUP.value,
      xEnum.ENUM_GOODS_GROUP_TYPE.TIME_LIMIT_GROUP.value
    ]
    order.hideScore = _.includes(hideScoreList, Number(order.goodGroupType))
    return order
  })
}

// eslint-disable-next-line no-unused-vars
async function checkQueryGranted({ commit, state, rootState }, payload) {
  let granted = false
  try {
    const resp = await getUserWhitePhoneIsExist(payload)
    granted = resp.data
  } catch (e) {
    // 不加任何语句，lint会报错
    console.info(e)
  }
  if (granted) return true
  return false
  // 无论用户是否确认跳转路由，都返回false，后续流程不再查询订单。
  // return Dialog.confirm({
  //   title: '温馨提示',
  //   className: 'grant-query-dialog',
  //   message: '为了保障您的个人隐私，请授权后再进行查询。',
  //   confirmButtonText: '去授权',
  //   confirmButtonColor: '#E54635',
  //   cancelButtonText: '取消',
  //   cancelButtonColor: '#AAAAAA',
  // })
  // .then(() => {
  //   router.push({ path: "/grantOrderQuery" });
  //   return false
  // })
  // .catch(() => {
  //   return false
  // })
}

export default {
  state: {
    list: [],
    classifyList: [],
    page: 1,
    pageSize: 10,
    hasMore: true,
    queryCond: {
      sort: '-create_time'
    },
    searching: false,
    searchWithPhone: false
  },

  mutations: {
    SET_ORDER_LIST: (state, payload) => {
      for (const key in payload) {
        state[key] = payload[key]
      }
    }
  },

  actions: {
    checkQueryGranted,
    async loadMoreOrderList({ commit, state, rootState }, payload) {
      const isClassifyList = payload ? payload.isClassifyList : false
      const { list, classifyList, page, pageSize, queryCond } = state
      try {
        const params = {
          pageNum: page - 1,
          pageSize,
          pId: rootState.appState.pId
        }
        params.userId = rootState.user.userId
        if (queryCond.phone && queryCond.phone.trim() !== '') {
          params.phone = queryCond.phone
        }

        if (queryCond.status !== null) {
          params.status = queryCond.status
        }
        if (queryCond.phoneCode && queryCond.phoneCode.trim() !== '') {
          params.phoneCode = queryCond.phoneCode
        }
        commit('SET_ORDER_LIST', {
          searching: true,
          searchWithPhone: params.phone
        })
        // 产品要求第一页只显示两个
        const rsp = await getOrderList(params)
        const moreList = rsp.data.list
        moreList.forEach((item) => {
          item.imgUrl = item.imgUrl + process.env.VUE_APP_IMG_PARAMS
        })
        if (params.pageNum === 1) {
          moreList.splice(0, 2)
        }
        if (isClassifyList) {
          commit('SET_ORDER_LIST', {
            classifyList: [...classifyList, ...formatOrder(moreList)],
            page: page + 1,
            hasMore: rsp.data.hasNextPage,
            searching: false
          })
        } else {
          commit('SET_ORDER_LIST', {
            list: [...list, ...formatOrder(rsp.data.list)],
            page: page + 1,
            hasMore: rsp.data.hasNextPage,
            searching: false
          })
        }
      } catch (err) {
        console.error('获取订单列表失败', err)
      }
    },
    async refreshOrderList({ commit, state, rootState }, payload) {
      const isClassifyList = payload ? payload.isClassifyList : false
      // eslint-disable-next-line no-unused-vars
      const { list, classifyList, page, pageSize, queryCond } = state

      // 如果使用手机号码，则检查是否已经授权
      // if (queryCond.phone) {
      //   let isGranted = await checkQueryGranted({
      //     userId: rootState.user.userId,
      //     phone: queryCond.phone
      //   })
      //   if (!isGranted) return
      // }

      try {
        const params = {
          pageNum: 1,
          pageSize: 2,
          pId: rootState.appState.pId
        }

        params.userId = rootState.user.userId
        if (queryCond.phone) {
          params.phone = queryCond.phone
        }

        if (queryCond.status !== null) {
          params.status = queryCond.status
        }
        if (queryCond.phoneCode && queryCond.phoneCode.trim() !== '') {
          params.phoneCode = queryCond.phoneCode
        }
        commit('SET_ORDER_LIST', {
          searching: true,
          searchWithPhone: params.phone
        })

        const rsp = await getOrderList(params)
        if (isClassifyList) {
          commit('SET_ORDER_LIST', {
            classifyList: formatOrder(rsp.data.list),
            page: 2,
            hasMore: rsp.data.hasNextPage,
            searching: false
          })
        } else {
          commit('SET_ORDER_LIST', {
            list: formatOrder(rsp.data.list),
            page: 2,
            hasMore: rsp.data.hasNextPage,
            searching: false
          })
        }
      } catch (err) {
        console.error('刷新订单列表失败', err)
      }
    }
  }
}
