<template>
  <div
    v-if="visible"
    @click="goDownloadApp"
    class="coupon-entry animate__animated animate__tada animate__infinite fixed flex-column items-center right-0 mr3"
  >
    <div class="background-image" :style="{ 'background-image': `url(${downloadAppImg})` }"></div>
    <!-- <div class="hint">百亿补贴</div> -->
    <!-- <div class="hint">50元现金券</div> -->
    <SuspendShareThemed
      ref="suspendShare"
      :is-share="false"
      share-event-name="分享-双十一超级红包"
      :share-text="shareText"
      share-hint="分享给好友后，即可解锁双十一超级红包！"
    />
  </div>
</template>

<script>
import quickApp from 'base/utils/quickApp'
import { trackEvent } from 'base/utils/rp'
import { mapGetters } from 'vuex'
import utils from 'base/utils'
import { SuspendShareThemed } from 'widgets'
import downloadAppImg from 'base/assets/img/downloadApp.png'
const couponAddr = 'https://shop46327261.youzan.com/v2/ump/promocard/fetch?alias=nrpv7kqr'
const webviewMinVersion = '1.0.7'
export default {
  name: 'FloatAdThemed',
  components: {
    SuspendShareThemed
  },

  data() {
    return {
      downloadAppImg
    }
  },

  computed: {
    ...mapGetters({
      appState: 'appState'
    }),

    visible() {
      const routeName = this.$route.name
      return (
        this.appState.isInQuickApp &&
        this.appState.manufacturer !== 'huawei' &&
        routeName !== '疯抢排行' &&
        routeName !== '9.9包邮' &&
        routeName !== this.renderBrandElement({ id: 'primary.tabbarName' }) &&
        // 内嵌支付宝不展示
        !(this.appState.isInMini && this.appState.isInAliPay)
      )
    },

    shareText() {
      return `双十一热身，已启动${this.globalPointName}${
        this.globalExchangeWord
      }，超多好物等你来抢！亲测有效，还可以抢双十一大额红包！【${this.renderBrandElement({ id: 'share.mallUrl' })}】`
    }
  },

  created() {},

  methods: {
    async onGetCoupon() {
      if (utils.compareAppVersion(this.appState.appVersion, webviewMinVersion) >= 0) {
        quickApp.openWebview(couponAddr)
      } else {
        window.location.href = couponAddr
      }

      trackEvent({
        category: `点击天天有礼`,
        action: '总数'
      })
      await utils.waitFor(1000)
      quickApp.trackEvent('点击天天有礼', {
        action: '总数'
      })
    },

    async goTaoke() {
      this.$router.push({ path: '/tk-guide' })

      trackEvent({
        category: `点击百亿补贴`,
        action: '总数'
      })
      await utils.waitFor(1000)
      quickApp.trackEvent('点击百亿补贴', {
        action: '总数'
      })
    },

    async goDownloadApp() {
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.tpyx.app'

      trackEvent({
        category: `浮窗-点击下载APP`,
        action: '总数'
      })
      await utils.waitFor(1000)
      quickApp.trackEvent('浮窗-点击下载APP', {
        action: '总数'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.coupon-entry {
  z-index: 1000;
  bottom: 160px;
  text-align: center;
  .background-image {
    width: 80px;
    height: 80px;
  }
  .hint {
    color: #d8000f;
    font-weight: bolder;
  }
}
</style>
