<template>
  <div
    class="com-point-notice-mask top"
    v-if="!hidden && isValidPoint && enable"
    :style="{ background: jinriBackground }"
  >
    <van-notice-bar
      :speed="60"
      :scrollable="true"
      color="rgba(255, 255, 255, 0.9)"
      :background="jinriBackground"
      style="height: 27px"
    >
      <div class="t_txt flex items-center">
        <img class="t_Img" src="~base/assets/img/laba02.gif" />
        <span> {{ '您的' + virtualCoupon.value + virtualCoupon.name + '将在' }} </span>
        <van-count-down class="white fs12" :millisecond="true" format="HH:mm:ss" :time="countDownTime"></van-count-down>
        <span>后过期</span>
      </div>
      <!-- <div class="t_txt" v-if="!points.expirePoints && points.activePoints > 0">
        您还有
        <span>{{ points.activePoints }}</span>
        {{ globalPointName }}未使用 请尽快使用
      </div> -->
    </van-notice-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { NoticeBar } from 'vant'

export default {
  name: 'PointNoticeMaskThemed',
  components: {
    [NoticeBar.name]: NoticeBar
  },

  props: {
    /**
     * 滚动到某个位置隐藏该组件，当为0时则不隐藏
     */
    hiddenOffset: {
      type: Number,
      default: 0
    },

    jinriBackground: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      hidden: false,
      enable: this.renderBrandElement({ id: 'pointNoticeMask.enable', type: 'boolean' }),
      expireTime: ''
    }
  },

  computed: {
    ...mapState({
      points: (state) => state.user.points,
      isValidPoint: (state) => {
        if (!state.user.points || !state.user.points.expireTime) {
          return false
        }

        if (state.user.points.expirePoints <= 0) {
          return false
        }

        if (dayjs().isAfter(state.user.points.expireTime)) {
          return false
        }

        return true
      }
    })
  },

  created() {
    const now = dayjs()
    const countDownTime = dayjs(dayjs().endOf('day')).diff(now, 'seconds') * 1000
    this.countDownTime = countDownTime
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },

  methods: {
    handleScroll(e) {
      if (this.hiddenOffset === 0) {
        return
      }

      const scrollTop =
        (e.target.documentElement && e.target.documentElement.scrollTop) || (e.target.body && e.target.body.scrollTop)
      // console.log(scrollTop)

      if (scrollTop > 200) {
        this.hidden = true
      } else {
        this.hidden = false
      }
    }
  }
}
</script>
<style lang="less">
.com-point-notice-mask {
  .van-count-down {
    color: #fff;
  }
}
</style>

<style lang="less" scoped>
.com-point-notice-mask {
  .t_Img {
    display: inline-block;
    vertical-align: middle;
    width: 0.6rem;
    margin-left: 0.5rem;
    margin-right: 0.3rem;
  }
  .t_txt {
    vertical-align: middle;
    width: calc(100% - 2rem);
    font-size: 0.5rem;
    color: #ffffff;
  }

  &.top {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
</style>
