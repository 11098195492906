// vm.$mount(el) => document.body.appendChild();
// Vue.extend() 继承 【子类继承父类的方法】
// 父类 Vue--Vue.extend--子类 定制化的 Vue 构造函数
import Vue from 'vue'
import ServiceRuleDialog from './serviceRuleDialog'
import store from '@/store'

const Customized = Vue.extend(ServiceRuleDialog)

// eslint-disable-next-line no-unused-vars
export const serviceRuleDialog = (option = {}) =>
  new Promise((resolve, reject) => {
    const res = new Customized({
      data() {},
      methods: {
        resolve,
        reject
      },
      store
    }).$mount()
    // console.log(res.$el);
    document.body.appendChild(res.$el)
  })
