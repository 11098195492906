import { render, staticRenderFns } from "./WelfarePointItem.vue?vue&type=template&id=05e892fd&scoped=true&"
import script from "./WelfarePointItem.vue?vue&type=script&lang=js&"
export * from "./WelfarePointItem.vue?vue&type=script&lang=js&"
import style0 from "./WelfarePointItem.vue?vue&type=style&index=0&id=05e892fd&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e892fd",
  null
  
)

export default component.exports