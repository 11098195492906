<template>
  <div @click="onClick" class="container">
    <van-image class="In_c_Img" :src="source.imgUrl" :lazy-load="true">
      <template v-slot:loading>
        <ImageThemed :width="100" image-id="goodsNormalItem.loading"></ImageThemed>
      </template>
    </van-image>
    <img v-if="source.stockNum <= 0" class="dw_img" src="~base/assets/img/dw.png" />
    <div class="goods-info flex flex-column pt2">
      <div class="name van-ellipsis">{{ source.goodsName }}</div>
      <div class="flex items-end">
        <div class="color_primary bold mr2">
          <span class="point">补贴价</span>
          <span class="orginal-price">￥{{ source.price }}元</span>
        </div>
      </div>
      <div class="goods-info-price flex">
        <div class="sale-desc">{{ source.saleDesc | formatSaleDesc }}</div>
        <div class="price">￥{{ source.originalPrice }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { trackUserEvent } from 'base/utils/rp'
import { ImageThemed } from 'components'
import xEnum from 'base/utils/enum'
import { Dialog } from 'vant'
import { mapState } from 'vuex'

export default {
  name: 'GoodsSeckillItemThemed',
  components: { ImageThemed },
  filters: {
    formatSaleDesc(value) {
      if (!value) {
        return ''
      }

      return String(value).replace('兑换热度', '兑换热度')
    }
  },

  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: () => 0
    },

    type: {
      type: Number,
      default: () => 1
    },

    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    onClickItem: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId
    })
  },

  methods: {
    onClick() {
      const { source, index } = this

      if (source.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已抢完，您可以挑选其它商品',
          confirmButtonText: '好的'
        })
        return
      }

      this.$router.push({
        path: `/goodsProfile/${source.id}`,
        query: {
          type: this.type,
          activityType: 'seckill',
          pageSource: this.pageSource
        }
      })

      this.onClickItem(source, index)

      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        goodsName: source.goodsName,
        goodsIndex: index,
        goodsGroupId: this.type
      }
      trackUserEvent({
        eventType: 'goodsClick',
        eventInfo
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  display: inline-block;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  background: #fff;

  .In_c_Img {
    display: block;
    width: 100%;
  }

  .in_c_I_p {
    display: inline-block;
    vertical-align: middle;
  }

  .in_c_I_p1 {
    width: 100%;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
  .goods-info {
    height: 85px;
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-left: 7px;
    padding-right: 7px;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .point {
      font-size: 13px;
    }

    .orginal-price {
      font-size: 16px;
    }
  }

  .goods-info-price {
    display: flex;
    justify-content: space-between;

    .price {
      text-decoration: line-through;
      font-size: 12px;
      color: #888888;
    }

    .sale-desc {
      font-size: 0.5rem;
      color: #ff3600;
    }
  }
}
</style>
