import { render, staticRenderFns } from "./GoodsSeckillItemThemed.vue?vue&type=template&id=50df1500&scoped=true&"
import script from "./GoodsSeckillItemThemed.vue?vue&type=script&lang=js&"
export * from "./GoodsSeckillItemThemed.vue?vue&type=script&lang=js&"
import style0 from "./GoodsSeckillItemThemed.vue?vue&type=style&index=0&id=50df1500&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50df1500",
  null
  
)

export default component.exports