<template>
  <div id="home-header" class="home-header pt2" ref="header" style="background-color: #d32d25">
    <div class="flex items-center px2">
      <div class="white fs18 bold" style="font-family: '宋体'">{{ title }}</div>
      <div class="flex-auto flex items-center search-bar mx2 pl2" @click="toSearch">
        <IconFont size="20px" class="icon" :icon="searchIcon" color="#d92e21" />
        <van-swipe
          :autoplay="3000"
          :show-indicators="false"
          :initial-swipe="initialIndex"
          @change="recommendWordChange"
          :vertical="true"
          :touchable="false"
          style="width: 96%; margin: 0 0 0 4px; padding: 0 0; height: 18px"
        >
          <van-swipe-item
            class="flex swipe-item items-center"
            v-for="(item, index) in recommendWords"
            :key="index"
            style="height: 18px"
          >
            <div class="buyInfo fs14 color_tips">{{ item }}</div>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div
        v-if="(hasCustom = true)"
        class="service-icon flex flex-column justify-center items-center"
        @click="toCustomService"
      >
        <IconFont size="16px" class="icon" icon="iconkefu4" color="white" />
        <div class="white title">客服</div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconFont } from 'components'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'HomeHeader',
  components: { IconFont },
  props: {
    title: {
      type: String,
      default: () => ''
    },

    searchIcon: {
      type: String,
      default: () => 'iconsousuo'
    },

    searchIconColor: {
      type: String,
      default: () => '#d92e21'
    },

    recommendWords: {
      type: Array,
      default: () => []
    },

    hasCustom: {
      type: Boolean,
      default: () => true
    },

    customIcon: {
      type: String,
      default: () => 'iconkefu4'
    },

    customIconColor: {
      type: String,
      default: () => '#FFFFFF'
    },

    initialIndex: {
      type: Number,
      default: () => 0
    },

    recommendWord: {
      type: String,
      default: () => ''
    }
  },

  data() {
    return {}
  },

  methods: {
    toSearch() {
      this.$router.push({ path: '/goodsSearch', query: { recommendWord: this.recommendWord } })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hp_searchbar_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_hp_page',
        params: {}
      })
    },

    toCustomService() {
      this.$router.push({
        path: '/customService1',
        query: {
          location: window.location.href
        }
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hp_humanservice_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_hp_page',
        params: {}
      })
    },

    recommendWordChange(index) {
      this.$emit('recommend-word-change', index)
    }
  }
}
</script>

<style lang="less" scoped>
.search-bar {
  background-color: white;
  width: 60%;
  height: 34px;
  border-radius: 17px;
}
</style>
