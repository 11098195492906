<template>
  <transition name="fade">
    <div v-if="visible" class="mask" @touchmove.prevent @click="clickDialog">
      <div class="dialog-content relative">
        <img class="image" src="https://img.bj0yx.com/875135802277888" />
        <div class="text bold absolute">
          再次获得
          <span style="color: #f33805">{{ morePoints }}</span>
          分
        </div>
        <div id="close" class="close-btn" @click="closeDialog">
          <van-icon size="30" name="close" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import store from '@/store'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'PaySuccessfulDialog',
  data() {
    return {
      visible: true,
      morePoints: store.state.appState.morePoints
    }
  },

  methods: {
    hide() {
      this.visible = false
    },

    closeDialog() {
      this.onClick()
      this.visible = false
    },

    clickDialog() {
      this.hide()
      this.onClick()
      // 积分弹窗
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_points_windows_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          point_value: this.morePoints,
          get_point_type: '积分不足获得积分'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;

  .dialog-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 80px;
    .image {
      width: 100%;
    }
    .text {
      font-family: PingFang SC;
      font-size: 20px;
      color: #f77229;
      left: 50%;
      transform: translate(-50%, 0);
      top: 100vw;
    }
    .close-btn {
      text-align: center;
      margin: auto;
      color: white;
    }
  }
}

.fade-enter {
  opacity: 0;
  top: -30px;
}
.fade-enter-active {
  transition: all 0.3s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
  top: 10px;
}

.fade-leave {
  opacity: 1;
  top: 10px;
}
.fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.fade-leave-to {
  opacity: 0;
  top: -30px;
}
</style>
