<template>
  <div v-if="goods.goodsDetailEx.originalPrice" class="com-goods-header-info">
    <div class="mx2 px2 my2 py2 content">
      <div class="">
        <div v-if="activityType === 'seckill'" class="coupon-bg flex items-center justify-between white" @click="toBuy">
          <div class="flex pl3">
            <div class="flex items-end pr2">
              <div class="fs18 mb1">￥</div>
              <div class="fs27">{{ goods.goodsDetailEx.originalPrice - goods.goodsDetailEx.price }}</div>
            </div>
            <div>
              <div class="fs16">工厂百万补贴专享券</div>
              <div class="flex">
                <CountDown
                  :end-time="countDownEnd"
                  :millisecond="true"
                  :ms-two-letter="true"
                  bg-color="transparent"
                  :block-style="{ fontSize: '12px', color: 'white', margin: 0, padding: 0 }"
                  :colon-style="{ fontSize: '12px', color: 'white' }"
                />
                <div class="fs12 ml1">后过期</div>
              </div>
            </div>
          </div>
          <div class="fs15 pr3">下单立享</div>
        </div>
        <div
          v-if="activityType === 'drawPrize'"
          class="coupon-bg flex items-center justify-between white"
          @click="toBuy"
        >
          <div class="flex pl3">
            <div class="flex items-end pr2">
              <div class="fs18 mb1">￥</div>
              <div class="fs27">{{ goods.goodsDetailEx.originalPrice - goods.goodsDetailEx.price }}</div>
            </div>
            <div>
              <div class="fs16">中奖用户专用券</div>
              <div class="flex">
                <CountDown
                  :end-time="countDownEnd"
                  :millisecond="true"
                  :ms-two-letter="true"
                  bg-color="transparent"
                  :block-style="{ fontSize: '12px', color: 'white', margin: 0, padding: 0 }"
                  :colon-style="{ fontSize: '12px', color: 'white' }"
                />
                <div class="fs12 ml1">后过期</div>
              </div>
            </div>
          </div>
          <div class="fs15 pr3">立即领取</div>
        </div>
        <div
          v-if="activityType === 'tencentAd'"
          class="coupon-bg flex items-center justify-between white"
          @click="toBuy"
        >
          <div class="flex pl3">
            <div class="flex items-end pr2">
              <div class="fs18 mb1">￥</div>
              <div class="fs27">{{ goods.goodsDetailEx.originalPrice - goods.goodsDetailEx.price }}</div>
            </div>
            <div>
              <div class="fs16">{{ couponDesc }}</div>
              <div class="flex">
                <CountDown
                  :end-time="countDownEnd"
                  :millisecond="true"
                  :ms-two-letter="true"
                  bg-color="transparent"
                  :block-style="{ fontSize: '12px', color: 'white', margin: 0, padding: 0 }"
                  :colon-style="{ fontSize: '12px', color: 'white' }"
                />
                <div class="fs12 ml1">后过期</div>
              </div>
            </div>
          </div>
          <!-- <div class="fs15 pr3">{{renderBrandElement({id: 'headerInfo.couponReceive'})}}</div> -->
          <div class="fs15 pr3">立即领取</div>
        </div>
      </div>
      <div class="flex items-center">
        <div class="goods-name bold fs16" :class="{ 'extra-font-style': activityType === 'singleProduct' }">
          {{ goods.goodsDetailEx.goodsDetailDesc }}
        </div>
      </div>
      <div
        v-if="activityType !== 'drawPrize' && goods.goodsRank"
        class="flex justify-between items-center rank"
        @click="rankClick(rankInfo.id)"
      >
        <div class="flex items-center">
          <div class="flex items-center px1 tag">
            <img src="https://img.bj0yx.com/881195207884800" alt="" />
            <div class="white fs12">榜单</div>
          </div>
          <div class="fs14 ml1 rank-info">
            入选{{ rankInfo.name }}
            <span v-show="rankInfo.ranking">第{{ rankInfo.ranking }}名</span>
          </div>
        </div>
        <IconFont size="14px" icon="iconpageleft" color="#b29183"></IconFont>
      </div>
      <div class="flex flex-wrap fs11 mt-[5px] mb-[8px]" v-if="goods.styleTags?.length">
        <span
          v-for="item in goods.styleTags"
          :key="item.text"
          class="mt-[5px] px1 mr1 fs11"
          :style="{ ...item.styleWrap }"
        >
          {{ item.text }}
        </span>
      </div>
      <div
        v-if="activityType !== 'payOnDelivery' && activityType !== 'payOnDeliveryFreeReceive'"
        class="flex justify-center mt2"
      >
        <van-button v-if="activityType === 'drawPrize'" @click="toBuy" class="btn bold fs18">优惠购买</van-button>
        <van-button v-else @click="toBuy" class="btn bold fs18">立即{{ globalExchangeWord }}</van-button>
      </div>
      <div v-if="activityType === 'payOnDelivery' || activityType === 'payOnDeliveryFreeReceive'" class="px2 my3">
        <div @click="payOnDelivery" class="pay-on-delivery flex justify-center items-center">
          <IconFont size="25px" icon="icongoumai" color="#fff" class="mr1 bold"></IconFont>
          <div class="white fs16">{{ payOnDeliveryDesc }}</div>
        </div>
      </div>
      <div
        v-if="goods.goodsDetailEx.goodsType !== xEnum.ENUM_GOODS_TYPE.PAY_ON_DELIVERY_FREE_RECEIVE.getValue()"
        class="divider mt2"
      ></div>
      <div
        v-if="goods.goodsDetailEx.goodsType !== xEnum.ENUM_GOODS_TYPE.PAY_ON_DELIVERY_FREE_RECEIVE.getValue()"
        @click="onClickService"
        class="pt2 flex items-center justify-between"
      >
        <img class="service-img mr1" src="~/base/assets/img/goodsDetail/fangxingou.png" alt="" />
        <div v-for="(tag, index) in tags" :key="index">
          <span class="service-title fs13 px1">{{ tag.title }}</span>
        </div>

        <IconFont size="14px" icon="iconpageleft" color="#b29183"></IconFont>
      </div>
    </div>

    <div class="footDialogBg" @click="onClickService" @touchmove.prevent>
      <div class="footDialog" @click.stop>
        <h2 class="fd_title">服务说明<span class="fd_title_close" @click="onClickService">×</span></h2>
        <ul>
          <li v-for="(tag, index) in tags" :key="index" style="padding: 10px 20px">
            <div style="display: inline-block; vertical-align: top; margin-right: 5px">
              <img style="height: 20px" src="~/base/assets/img/dui.png" />
            </div>
            <div style="display: inline-block">
              <p v-if="index < 4" style="font-weight: bold; padding-bottom: 5px; align-items: center" class="flex">
                {{ tag.title }}
                <img
                  v-if="tag.icon"
                  :src="tag.icon"
                  style="height: 15px; width: 15px; vertical-align: center; margin-left: 4px"
                />
              </p>
              <p v-if="index === 4" style="font-weight: bold; padding-bottom: 5px">24小时发货</p>
              <p style="color: #999; font-size: 13px">{{ tag.subTitle }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { trackEvent, trackUserEvent } from 'base/utils/rp'
import { IconFont, CountDown } from 'components'
import { mapGetters, mapState } from 'vuex'
import xEnum from 'base/utils/enum'

const chinaMobilePIdMap = ['857699249553408', '857699265806336', '857699284680704']

export default {
  components: { IconFont, CountDown },
  props: {
    goods: {
      type: Object,
      default: () => {}
    },

    activityType: {
      type: String,
      default: () => 'point'
    },

    showStock: {
      type: Boolean,
      default: false
    },

    showShareBtn: {
      type: Boolean,
      default: () => true
    }
  },

  data() {
    return {
      xEnum,
      countDownEnd: dayjs(),
      tags: [
        {
          title: '差必赔',
          iconFont: 'iconpeichangjine',
          subTitle: '平台担保商品质量，最高可全额赔付',
          icon: require('base/assets/img/baozhang.png')
        },
        { title: '假一赔十', iconFont: 'icontubiao-', subTitle: '若商品非官方正品，平台承诺假一赔十' },
        { title: '7天退换', iconFont: 'icontui', subTitle: '平台承诺七天无理由退换货' },
        { title: '全国包邮', iconFont: 'iconyou', subTitle: '新疆、西藏等偏远地区除外' }
      ],

      goodsRanks: [
        { id: '-1', name: '爆品榜', goodsGroupType: xEnum.ENUM_GOODS_GROUP_TYPE.RUSH_EXPLODE_GROUP.value },
        { id: '0', name: '疯抢榜', goodsGroupType: xEnum.ENUM_GOODS_GROUP_TYPE.RUSH_RANK_GROUP.value },
        { id: '1', name: '新品榜', goodsGroupType: xEnum.ENUM_GOODS_GROUP_TYPE.RUSH_NEW_GROUP.value }
      ],

      rankInfo: {}
    }
  },

  computed: {
    ...mapGetters({
      appState: 'appState'
    }),

    ...mapState({
      pId: (state) => state.appState.pId
    }),

    payOnDeliveryDesc() {
      return this.activityType === 'payOnDelivery' ? '验货满意后再付款' : '立即免费领取'
    },

    couponDesc() {
      const flag = chinaMobilePIdMap.find((item) => {
        return item === this.pId
      })
      if (flag) {
        return '移动用户体验券'
      } else {
        return this.renderBrandElement({ id: 'headerInfo.couponDesc' })
      }
    }
  },

  watch: {
    goods: {
      handler(newVal) {
        if (newVal.goodsRank) {
          const rank = this.goodsRanks.find((item) => {
            return this.goods?.goodsRank?.type === item.goodsGroupType
          })
          this.rankInfo = {
            id: rank.id,
            name: rank.name,
            ranking: this.goods?.goodsRank?.rank <= 10 ? this.goods?.goodsRank?.rank : null
          }
        }
      },

      immediate: true,
      deep: true
    }
  },

  created() {
    if (this.activityType === 'point') {
      this.countDownEnd = dayjs().endOf('d')
    } else if (this.activityType === 'drawPrize') {
      this.countDownEnd = dayjs().add(15, 'm')
    } else {
      const PERIODS = [0, 8, 9, 10, 12, 18, 20, 24]
      const currentHour = dayjs().get('hour')
      let i = 0
      for (i = 0; i < PERIODS.length - 1; i++) {
        if (currentHour < PERIODS[i]) break
      }
      this.countDownEnd = dayjs().set('hour', PERIODS[i]).set('minute', 0).set('second', 0)
    }
  },

  mounted() {},
  beforeDestroy() {},

  methods: {
    onClickService() {
      trackEvent({
        category: `点击-详情页-服务说明`,
        action: null
      })

      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_INFO_BAR_CLICK.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: this.goods?.goodsDetailEx?.goodsId,
          goodsPrice: this.goods?.goodsDetailEx?.price,
          score: this.goods?.goodsDetailEx?.score,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      })

      const bg = document.querySelector('.footDialogBg')
      const fg = document.querySelector('.footDialog')
      if (bg.style.zIndex == -1 || bg.style.zIndex == '') {
        bg.style.zIndex = 9999
        bg.style.backgroundColor = 'rgba(0,0,0,.5)'
        document.body.style.overflow = 'hidden'
        fg.style.bottom = '0%'
      } else {
        bg.style.zIndex = -1
        bg.style.backgroundColor = 'rgba(0,0,0,0)'
        document.body.style.overflow = 'scroll'
        fg.style.bottom = '-100%'
      }
    },

    toShare() {
      this.$emit('to-share')

      trackEvent({
        category: `点击-详情页-分享按钮`,
        action: '总数'
      })
    },

    toBuy() {
      this.$emit('click-buy')
      trackEvent({
        category: `点击-详情页-中部立即兑换按钮`,
        action: '总数'
      })
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_INFO_BUY_CLICK.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: this.goods?.goodsDetailEx?.goodsId,
          goodsPrice: this?.goods.goodsDetailEx?.price,
          score: this.goods?.goodsDetailEx?.score,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      })
    },

    payOnDelivery() {
      this.$emit('pay-on-delivery')
    },

    rankClick(rankTabIndex) {
      if (rankTabIndex === '-1') {
        this.$router.push({ path: '/leftFirst' })
      } else {
        this.$router.push({ name: 'leftFirst', params: { rankTabIndex: rankTabIndex } })
      }
      trackEvent({
        category: `点击-详情页-榜单`,
        action: '总数'
      })
    }
  }
}
</script>
<style>
.video-controls {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 2.4rem;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.video-controls:before {
  position: absolute;
  top: 50%;
  left: 52%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  width: 0;
  height: 0;
  border-top: 0.48rem solid transparent;
  border-left: 0.8rem solid #fff;
  border-bottom: 0.48rem solid transparent;
}
</style>
<style lang="less" scoped>
.rank {
  padding: 10px 6px 10px 10px;
  box-sizing: border-box;
  background: linear-gradient(90deg, #fff6e7 0%, rgba(254, 246, 233, 0.2) 100%);
  border-radius: 5px;
  .tag {
    // width: 52px;
    height: 19px;
    // padding: 2px 6px;
    box-sizing: border-box;
    background: linear-gradient(135deg, #e2b87a 0%, #e38647 100%);
    border-radius: 5px;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .rank-info {
    color: #ab6d44;
  }
}
.btn {
  height: 45px;
  width: 100%;
  border-radius: 6px;
  color: white;
  background: linear-gradient(to right, rgb(255, 36, 0), rgb(254, 160, 5));
  font-family: 'PingFangSC-Medium';
}
.com-goods-header-info {
  border-radius: 10px;
  position: relative;

  .content {
    background-color: #fff;
    border-radius: 10px;
  }

  .coupon-bg {
    background-image: url('~base/assets/img/goods_profile_header_info_coupon_bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: calc((100vw - 0.8rem) / 5.014);
  }

  .share-btn-box {
    padding: 5px 5px;
    box-sizing: border-box;
    border-radius: 15px 0px 0px 15px;
    background-color: #fcdad7;
    color: #e63729;
    font-size: 13px;
    height: 30px;
    img {
      width: 40px;
      height: 25px;
    }
  }
  .divider {
    width: 96%;
    position: relative;
    left: 2%;
    height: 1px;
    background-color: #f2f2f2;
  }
  .service-img {
    width: 50px;
    height: 16px;
  }
  .service-title {
    color: #b29183;
    border: 1px solid #bf987e;
    border-radius: 5px;
    margin-right: 5px;
  }
  .service-icon {
    font-weight: bold;
    margin-right: 2px;
  }
  .goods-name {
    line-height: 1.5;
    box-sizing: border-box;
  }
  .extra-font-style {
    font-size: 20px;
  }
  .sell-keys {
    position: relative;
    .tag-inline:not(:last-child) {
      margin-right: 10px;
    }
    .share-icon {
      float: right;
    }
  }

  .recommend {
    .arrow {
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid #f2f2f2;
      margin-left: 15px;
    }

    .desc {
      font-size: 12px;
      background-color: #f2f2f2;
      color: #4c4c4c;
      border-radius: 5px;
      line-height: 1.5;
    }
  }
  .pay-on-delivery {
    height: 40px;
    background: #d92e21;
    border-radius: 8px;
    margin: 0 auto;
  }

  .footDialogBg {
    position: fixed;
    top: 0px;
    background-color: rgba(0, 0, 0, 0);
    height: 100%;
    width: 100%;
    z-index: -1;
    transition: 0.5s;
  }
  .footDialog {
    position: absolute;
    bottom: -100%;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 100px;
    width: 100%;
    transition: 0.5s;
  }
  .fd_title {
    position: relative;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px #f0f0f0 solid;
  }
  .fd_title_close {
    position: absolute;
    right: 15px;
    font-weight: normal;
    border: 1px solid #999;
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    height: 18px;
    width: 18px;
    color: #999;
    line-height: 14px;
    top: 13px;
  }
  .banner_float {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    width: 50%;
  }

  .top-swipe {
    height: 100vw;
  }
  .before-goodsname {
    position: relative;
    top: 2px;
    height: 22px;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    span {
      width: 105px;
      height: 22px;
      color: #fff;
    }
    img {
      width: 105px;
      height: 22px;
    }
  }
}
</style>
