<template>
  <div ref="liveItem" @click="onClick" class="live-item-container mt2">
    <div class="title flex justify-between">
      <div class="room-info flex flex-auto">
        <div class="pic">
          <van-image :src="goodsLiveItemVO.liveRoomIcon" />
        </div>
        <span class="name ml1">{{ goodsLiveItemVO.liveRoomName }}的直播间</span>
      </div>
      <div class="watch-times flex flex-none">
        <div class="gif">
          <img src="~/base/assets/img/videoLive/zhibogif.gif" alt="" />
        </div>
        <span class="times ml1">{{ goodsLiveItemVO.viewsDesc }}</span>
      </div>
    </div>
    <div class="content flex justify-between mt2">
      <div class="goods-pic">
        <div v-if="!isActiveVideo">
          <van-image :src="goodsLiveItemVO.coverImgUrl" class="img" />
          <div class="mask">
            <IconFont size="30px" icon="iconicon_play" color="#fff" class="play-icon"></IconFont>
          </div>
        </div>
        <tp-video
          v-else
          ref="videoPlayer"
          class="video-player"
          :src="goodsLiveItemVO.liveVideoUrl"
          @on-player-play="videoPlaying = true"
          @on-player-pause="videoPlaying = false"
          :width="117"
          :height="117"
          :controls="true"
          :loop="true"
          :muted="true"
        >
        </tp-video>
      </div>
      <div class="goods-info">
        <div class="goods-name">{{ source.goodsName }}</div>
        <div class="goods-desc mt1">{{ source.goodsDesc }}</div>
        <div class="price-info flex justify-between mt3">
          <div class="cheap-price">
            <div v-if="itemType === 'point'" class="flex items-center">
              <div class="color_primary" style="font-size: 16px">{{ source.costPoints }}{{ globalPointName }}</div>
              <div class="color_primary">+</div>
              <span class="color_primary">{{ source.price }}元</span>
            </div>
            <div v-if="itemType === 'seckill'" class="flex items-center">
              <div class="color_primary" style="font-size: 13px">补贴价</div>
              <span class="color_primary ml1" style="font-size: 16px">{{ source.price }}元</span>
            </div>
          </div>
          <div class="original-price">原价:￥{{ source.originalPrice }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconFont } from 'components'
import { mapState } from 'vuex'
import TpVideo from 'components/TpVideo'

export default {
  name: 'VideoLiveItem',
  components: { IconFont, TpVideo },
  props: {
    source: {
      type: Object,
      default: () => {}
    },

    index: {
      type: Number,
      default: 0
    },

    onClickItem: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      itemType: this.renderBrandElement({ id: 'live.goodsType' }),
      isActiveVideo: false, // 是否为列表中当前激活的视频
      videoPlaying: true
    }
  },

  computed: {
    ...mapState({
      isInWechat: (state) => state.appState.isInWechat
    }),

    goodsLiveItemVO() {
      if (this.source && this.source.goodsLiveItemVO) {
        return this.source.goodsLiveItemVO
      } else {
        return {}
      }
    }
  },

  watch: {},

  created() {},
  mounted() {
    window.addEventListener('scroll', this.visibleAreaPlay)
  },

  destroyed() {
    window.removeEventListener('scroll', this.visibleAreaPlay) // 每个组件中添加的定时器和事件监听器在组件销毁时都要记得移除！！！---> double
  },

  methods: {
    visibleAreaPlay() {
      // console.log('距离顶部高度',this.$refs.liveItem.getBoundingClientRect().top)
      // 微信浏览器不支持视频自动播放
      if (this.isInWechat) {
        return
      }

      if (
        this.$refs.liveItem.getBoundingClientRect().top > 100 &&
        this.$refs.liveItem.getBoundingClientRect().top < 285
      ) {
        if (!this.isActiveVideo) {
          this.isActiveVideo = true
          setTimeout(() => {
            this.$refs.videoPlayer && this.$refs.videoPlayer.player.play()
          }, 500)
        }
      } else {
        if (this.isActiveVideo) {
          this.isActiveVideo = false
          setTimeout(() => {
            this.$refs.videoPlayer && this.$refs.videoPlayer.player.pause()
          }, 500)
        }
      }
    },

    onClick() {
      const { source, index } = this

      this.$router.push({ path: `/live/detail/${source.id}` })
      this.onClickItem(source, index)
    }
  }
}
</script>

<style lang="less" scoped>
.live-item-container {
  width: 100%;
  height: 175px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  .title {
    height: 25px;
    line-height: 25px;
    .room-info {
      width: 100%;
      height: 25px;
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .pic {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .watch-times {
      width: 120px;
      height: 25px;
      line-height: 25px;
      background-color: #999999;
      border-radius: 20px;
      color: #fff;
      font-size: 12px;
      .gif {
        width: 40px;
        height: 25px;
        background-color: #f3192c;
        border-radius: 20px;
        // padding: 3px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        img {
          width: 50%;
          height: 80%;
        }
      }
    }
  }
  .content {
    display: flex;
    height: 120px;
    .goods-pic {
      width: 35%;
      height: 98%;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      .img {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
      }
      .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        z-index: 2;
      }
      .play-icon {
        position: absolute;
        z-index: 3;
        top: 35%;
        left: 35%;
      }
    }
    .goods-info {
      width: 60%;
      .goods-name {
        font-size: 15px;
      }
      .goods-desc {
        font-size: 12px;
        color: gray;
        width: 90%;
        height: 52px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
    .price-info {
      .cheap-price {
        span:nth-child(1) {
          color: #ff0802;
          font-weight: bold;
          font-size: 16px;
        }
        span:nth-child(2) {
          color: #ff0802;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
