<template>
  <div>
    <van-swipe class="my-swipe" :autoplay="3000" :loop="true" indicator-color="white">
      <van-swipe-item v-for="(item, index) in activityEntryData" :key="item.img" @click="onClick(item, index)">
        <van-image class="sbImg1" :src="item.img" />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import xEnum from 'base/utils/enum'
import utils from 'base/utils'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

/**
 * 引导用户打开app的导航栏
 */
export default {
  name: 'ActivityEntrySectionThemed',
  components: {},
  props: {
    activityEntryData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      entries: []
    }
  },

  computed: {
    ...mapState({
      isInWechat: (state) => state.appState.isInWechat,
      isInApp: (state) => state.appState.isInApp
    })
  },

  created() {},
  destroyed() {},

  methods: {
    async onClick(source, index) {
      let placeType = ''
      let placeDetail = ''
      if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.value) {
        const mpScheme = await this.$store.dispatch('getMpUrlScheme', source.businessKey)
        location.href = mpScheme
        placeDetail = source.businessKey
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.getText()
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.value) {
        this.$router.push({ path: '/activity/brand', query: { pageId: source.businessKey.pageId } })
        placeDetail = source.businessKey.pageId
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.getText()
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.value) {
        this.$router.push({
          path: `/goodsProfile/${source.businessKey.goodsId}`,
          query: { type: source.businessKey.type }
        })
        placeDetail = source.businessKey.goodsId
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.getText()
      } else {
        const isWebUrl = utils.isWebUrl(source.businessKey.path)
        if (isWebUrl) {
          window.location.href = source.businessKey.path
        } else {
          this.$router.push({ path: source.businessKey.path })
        }
        placeDetail = source.businessKey.path
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.LINK.getText()
      }

      sendQuickTrackingEvent({
        eventName: 'dacuecmc_myorder_place_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_myorder_page',
        params: {
          place_type: placeType,
          place_name: '我的订单页-中间-banner',
          place_addr: index,
          place_detail: placeDetail
        }
      })
    }

    // getEventInfo(item, index) {
    //   return {
    //     eventType: xEnum.ENUM_RP_EVENT_TYPE.MY_ORDER_PAGE_SOURCE_PLACE_EP.value,
    //     eventInfo: {
    //       place_type: xEnum.ENUM_ADVERTISE_URL_TYPE.toText(item.urlType),
    //       place_name: '我的订单-中间-banner',
    //       place_addr: index
    //     }
    //   }
    // }
  }
}
</script>
<style lang="less" scoped>
.item {
  width: 32%;
}
.entry-icon {
  width: 100%;
}
</style>
