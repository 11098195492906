<template>
  <div class="com-comment-gallery mt2 mx2 py2 px2" v-if="goodsCommentList.length > 0">
    <div class="H_pl">
      <comment :comment="commentInfo" />
      <div class="H_pl_container_box">
        <div v-for="(item, index) in getTopThreePopList(goodsCommentList)" :key="index" class="H_pl_ubox">
          <div class="H_pl_user">
            <img class="H_pl_uImg" :src="item.userLogo" />
            <div class="H_pl_info">
              <p class="H_pl_uname">{{ item.userName }}</p>
              <p class="H_pl_goodsName">{{ goodsName }}</p>
            </div>
          </div>
          <div class="H_pl_txt">{{ item.content }}</div>
          <div class="goods-pics" v-if="getPicUrl(item.commentImagesArr).length">
            <div
              v-for="(picUrl, _index) in getPicUrl(item.commentImagesArr)"
              :key="_index"
              @click="imgPreview(getPicUrl(item.commentImagesArr), _index)"
            >
              <img :src="picUrl" alt="" class="goods-pic" />
            </div>
          </div>
          <van-rate
            v-model="item.score"
            :count="5"
            :size="16"
            color="#E54635"
            void-icon="star"
            void-color="#eee"
            readonly
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { IconFont, SectionTitle } from 'components'

// vue 函数式组件+jsx
// https://learnku.com/articles/4382/seven-ways-to-define-a-component-template-by-vuejs
// https://cn.vuejs.org/v2/guide/render-function.html#%E5%87%BD%E6%95%B0%E5%BC%8F%E7%BB%84%E4%BB%B6
// https://www.cnblogs.com/star91/p/ru-he-zai-yi-ge-wen-jian-zhong-xie-duo-gevue-zu-ji.html
Vue.component('Comment', {
  // 加 functional 才能编译成内联组件，style 的 scope 能够跟父保持一致。
  functional: true,
  components: { IconFont, SectionTitle },
  // eslint-disable-next-line vue/require-prop-types
  props: ['comment'],
  render(createElement, context) {
    return (
      <SectionTitle title={`用户评价`} style="margin-bottom: 15px">
        <div class="H_pl_t2 fs14 flex items-center" style="color:#d92e21;">
          {Number(context.props.comment.rate).toFixed(2)}%
          <span class="ml1 fs14" style="color:#999999;">
            好评率
          </span>
        </div>
      </SectionTitle>
    )
  }
})
import { ImagePreview } from 'vant'
export default {
  name: 'CommentGallery',
  props: {
    goodCommentCount: {
      type: Number,
      default: 0
    },

    goodCommentRate: {
      type: Number,
      default: 0
    },

    goodsCommentList: {
      type: Array,
      default: () => []
    },

    goodsName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      goodsCommentWidth: document.documentElement.offsetWidth * 0.7
    }
  },

  computed: {
    commentInfo() {
      return {
        count: this.goodsCommentList.length > 0 ? this.goodCommentCount : '',
        rate: this.goodsCommentList.length > 0 ? this.goodCommentRate : '暂无评价'
      }
    }
  },

  methods: {
    getTopThreePopList(list) {
      list = JSON.parse(JSON.stringify(list))
      list.sort((a, b) => {
        return b.priority - a.priority
      })
      return list.slice(0, 3)
    },

    getPicUrl(urls) {
      if (!urls) return []
      return urls
    },

    imgPreview(images, startPosition) {
      ImagePreview({
        images,
        closeable: true,
        startPosition
      })
    }
  }
}
</script>
<style lang="less" scoped>
.com-comment-gallery {
  background: #fff;
  border-radius: 10px;
  /*评论*/
  .H_pl {
    /* margin-top: 10px; */
    border-radius: 4px;
  }
  .H_pl_title {
    font-size: 0;
    margin: 0 auto;
    padding: 15px 0;
    /* border-bottom: 1px solid #ececee; */
  }
  .H_pl_t1 {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    font-size: 0.6rem;
    text-align: left;
    color: #323332;
  }
  .H_pl_t2 {
    text-align: right;
    color: #828282;
  }
  .H_pl_container_box {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .H_pl_ubox {
    width: 100%;
    border-radius: 3px;
    min-height: 97px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    vertical-align: top;
    position: relative;
    border-bottom: 0.5px solid #f2f2f2;
    padding-bottom: 15px;
    &:nth-last-child(1) {
      border-bottom: none;
      padding: 0;
    }
  }
  .H_pl_ub1 {
    width: auto; /*根据内容调整宽度*/
  }
  .H_pl_info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .H_pl_user {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  .H_pl_uImg {
    height: 36px;
    aspect-ratio: 1;
    vertical-align: middle;
    border-radius: 5px;
  }
  .H_pl_uname {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.6rem;
    color: #2a2a2c;
  }
  .H_pl_goodsName {
    color: #999999;
    font-size: 12px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .H_pl_txt {
    font-size: 0.6rem;
    color: #4d4d4d;
    letter-spacing: 1px;
  }
}
.goods-pics {
  display: flex;
  flex-direction: row;
  gap: 8px;
  .goods-pic {
    height: 90px;
    width: 90px;
    border-radius: 5px;
  }
}
</style>
