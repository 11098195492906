<template>
  <div class="com-bottom-padding" :style="{ height: height + 'px', backgroundColor: bgColor }"></div>
</template>

<script>
/**
 * 组件用于底部占位，当页面底部使用了fixed元素时，使用该占位组件，而不要直接使用padding属性。
 * 否则在华为浏览器中，当浏览器地址栏隐藏时，会导致底部点击区域失效
 */
export default {
  name: 'BottomPadding',
  props: {
    height: {
      type: Number,
      default: () => 60
    },

    bgColor: {
      type: String,
      default: '#f5f5f5'
    }
  },

  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.com-bottom-padding {
  width: 100%;
}
</style>
