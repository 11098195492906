<template>
  <div class="mx2 px2 my2 py2 bg-white rounded-[10px]">
    <div class="fs14 bg-[#f2f2f2] rounded-[5px] p2">
      <div class="text-[#E54635]">推荐理由</div>
      <div class="text-[#4d4d4d]">
        <span v-for="(item, index) in goods.recommendReasonList" :key="index" class="item">{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecommendContent',
  props: {
    goods: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped></style>
