<template>
  <div class="select-payment-container relative">
    <van-radio-group v-model="payment">
      <div class="pay" v-for="(payment, index) in paymentList" :key="index" @click="onChange(payment.value)">
        <div class="payBox">
          <img class="payImg" :src="payment.icon" />
          <ul class="payTxt">
            <li class="payt1 flex items-center">
              {{ payment.label }}
              <span v-if="index === 0" class="fs12" style="color: #e54635">(推荐)</span>
              <div>
                <span class="color_tips fs12">{{ payment.tip }}</span>
              </div>
            </li>
          </ul>
          <ul class="paytj">
            <van-radio :name="payment.value" checked-color="#e00" style="float: right" />
          </ul>
        </div>
      </div>
      <div
        v-if="appState.isInAliPay || appState.isInWechat"
        class="p2 fs12 flex items-center justify-center color_tips"
      >
        <span v-if="appState.isInAliPay">微信</span>
        <span v-if="appState.isInWechat">支付宝</span>
        <span>支付，请复制链接后在浏览器打开</span>
      </div>
    </van-radio-group>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xEnum from 'base/utils/enum'

export default {
  props: {
    enableWxPay: {
      type: Boolean,
      default: false
    },

    enableAliPay: {
      type: Boolean,
      default: false
    },

    enableCloudPay: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      xEnum,
      ENUM_PAYMENT_TYPE: xEnum.ENUM_PAYMENT_TYPE,
      payment: xEnum.ENUM_PAYMENT_TYPE.ALI.getValue(),
      showArrivePay: false,
      defaultWxPay: xEnum.DEFAULT_PAY_TYPE.WEIXIN.getValue(),
      defaultAlipayPay: xEnum.DEFAULT_PAY_TYPE.ALIPAY.getValue()
    }
  },

  computed: {
    ...mapState({
      defaultPayType: (state) => state.appState.defaultPayType
    }),

    ...mapGetters({
      appState: 'appState'
    }),

    wxPayment() {
      return {
        label: '微信支付',
        value: xEnum.ENUM_PAYMENT_TYPE.WX.getValue(),
        icon: 'https://img.bj0yx.com/901544176779265',
        tip: '安全方便',
        enable: this.enableWxPay && !this.appState.isInAliPay
      }
    },

    aliPayment() {
      return {
        label: '支付宝支付',
        value: xEnum.ENUM_PAYMENT_TYPE.ALI.getValue(),
        icon: 'https://img.bj0yx.com/901544176779264',
        tip: '支持花呗分期',
        enable: this.enableAliPay && !this.appState.isInWechat
      }
    },

    yinlianPayment() {
      return {
        label: '云闪付',
        value: xEnum.ENUM_PAYMENT_TYPE.CLOUD.getValue(),
        icon: 'https://img.bj0yx.com/905338958643200',
        tip: '方便快捷',
        enable: this.enableCloudPay && !this.appState.isInAliPay && !this.appState.isInWechat
      }
    },

    paymentList() {
      let list = []
      if (this.defaultPayType === this.defaultWxPay) list = [this.wxPayment, this.aliPayment, this.yinlianPayment]
      else if (this.defaultPayType === this.defaultAlipayPay)
        list = [this.aliPayment, this.wxPayment, this.yinlianPayment]
      else list = [this.yinlianPayment, this.wxPayment, this.aliPayment]
      list = list.filter((item) => item.enable)
      if (list.length) this.onChange(list[0].value)
      return list
    }
  },

  methods: {
    onChange(payment) {
      this.payment = payment
      this.$emit('on-change', payment)
    }
  }
}
</script>
<style lang="less" scoped>
.select-payment-container {
  /*支付方式*/
  .title {
    width: 95%;
  }
  .pay {
    padding: 10px 0;
    border-bottom: 1px dashed #e0e0e0;
  }
  .pay:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 5px;
  }
  .payBox {
    width: 100%;
    margin: 0 auto;
  }
  .payImg {
    display: inline-block;
    vertical-align: middle;
    width: 36px;
  }
  .payTxt {
    width: calc(100% - 90px);
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  .payt1 {
    color: #4d4d4d;
    display: inline-block;
    font-size: 16px;
    // font-weight: 900;
  }
  .payf1 {
    color: #e00000;
    font-size: 16px;
    font-weight: normal;
  }
  .payf2 {
    color: #999;
    font-size: 16px;
    font-weight: normal;
  }
  .payt2 {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    font-size: 10px;
    text-align: center;
    line-height: 20px;
    height: 20px;
    background-color: #e00000;
    border-radius: 15px;
    color: #fff;
  }
  .paytj {
    width: 30px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
  }
  .payj1 {
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  .payj2 {
    width: 18px;
    display: inline-block;
    vertical-align: middle;
  }
  .ohterpay {
    text-align: center;
  }
  .otherpay1 {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.55rem;
    color: #191919;
  }
  .otherpayImg {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    margin-left: 5px;
  }
}
</style>
