import { render, staticRenderFns } from "./Seckill.vue?vue&type=template&id=ebd860b0&scoped=true&"
import script from "./Seckill.vue?vue&type=script&lang=js&"
export * from "./Seckill.vue?vue&type=script&lang=js&"
import style0 from "./Seckill.vue?vue&type=style&index=0&id=ebd860b0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebd860b0",
  null
  
)

export default component.exports