<template>
  <div>
    <div
      class="rank-item flex p2 mb2 relative"
      @click="onClick"
      v-track:exposure
      :track-params="JSON.stringify(eventInfo)"
    >
      <div class="medal medal1">
        <img class="icon-medal" :src="rankImgMap.get(index) || rankOthersImg" alt="" />
        <span class="absolute medal-num">{{ index + 1 }}</span>
      </div>
      <img class="br4" :src="item.imgUrl" alt="" />
      <div class="goods-info">
        <div class="rank-goodsname fs16 color_title" v-if="!showGoodsCardName">{{ item.goodsName }}</div>
        <div class="rank-goodsname fs16 color_title text-ellipsis">
          {{ item.goodsCardName || item.goodsDesc || item.goodsName }}
        </div>
        <div class="saleDesc fs12 color_tips">{{ item.saleDesc }}</div>
        <div class="flex items-center buy-info">
          <van-swipe :autoplay="3000" :show-indicators="false" :vertical="true" :touchable="false" style="height: 18px">
            <van-swipe-item
              class="flex swipe-item items-center"
              v-for="(item, index) in buyInfoList"
              :key="index"
              style="height: 18px"
            >
              <div class="fs12">{{ item.desc }}</div>
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="pirce-info flex items-center justify-between">
          <div class="color_primary fs14">
            <span class="bold">{{ item.costPoints }}{{ globalPointName }}</span>
            <span>+{{ item.price }}元</span>
          </div>
          <div class="color_tips fs12 line-through" style="text-decoration: line-through">
            {{ item.originalPrice }}元
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mock from 'base/utils/mock'
import { Dialog } from 'vant'
import xEnum from 'base/utils/enum'
import { trackUserEvent } from 'base/utils/rp'
import rankFirstImg from 'base/assets/img/icon/medal_top1.png'
import rankSecondImg from 'base/assets/img/icon/medal_top2.png'
import rankThirdImg from 'base/assets/img/icon/medal_top3.png'
import rankOthersImg from 'base/assets/img/icon/medal_other.png'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },

    type: {
      type: Number,
      default: 0
    },

    index: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      buyInfoList: [],
      rankImgMap: new Map([
        [0, rankFirstImg],
        [1, rankSecondImg],
        [2, rankThirdImg]
      ]),

      rankOthersImg
    }
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId
    }),

    eventInfo() {
      const groupName = this.type === 110 ? '疯抢排行-排行' : '疯抢排行-新品'
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_CARD_EP.value,
        eventInfo: {
          goodsId: this.item.id,
          goodsPrice: this.item.price,
          score: this.item.score,
          goodsGroupId: this.type,
          goodsName: this.item.goodsName,
          goodsIndex: this.index,
          eventSource: groupName,
          goodsCategoryId: this.item.categoryId
        }
      }
    }
  },

  created() {
    this.buyInfoList = _.chain(Array(10))
      .map(() => {
        const familyName = mock.familyNames[_.random(mock.familyNames.length - 1)]
        const second = _.random(1, 9)
        const userIcon = mock.avatars[_.random(mock.avatars.length - 1)]
        return {
          avatars: userIcon,
          desc: `${familyName}**在${second}分钟前${this.globalExchangeWord}了`
        }
      })
      .value()
  },

  methods: {
    onClick() {
      const { item, index } = this
      console.log(index)
      if (item.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        })
        return
      }

      this.$router.push({
        path: `/goodsProfile/${item.id}`,
        query: {
          type: this.type
        }
      })

      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: item.id,
        goodsName: item.goodsName,
        goodsIndex: index,
        goodsGroupId: this.type
      }
      trackUserEvent({
        eventType: 'goodsClick',
        eventInfo
      })

      const groupName = this.type === 110 ? '疯抢排行-排行' : '疯抢排行-新品'
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: item?.score,
          goods_group_type: this.type,
          goods_name: item.goodsName,
          goods_id: item.id,
          goods_index: index,
          display_price: item.price,
          display_original_price: item.originalPrice,
          goods_source_waterfall: groupName,
          goods_category_id: item.categoryId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.rank-item {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  .icon-medal {
    position: absolute;
    width: 26px;
    height: 29px;
    top: 0px;
    left: 0px;
  }
  img {
    width: 96px;
    height: 96px;
    margin-right: 8px;
  }
}
.goods-info {
  width: calc(100% - 96px - 8px);
}
.buy-info {
  width: fit-content;
  color: #804800;
  margin-top: 4px;
  box-sizing: border-box;
  padding: 4px 16px;
  background: linear-gradient(277deg, #fffcf4 0%, #fff3e3 100%);
  border-radius: 58px 58px 58px 58px;
}
.pirce-info {
  margin-top: 4px;
}
.medal {
  position: absolute;
  width: 26px;
  height: 29px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
.medal1 {
  color: #f3a844;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.2), 0px 0px 0px #d89a41;
}
.medal2 {
  color: #b1b1b1;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.2), 0px 0px 0px #9c9c9c;
}
.medal3 {
  color: #efa757;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.2), 0px 0px 0px #e8983f;
}
.medal-other {
  color: #cf8f3c;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.2), 0px 0px 0px #c17e29;
}
.medal-num {
  font-size: 12px;
  top: 2px;
}
</style>
