<template>
  <!-- 微信中统一不显示，因为微信会导致被判定为诱导分享 -->
  <div>
    <div @click="onShare" ref="floatBtn" class="float-icon-con" :class="{ isSettlement: isSettlement }">
      <!-- v-show 只能写在 img 上面，不能写到父 div 上面，因为父 div 即使 v-show=false 了，内部由于是 fix 布局，仍然会展示在界面上。 -->
      <!-- <img v-show="isShare && !appState.isInWechat && !isExpire" src="~base/assets/img/fuchuang.png" alt="" /> -->
      <div
        v-show="isShare && !appState.isInWechat && !isInAliPay && !isExpire"
        :style="{
          'background-image': `url(${tipFloatConfig?.img})`,
          'background-size': 'cover',
          width: '60px',
          height: '60px'
        }"
        class=""
      ></div>
      <div
        v-show="isExpire && !isInAliPay"
        :style="{ 'background-image': `url(${tipFloatConfig?.img})` }"
        :class="isShake ? ['animate__animated', 'animate__tada', 'animate__infinite', 'expire', 'flex'] : 'expire'"
      >
        <!-- <span class="point-name">{{ pointName }}</span>
        <span class="expire-text">即将过期</span> -->
      </div>
      <!-- <img  src="~base/assets/img/expire.png" alt="" > -->
    </div>
    <WechatShareMask :is-share.sync="maskVisible" />
    <div
      v-show="isService"
      class="service-btn"
      :class="isShake ? ['animate__animated', 'animate__tada', 'animate__infinite', 'expire', 'flex'] : 'expire'"
      @click="toService"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { shareDialog } from 'components/shareDialog'
import { trackEvent, trackUserEvent } from 'base/utils/rp'
import { WechatShareMask } from 'widgets'
import { eventBus } from 'base/utils/eventBus'
import brand from 'base/brands'
import xEnum from 'base/utils/enum'
import utils from 'base/utils'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

// props中无法使用vue全局变量，在此单独引入
// const globalPointName = brand.renderBrandElement({ id: 'global.pointName' })
// const shareUrl = brand.renderBrandElement({ id: 'share.mallUrl' })
const shareText = brand.renderBrandElement({ id: 'suspendShare.text' })

export default {
  name: 'SuspendShare',
  components: { WechatShareMask },
  props: {
    shareEventName: {
      type: String,
      default: () => '分享-其他'
    },

    shareText: {
      type: String,
      default: () => `${shareText}`
    },

    shareHint: {
      type: String,
      default: () => ''
    },

    isShare: {
      type: Boolean,
      default: () => true
    },

    isExpire: {
      type: Boolean,
      default: () => false
    },

    isSettlement: {
      type: Boolean,
      default: false
    },

    pointName: {
      type: String,
      default: () => '积分'
    },

    bgImg: {
      type: String,
      default: () => ''
    },

    isService: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      maskVisible: false,
      oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
      scrollTop: 0, // 记录当前的滚动距离
      delayer: null, //延时器
      isShake: true
    }
  },

  computed: {
    ...mapState({
      tipFloatConfig: (state) => state.appState.tipFloatConfig
    }),

    ...mapGetters({
      appState: 'appState'
    }),

    isInAliPay() {
      return this.$store.state.appState.isInAliPay
    }
  },

  watch: {
    scrollTop(newValue, oldValue) {
      this.delayer = setTimeout(() => {
        if (newValue == window.scrollY) {
          //延时执行后当newValue等于window.scrollY，代表滚动结束
          // console.log('滚动结束');
          this.$refs.floatBtn.style = 'right: 5px'
          this.isShake = true
          this.$refs.floatBtn.style.transition = '.3s'
          this.oldScrollTop = newValue //每次滚动结束后都要给oldScrollTop赋值
        }
      }, 1500) //使用延时器，否则每次newValue和window.scrollY都相等

      if (this.oldScrollTop == oldValue) {
        //每次滚动开始时oldScrollTop与oldValue相等
        // console.log('滚动开始');
        this.$refs.floatBtn.style = 'right: -35px'
        this.isShake = false
        this.$refs.floatBtn.style.opacity = ' .5'
        this.$refs.floatBtn.style.transition = '.3s'
      }
    }
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollTop = window.scrollY
    })
  },

  destroyed() {
    clearTimeout(this.delayer)
  },

  methods: {
    copySharetxt() {
      eventBus.$emit('copy-to-clipboard', {
        content: this.shareText,
        silent: false,
        onSuccess: () => {
          console.log('copy success')
          location.href = 'weixin://'
        }
      })
      trackEvent({
        category: this.shareEventName,
        action: '总数'
      })
    },

    async onShare() {
      //此分支没请求浮窗
      const flag = true
      if (flag) return
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.FLOAT_BAR_CLICK.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          webUrl: window.location.href
        }
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_points_floating_windows_clk',
        eventType: EventType.Click
      })
      if (this.tipFloatConfig.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.value) {
        // console.log('businessKey: ', this.tipFloatConfig.businessKey)
        const mpScheme = await this.$store.dispatch('getMpUrlScheme', this.tipFloatConfig.businessKey)
        // console.log('mpScheme: ', mpScheme)
        location.href = mpScheme
      } else if (this.tipFloatConfig.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.value) {
        this.$router.push({ path: '/activity/brand', query: { pageId: this.tipFloatConfig.businessKey.pageId } })
      } else if (this.tipFloatConfig.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.value) {
        this.$router.push({
          path: `/goodsProfile/${this.tipFloatConfig.businessKey.goodsId}`,
          query: { type: this.tipFloatConfig.businessKey.type }
        })
      } else {
        const isWebUrl = utils.isWebUrl(this.tipFloatConfig.businessKey.path)
        if (isWebUrl) {
          window.location.href = this.tipFloatConfig.businessKey.path
        } else {
          this.$router.push({ path: this.tipFloatConfig.businessKey.path })
        }
      }

      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.FLOAT_BAR_CLICK.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          webUrl: window.location.href
        }
      })
      if (this.isExpire) {
        return
      }
      if (this.appState.isInWechat) {
        this.maskVisible = true
      } else {
        shareDialog({
          shareCopyDesc: '快去微信粘贴给好友吧',
          shareBtnDesc: '去微信粘贴',
          shareHint: this.shareHint
        }).then(() => {
          this.copySharetxt()
        })
      }
    },

    toService() {
      this.$emit('pop-service')

      // 全局客服浮窗
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_service_windows_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.isSettlement {
  position: absolute !important;
  top: -20px !important;
  right: -20px !important;
}
.float-icon-con {
  position: fixed;
  top: 70%;
  right: 5px;
  z-index: 9;
  img {
    width: 60px;
    height: 60px;
  }
  .expire {
    width: 75px;
    height: 75px;
    // background: url(~base/assets/img/expire_bg.png) no-repeat;
    background-repeat: no-repeat;
    background-size: 75px 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    .point-name {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 900;
      text-shadow: 0px 3px 5px #7d0ea7;
      color: #fff9dd;
    }
    .expire-text {
      padding: 0;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 900;
      text-shadow: 0px 3px 5px #7d0ea7;
      color: #ffed98;
    }
  }
}
.service-btn {
  position: fixed;
  top: 70%;
  right: 5px;
  z-index: 9;
  background-image: url('https://img.bj0yx.com/889133674266624');
  background-repeat: no-repeat;
  background-size: 70px 74px;
  width: 70px;
  height: 74px;
  display: flex;
  justify-content: center;
  padding-top: 4px;
}
</style>
