<template>
  <van-count-down
    millisecond
    :format="format"
    :time="countDownTime"
    :style="{ color: fontColor }"
    class="count-down flex items-center justify-center"
  >
    <template v-if="type === 'normal'" v-slot="timeData">
      <span class="block" :style="{ backgroundColor: bgColor, ...blockStyle }">{{ timeData.hours | padZero }}</span>
      <span class="colon" :style="{ color: colonColor, ...colonStyle }">{{ colonText }}</span>
      <span class="block" :style="{ backgroundColor: bgColor, ...blockStyle }">{{ timeData.minutes | padZero }}</span>
      <span class="colon" :style="{ color: colonColor, ...colonStyle }">{{ colonText }}</span>
      <span class="block" :style="{ backgroundColor: bgColor, ...blockStyle }">{{ timeData.seconds | padZero }}</span>
      <span class="colon" v-show="millisecond" :style="{ color: colonColor, ...colonStyle }">{{ colonText }}</span>
      <span
        class="block min-w-[18px]"
        v-show="millisecond && msTwoLetter"
        :style="{ backgroundColor: bgColor, ...blockStyle }"
      >
        {{ timeData.milliseconds | formatMs }}
      </span>
      <span
        class="block min-w-[18px]"
        v-show="millisecond && !msTwoLetter"
        :style="{ backgroundColor: bgColor, ...blockStyle }"
      >
        {{ String(timeData.milliseconds).substring(0, 2) }}
      </span>
    </template>
    <template v-else v-slot="timeData">
      <div class="block split-digit-0" :style="{ backgroundColor: bgColor, ...blockStyle }">
        {{ timeData.hours | splitTime(0) }}
      </div>
      <div class="block split-digit-1" :style="{ backgroundColor: bgColor, ...blockStyle }">
        {{ timeData.hours | splitTime(1) }}
      </div>
      <div class="colon" :style="{ color: colonColor, ...colonStyle }">时</div>
      <div class="block split-digit-0" :style="{ backgroundColor: bgColor, ...blockStyle }">
        {{ timeData.minutes | splitTime(0) }}
      </div>
      <div class="block split-digit-1" :style="{ backgroundColor: bgColor, ...blockStyle }">
        {{ timeData.minutes | splitTime(1) }}
      </div>
      <div class="colon" :style="{ color: colonColor, ...colonStyle }">分</div>
      <div class="block split-digit-0" :style="{ backgroundColor: bgColor, ...blockStyle }">
        {{ timeData.seconds | splitTime(0) }}
      </div>
      <div class="block split-digit-1" :style="{ backgroundColor: bgColor, ...blockStyle }">
        {{ timeData.seconds | splitTime(1) }}
      </div>
      <div class="colon" :style="{ color: colonColor, ...colonStyle }">秒</div>
    </template>
  </van-count-down>
</template>

<script>
export default {
  components: {},
  filters: {
    padZero: (value) => _.padStart(value, 2, '0'),
    splitTime: (value, index) => {
      if (!value) {
        return '0'
      }

      const splits = String(value).split('')
      if (splits.length === 0) {
        return '0'
      } else if (splits.length === 1) {
        if (index === 0) {
          return '0'
        } else {
          return splits[0]
        }
      } else {
        return splits[index]
      }
    },

    formatMs(ms) {
      return _.padStart(_.slice(String(ms), 0, 2).join(''), 2, '0')
    }
  },

  props: {
    endTime: {
      type: Object,
      default: () => {}
    },

    bgColor: {
      type: String,
      default: '#F09820'
    },

    colonColor: {
      type: String,
      default: 'white'
    },

    fontColor: {
      type: String,
      default: '#fff'
    },

    blockStyle: {
      type: Object,
      default: () => {}
    },

    colonStyle: {
      type: Object,
      default: () => {}
    },

    colonText: {
      type: String,
      default: ':'
    },

    type: {
      type: String,
      default: 'normal' //normal, scoreboard
    },

    millisecond: {
      type: Boolean,
      defalut: false
    },

    format: {
      type: String,
      default: 'HH:mm:ss'
    },

    msTwoLetter: {
      type: Boolean,
      defalut: false
    }
  },

  data() {
    return {
      countDownTime: 0
    }
  },

  computed: {},
  watch: {
    endTime: function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        const now = dayjs()
        this.countDownTime = dayjs(newVal).diff(now, 'seconds') * 1000
      }
    }
  },

  async created() {
    const now = dayjs()
    const countDownTime = dayjs(this.endTime).diff(now, 'seconds') * 1000
    this.countDownTime = _.max([countDownTime, 0])
  },

  mounted() {},
  destroyed() {},

  methods: {}
}
</script>

<style lang="less">
.van-count-down {
  font-family: 'tahoma';
}
</style>
<style lang="less" scoped>
.count-down {
  .block {
    background-color: #f09820;
    text-align: center;
    font-size: 12px;
    border-radius: 3px;
    padding: 1px 4px;

    &.split-digit-0 {
      margin-right: 5px;

      &:not(:first-child) {
        margin-left: 5px;
      }
    }

    &.split-digit-1 {
      margin-right: 5px;
    }
  }
  .colon {
    padding: 0 2px;
    color: #fff;
  }
}
</style>
