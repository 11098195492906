<template>
  <van-action-sheet v-model="visible" cancel-text="完成" title="请选择优惠券">
    <div class="coupon-purchase-form px2 pt1">
      <div v-for="(coupon, index) in couponList" :key="index">
        <div class="flex item px3 py2 items-center">
          <div class="flex flex-auto items-center">
            <div class="color_primary mr3">
              <div>
                <span class="fs24 bold">
                  {{ coupon.money }}
                </span>
                <span class="fs14 ml1">元</span>
              </div>
              <div>满{{ coupon.minUsePrice }}元使用</div>
            </div>
            <div>
              <div class="bold">专属优惠券</div>
              <div>{{ coupon.validDateStr }}</div>
            </div>
          </div>
          <van-radio-group v-model="selectCouponIndex">
            <van-radio :name="0" checked-color="#e00"></van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
import { ActionSheet } from 'vant'
export default {
  name: 'CouponActionSheet',
  components: { [ActionSheet.name]: ActionSheet },
  props: {
    couponList: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      visible: false,
      selectCouponIndex: 0
    }
  },

  computed: {},
  created() {},
  methods: {
    show() {
      this.visible = true
    },

    close() {
      this.visible = false
    }
  }
}
</script>
<style lang="less">
.purchase-action-sheet {
  &.van-popup {
    overflow-y: hidden;
  }

  .van-tag {
    min-width: 60px;
    text-align: center;
    justify-content: center;
    height: 30px;
    box-sizing: border-box;
  }
}
</style>
<style lang="less" scoped>
.coupon-purchase-form {
  height: 50vh;
  background-color: #f7f7f7;

  .item {
    background-color: white;
    border-radius: 5px;
    margin-top: 10px;
  }
  .info-zone {
    .spec-zone {
      // 此处不能写死高度，写死会导致vivo浏览器底部遮挡
      min-height: 200px;
      // overflow: scroll;
    }
  }

  .dot {
    width: 6px;
    height: 18px;
    border-radius: 3px;
    background-color: #d92e21;
  }

  .spec-str {
    .label {
      color: #9c9c9c;
    }
    font-size: 13px;
    // color: #E85D76;
    color: #d92e21;
  }
  .degree-of-heat {
    color: #666666;
    img {
      width: 18px;
      height: 23px;
    }
  }
  .service-tag {
    .van-tag {
      background: linear-gradient(-90deg, #61bbfd 0%, #438eff 99%);
      padding: 5px 5px;
      font-size: 11px;
      line-height: 9px;
    }
  }

  .invalid {
    background-color: #eeeeee !important;
    color: rgba(51, 51, 51, 0.3) !important;
    text-decoration: line-through;
  }

  .spec-title {
    font-size: 15px;
  }

  .submit-btn {
    margin-bottom: 20px;
    border-radius: 22px;
    height: 44px;
    text-align: center;
    color: #fff;

    .extra-text {
      font-size: 12px;
    }

    .submit-text {
      font-size: 16px;
    }
  }
}
</style>
