<template>
  <div class="com-back-top flex items-center center justify-center" @click="goTop" v-show="isVisible">
    <IconFont size="25px" icon="iconi-back-top" color="#DDDDDD"></IconFont>
  </div>
</template>

<script>
import utils from 'base/utils'
import { IconFont } from 'components'
import { trackEvent, trackUserEvent } from 'base/utils/rp'
import xEnum from 'base/utils/enum'

export default {
  name: 'BackTop',
  components: {
    IconFont
  },

  props: {
    visibleOffsetTop: {
      type: Number,
      default: () => 1000
    },

    animationTime: {
      type: Number,
      default: () => 200
    },

    targetDomName: {
      type: String,
      default: () => '#app'
    },

    currentPageName: {
      type: String,
      default: '默认'
    },

    goods: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      isVisible: false
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    goTop() {
      utils.scroll(this.targetDomName, this.animationTime)
      trackEvent({
        category: `点击-返回顶部-${this.currentPageName}`,
        action: '总数'
      })
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_BACK_TO_TOP.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: this.goods?.goodsId,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      })
    },

    handleScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.isVisible = scrollTop >= this.visibleOffsetTop
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.com-back-top {
  position: fixed;
  bottom: 80px;
  right: 25px;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: white;
}
</style>
