// eslint-disable-next-line
;(function (doc, win) {
  const docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      // var clientWidth = docEl.clientWidth;
      // if (!clientWidth) return;
      // docEl.style.fontSize = 50 * (clientWidth / 375) + 'px';
      // console.log(docEl.style.fontSize)
    }
  // TODO: 1rem 应该设为我们设计标准的基础大小。比如 12 或者 14px。
  // 3.75让iphone6下fontsize刚好为14px。
  // docEl.style.fontSize = docEl.getBoundingClientRect().width / 375*14 + "px";
  docEl.style.fontSize = docEl.getBoundingClientRect().width / 15 + 'px'

  if (!doc.addEventListener) return

  win.addEventListener(resizeEvt, recalc, false)

  // doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window)
