// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import axios from 'axios'
const BASE_URL = 'https://exapi.svipduihuan.com/api/'
// const BASE_URL = 'http://192.168.1.155:8081/api/'

const srvApi = axios.create({
  // timeout: 10000,
  baseURL: BASE_URL
})

// srvApi.interceptors.request.use(function (config) {
//   let rootState = store.getState()
//   let { dexAccount: { address } } = rootState
//   // config.headers.common['x-user-wallet-addr'] = '0x07f452344909f601aa8f9167e673d432404735d9'
//   config.headers.common['x-user-wallet-addr'] = address
//   return config
// })

const postRegInfo = (params) => srvApi.post(`quick/v1/regInfo`, params)
const getRankingList = (params) => srvApi.get(`quick/v1/dtk/rankings`, { params })
const getNineGoodsList = (params) => srvApi.get(`quick/v1/dtk/nineGoods`, { params })
const getGoodsList = (params) => srvApi.get(`quick/v1/dtk/goods`, { params })
const getPrivilegeLink = (params) => srvApi.get(`quick/v1/dtk/privilegeLink`, { params })

const getChannelByPid = (pId) => srvApi.get(`web/v1/channels/pId/${pId}`)
const getChannelDrawPrizeList = (pId, clientId) =>
  srvApi.get(`web/v1/channels/pId/${pId}/drawPrizes`, { params: { clientId } })
const getChannelAdvertisementList = (pId) => srvApi.get(`web/v1/channels/pId/${pId}/advertisements`)
const getAbAllocationByAbTests = (params) => srvApi.post(`web/v1/abTests/abAllocations`, params)

export default {
  postRegInfo,
  getRankingList,
  getNineGoodsList,
  getGoodsList,
  getPrivilegeLink,
  getChannelByPid,
  getChannelDrawPrizeList,
  getChannelAdvertisementList,
  getAbAllocationByAbTests
}
