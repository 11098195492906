import baseConfig from './config.base'
import _ from 'lodash'

/**
 * 今日兑-兑换币-H5
 */
const config = {
  'global.componentName': 'A',
  'global.showTabbar': true,
  'global.showRecommendGoods': true, // 是否显示推荐商品组
  'global.brand': '今日兑',
  'global.quickBrand': '今日兑商城',
  'global.company': '今日兑商城',
  'global.mall': '今日兑商城',
  'global.logo': 'favicon/logo.png',
  'global.appDownloadUrl': '', //TODO: 暂时没有，先空置
  'global.icp': '',
  'global.enableQuick': false,
  'global.enableApp': false,
  'global.isTabbarWelfare': false,
  'global.antiFraudStatementUrl': 'https://mp.weixin.qq.com/s/9mypuszrdw4qqTLVezDQFw',
  'global.pointName': '兑换币',
  'global.exchangeWord': '兑换',
  'global.pointTabbarName': '兑换币中心',
  'privacy.agreement': '',
  'global.defaultRouterPath': 'primary',
  'primary.tabbarName': '兑换币中心',
  'primary.tabbarIcon': 'iconjifen',
  'primary.component': 'pointCenter',
  'primary.topBanner': 'https://img.bj0yx.com/870960266215424',
  'live.title': '今日兑直播',
  'live.swipeLiving': 'videoLive/living_banner.png',
  'home.topLeftLogo': 'home_header_logo_jinridui.png',
  'homeSwipe.point': 'themes/common/home_swipe_pointExchange_jinri.png',
  'homeSwipe.returnScore': 'themes/common/home_swipe_returnscore_jinri.png',
  'homeKingKong.primaryTitle': '兑换币换购',
  'homeKingKong.enableReturnScore': true,
  'homeKingKong.enableLive': false,
  'homeActivity.title': '兑换币中心',
  'homeActivity.subTitle': '兑换币当钱花，好礼享不停',
  'homeActivity.metaText': '',
  'homeActivity.metaShowPoint': true,
  'homeActivity.point1': 'themes/common/home_entrance_exchange_1.png',
  'homeActivity.point2': 'themes/common/home_entrance_exchange_2.png',
  'homeCapsule.banner': 'home_capsule/wine_capsule.gif',
  'homeCapsule.navigateType': 'wine',
  'mine.aboutUs': 'https://taopinyouxuan.com/today/index.html',
  'goodsProfile.priceBannerBg': 'themes/common/priceBanner_dh_mohu.png',
  'goodsList.loading': 'loading_jinridui.gif',
  'goodsList.moreItemImg': 'themes/common/goods_list_more_cell_duihuan.png',
  'goodsList.moreItemPath': '/primary1',
  'goodsNormalItem.loading': 'goodsList_loading_jinridui.png',
  'weChat.publicAccount': '今日兑会员',
  'weChat.wxPubImage': 'duihaoli_wxPub.png',
  'weChat.toFocusWxPubBg': 'themes/common/wxpubTutorial_bg_duihaoli.png',
  'weChat.shareMsg': '今日兑 - 优选精品，天天实惠',
  'guessYourFavor.tabs': [
    { title: '猜你喜欢', subtitle: '品质甄选' },
    { title: '好物精选', subtitle: `700~799币` },
    { title: '今日兑严选', subtitle: `600~699币` },
    { title: '超值优选', subtitle: `0~599币` }
  ],
  'activityReturnScore.topBackground': 'themes/common/returnScore_topBackground_jinri_credit.png',
  'activityEntry.returnscore': 'themes/common/activity_entry_returnscore.png',
  'activityEntry.point': 'themes/common/activity_entry_point_dh.png',
  'share.mallUrl': '3g1.cn/C3QNG2',
  'suspendShare.defaultImage': 'jinri.png',
  'suspendShare.text':
    'Hi~ 这个商城有超多高性价比好物，还可以用兑换币兑换，我给你分享了6000+兑换币，24小时内过期。点这个链接，抓紧兑换！【3g1.cn/C3QNG2】',
  'ysf.templateId': null,
  'ysf.groupId': '480404035',
  'ysf.welcomeTemplateId': '1120',
  'pointCenter.defaultThemeIndex': 5,
  'pointCenter.themeOneIcon': 'themes/credit/theme_one_icon.svg',
  'priceBanner.couponDesc': '优惠券后价￥',
  'priceBanner.tencentAdBg': 'goodsDetail/priceBanner_default.png',
  'headerInfo.couponDesc': '新用户体验券',
  'headerInfo.couponReceive': '立即领取',
  'goodsProfile.submitText': '立即领取',
  'cooperation.bgImage': 'https://img.yanxuan618.com/870976496599040',
  'primary.goodsRepeat': 'true',
  'global.presentPointDialog': '',
  'global.trafficGuideEnterImg': '',
  'global.trafficGuidePopImg': '',
  'mine.borrowNoteSwitch': '',
  'mine.borrowNoteContent': '',
  'global.point': {
    name: '',
    value: '',
    unitName: ''
  },
  'order.showPointOrderItem': true, //订单列表是否显示积分
  'order.showSaveMoneyOrderItem': true, //订单列表是否显示已省金额
  'global.showGoodsCardName': true // 商品卡片显示商品卡片名
}

export default _.merge(_.merge({}, baseConfig), config)
