<template>
  <div class="com-goods-bottom-bar">
    <div class="flex">
      <div v-show="showPageEntranceBtns" class="flex items-center justify-around">
        <!-- <div class="center icon-btn" @click="goHome()" v-if="showHomeButton">
          <IconFont size="23px" icon="iconshouye1" color="" class="color_primary"></IconFont>
          <div>首页</div>
        </div> -->
        <div class="center icon-btn px3" @click="kefu">
          <IconFont size="23px" icon="iconkefu2" color="#1A1A1A"></IconFont>
          <div>客服</div>
        </div>
      </div>
      <div class="flex flex-1 button" :style="{ width: buttonWidth }">
        <div v-if="showOriginBtn" class="origin-btn flex flex-column items-center justify-center" @click="onSubmit(0)">
          <div style="font-size: 16px">{{ originalPrice }}元</div>
          <div style="font-size: 14px">直接购买</div>
        </div>
        <div class="submit-btn flex flex-column items-center justify-center" @click="onSubmit(1)">
          <div class="extra-text">{{ extraText }}</div>
          <div class="submit-text" :style="{ ...submitTextStyle }">{{ submitText }}</div>
          <div class="sub-text white">{{ subText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { IconFont } from 'components'
import { trackEvent, trackUserEvent } from 'base/utils/rp'
import xEnum from 'base/utils/enum'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'
import customService from 'base/utils/customServiceMixin'

export default {
  name: 'DrawPrizeBottomBar',
  components: { IconFont },
  mixins: [customService],
  props: {
    hidden: {
      type: Boolean,
      default: false
    },

    submitText: {
      type: String,
      default: () => '立即兑换'
    },

    extraText: {
      type: String,
      default: () => null
    },

    activityType: {
      type: String,
      default: () => 'point'
    },

    showPageEntranceBtns: {
      type: Boolean,
      default: () => true
    },

    showHomeButton: {
      type: Boolean,
      default: () => true
    },

    submitTextStyle: {
      type: Object,
      default: () => {}
    },

    subText: {
      type: String,
      default: null
    },

    originalPrice: {
      type: Number,
      default: 0
    },

    goods: {
      type: Object,
      default: () => {}
    },

    singleProductCouponTip: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      appState: 'appState'
    }),

    buttonWidth() {
      let buttonWidth
      if (this.activityType == 'timeLimit' || this.activityType == 'payOnDeliveryFreeReceive') {
        buttonWidth = '60vw'
      }
      if (!this.showPageEntranceBtns && !this.showOriginBtn) {
        buttonWidth = '100%'
      }
      return buttonWidth
    },

    showOriginBtn() {
      return (
        this.activityType !== 'timeLimit' &&
        this.activityType !== 'payOnDeliveryFreeReceive' &&
        this.activityType !== 'payOnDelivery' &&
        this.activityType !== 'drawPrize'
      )
    }
  },

  methods: {
    to(path) {
      this.$router.push({
        path: path,
        query: {
          location: window.location.href,
          goodsId: this.goods?.goodsDetailEx?.goodsId
        }
      })
    },

    onSubmit(type) {
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_BUY_CLICK.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: this.goods?.goodsDetailEx.goodsId,
          goodsPrice: this.goods?.goodsDetailEx.price,
          score: this.goods?.goodsDetailEx.score,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      })

      if (type === 0) {
        trackEvent({
          category: `点击-详情页-底部-直接购买按钮`,
          action: '总数'
        })
      } else {
        trackEvent({
          category: `点击-详情页-底部-立即兑换按钮`,
          action: '总数'
        })
      }
      const data = 1
      this.$emit('on-submit', data)
    },

    goHome() {
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_HOME_CLICK.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: this.goods?.goodsDetailEx?.goodsId,
          goodsPrice: this.goods?.goodsDetailEx?.price,
          score: this.goods?.goodsDetailEx?.score,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_goods_detail_hp_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_goods_detail_page',
        params: {
          goods_score: this.goods?.goodsDetailEx?.score,
          goods_group_type: this.$route.query.type,
          goods_name: this.goods?.goodsDetailEx?.goodsName,
          goods_id: this.goods?.goodsDetailEx?.goodsId,
          display_price: this.goods?.goodsDetailEx?.price,
          display_original_price: this.goods?.goodsDetailEx?.originalPrice,
          goods_category_id: this.goods?.goodsDetailEx?.categoryId
        }
      })
      if (this.activityType === 'point' || this.activityType === 'drawPrize') {
        this.to('/primary')
      } else {
        this.to('/primary')
      }
    },

    onClickCustomService() {
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_CUSTOM_SERVICE_CLICK.value,
        eventInfo: {
          pId: this.$store.state.appState.pId,
          userId: this.$store.state.user.userId,
          goodsId: this.goods?.goodsDetailEx?.goodsId,
          goodsPrice: this.goods?.goodsDetailEx?.price,
          score: this.goods?.goodsDetailEx?.score,
          goodsGroupId: this.$route.query.type,
          webUrl: window.location.href
        }
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_goods_detail_humanservice_page',
        eventType: EventType.Click,
        pageName: 'dacuecmc_goods_detail_page',
        params: {
          goods_score: this.goods?.goodsDetailEx?.score || 0,
          goods_group_type: this.$route.query.type,
          goods_name: this.goods?.goodsDetailEx?.goodsName,
          goods_id: this.goods?.goodsDetailEx?.goodsId,
          display_price: this.goods?.goodsDetailEx?.price,
          display_original_price: this.goods?.goodsDetailEx?.originalPrice,
          goods_category_id: this.goods?.goodsDetailEx?.categoryId
        }
      })
      if (this.showHomeButton) {
        this.to('/customService1')
      } else {
        window.ysf('open', {
          templateId: this.renderBrandElement({ id: 'ysf.templateId' })
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.coupon-tip::before {
  content: '';
  width: 0px;
  height: 0px;
  border-top: 8px solid #ff5029;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  top: 40px;
  right: 20px;
}
.com-goods-bottom-bar {
  position: fixed;
  bottom: 0px;
  margin: 0 auto;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px -4px 10px rgba(26, 26, 26, 0.1);
  // height: 60px;

  .icon-btn {
    font-size: 12px;
    line-height: 1.2;

    img {
      width: 42%;
      margin-bottom: 2px;
    }
  }
  .button {
    // width: 70vw;
    flex: 1;
    .origin-btn {
      flex: 1;
      height: 60px;
      text-align: center;
      border-left: 1px solid #e6e6e6;
    }
    .submit-btn {
      flex: 1.5;
      height: 60px;
      text-align: center;
      color: #fff;
      background: linear-gradient(to left, #ff2400, #ff7538);
      .extra-text {
        font-size: 16px;
      }

      .submit-text {
        font-size: 16px;
      }
    }
  }
}
</style>
