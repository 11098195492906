<template>
  <div
    class="tabs"
    :class="{ 'enable-slide-style': enableSlide, 'flex items-center justify-between': !enableSlide }"
    :style="styleWrap"
  >
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      :class="`tab flex flex-column justify-center items-center ${tab === activeTab && 'active'} ${
        tab === activeTab && hasNormalBubbleBg && 'tab-bg'
      }`"
      :style="{
        ...styleTab,
        ...(enableSlide ? tabWidth : {}),
        ...(lastTabActived ? moveLeftDistance : {}),
        'background-image': tab === activeTab && hasRainbowBubbleBg ? `url(${getRainbowBgByIndex(index)})` : ''
      }"
      @click="onTabChange(tab, index)"
    >
      <div class="title" :style="{ ...styleTabTitle, ...(tab === activeTab ? styleActiveTabTitle : {}) }">
        {{ tab.title }}
        <IconFont v-if="tab.icon" size="14px" class="icon" :icon="tab.icon" color=""></IconFont>
      </div>
      <div v-if="tab.subtitle" class="subtitle" :style="tab !== activeTab ? inActiveStyle : styleActiveSubtitle">
        {{ tab.subtitle }}
      </div>
      <div v-if="enableDivider" class="divider"></div>
      <div v-show="tab === activeTab" class="indicator flex" :style="styleActiveIndicator">
        <img class="rac" v-if="isShowArcIndicator" src="~base/assets/img/icon/rac.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { IconFont } from 'components'

export default {
  name: 'TabFilter',
  components: { IconFont },
  props: {
    activeTabIndex: {
      type: Number,
      default: 0
    },

    tabs: {
      type: Array,
      default: () => []
    },

    isShowArcIndicator: {
      type: Boolean,
      default: () => false
    },

    inActiveStyle: {
      type: Object,
      default: () => {}
    },

    styleWrap: {
      type: Object,
      default: () => {}
    },

    styleTab: {
      type: Object,
      default: () => {}
    },

    // TODO: 以下两个自定义属性不能同时传true，后期需优化
    hasNormalBubbleBg: {
      type: Boolean,
      default: () => false
    },

    hasRainbowBubbleBg: {
      type: Boolean,
      default: () => false
    },

    styleActiveIndicator: {
      type: Object,
      default: () => {}
    },

    styleTabTitle: {
      type: Object,
      default: () => {}
    },

    styleActiveSubtitle: {
      type: Object,
      default: () => {}
    },

    styleActiveTabTitle: {
      type: Object,
      default: () => {}
    },

    enableDivider: {
      type: Boolean,
      default: () => false
    },

    enableSlide: {
      type: Boolean,
      default: () => false
    },

    tabWidth: {
      type: Object,
      default: () => {}
    },

    moveLeftDistance: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      activeTab: this.tabs[0],
      lastTabActived: false
    }
  },

  created() {},

  methods: {
    onTabChange(tab, index) {
      if (tab.title === this.activeTab.title) {
        return
      }
      this.activeTab = tab
      this.$emit('on-tab-change', index)
      if (index === this.tabs.length - 1) {
        this.lastTabActived = true
      } else {
        this.lastTabActived = false
      }
    },

    changeTab(index) {
      this.activeTab = this.tabs[index]
    },

    getRainbowBgByIndex(index) {
      return require(`base/assets/img/tabBg/tab_active_${index}.png`)
    }
  }
}
</script>
<style lang="less" scoped>
.tabs {
  padding: 10px;
  .tab {
    background-size: contain;
    background-repeat: no-repeat;
    .title {
    }
    .subtitle {
      padding: 2px 5px;
      font-size: 12px;
      color: #b1b1b1;
      border-radius: 11px;
      background: transparent;
    }

    position: relative;

    &:not(:last-child) .divider {
      position: absolute;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: #ddbc83;
    }

    .indicator {
    }
  }
  .tab.active {
    .title {
      color: #d92e21;
    }
    .subtitle {
      color: #999999;
    }
  }
}
.enable-slide-style {
  overflow-x: auto;
  display: -webkit-box;
  -webkit-overflow-scrolling: touch;
}
.enable-slide-style::-webkit-scrollbar {
  display: none;
}
.tab-bg {
  background-image: url(../base/assets/img/tab_bg.png);
}
.rac {
  width: 30px;
  height: 5px;
}
</style>
