// vm.$mount(el) => document.body.appendChild();
// Vue.extend() 继承 【子类继承父类的方法】
// 父类 Vue--Vue.extend--子类 定制化的 Vue 构造函数
import Vue from 'vue'
import GetCouponDialog from './getCouponDialog'

const Customized = Vue.extend(GetCouponDialog)

export const getCouponDialog = (option = {}) =>
  new Promise((resolve, reject) => {
    const res = new Customized({
      data() {
        return {
          duration: option.duration || 1000,
          onClose: typeof option.onClose === 'function' ? option.onClose : () => {}
        }
      },
      methods: {
        resolve,
        reject
      }
    }).$mount()
    // console.log(res.$el);
    document.body.appendChild(res.$el)
  })
