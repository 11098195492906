<!-- 导流模块。可导流到公众号，快应用 -->
<template>
  <!-- 重要：微信中不显示此组件。微信看到引导公众号内容，会封掉这个页面的路由 -->
  <div class="qrcode-area mt2 center flex" v-if="!appState.isInWechat">
    <van-image :src="trafficGuideEnterImg" @click="onClickTrafficGuideEnterImg" />
    <van-popup v-model="popupVisible" :close-on-click-overlay="false" class="tutorial-modal bg-transparent">
      <a @click="onSaveQrcode" :href="trafficGuidePopImg" :download="pubName">
        <van-image class="tutorial" :src="trafficGuidePopImg" width="13.2rem" />
      </a>
      <!-- <div class="button button-save-qrcode absolute">
        <a @click="onSaveQrcode" :href="qrcode" :download="pubName" class="download-quick-btn">
          <img class="fit" src="~base/assets/img/wxpubTutorial/saveQrcode.png" />
        </a>
      </div> -->
      <!-- <div @click="copyWxpub" class="button button-copy-name absolute">
        <img class="fit" src="~base/assets/img/wxpubTutorial/copyName.png" />
      </div> -->
      <div class="close-btn absolute">
        <IconFont @click.native="popupVisible = false" size="20px" icon="iconguanbi1" color="white"></IconFont>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from 'vant'
import { mapGetters } from 'vuex'
import xEnum from 'base/utils/enum'
import { trackEvent } from 'base/utils/rp'
import IconFont from 'components/IconFont'
import { eventBus } from 'base/utils/eventBus'

export default {
  components: { IconFont },
  props: {
    trafficGuideEnterImg: {
      type: String,
      default: ''
    },

    trafficGuidePopImg: {
      type: String,
      default: ''
    },

    trafficGuideType: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      pubName: this.renderBrandElement({ id: 'weChat.publicAccount' }),
      popupVisible: false,
      qrcode: this.renderBrandElement({ id: 'weChat.wxPubImage', type: 'image' })
    }
  },

  computed: {
    ...mapGetters({
      appState: 'appState'
    })
  },

  methods: {
    onClickTrafficGuideEnterImg() {
      if (this.trafficGuideType === xEnum.ENUM_TRAFFIC_GUIDE_TYPE.DEFAULT.value) {
        this.popupVisible = true
      } else {
        this.$emit('on-click-traffic-guide-enter-img')
      }
    },

    onSaveQrcode() {
      trackEvent({
        category: `沉淀-公众号`,
        action: '保存二维码',
        opt_label: `总数`
      })
      this.wxPreview()
    },

    wxPreview() {
      if (this.appState.isInWechat) {
        trackEvent({
          category: `沉淀-公众号`,
          action: '保存二维码',
          opt_label: `微信内`
        })
        if (typeof WeixinJSBridge == 'undefined') {
          trackEvent({
            category: `沉淀-公众号`,
            action: '保存二维码',
            opt_label: `微信内Bridge未初始化`
          })
        } else {
          const url = window.location.origin + this.qrcode
          window.WeixinJSBridge.invoke('imagePreview', {
            urls: [url],
            current: url
          })
        }
      }
    },

    copyWxpub() {
      trackEvent({
        category: `沉淀-公众号`,
        action: '复制公众号',
        opt_label: `总数`
      })
      eventBus.$emit('copy-to-clipboard', {
        content: this.pubName,
        silent: false,
        onSuccess: () => {
          trackEvent({
            category: `沉淀-公众号`,
            action: '复制公众号',
            opt_label: `成功`
          })
          Toast.success('复制成功')
          location.href = 'weixin://'
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';

.download-quick-btn {
  display: block;
  .rem(height, 42);
}
.qrcode-area {
}
.tutorial-modal {
  height: 21rem;
}
.add-quick-btn,
.button {
  .rem(height, 42);
  .rem(bottom, 72);
  img {
    max-height: 100%;
  }
  &.button-save-qrcode {
    .rem(left, 46);
  }
  &.button-copy-name {
    .rem(right, 46);
  }
}
.close-btn {
  left: 50%;
  top: 95%;
  transform: translate(-50%, -50%);
  margin: auto;
}
</style>
