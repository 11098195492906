export default {
  'global.brand': '淘品优选',
  'global.quickBrand': '淘品优选',
  'global.company': '淘品优选',
  'global.mall': '淘品商城',
  'global.title': '兑换商城',
  'global.logo': 'taopin.png', // 全局logo
  'appGuideBar.appDownloadUrl': {
    // app下载url （根据需求oppo暂时默认跳应用宝）
    default: 'https://a.app.qq.com/dom/micro/open.jsp?pkgname=com.tpyx.app&fromcase=40002',
    xiaomi: 'https://app.mi.com/details?id=com.tpyx.app&ref=search',
    vivo: 'https://h5coml.vivo.com.cn/h5coml/appdetail_h5/browser_v2/index.html?appId=2913505&resource=301&source=7',
    huawei: 'https://appgallery.huawei.com/#/app/C103160077'
  },
  'global.icp': '', // 公司备案号
  'global.enableQuick': true, // 是否开启快应用拉起功能
  'global.enableApp': true, // 是否开启app导流功能
  'global.isTabbarWelfare': false, // 福利中心是否放在底部导航栏，如果不放，则需要把积分中心放在首页导航栏
  'global.antiFraudStatementUrl': 'https://mp.weixin.qq.com/s/SR9Xk-Eeby_-gpNXmHbPsA', // 防诈骗声明链接
  'global.pointName': '积分', // point名称
  'global.exchangeWord': '兑换', // globalExchangeWord兑换字配置
  'primary.tabbarName': '积分中心', // primary tab的名称
  'primary.tabbarIcon': 'iconjifen', // primary页面所使用的组件
  'primary.component': 'PointCenter', // primary页面所使用的组件
  'privacy.agreement': '', // 隐私协议
  'global.agreement': '', // 隐私协议链接
  'global.activityRules': '',
  'global.disableReturnscore': false, // 是否关闭等价全返模块
  'global.isQuickApp': false, // 是否是快应用
  'global.enablePresentPointDialog': true, // 是否打开积分弹窗
  'global.enablePresentPointDialogAgain': true, // 是否打开再次送积分弹窗
  'global.presentPointDialogRouterPath': '/primary', // 积分弹窗跳转路由
  'global.defaultRouterPath': 'primary', // 默认跳转路由
  'pointNoticeMask.enable': true, // 积分过期提醒是否打开
  'live.title': '淘品直播', // 直播标题
  'live.swipeLiving': 'videoLive/living_taopin.png', // 直播页轮播图直播间图片
  'live.goodsType': 'point', // 直播商品销售类型：point-兑换 seckill-立减
  'home.topLeftLogo': 'home_header_logo_taopin.png', // 商城首页左上角logo
  'homeSwipe.primaryPath': '/primary', // 首页轮播图第一张图跳转链接
  'homeSwipe.enablePoint': true, // 首页轮播图是否开启等价全返入口
  'homeKingKong.primaryTitle': '积分换购', // 首页金刚位首入口标题
  'homeKingKong.primaryPath': '/primary', // 首页金刚位首入口路由
  'homeKingKong.enableReturnScore': true, // 首页金刚位是否开启等价全返入口
  'homeKingKong.enableLive': false, // 首页金刚位是否开启直播入口
  'homeActivity.title': '积分中心',
  'homeActivity.subTitle': '工厂补贴积分 限时抢购',
  'homeActivity.metaText': '积分:',
  'homeActivity.metaShowPoint': true,
  'homeActivity.point1': 'home_entrance/point_1.png', // 首页四宫格积分图1
  'homeActivity.point2': 'home_entrance/point_2.png', // 首页四宫格积分图2
  'homeActivity.path': '/primary', // 首页金刚位首入口路由
  'homeCapsule.banner': 'home_capsule/rush_buy_capsule.gif', // 首页胶囊区banner图
  'homeCapsule.navigateType': 'leftFirst', // HomeCapsule组件跳转类型 fastMoving-快消品 bargain-砍价
  'mine.aboutUs': 'https://taopinyouxuan.com', // 个人中心页关于我们跳转链接
  'mine.enableSignin': true, // 个人中心页开启签到入口
  'goodsProfile.priceBannerBg': 'goodsDetail/priceBanner_1.png', // goodsProfile组件顶部price banner背景图
  'goodsProfile.seckillPriceBannerBg': 'goodsDetail/priceBanner_1.png', // goodsProfile组件顶部秒杀price banner背景图
  'goodsList.loading': 'loading_taopin.gif', // GoodsList组件loading图
  'goodsList.moreItemImg': 'goods_list_more_cell_jifen.png', // GoodsList组件查看更多的图片
  'goodsList.moreItemPath': '/primary', // GoodsList组件查看更多跳转路由
  'goodsNormalItem.loading': 'goodsList_loading_taopin.png', // GoodsNormalItem组件loading图
  'weChat.publicAccount': '淘品优选商城',
  'weChat.wxPubImage': 'guanZhuYouLi_tpyx.png', // 砍价详情页关注微信公众号二维码
  'weChat.toFocusWxPubBg': 'wxpubTutorial/bg_tpyx.png', // 个人中心引导关注微信公众号背景图
  'weChat.shareMsg': '淘品优选 - 淘精彩，购实惠', // main.js中wechatSdk分享描述
  'guessYourFavor.tabs': [
    // GuessYourFavor组件中tabs
    { title: '超值兑换', subtitle: '口碑优选' },
    { title: '限量兑换', subtitle: `700~799积分` },
    { title: '品质兑换', subtitle: `600~699积分` },
    { title: '特惠兑换', subtitle: `0~599积分` }
  ],
  'guessYourFavor.listItemType': 'point', // 猜你喜欢组件中列表项的类型：point-兑换 seckill-立减
  'activityReturnScore.topBackground': 'returnScore/topBackground.jpg', // 等价全返页面顶部背景图
  'activityEntry.returnscore': 'activity_entries/returnscore.png', // 幻灯片广告等价全返图片
  'activityEntry.point': 'activity_entries/point.png', // 幻灯片广告积分兑换图片
  'activityEntry.pointPath': '/primary', // 幻灯片广告积分兑换路由
  'activityEntry.enableReturnscore': true, // 幻灯片广告是否开启等价全返入口
  'share.mallUrl': '3g1.cn/C3QNG2', // 分享商城链接
  'suspendShare.defaultImage': 'taopin.png',
  'suspendShare.text':
    'Hi~ 这个商城有超多高性价比好物，还可以用积分兑换，我给你分享了6000+积分，24小时内过期。点这个链接，抓紧兑换！【3g1.cn/C3QNG2】',
  'ysf.templateId': '6392089', // 七鱼客服
  'ysf.groupId': '480409652', // 七鱼组
  'ysf.welcomeTemplateId': '1013', // 七鱼机器人
  'brandGoodsList.listItemType': 'point', // 品牌专场组件中列表项的类型：point-兑换 seckill-立减
  'homeGoodsList.listItemType': 'point', // 首页中列表项的类型：point-兑换 seckill-立减
  'goodsMoreItem.itemType': 'point', // 更多商品item的类型：point-兑换 seckill-立减
  'goodsGallery.itemType': 'point', // 商品推荐廊item的类型：point-兑换 seckill-立减
  'bargainReferral.listItemType': 'point', // 砍价导流组件中列表项的类型：point-兑换 seckill-立减
  'bargainReferral.disablePointLeftMask': false, // 砍价导流组件中是否隐藏积分mask
  'bargainMinePanel.directBuyText': '用积分直接兑换', // 我的砍价面板中直接购买商品文案
  'bargainMinePanel.directBuyGoodsType': 'point', // 我的砍价面板中直接购买商品类型：point-兑换 seckill-立减
  'bargainFriendPanel.awardText': '帮我砍价即可获得6000+积分', // 帮人砍价面板中砍价奖励文案
  'bargainFriendPanel.resultDialogBuyText': '用积分兑换商品', // 帮人砍价面板中砍价成功购买按钮文案
  'bargainFriendPanel.resultDialogNewUserTips': '`送你6000+${this.globalPointName}，可兑换商品哦~`', // 帮人砍价面板中砍价成功新用户提醒文案
  'bargainFriendPanel.resultDialogOldUserTips': '`您的${this.points}${this.globalPointName}快过期了，快去使用吧`', // 帮人砍价面板中砍价成功老用户提醒文案
  'pointCenter.defaultThemeIndex': 1, // 积分中心默认主题
  'pointCenter.themeOneIcon': 'ring.svg' // 积分中心-主题1圆环图标
}
