import { render, staticRenderFns } from "./payOnDeliveryDialog.vue?vue&type=template&id=24a1f554&scoped=true&"
import script from "./payOnDeliveryDialog.vue?vue&type=script&lang=js&"
export * from "./payOnDeliveryDialog.vue?vue&type=script&lang=js&"
import style0 from "./payOnDeliveryDialog.vue?vue&type=style&index=0&id=24a1f554&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a1f554",
  null
  
)

export default component.exports