<template>
  <transition name="fade">
    <div v-if="visible" class="mask" id="payOnDeliveryDialog" v-fixed>
      <div class="dialog-content flex flex-column">
        <div class="relative flex justify-center">
          <div class="" v-if="isPackageClose" @click="isPackageClose = false">
            <img :width="300" class="package animate__animated animate__shakeX animate__repeat-2" :src="popupImgs[0]" />
          </div>
          <div class="" v-else @click="visible = false">
            <img :width="300" :src="popupImgs[1]" />
          </div>
        </div>

        <div v-if="!isPackageClose" id="close" class="close-btn" @click="closeDialog">
          <van-icon size="30" name="close" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { IconFont } from 'components'

const screenWidth = window.screen.width
const screenHeight = window.screen.height
const widthHeightRatio = 5 / 6

export default {
  name: 'PayOnDeliveryDialog',
  directives: {
    // 【解决滑动穿透问题】该指令的注意点是必须使用v-if来开启关闭弹窗，因为该指令依赖于dom的插入和注销，使用v-show肯定是不行的
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        document.body.style.cssText += 'position:fixed;width:100%;top:-' + scrollTop + 'px;'
      },

      // unbind 指令与元素解绑时调用
      unbind() {
        const body = document.body
        body.style.position = ''
        const top = body.style.top
        document.body.scrollTop = -parseInt(top)
        document.documentElement.scrollTop = -parseInt(top)
        body.style.top = ''
      }
    }
  },

  props: {},

  data() {
    return {
      visible: true,
      screenHeight,
      imageWidth: screenWidth * (176 / 302),
      imageHeight: (screenWidth * (176 / 302)) / widthHeightRatio,
      isPackageClose: true
    }
  },

  computed: {},
  created() {},
  mounted() {},
  methods: {
    closeDialog() {
      this.visible = false
    },

    onClickBtn() {
      this.closeDialog()

      this.onClick()
    }
  }
}
</script>

<style lang="less" scoped>
.amount {
  font-size: 48px;
}
.mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog-content {
    // width: 250px;
    // height: 300px;
    position: relative;

    .point {
      position: absolute;
      // top: 165px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      color: #fefbe9;
    }

    .close-btn {
      position: absolute;
      bottom: -30px;
      width: 100%;
      text-align: center;
      margin: auto;
      margin-top: 10px;
      color: white;
      opacity: 0.5;
    }

    .close-dialog {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 5px;
    }
    .to-cod-btn {
      width: 200px;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.5s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
}

.fade-leave {
  opacity: 1;
}
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}
.fade-leave-to {
  opacity: 0;
}
</style>
