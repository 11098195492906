const buryState = {
  state: {
    exposureDomSet: new Set(),
    exposureLimitForMorePurchaseCard: [] //处理顺手买商品重复曝光
  },

  mutations: {
    SET_EXPOSURE_DOM_SET(state, dom) {
      if (state.exposureDomSet.has(dom)) return
      state.exposureDomSet.add(dom)
    },

    CLEAR_EXPOSURE_DOM_SET(state) {
      state.exposureDomSet.clear()
      state.exposureLimitForMorePurchaseCard = []
    },

    SET_MORE_PURCHASE_ITEM(state, item) {
      const hasItem = state.exposureLimitForMorePurchaseCard.includes(item)
      if (hasItem) return
      state.exposureLimitForMorePurchaseCard.push(item)
    }
  },

  actions: {}
}

export default buryState
