<template>
  <div class="FootBar">
    <div style="height: 60px; clear: both"></div>
    <van-tabbar
      v-model="footBarActive"
      :safe-area-inset-bottom="true"
      active-color="#d92e21"
      inactive-color="#acacac"
      @change="onTabChange"
      class="tabbar"
    >
      <van-tabbar-item
        v-for="(item, index) in tabbarList"
        :key="index"
        replace
        :to="item.to"
        :name="item.to.path"
        :badge="item.enableBadge && unpayOrderCount > 0 ? unpayOrderCount : ''"
      >
        <span v-if="item.to.path !== '/primary1'">{{ item.text }}</span>
        <template #icon="props">
          <img
            v-if="!props.active"
            :width="item.to.path === '/primary1' ? '75px' : '28px'"
            :src="item.to.path === '/primary1' ? tabbarCenterImage : item.inactiveImg"
            :style="{ height: item.to.path === '/primary1' ? '51px' : '28px' }"
          />
          <img
            v-else
            :width="item.to.path === '/primary1' ? '75px' : '28px'"
            :src="item.to.path === '/primary1' ? tabbarCenterImage : item.activeImg"
            :style="{ height: item.to.path === '/primary1' ? '51px' : '28px' }"
          />
        </template>
      </van-tabbar-item>
      <div class="point-center" :style="{ display: isShowFootBarTip ? 'block' : 'none' }">
        <img width="136px" height="34px" :src="tabbarFloatImg" />
      </div>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
import { trackEvent, trackUserEvent } from 'base/utils/rp'
import { mapState } from 'vuex'
import xEnum from 'base/utils/enum'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'FootBar',
  components: { [Tabbar.name]: Tabbar, [TabbarItem.name]: TabbarItem },

  data() {
    return {
      footBarActive: this.$route.path,
      tabbarList: [
        {
          to: { path: '/home', query: this.$route.query },
          text: '首页',
          icon: 'iconshouye2',
          inactiveImg: require('base/assets/img/footbar/home@2x.png'),
          activeImg: require('base/assets/img/footbar/home_active@2x.png')
        },
        {
          to: { path: '/leftFirst', query: this.$route.query },
          text: '疯抢排行',
          icon: 'iconfestival-f',
          inactiveImg: require('base/assets/img/footbar/rank@2x.png'),
          activeImg: require('base/assets/img/footbar/rank_active@2x.png')
        },
        {
          to: { path: '/primary1', query: this.$route.query },
          text: this.renderBrandElement({ id: 'primary.tabbarName' }),
          icon: this.renderBrandElement({ id: 'primary.tabbarIcon' }),
          inactiveImg: require('base/assets/img/footbar/pointscenter@2x.png'),
          activeImg: require('base/assets/img/footbar/pointscenter@2x.png')
        },
        {
          to: { path: '/myorder3', query: this.$route.query },
          text: '我的订单',
          icon: 'icondingdan',
          enableBadge: true,
          inactiveImg: require('base/assets/img/footbar/order@2x.png'),
          activeImg: require('base/assets/img/footbar/order_active@2x.png')
        },
        {
          to: { path: '/mine', query: this.$route.query },
          text: '个人中心',
          icon: 'iconwode2',
          inactiveImg: require('base/assets/img/footbar/usercenter@2x.png'),
          activeImg: require('base/assets/img/footbar/usercenter_active@2x.png')
        }
      ],

      isShowFootBarTip: true,
      tabChangePath: '',
      firstEnterPointCenter: true
    }
  },

  computed: {
    ...mapState({
      tabbarFloatImg: (state) => state.appState.tabbarFloatImg,
      unpayOrderCount: (state) => state.user.unpayOrderCount,
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId,
      tabbarCenterImage: (state) => state.appState.tabbarCenterImage
    })
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route: function (newVal, oldVal) {
      this.footBarActive = newVal.path
    },

    tabChangePath(newVal) {
      if (!this.firstEnterPointCenter) return
      if (newVal === '/primary1') {
        this.isShowFootBarTip = true
        this.firstEnterPointCenter = false
      } else {
        this.isShowFootBarTip = false
      }
    }
  },

  created() {
    this.$nextTick(() => {
      document.addEventListener('visibilitychange', this.visibilitychange)
    })
  },

  mounted() {
    window.addEventListener('scroll', this.setScrollTopPosition, true)
  },

  deactivated() {
    document.removeEventListener('visibilitychange', this.visibilitychange)
    window.removeEventListener('scroll', this.setScrollTopPosition, true)
  },

  methods: {
    onTabChange(path) {
      this.tabChangePath = path
      const tabIndex = this.tabbarList.findIndex((tab) => tab.to.path === path)
      const tab = this.tabbarList[tabIndex]
      const tabName = tab && tab.text
      trackEvent({
        category: `点击-底部导航-${tabName}`
      })
      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        webUrl: window.location.href,
        clickPath: path
      }
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.FOOT_BAR_THEMED_CLICK.value,
        eventInfo
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_bottomtab_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          tab_name: tabName
        }
      })
    },

    visibilitychange() {
      if (document.visibilityState === 'visible') {
        //页面展示
        this.isShowFootBarTip = true
      }
    },

    setScrollTopPosition() {
      // 有滑动后取消展示
      if (window.scrollY) {
        this.isShowFootBarTip = false
      }
    }
  }
}
</script>

<style lang="less">
.FootBar {
  .van-hairline--top-bottom {
    z-index: 999;
  }
  .van-tabbar-item__icon img {
    height: 28px;
  }
  .van-tabbar--fixed {
    padding: 5px 0px;
  }
  .tabbar {
    position: fixed;
    .point-center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(calc(-100% - 5px));
      -webkit-transform: translateX(-50%) translateY(calc(-100% - 5px));
      animation: foolishShake 0.5s infinite;
    }
  }
}

@keyframes foolishShake {
  0% {
    transform: translateX(-50%) translateY(calc(-100% - 5px));
  }
  50% {
    transform: translateX(-50%) translateY(calc(-100% - 10px));
  }
  100% {
    transform: translateX(-50%) translateY(calc(-100% - 5px));
  }
}
</style>
