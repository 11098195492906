<template>
  <div class="com-comment-gallery mt-[10px] py2 px2" v-if="goodsCommentList.length > 0">
    <div class="H_pl">
      <comment :comment="commentInfo" />
      <div class="H_pl_swiper_box">
        <van-swipe
          :autoplay="-1"
          :show-indicators="false"
          :vertical="false"
          :touchable="true"
          :width="goodsCommentWidth"
          :loop="false"
        >
          <van-swipe-item v-for="(item, index) in goodsCommentList" class="pr-[10px] h-auto" :key="index">
            <div class="H_pl_ubox">
              <div class="H_pl_user">
                <img class="H_pl_uImg" :src="item.userLogo" />
                <p class="H_pl_uname">{{ item.userName }}</p>
              </div>
              <p class="H_pl_txt">{{ item.content }}</p>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { IconFont, SectionTitle } from 'components'

// vue 函数式组件+jsx
// https://learnku.com/articles/4382/seven-ways-to-define-a-component-template-by-vuejs
// https://cn.vuejs.org/v2/guide/render-function.html#%E5%87%BD%E6%95%B0%E5%BC%8F%E7%BB%84%E4%BB%B6
// https://www.cnblogs.com/star91/p/ru-he-zai-yi-ge-wen-jian-zhong-xie-duo-gevue-zu-ji.html
// eslint-disable-next-line no-unused-vars
const Comment = Vue.component('Comment', {
  // 加 functional 才能编译成内联组件，style 的 scope 能够跟父保持一致。
  functional: true,
  // eslint-disable-next-line no-unused-vars
  components: { IconFont, SectionTitle },
  // eslint-disable-next-line vue/require-prop-types
  props: ['comment'],
  render(createElement, context) {
    return (
      <SectionTitle title={`用户评价(${context.props.comment.count})`} class="mb2">
        <div class="H_pl_t2 fs14 flex items-center" style="color:#d92e21;">
          {context.props.comment.rate}%
          <span class="ml1 fs14" style="color:#999999;">
            好评率
          </span>
        </div>
      </SectionTitle>
    )
  }
})

export default {
  name: 'CommentGallery',
  // components: { IconFont, SectionTitle },
  props: {
    goodCommentCount: {
      type: Number,
      default: 0
    },

    goodCommentRate: {
      type: Number,
      default: 0
    },

    goodsCommentList: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      goodsCommentWidth: document.documentElement.offsetWidth * 0.7
    }
  },

  computed: {
    commentInfo() {
      return {
        count: this.goodsCommentList.length > 0 ? this.goodCommentCount : '',
        rate: this.goodsCommentList.length > 0 ? this.goodCommentRate : '暂无评价'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.com-comment-gallery {
  background: #fff;
  /*评论*/
  .H_pl {
    /* margin-top: 10px; */
    border-radius: 4px;
  }
  .H_pl_title {
    font-size: 0;
    margin: 0 auto;
    padding: 15px 0;
    /* border-bottom: 1px solid #ececee; */
  }
  .H_pl_t1 {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    font-size: 0.6rem;
    text-align: left;
    color: #323332;
  }
  .H_pl_t2 {
    text-align: right;
    color: #828282;
  }
  .H_pl_swiper_box {
    margin: 0 auto;
  }
  .H_pl_ubox {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    border-radius: 3px;
    min-height: 97px;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    position: relative;
  }
  .H_pl_ub1 {
    width: auto; /*根据内容调整宽度*/
  }
  .H_pl_user {
    width: 90%;
    margin: 0 auto;
  }
  .H_pl_uImg {
    width: 30px;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 5px;
  }
  .H_pl_uname {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.6rem;
    color: #2a2a2c;
  }
  .H_pl_txt {
    width: 90%;
    margin: 8px auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 0.6rem;
    color: #7f7f7f;
    letter-spacing: 1px;
    margin-top: 10px;
  }
}
</style>
