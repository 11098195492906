import _ from 'lodash'
import quickApp from 'base/utils/quickApp'
import { trackEvent, rpInit } from 'base/utils/rp'
import distribute from '@/utils/distribute'
import { getProjectConfig, getGoodsGroupConfig, getMpUrlScheme, getAdvertiseConfig } from 'base/api'
import xEnum from 'base/utils/enum'
const UA = require('ua-device')
import brand from 'base/brands'
import router from '@/router'
import utils from 'base/utils'

// 过审厂家列表。
// 用白名单。确保能正确识别机型的手机，才拉起快应用。这样才可以强制唤起，不担心打不开的流失。
const WHITE_LIST = [
  // ZTE|Samsung|Motorola|HTC|Coolpad|Huawei|Lenovo|LG|Sony Ericsson|Oppo|TCL|Vivo|Sony|Meizu|Nokia
  'meizu',
  'xiaomi',
  'vivo',
  'oppo',
  'huawei'
]

const globalExchangeWord = brand.renderBrandElement({ id: 'global.exchangeWord' })
const defaultTabs = [
  {
    groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.POINT_CENTER_0_CATEGORY_RECOMMEND_GROUP.value,
    title: `限时抢兑`
  },
  {
    groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.POINT_CENTER_0_CATEGORY_DIANQI_GROUP.value,
    title: `电器${globalExchangeWord}`
  },
  {
    groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.POINT_CENTER_0_CATEGORY_JUJIA_GROUP.value,
    title: `居家${globalExchangeWord}`
  },
  {
    groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.POINT_CENTER_0_CATEGORY_MEIZHUANG_GROUP.value,
    title: `美妆${globalExchangeWord}`
  },
  {
    groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.POINT_CENTER_0_CATEGORY_MEISHI_GROUP.value,
    title: `美食${globalExchangeWord}`
  }
]

const DEFAULT_PID = process.env.VUE_APP_DEFAULT_PID
const DEFAULT_ORDER_SUCCESS_TIP_CONFIG = {
  type: xEnum.ENUM_LINK_TYPE.LOCAL.getValue(),
  content: '【专属福利】超值兑换，限时抢购！',
  duration: 5000,
  url_name: 'home'
}

const appState = {
  state: {
    pId: DEFAULT_PID,
    passPId: false,
    supportQuickApp: false, // 手机支持在快应用
    isInQuickApp: false, // 当前是否在快应用中打开
    isHuawei: false,
    isInMini: false, // 是否在小程序中
    isAudited: false, // 快应用是否在当前厂家已经过审
    umengId: null,
    baiduId: null,
    bottomBannerVisible: true,
    manufacturer: null,
    isInWechat: false, // 是否在微信中打开
    isInAliPay: false,
    isInApp: false, // 是否在app中打开
    appType: xEnum.ENUM_APP_TYPE.H5.getValue(),
    firstBindReturnPoints: 0,
    isChinaMobile: false,
    appReady: false, // app加载标识
    userTag: null,
    zhtsuid: null, // 智慧推手uid
    orderSuccessfulDialogOption: DEFAULT_ORDER_SUCCESS_TIP_CONFIG,
    themeConfig: {
      pointClearText: '清零'
    },
    pointTemplate: {},
    showHomeButton: true,
    isPointsDialog: false,
    mallNotice: null,
    morePoints: 0,
    tabs: defaultTabs,
    cornerImg: [],
    feedAdvert: [],
    buryPointConfig: {
      exposeDuration: 3000,
      exposeMinAreaRate: 0.8,
      pushInterval: 2000,
      queueSize: 20,
      eventTypes: [Number(xEnum.ENUM_RP_EVENT_TYPE.ROUTE_TO_PAGE.value)]
    },
    tipFloatConfig: {},
    urlAllParams: {},
    enterUrl: '',
    pvFlag: true, // 用来控制第一次pv上报埋点
    goodsProfileTimeLimitSwitch: true,
    refundConfig: {
      autoRefundMinute: 0,
      refundOpen: false,
      autoRefundOpen: false
    },
    stepNumber: 'first',
    hadGotRedPacket: 0,
    specificValOfRedPacket: '100',
    wechatSdkReject: true,
    boolOpen: true,
    tabbarFloatImg: '',
    projectName: '',
    convenientBuyingId: '', //顺手买id
    cacheQtEvents: [], // 缓存的QT事件
    firstRedPacketOpen: true, // 首个红包是否开启默认为存在状态
    firstRedPacketImage: '', // 首个红包图片
    mallHomeBannerDefaultImage: '', // 商城首页顶部默认背景图
    mallHomeBannerBlockImage: '', // 商城首页顶部屏蔽背景图
    receivedPeopleShow: true, //已领取人数是否展示
    marketingBlockAreaFlag: false, // 红包营销块区域是否屏蔽 默认false不屏蔽
    redPacketReceivedListVisible: true, // 红包领取列表是否显示
    backInterceptImage: '', // 返回拦截弹窗图片
    backInterceptNavigatePath: '', // 返回拦截弹窗跳转路径
    enableRefundSelf: false, // 是否允许自助退款
    virtualCoupon: {
      name: process.env.couponName,
      value: process.env.couponValue
    },
    tabbarCenterImage: '', // 导航栏中间展示图标
    topPointShowMoney: true, // 预计省金额区域是否展示  默认不展示
    forwardPathParams: {}, // 记录前一个页面路径和停留时长的对象
    alipayAppId: '', // 记录支付宝小程序内嵌时 appId
    showGoodPrice: true, // 是否展示商品售价
    minePageShowActivity: false, // 个人中心页是否展示活动板块  默认不展示
    backToCustomPageConfig: {
      // 首次进入商城先跳去挽留页，再从挽留页跳去商城目的页。退出最后一页是挽留页，在挽留页返回退出商城。
      primaryToCustom: true, // 首次判断在积分页是否跳去特殊页：true-跳转，false-不跳转
      customToMall: true, // 判断在挽留页是否跳去商城：true-跳去商城，false-停留挽留页_展示内容
      firstToCustom: true, // 判断第一次到达挽留页
      mallBackInterceptImg: '' // 商城返回拦截导流弹窗图片（图片文案模板可配）
    },
    mallNoticeOpacity: '', // 商城顶部公告字体透明度
    defaultPayType: null, // 默认支付
    payOnDeliveryWorkWxImg: '', // 货到付款直拉企微素材
    payOnDeliveryWorkWxLink: '' // 货到付款直拉企微链接
  },

  mutations: {
    SET_APP_READY: (state) => {
      state.appReady = true
    },
    UPDATE_APP_STATE: (state, { key, value }) => {
      state[key] = value
    },
    UPDATE_THEME_CONFIG: (state, { key, value }) => {
      state.themeConfig[key] = value
    },
    UPDATE_PID: (state, value) => {
      if (!value || value.trim() === '' || !/\d{0,20}/g.test(value)) {
        state.pId = DEFAULT_PID
      } else {
        state.pId = value
        localStorage.setItem('pId', value)

        // TODO: delete写死的判断
        if (
          (process.env.NODE_ENV === 'production' && value === '839611558395904') ||
          (process.env.NODE_ENV !== 'production' && value === '839611558395904')
        ) {
          state.isChinaMobile = true
        }
      }
    },
    SET_FIRST_BIND_RETURN_POINTS: (state, value) => {
      state.firstBindReturnPoints = value
    },
    UPDATE_BURY_POINT_CONFIG: (state, config) => {
      state.buryPointConfig = config
    },
    TOGGLE_PV_FLAG: (state, bool) => {
      state.pvFlag = bool
    },
    UPDATE_AFTER_SALE_REASONS: (state, reasons) => {
      state.afterSaleReasons = reasons
    },

    UPDATE_REFUND_CONFIG: (state, refundConfig) => {
      state.refundConfig = refundConfig
    },
    UPDATE_TO_CUSTOM_CONFIG: (state, { key, value }) => {
      state.backToCustomPageConfig[key] = value
    }
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    async checkUserAgent({ dispatch, commit, state }) {
      const userAgent = navigator.userAgent.toLowerCase()
      const isInWechat = userAgent.match(/MicroMessenger/i) == 'micromessenger'
      const isInAliPay = userAgent.match(/aliapp/i) == 'aliapp'
      const isInApp = /com\.tpyx\.app/.test(userAgent) ? true : false
      const isInMini = userAgent.indexOf('miniprogram') !== -1

      commit('UPDATE_APP_STATE', {
        key: 'isInWechat',
        value: isInWechat
      })

      commit('UPDATE_APP_STATE', {
        key: 'isInAliPay',
        value: isInAliPay
      })

      commit('UPDATE_APP_STATE', {
        key: 'isInApp',
        value: isInApp
      })

      commit('UPDATE_APP_STATE', {
        key: 'isInMini',
        value: isInMini
      })
      let appType = xEnum.ENUM_APP_TYPE.H5.getValue()
      if (isInMini) {
        if (isInAliPay) {
          appType = xEnum.ENUM_APP_TYPE.H5_IN_ALI_MP.getValue()
        }
      } else {
        if (isInAliPay) {
          appType = xEnum.ENUM_APP_TYPE.H5_IN_ALI.getValue()
        } else if (isInWechat) {
          appType = xEnum.ENUM_APP_TYPE.WX_PUB.getValue()
        }
      }
      commit('UPDATE_APP_STATE', {
        key: 'appType',
        value: appType
      })
    },
    /**
     * 初始化快应用相关配置: 手机是否支持快应用，当前H5是否在快应用中打开
     */
    // eslint-disable-next-line no-unused-vars
    async initQuickAppConfig({ dispatch, commit, state }) {
      // let { pId } = state

      const userAgent = navigator.userAgent.toLowerCase()
      const isInQuickApp = /com\.tpyx\.quickapp/.test(userAgent) ? true : false
      // 这种判断方式，只在快应用内的 webview 中才生效，因为快应用会在 ua 中追加。
      // https://doc.quickapp.cn/widgets/web.html
      const isHuawei = /hap\/.+\/[Hh]uawei/.test(userAgent)

      commit('UPDATE_APP_STATE', {
        key: 'isInQuickApp',
        value: isInQuickApp
      })
      commit('UPDATE_APP_STATE', {
        key: 'isHuawei',
        value: isHuawei
      })

      // 不能用 userAgent.toLowerCase()，因为小米，oppo等的 ua 对大小写非常敏感。
      const uaDevice = new UA(navigator.userAgent)
      // let uaDevice = new UA(`Mozilla/5.0 (Linux; Android 9; MI 8 Build/PKQ1.180729.001; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/66.0.3359.126 MQQBrowser/6.2 TBS/45016 Mobile Safari/537.36 MMWEBID/5167 MicroMessenger/7.0.4.1420(0x2700043C) Process/tools NetType/4G Language/zh_CN`)
      const manufacturer = _.lowerCase(uaDevice.device.manufacturer)
      commit('UPDATE_APP_STATE', {
        key: 'manufacturer',
        value: manufacturer
      })
      const isAudited = _.includes(WHITE_LIST, manufacturer)
      commit('UPDATE_APP_STATE', {
        key: 'isAudited',
        value: isAudited
      })

      if (isInQuickApp) {
        return
      }

      try {
        let supportQuickApp = false
        if (manufacturer === 'vivo') {
          // VIVO 的厂商浏览器，默认开启了广告拦截。此处读取supportQuickApp为 false，但是可以强拉
          supportQuickApp = true
        } else {
          supportQuickApp = await quickApp.promisefyGetQuickAppChannelReady()
        }
        commit('UPDATE_APP_STATE', {
          key: 'supportQuickApp',
          value: supportQuickApp
        })
        dispatch('openQuickApp')
      } catch (err) {
        console.log('检测快应用能力失败', err)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async openQuickApp({ commit, state, rootState }) {
      const { supportQuickApp, isAudited, pId, isInApp, manufacturer, isInQuickApp, isInWechat } = state

      if (!isAudited) {
        return
      }
      if (!supportQuickApp) {
        return
      }
      if (isInQuickApp) {
        return
      }
      // 微信中统一不拉快应用
      if (isInWechat) {
        return
      }
      // APP中统一不拉快应用
      if (isInApp) {
        return
      }

      // 尝试拉起，同时跳转快应用。
      const needWakeQuickApp = distribute.isNeedDirectWakeQuickApp(pId)
      if (!needWakeQuickApp) {
        return
      }
      trackEvent({
        category: `强制拉快应用`,
        action: '总数'
        // opt_label: `支付方式${this.radio}`,
        // opt_value: good.price
      })

      distribute.jumpToQuickApp({
        silence: true,
        params: {
          h5UserId: rootState.user.userId,
          createShortcut: true,
          // 如果在白名单，则使用白名单中对应的，否则透传自己的渠道
          // h5PId: quickPidPair ? quickPidPair.LAND_PID : pId,
          // 2020-10-14 改为黑名单，并且拉起的全部是自己。
          h5PId: pId
        },
        isVivo: manufacturer === 'vivo'
      })
    },
    // eslint-disable-next-line no-unused-vars
    async initConfig({ commit, state, rootState }, payload) {
      const { pId, userId } = payload

      try {
        const res = await getProjectConfig({
          pId: pId,
          userId: userId
        })
        const { umengId = null, baiduId = null, isPointsDialog = null, boolOpen = true } = res?.data ?? {}
        commit('UPDATE_APP_STATE', {
          key: 'umengId',
          value: umengId
        })
        commit('UPDATE_APP_STATE', {
          key: 'isPointsDialog',
          value: isPointsDialog
        })
        commit('UPDATE_APP_STATE', {
          key: 'baiduId',
          value: baiduId
        })
        commit('UPDATE_APP_STATE', {
          key: 'boolOpen',
          value: boolOpen
        })

        commit('UPDATE_APP_STATE', {
          key: 'convenientBuyingId',
          value: res.data?.mallConfig?.convenient_buying_id ?? ''
        })

        rpInit({ umengId, baiduId, pId })

        commit('SET_FIRST_BIND_RETURN_POINTS', res.data?.bindPhonePoints)

        commit('UPDATE_APP_STATE', {
          key: 'showHomeButton',
          value: res.data?.showHomeButton
        })
        commit('UPDATE_APP_STATE', {
          key: 'mallNotice',
          value: res.data?.mallConfig?.carousel_top_text
        })
        commit('UPDATE_APP_STATE', {
          key: 'morePoints',
          value: res.data?.mallConfig?.overdue_donate_points
        })
        commit('UPDATE_APP_STATE', {
          key: 'marketingBlockAreaFlag',
          value: res.data?.mallConfig?.marketing_block_area_flag ?? false
        })
        commit('UPDATE_APP_STATE', {
          key: 'projectName',
          value: res.data?.projectName
        })

        commit('UPDATE_REFUND_CONFIG', {
          autoRefundMinute: res.data?.mallConfig?.auto_refund_minute ?? 0,
          autoRefundOpen: res.data?.mallConfig?.auto_refund_open ?? false,
          refundOpen: res.data?.mallConfig?.refund_open ?? false
        })

        commit('UPDATE_APP_STATE', {
          key: 'morePoints',
          value: res.data?.mallConfig?.overdue_donate_points
        })

        const configTab = JSON.parse(res.data?.mallConfig?.points_goods_group_tab ?? '[]')
        commit('UPDATE_APP_STATE', {
          key: 'tabs',
          value: configTab.length > 0 ? configTab : defaultTabs
        })
        if (res?.data?.webPointTemplate) {
          const webPointTemplate = res.data.webPointTemplate
          commit('UPDATE_APP_STATE', {
            key: 'pointTemplate',
            value: webPointTemplate
          })
        }
        const dialogOption = res.data?.orderSuccessTipConfig ? JSON.parse(res.data?.orderSuccessTipConfig) : null
        commit('UPDATE_APP_STATE', {
          key: 'orderSuccessfulDialogOption',
          value: dialogOption
            ? {
                content: dialogOption.content,
                duration: dialogOption.duration,
                type: dialogOption.type,
                url_name: dialogOption.url_name
              }
            : DEFAULT_ORDER_SUCCESS_TIP_CONFIG
        })
        // 渠道主题定制配置
        if (res.data && res.data.webTextConfig && res.data.webTextConfig !== '') {
          try {
            const themeConfig = JSON.parse(res.data.webTextConfig) || {}
            if (themeConfig.point_clear_text && themeConfig.point_clear_text.trim() !== '') {
              commit('UPDATE_THEME_CONFIG', {
                key: 'pointClearText',
                value: themeConfig.point_clear_text
              })
            }

            if (themeConfig['global.pointName'] && themeConfig['global.pointName'].trim() !== '') {
              commit('UPDATE_THEME_CONFIG', {
                key: ['global.pointName'],
                value: themeConfig['global.pointName']
              })
            }
            // 商品详情页，限时兑换开关
            if (themeConfig['goodsProfileTimeLimitSwitch']) {
              const goodsProfileTimeLimitSwitch = JSON.parse(themeConfig.goodsProfileTimeLimitSwitch)
              commit('UPDATE_APP_STATE', {
                key: 'goodsProfileTimeLimitSwitch',
                value: goodsProfileTimeLimitSwitch
              })
            }
            // 支付宝内嵌小程序 场景入口配置
            if (state.isInAliPay && themeConfig['sceneEntrance']) {
              const aliPayScene = themeConfig.sceneEntrance
              if (aliPayScene.indexOf('?point') > 0) {
                const aliPaySceneEntrance = aliPayScene.split('?')[0]
                const params = aliPayScene.split('?')[1].split('=')[1] //携带的参数

                router.push({ path: aliPaySceneEntrance })
                commit('UPDATE_APP_STATE', {
                  key: 'specificValOfRedPacket',
                  value: params
                })
              } else {
                router.push({ path: aliPayScene })
              }
            }

            commit('UPDATE_APP_STATE', {
              key: 'showGoodPrice',
              value: themeConfig.showGoodPrice !== 'false'
            })

            commit('UPDATE_APP_STATE', {
              key: 'minePageShowActivity',
              value: themeConfig.minePageShowActivity === 'true'
            })

            commit('UPDATE_APP_STATE', {
              key: 'mallNoticeOpacity',
              value: themeConfig.mallNoticeOpacity || '0.3'
            })

            commit('UPDATE_APP_STATE', {
              key: 'topPointShowMoney',
              value: themeConfig.topPointShowMoney !== 'true'
            })

            commit('UPDATE_APP_STATE', {
              key: 'tabbarCenterImage',
              value: themeConfig.tabbarCenterImage || require('base/assets/img/footbar/pointscenter@2x.png')
            })

            commit('UPDATE_APP_STATE', {
              key: 'firstRedPacketOpen',
              value: themeConfig.firstRedPacketOpen === 'false' ? false : true
            })
            commit('UPDATE_APP_STATE', {
              key: 'firstRedPacketImage',
              value: themeConfig.firstRedPacketImage || 'https://img.bj0yx.com/901550626045952'
            })
            commit('UPDATE_APP_STATE', {
              key: 'mallHomeBannerDefaultImage',
              value: themeConfig.mallHomeBannerDefaultImage || 'https://img.bj0yx.com/903937417805824'
            })
            commit('UPDATE_APP_STATE', {
              key: 'mallHomeBannerBlockImage',
              value: themeConfig.mallHomeBannerBlockImage
            })

            commit('UPDATE_APP_STATE', {
              key: 'receivedPeopleShow',
              value: themeConfig.receivedPeopleShow !== 'false'
            })

            commit('UPDATE_APP_STATE', {
              key: 'redPacketReceivedListVisible',
              value: themeConfig.redPacketReceivedListVisible !== 'false'
            })

            commit('UPDATE_APP_STATE', {
              key: 'backInterceptImage',
              value: themeConfig.backInterceptImage
            })

            commit('UPDATE_APP_STATE', {
              key: 'backInterceptNavigatePath',
              value: themeConfig.backInterceptNavigatePath
            })

            commit('UPDATE_APP_STATE', {
              key: 'defaultPayType',
              value: res.data?.mallConfig?.default_pay_type
            })

            commit('UPDATE_APP_STATE', {
              key: 'enableRefundSelf',
              value: themeConfig.enableRefundSelf === 'true'
            })

            commit('UPDATE_APP_STATE', {
              key: 'payOnDeliveryWorkWxImg',
              value: themeConfig.payOnDeliveryWorkWxImg
            })
            commit('UPDATE_APP_STATE', {
              key: 'payOnDeliveryWorkWxLink',
              value: themeConfig.payOnDeliveryWorkWxLink
            })
            commit('UPDATE_TO_CUSTOM_CONFIG', {
              key: 'mallBackInterceptImg',
              value: themeConfig.mallBackInterceptImg || ''
            })
            brand.load(themeConfig)
          } catch (err) {
            console.log(err)
          }
        }
      } catch (err) {
        console.log(err)
      }

      commit('SET_APP_READY')
    },
    // eslint-disable-next-line no-unused-vars
    async getCornerImg({ commit, state, rootState }, payload) {
      let { pId } = payload
      pId = Number(pId)
      try {
        const res = await getGoodsGroupConfig({ pId })
        commit('UPDATE_APP_STATE', {
          key: 'cornerImg',
          value: res.data
        })
      } catch (err) {
        console.log(err)
      }
    },
    async getMpUrlScheme({ state, rootState }, payload) {
      const { accountId, page = null, path = null, param = null } = payload
      try {
        const resp = await getMpUrlScheme({
          pId: state.pId,
          userId: rootState.user.userId,
          accountId,
          path: page ?? path,
          param
        })
        return resp.data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getTipFloatConfig({ commit, state, rootState }, payload) {
      try {
        const resp = await getAdvertiseConfig({
          advType: 'tipFloatAdvert',
          subType: process.env.VUE_APP_BRANCH_ENUM
        })
        if (resp.data == []) return
        const config = resp.data
        config.forEach((item) => {
          item.businessKey = JSON.parse(item.businessKey)
        })
        commit('UPDATE_APP_STATE', {
          key: 'tipFloatConfig',
          value: config[0]
        })
      } catch (error) {
        console.log('error: ', error)
      }
    },

    async getTabbarFloatImg({ commit }) {
      try {
        const resp = await getAdvertiseConfig({
          advType: 'tabbarFloatImg',
          subType: process.env.VUE_APP_BRANCH_ENUM
        })
        if (resp.data == []) return
        const config = resp.data
        config.forEach((item) => {
          item.businessKey = JSON.parse(item.businessKey)
        })
        commit('UPDATE_APP_STATE', {
          key: 'tabbarFloatImg',
          value: config?.[0]?.img || 'https://img.bj0yx.com/893758866980864'
        })
      } catch (error) {
        console.log('error: ', error)
      }
    },
    sceneGotRedPacket({ commit }) {
      const value = utils.hadGotRedPacket(400000, 800000)
      commit('UPDATE_APP_STATE', {
        key: 'hadGotRedPacket',
        value: value
      })
    }
  }
}

export default appState
