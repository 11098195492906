<!-- eslint-disable vue/return-in-computed-property -->
<template>
  <div class="order" v-track:exposure :track-params="getEventInfo()">
    <div class="top">
      <p class="t1">
        订单编号: <span>{{ source.formatOrderId || source.orderId }}</span>
      </p>
      <p class="t2 color_primary">
        {{
          source.afterStatus == 1
            ? '售后中'
            : source.afterStatus == 2
            ? '售后完成'
            : xEnum.ENUM_ORDER_STATUS.toText(source.orderStatus)
        }}
      </p>
    </div>
    <div class="shop" @click="onClickOrder(source)">
      <van-image class="shopImg" radius="5" :src="source.goodsImgUrl" :lazy-load="true" fit="cover" />
      <div class="shopBox">
        <p class="shop1" v-if="!showGoodsCardName">{{ source.goodsName }}</p>
        <p class="shop1 text-ellipsis" v-else>
          {{ source.goods.goodsCardName || source.goods.goodsDesc || source.goodsName }}
        </p>
        <p class="spec-desc van-ellipsis">{{ sepcDesc }}</p>
        <div class="shop2 mt2 flex items-center gap-[4px]">
          <div v-if="isDrawPrizeOrder" class="flex items-center">
            <div class="color_primary fs12">中奖券后价</div>
            <div class="color_primary ml1 fs18">{{ source.price }}元</div>
          </div>
          <div v-else>
            <span v-if="!source.hideScore && !isSingleProduct && showPoint">
              <b>{{ source.costPoints }}</b>
              {{ globalPointName }}+
            </span>
            <span>{{ source.price }}</span>
            元
          </div>
          <span
            v-if="source.activityType === xEnum.ENUM_ACTIVITY_TYPE.CONVENIENT_BUY.getValue()"
            class="convenient-buy-tag"
          >
            顺手买
          </span>
        </div>
      </div>
      <IconFont v-show="soldOut" size="86px" class="sold-out" icon="iconyiqiangwan" color="#c0c0c0"></IconFont>
      <!-- 退换货 -->
      <div class="flex justify-between items-center refund-tip" v-if="isRefunded">
        <div class="color_primary">请退货</div>
        <div class="flex flex-wrap= items-center my1" style="font-size: 12px">
          <div>您需在</div>
          <div class="color_primary">7天</div>
          <div>内处理，若未处理售后将关闭</div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center" v-if="unpay">
      <div v-if="isShowCountDown" class="flex items-center bold">
        待支付，剩余：<van-count-down
          @finish="showCountDown"
          :millisecond="true"
          format="mm:ss:SS"
          :time="countDown"
        ></van-count-down>
        <span class="fs14"></span>
      </div>
      <div v-else class="bold fs14">
        <template v-if="showSaveMoney">
          <span class="text-[#333333]">已省</span>
          <span class="text-[#d92a1d]">
            {{ (Number(source.goods.originalPrice) - Number(source.price)).toFixed(2) }}
          </span>
          <span class="text-[#333333]">元</span>
        </template>
        <span class="text-[#d92a1d] ml-1">现在兑换最划算！</span>
      </div>
      <div class="pay-btn bg_primary" @click="buy(source)">继续支付</div>
    </div>
    <!-- 退货退款、退货补发 -->
    <div class="bottom" v-else-if="isRefunded">
      <div class="kefu" tag="div" @click="toCustom">联系客服</div>
      <div class="buy-again bg_primary ml2" @click="goRefund(source)">查看详情</div>
    </div>
    <div class="bottom" v-else>
      <div class="kefu" tag="div" @click="toCustom">联系客服</div>
      <div class="logistics ml2" tag="div" @click="goExpress">物流详情</div>
      <div class="buy-again bg_primary ml2" @click="reOrder(source)">再次购买</div>
    </div>
  </div>
</template>

<script>
import { reOrder } from 'base/api'
import { trackEvent } from 'base/utils/rp'
import { IconFont, goodsProfilePointDialog } from 'components'
import xEnum from 'base/utils/enum'
import { Toast, Dialog } from 'vant'
import { mapState, mapActions } from 'vuex'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'
import brand from 'base/brands'

export default {
  name: 'GoodsNormalItem',
  components: { IconFont },
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: () => 0
    },

    onClickItem: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      xEnum,
      isBuyLoading: false,
      isShowCountDown: true
    }
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userPhone: (state) => state.user.phone,
      activePoints: (state) => state.user.points.activePoints,
      morePoints: (state) => state.appState.morePoints,
      userId: (state) => state.user.userId,
      urlAllParams: (state) => state.appState.urlAllParams,
      phoneCode: (state) => state.user.phoneCode
    }),

    showPoint() {
      return brand.renderBrandElement({ id: 'order.showPointOrderItem' })
    },

    showSaveMoney() {
      return brand.renderBrandElement({ id: 'order.showSaveMoneyOrderItem' })
    },

    unpay() {
      return this.source.orderStatus === xEnum.ENUM_ORDER_STATUS.NOT_PAYED.getValue()
    },

    soldOut() {
      return this.unpay && !this.source.goods?.boolOpen
    },

    countDown() {
      const expireTime = dayjs(new Date(dayjs(this.source?.createTime).format('YYYY/MM/DD HH:mm:ss'))) + 5 * 60000
      const now = dayjs(new Date())
      const countDown = expireTime - now
      return countDown
    },

    sepcDesc() {
      if (!this.source.goodsAttribute || this.source.goodsAttribute.trim() === '') {
        return null
      }

      try {
        const attr = JSON.parse(this.source.goodsAttribute)
        return _.reduce(
          attr,
          (result, value) => {
            result += value + ' '
            return result
          },
          ''
        )
      } catch (err) {
        return null
      }
    },

    isDrawPrizeOrder() {
      return this.source && this.source.orderType === xEnum.ENUM_ORDER_TYPE.DRAW_PRIZE.value
    },

    isSingleProduct() {
      return this.source && this.source.goods?.goodsType === xEnum.ENUM_GOODS_TYPE.SINGLE_PRODUCT_PROMOTION.value
    },

    isRefunded() {
      if (
        this.source.afterStatus == 1 &&
        (this.source.serviceType == xEnum.ENUM_SERVICE_TYPE.DELIVERED_REFUND.value ||
          this.source.serviceType == xEnum.ENUM_SERVICE_TYPE.DELIVERED_REISSUE.value) &&
        this.source.returnGoodsStatus == xEnum.ENUM_RETURN_GOODS_STATUS.UNCHECKED.value
      ) {
        return true
      } else {
        return false
      }
    }
  },

  created() {},

  deactivated() {},

  methods: {
    ...mapActions(['finishPresentPointAgain']),

    toCustom() {
      this.$router.push({ path: '/customService1', query: { location: window.location.href } })
    },

    goExpress() {
      if (this.phoneCode && !this.userPhone) {
        return Dialog.confirm({
          title: '温馨提示',
          className: 'grant-query-dialog',
          message: '为了保障您的个人隐私，请授权后再进行查询。',
          confirmButtonText: '去授权',
          confirmButtonColor: '#E54635',
          cancelButtonText: '取消',
          cancelButtonColor: '#AAAAAA'
        })
          .then(() => {
            this.$router.push({ path: '/grantPhone', query: { phoneUnbound: true, from: 'updatePhone' } })
          })
          .catch(() => {
            return false
          })
      }
      this.$router.push({
        path: '/express',
        query: {
          orderId: this.source?.orderId,
          pId: this.pId
          // phone: orderPhone
        }
      })
    },

    onClick() {
      const { source, index } = this

      this.onClickItem(source, index)
    },

    showCountDown() {
      this.isShowCountDown = false
    },

    onClickOrder(order) {
      if (this.phoneCode && !this.userPhone) {
        return Dialog.confirm({
          title: '温馨提示',
          className: 'grant-query-dialog',
          message: '为了保障您的个人隐私，请授权后再进行查询。',
          confirmButtonText: '去授权',
          confirmButtonColor: '#E54635',
          cancelButtonText: '取消',
          cancelButtonColor: '#AAAAAA'
        })
          .then(() => {
            this.$router.push({ path: '/grantPhone', query: { phoneUnbound: true, from: 'updatePhone' } })
          })
          .catch(() => {
            return false
          })
      }
      //点击订单事件埋点
      this.clickOrderItemQtBurying()
      if (this.unpay) {
        if (!order.goods?.boolOpen) {
          Dialog.alert({
            message: '该商品太抢手了，已被抢完，抓紧去看看其他超值商品吧',
            confirmButtonText: '好的'
          }).then(() => {
            this.$router.push({ path: '/primary' })
          })
          return
        }
        this.$store.dispatch('enterSettlement', {
          module: '我的订单-继续支付',
          goods: order.goods
        })
        this.$router.push({
          path: '/payindex',
          query: { orderId: order?.orderId, fromPage: 'myorder' }
        })
      } else {
        trackEvent({
          category: '点击-我的订单-主体'
        })
        this.$router.push({
          path: '/ordersuccess',
          query: { orderId: order?.orderId, fromPage: 'myorder' },
          params: { from: 'myorder' }
        })
      }
    },

    checkGoodsBoolOpen(orderInfo) {
      if (!orderInfo.goods?.boolOpen) {
        Dialog.alert({
          message: '该商品太抢手了，已被抢完，抓紧去看看其他超值商品吧',
          confirmButtonText: '好的'
        })
        return false
      }
      return true
    },

    checKActivePoints(orderInfo) {
      if (this.activePoints < orderInfo.costPoints) {
        goodsProfilePointDialog({
          onClick: async () => {
            await this.finishPresentPointAgain({ pId: this.pId, skuId: orderInfo.goodsSpecId })
            Toast({
              message: `积分+${this.morePoints}`,
              position: 'bottom'
            })
          }
        })
        return false
      }
      return true
    },

    checkIsPayOnDeliveryOrder(orderInfo) {
      if (orderInfo.payType === xEnum.ENUM_PAYMENT_TYPE.ARRIVE.value) {
        const { goodGroupType, projectId, pageSource } = orderInfo
        this.$router.push({
          path: `/goodsProfile/${orderInfo.goods.id}`,
          query: {
            type: goodGroupType,
            pId: projectId,
            pageSource,
            activityType: 'payOnDelivery'
          }
        })
        return false
      }
      return true
    },

    buy(orderInfo) {
      if (orderInfo.goodGroupType == 0) {
        delete orderInfo['goodGroupType']
      }

      if (!this.checkGoodsBoolOpen(orderInfo)) return
      if (!this.checKActivePoints(orderInfo)) return

      if (this.unpay) {
        this.$store.dispatch('enterSettlement', {
          module: '我的订单-继续支付',
          goods: orderInfo.goods
        })
        this.$router.push({
          path: '/payindex',
          query: { orderId: this.source?.orderId, fromPage: 'myorder' }
        })
      }
    },

    reOrder(orderInfo) {
      if (orderInfo.goodGroupType == 0) {
        delete orderInfo['goodGroupType']
      }

      if (!this.checkGoodsBoolOpen(orderInfo)) return
      if (!this.checKActivePoints(orderInfo)) return
      if (!this.checkIsPayOnDeliveryOrder(orderInfo)) return

      trackEvent({
        category: '点击-我的订单-再次购买'
      })
      if (this.isBuyLoading) {
        return
      }
      const { orderId, pageSource, appType, userTag } = orderInfo
      this.isBuyLoading = true
      reOrder({
        orderId,
        userId: this.userId,
        projectId: this.pId,
        pageSource,
        appType,
        userTag,
        customParam: this.urlAllParams
      })
        .then((response) => {
          this.isBuyLoading = false
          // TODO: 当前规格 售罄/下架 后端的code不应给400
          if (response.code === 200) {
            this.reorderGeneratedSuccessQtBurying(response.data?.orderId) //再来一单埋点上报
            this.$store.dispatch('enterSettlement', {
              module: '我的订单-再次购买',
              goods: orderInfo.goods
            })
            this.$router.push({ path: '/payindex', query: { orderId: response.data?.orderId, fromPage: 'reOrder' } })
          } else {
            Toast.fail(response.message)
            this.reorderFailQtBurying(response.message)
          }
        })
        .catch(() => {
          this.isBuyLoading = false
        })
    },

    goRefund(orderInfor) {
      this.$router.push({ path: '/serviceOrdersDetail', query: { orderId: orderInfor.orderId } })
    },

    getEventInfo() {
      const orderStatus =
        this.source.afterStatus == 1
          ? '售后中'
          : this.source.afterStatus == 2
          ? '售后完成'
          : xEnum.ENUM_ORDER_STATUS.toText(this.source.orderStatus)
      const payStatus = this.source.payPrice > 0 ? 1 : 0
      return JSON.stringify({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.MY_ORDER_LIST_ITEM_EP.value,
        eventInfo: {
          orderCostPoints: this.source.costPoints,
          couponName: this.source.coupon || 0,
          couponNumber: 0,
          orderId: this.source.orderId,
          goodsSpecId: this.source.goodsSpecId,
          orderPayStatus: payStatus,
          orderStatus: orderStatus,
          goodsNum: this.source.quantity,
          goodsId: this.source.goodsId,
          goodsPrice: this.source.price,
          goodsGroupId: this.source.goodGroupType,
          goodsName: this.source.goodsName,
          goodsIndex: this.index,
          isFirstOrderUser: this.isFirstOrderUser,
          goodsCategoryId: this.source.goods.categoryId
        }
      })
    },

    clickOrderItemQtBurying() {
      const orderStatus =
        this.source.afterStatus == 1
          ? '售后中'
          : this.source.afterStatus == 2
          ? '售后完成'
          : xEnum.ENUM_ORDER_STATUS.toText(this.source.orderStatus)
      const payStatus = this.source.payPrice > 0 ? 1 : 0
      const payType = xEnum.ENUM_PAYMENT_TYPE.toText(this.source.payType) || ''

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_myorder_order_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_myorder_page',
        params: {
          order_status: orderStatus,
          order_pay_status: payStatus,
          goods_group_type: this.source.goodGroupType,
          goods_num: this.source.quantity,
          goods_id: this.source.goodsId,
          display_price: this.source.price,
          display_original_price: this.source.originalPrice,
          coupon_nm: this.source.coupon,
          coupon_num: 0,
          expend_integral_num: this.source.costPoints,
          sku_id: this.source.goodsSpecId,
          sku_name: this.source.goodsSpecName,
          if_first_order: this.isFirstOrderUser,
          order_id: this.source.orderId,
          goods_category_id: this.source.goods.categoryId,
          goods_name: this.source.goodsName,
          pay_method: payType
        }
      })
    },

    reorderGeneratedSuccessQtBurying(orderId) {
      // 再来一单 生成订单成功埋点上报
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_goods_detail_order_suc',
        eventType: EventType.Other,
        pageName: 'dacuecmc_goods_detail_page',
        params: {
          goods_num: 1,
          goods_id: this.source.goodsId,
          goods_group_type: this.source.goodGroupType,
          display_price: this.source.price,
          display_original_price: this.source.originalPrice,
          coupon_nm: '',
          coupon_num: 0,
          expend_integral_num: this.source.costPoints,
          sku_id: this.source.goodsSpecId,
          sku_name: this.source.goodsSpecName,
          if_first_order: this.isFirstOrderUser,
          goods_category_id: this.source.goods.categoryId,
          goods_name: this.source.goodsName,
          order_id: orderId,
          if_repurchase_order: 1, //是否是复购单
          if_ssm: 0,
          receiver_address: this.cookie.get('addressDetail'),
          receiver_area: this.cookie.get('addressArea'),
          receiver_city: this.cookie.get('addressCity'),
          receiver_name: this.cookie.get('receiverName'),
          receiver_province: this.cookie.get('addressProvince'),
          receiver_phone: this.cookie.get('phone')
        }
      })
    },

    reorderFailQtBurying(failMessage) {
      // 生成订单失败
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_goods_detail_order_fail',
        eventType: EventType.Other,
        pageName: 'dacuecmc_goods_detail_page',
        params: {
          goods_num: 1,
          goods_id: this.source.goodsId,
          goods_group_type: this.source.goodGroupType,
          display_price: this.source.price,
          display_original_price: this.source.originalPrice,
          coupon_nm: '',
          coupon_num: 0,
          expend_integral_num: this.source.costPoints,
          sku_id: this.source.goodsSpecId,
          sku_name: this.source.goodsSpecName,
          if_first_order: this.isFirstOrderUser,
          goods_category_id: this.source.goods.categoryId,
          goods_name: this.source.goodsName,
          if_repurchase_order: 1,
          false_reason: failMessage,
          if_ssm: 0,
          receiver_address: this.cookie.get('addressDetail'),
          receiver_area: this.cookie.get('addressArea'),
          receiver_city: this.cookie.get('addressCity'),
          receiver_name: this.cookie.get('receiverName'),
          receiver_province: this.cookie.get('addressProvince'),
          receiver_phone: this.cookie.get('phone')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.order {
  position: relative;
  // width: 100%;
  // display: inline-block;
  // box-shadow: 0px 0px 4px 1px #e4e4e4;
  // border-radius: 8px;
  // margin-bottom: 8px;
  // position: relative;
  // padding-bottom: 10px;
  // background: #fff;

  /*有订单*/
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
  .pay-btn {
    font-size: 13px;
    color: #fff;
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    box-sizing: border-box;
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    .kefu,
    .logistics,
    .buy-again,
    .pay-btn {
      font-size: 13px;
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 25px;
      box-sizing: border-box;
    }
    .kefu,
    .logistics {
      background: white;
      color: #656565;
      border: 1px solid #ddd;
    }
    .buy-again,
    .pay-btn {
      color: white;
    }
  }

  .top {
    margin: 0 auto;
  }
  .t1 {
    display: inline-block;
    vertical-align: middle;
    width: 70%;
    font-size: 15px;
    color: #333333;
    text-align: left;
  }
  .t2 {
    display: inline-block;
    vertical-align: middle;
    width: 30%;
    font-size: 15px;
    text-align: right;
  }

  /*商品*/
  .shop {
    width: 100%;
    margin: 10px auto;
    position: relative;
    .sold-out {
      position: absolute;
      right: 15%;
      top: 15%;
    }

    .spec-desc {
      font-size: 13px;
      color: #7c7c7c;
    }

    .refund-tip {
      width: 100%;
      background-color: #f2f2f2;
      border-radius: 5px;
      padding: 0 5px;
      margin: 5px 0;
      box-sizing: border-box;
    }
  }
  .bargain-tag {
    width: 25px;
    height: 16px;
    font-size: 13px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    color: #fff;
    background-color: #ff4e4e;
    border-radius: 0 8px 8px 8px;
    align-items: center;
  }
  .shopImg {
    display: inline-block;
    vertical-align: top;
    width: 80px;
    height: 80px;
    margin-right: 10px;
    overflow: hidden;
  }
  .shopBox {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 120px);
  }
  .shop1 {
    color: #191919;
    width: 100%;
    font-size: 15px;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .shop2 {
    width: 100%;
    color: #e00000;
    font-size: 15px;
  }
  .shopNum {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    font-size: 12px;
    text-align: center;
  }
  .b1 {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 80px);
    font-size: 15px;
    font-weight: 900;
  }
  .b2 {
    float: right;
    width: 150px;
    height: 40px;
    line-height: 40px;
    background-color: #2ba245;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
  .b3 {
    float: left;
    width: 130px;
    height: 35px;
    line-height: 35px;
    color: #3e3a34;
    border: 1px solid #3e3a34;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
  }
  .b3 a {
    color: #3e3a34;
  }

  .b4 {
    float: right;
    width: 130px;
    height: 35px;
    line-height: 35px;
    color: #3e3a34;
    border: 1px solid #3e3a34;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
  }
  .b4 a {
    color: #3e3a34;
  }
}

.convenient-buy-tag {
  display: inline-flex;
  font-size: 12px;
  background: linear-gradient(45deg, #ff5775, #d92e21);
  color: white;
  padding: 2px 4px;
  border-radius: 4px 8px;
}
</style>
<style lang="less">
.order .van-count-down {
  color: #d92a1d;
}
</style>
