import _ from 'lodash'
import { Toast } from 'vant'
import store from '@/store'
import utils from 'base/utils'
import { trackEvent } from 'base/utils/rp'
import brand from 'base/brands'
const PKG_NAME = 'com.tpyx.quickapp'
const PATH = 'Home'
// eslint-disable-next-line no-unused-vars
const DIRECT_WAKE_PID_MAPPING = [
  // A
  { WAKE_UP_PID: '836041556623362', LAND_PID: '836041534078976' },
  // B
  { WAKE_UP_PID: '836184612798464', LAND_PID: '836184642682880' },
  // C
  { WAKE_UP_PID: '836184668372992', LAND_PID: '836184722898944' },
  // D
  { WAKE_UP_PID: '836219949285376', LAND_PID: '836219984936960' },
  // E
  { WAKE_UP_PID: '836220014297088', LAND_PID: '836220056240128' },
  // F
  { WAKE_UP_PID: '836275848871936', LAND_PID: '836275874562048' },
  // G
  { WAKE_UP_PID: '836275901300736', LAND_PID: '836275955302400' },
  // H
  { WAKE_UP_PID: '836276081655808', LAND_PID: '836276105773056' },
  // I
  { WAKE_UP_PID: '836276127793152', LAND_PID: '836276177076224' },
  // J
  { WAKE_UP_PID: '836276199620608', LAND_PID: '836276225310720' },
  // k
  { WAKE_UP_PID: '836361370206208', LAND_PID: '836361416343552' },
  // L
  { WAKE_UP_PID: '836362334371841', LAND_PID: '836362372120576' },
  // M
  { WAKE_UP_PID: '836362406723584', LAND_PID: '836362434510848' },
  // N
  { WAKE_UP_PID: '836362463346688', LAND_PID: '836362488512512' },
  // O
  { WAKE_UP_PID: '836505904349184', LAND_PID: '836505946816512' },
  // P
  { WAKE_UP_PID: '836506009731072', LAND_PID: '836506041188352' },
  // Q
  { WAKE_UP_PID: '836723989807105', LAND_PID: '836724145520640' },
  // R
  { WAKE_UP_PID: '836724169113600', LAND_PID: '836724192706560' },
  // S
  { WAKE_UP_PID: '831835977285632', LAND_PID: '831836032860160' },
  // T
  { WAKE_UP_PID: '831836086337536', LAND_PID: '831844877598720' },
  // U
  { WAKE_UP_PID: '831844920066048', LAND_PID: '831865559187456' },
  // V
  { WAKE_UP_PID: '831865584353280', LAND_PID: '831865636782080' },
  // W
  { WAKE_UP_PID: '831906826944512', LAND_PID: '831906867314688' },
  // X
  { WAKE_UP_PID: '831906897723392', LAND_PID: '831906923937792' },
  // Y
  { WAKE_UP_PID: '831926243426304', LAND_PID: '831926269640704' },
  // Z
  { WAKE_UP_PID: '831926285893632', LAND_PID: '831926315253760' },
  // AA
  { WAKE_UP_PID: '831926328885248', LAND_PID: '831926343565312' },
  // AB
  { WAKE_UP_PID: '831969558528000', LAND_PID: '831969578450944' },
  // AC
  { WAKE_UP_PID: '831969594703872', LAND_PID: '831969612529664' },
  // AD
  { WAKE_UP_PID: '831969629831168', LAND_PID: '831969645559808' },
  // AE
  { WAKE_UP_PID: '832017966563328', LAND_PID: '832017986486272' },
  // AF
  { WAKE_UP_PID: '832018006409216', LAND_PID: '832018024235008' },
  // AG
  { WAKE_UP_PID: '832018043633664', LAND_PID: '832018067226624' },
  // AH
  { WAKE_UP_PID: '832544793165824', LAND_PID: '832544822525952' },
  // AI
  { WAKE_UP_PID: '832565661925376', LAND_PID: '832565696528384' },
  // AJ
  { WAKE_UP_PID: '832565715927040', LAND_PID: '832565732704256' },
  // AK
  { WAKE_UP_PID: '832565752102912', LAND_PID: '832565779365888' },
  // AL
  { WAKE_UP_PID: '832611431219200', LAND_PID: '832613453922304' },
  // tp8112_AM
  { WAKE_UP_PID: '832613475418112', LAND_PID: '832613495341056' },
  // tp2576_AN
  { WAKE_UP_PID: '832613516312576', LAND_PID: '832613543575552' },
  // tp9920_AO
  { WAKE_UP_PID: '832613562449920', LAND_PID: '832649380233216' },
  // tp9104_AP
  { WAKE_UP_PID: '832649420079104', LAND_PID: '832649451012096' },
  // tp3632_AQ
  { WAKE_UP_PID: '832649475653632', LAND_PID: '832649499246592' },
  // tp9840_AR
  { WAKE_UP_PID: '832649588899840', LAND_PID: '832655081340928' },
  // tp9648_AS
  { WAKE_UP_PID: '832655115419648', LAND_PID: '832655138488320' },
  // tp1264_AT
  { WAKE_UP_PID: '832655158411264', LAND_PID: '832655194587136' },
  // tp5952_AU
  { WAKE_UP_PID: '832655250685952', LAND_PID: '832655293677568' },
  // tp4896_AV
  { WAKE_UP_PID: '832655336144896', LAND_PID: '832655401156608' },
  // tp5264_AW
  { WAKE_UP_PID: '832655420555264', LAND_PID: '832655481372672' },
  // tp3968_AX
  { WAKE_UP_PID: '832679371603968', LAND_PID: '832746154360832' },
  // tp2400_AY
  { WAKE_UP_PID: '832746186342400', LAND_PID: '833368229412864' },
  // tp5520_AZ
  { WAKE_UP_PID: '833408815595520', LAND_PID: '833408853868544' },
  // tp4416_BA
  { WAKE_UP_PID: '833408890044416', LAND_PID: '833409748828160' },
  // tp1216_BB
  { WAKE_UP_PID: '833452065161216', LAND_PID: '833452088229888' },
  // tp5696_BC
  { WAKE_UP_PID: '833452109725696', LAND_PID: '833452129124352' },
  // tp8784_BD
  { WAKE_UP_PID: '833471168118784', LAND_PID: '833471190663168' },
  // tp8976_BE
  { WAKE_UP_PID: '833471212158976', LAND_PID: '833471232081920' },
  // tp6928_BF
  { WAKE_UP_PID: '833655226236928', LAND_PID: '833655300161536' },
  // tp5968_BG
  { WAKE_UP_PID: '833655333715968', LAND_PID: '833655376183296' },
  // tp1984_BH
  { WAKE_UP_PID: '833655402921984', LAND_PID: '833731263201280' },
  // tp3280_BI
  { WAKE_UP_PID: '833731394273280', LAND_PID: '833742547976192' },
  // tp7712_BJ
  { WAKE_UP_PID: '833742568947712', LAND_PID: '833742587822080' },
  // tp5712_BK
  { WAKE_UP_PID: '833785363955712', LAND_PID: '833793065746432' },
  // tp0640_BL
  { WAKE_UP_PID: '833997847920640', LAND_PID: '833997875183616' },
  // tp6576_BM
  { WAKE_UP_PID: '833997898776576', LAND_PID: '833997928136704' },
  // tp8256_BN
  { WAKE_UP_PID: '833997956448256', LAND_PID: '834005150728192' },
  // tp1696_BO
  { WAKE_UP_PID: '834015457181696', LAND_PID: '834015472910336' },
  // tp3232_BP
  { WAKE_UP_PID: '834015481823232', LAND_PID: '834015488638976' },
  // tp1872_BQ
  { WAKE_UP_PID: '834015497551872', LAND_PID: '834064390553600' },
  // tp2384_BR
  { WAKE_UP_PID: '834064439312384', LAND_PID: '834064544694272' },
  // tp0208_BS
  { WAKE_UP_PID: '834103917150208', LAND_PID: '834103937073152' },
  // tp7520_BT
  { WAKE_UP_PID: '834103955947520', LAND_PID: '834103989501952' },
  // tp6352_BU
  { WAKE_UP_PID: '834104015716352', LAND_PID: '834104039309312' },
  // tp5632_BV
  { WAKE_UP_PID: '834149070405632', LAND_PID: '834149165301760' },
  // tp4736_BW
  { WAKE_UP_PID: '834149192564736', LAND_PID: '834149418008576' },
  // tp0496_BX
  { WAKE_UP_PID: '834193439850496', LAND_PID: '834193462394880' },
  // tp7856_BY
  { WAKE_UP_PID: '834193489657856', LAND_PID: '834193519017984' },
  // tp8128_BZ
  { WAKE_UP_PID: '834193552048128', LAND_PID: '834283708612608' },
  // tp0544_CA
  { WAKE_UP_PID: '834333042540544', LAND_PID: '834333082386432' },
  // tp0864_CB
  { WAKE_UP_PID: '834333115940864', LAND_PID: '834333146873856' },
  // tp5518_CC
  { WAKE_UP_PID: '839445878145518', LAND_PID: '839445878145252' },
  // tp5480_CD
  { WAKE_UP_PID: '839445878145480', LAND_PID: '839445878145214' },
  // tp5442_CE
  { WAKE_UP_PID: '839445878145442', LAND_PID: '839445878145176' },
  // tp5404_CF
  { WAKE_UP_PID: '839445878145404', LAND_PID: '839445878669426' },
  // tp5138_CG
  { WAKE_UP_PID: '839445878145138', LAND_PID: '839445878145632' },
  // tp5366_CH
  { WAKE_UP_PID: '839445878145366', LAND_PID: '839445878669388' },
  // tp5100_CI
  { WAKE_UP_PID: '839445878145100', LAND_PID: '839445878145594' },
  // tp5328_CJ
  { WAKE_UP_PID: '839445878145328', LAND_PID: '839445865037862' },
  // tp9350_CK
  { WAKE_UP_PID: '839445878669350', LAND_PID: '839445878145062' },
  // tp5556_CL
  { WAKE_UP_PID: '839445878145556', LAND_PID: '839445878145290' },
  // tp0032_CM
  { WAKE_UP_PID: '839445716140032', LAND_PID: '839445865037824' },
  // tp9312_CN
  { WAKE_UP_PID: '839445878669312', LAND_PID: '839445878145024' },
  // tp7136_CO
  { WAKE_UP_PID: '839445707227136', LAND_PID: '839446105686016' },
  // CP
  { WAKE_UP_PID: '839908506206208', LAND_PID: '839908585373696' },
  // CQ
  { WAKE_UP_PID: '839908585897984', LAND_PID: '839908506206766' },
  // CR
  { WAKE_UP_PID: '839908506206518', LAND_PID: '839908585898294' },
  // CS
  { WAKE_UP_PID: '839908506206270', LAND_PID: '839908585898046' },
  // CT
  { WAKE_UP_PID: '839908506206580', LAND_PID: '839908585898356' },
  // CU
  { WAKE_UP_PID: '839908506206332', LAND_PID: '839908585898108' },
  // CV
  { WAKE_UP_PID: '839908506206642', LAND_PID: '839908585898418' },
  // CW
  { WAKE_UP_PID: '839908506206394', LAND_PID: '839908585898170' },
  // CX
  { WAKE_UP_PID: '839908506206704', LAND_PID: '839908585898480' },
  // CY
  { WAKE_UP_PID: '839908506206456', LAND_PID: '839908585898232' },
  // CZ
  { WAKE_UP_PID: '839947678908416', LAND_PID: '839947678909470' },
  // DA
  { WAKE_UP_PID: '839947678909222', LAND_PID: '839947678908974' },
  // DB
  { WAKE_UP_PID: '839947678908726', LAND_PID: '839947678908478' },
  // DC
  { WAKE_UP_PID: '839947678909532', LAND_PID: '839947678909284' },
  // DD
  { WAKE_UP_PID: '839947678909036', LAND_PID: '839947678908788' },
  // DE
  { WAKE_UP_PID: '839947678908540', LAND_PID: '839947678909594' },
  // DF
  { WAKE_UP_PID: '839947678909346', LAND_PID: '839947678909098' },
  // DG
  { WAKE_UP_PID: '839947678908850', LAND_PID: '839947678908602' },
  // DH
  { WAKE_UP_PID: '839947678909408', LAND_PID: '839947678909160' },
  // DI
  { WAKE_UP_PID: '839947678908912', LAND_PID: '839947678908664' },

  // ====== 拉起的仍是自己 ======
  // 新版快应用tp2064_DJ
  { WAKE_UP_PID: '840163801432064', LAND_PID: '840163801432064' },
  // 新版快应用tp6352_DK
  { WAKE_UP_PID: '840163801956352', LAND_PID: '840163801956352' },
  // 新版快应用tp3366_DL
  { WAKE_UP_PID: '840163801433366', LAND_PID: '840163801433366' },
  // 新版快应用tp3118_DM
  { WAKE_UP_PID: '840163801433118', LAND_PID: '840163801433118' },
  // 新版快应用tp2870_DN
  { WAKE_UP_PID: '840163801432870', LAND_PID: '840163801432870' },
  // 新版快应用tp2622_DO
  { WAKE_UP_PID: '840163801432622', LAND_PID: '840163801432622' },
  // 新版快应用tp2374_DP
  { WAKE_UP_PID: '840163801432374', LAND_PID: '840163801432374' },
  // 新版快应用tp6662_DQ
  { WAKE_UP_PID: '840163801956662', LAND_PID: '840163801956662' },
  // 新版快应用tp2126_DR
  { WAKE_UP_PID: '840163801432126', LAND_PID: '840163801432126' },
  // 新版快应用tp6414_DS
  { WAKE_UP_PID: '840163801956414', LAND_PID: '840163801956414' },
  // 新版快应用tp3428_DT
  { WAKE_UP_PID: '840163801433428', LAND_PID: '840163801433428' },
  // 新版快应用tp3180_DU
  { WAKE_UP_PID: '840163801433180', LAND_PID: '840163801433180' },
  // 新版快应用tp2932_DV
  { WAKE_UP_PID: '840163801432932', LAND_PID: '840163801432932' },
  // 新版快应用tp2684_DW
  { WAKE_UP_PID: '840163801432684', LAND_PID: '840163801432684' },
  // 新版快应用tp2436_DX
  { WAKE_UP_PID: '840163801432436', LAND_PID: '840163801432436' },
  // 新版快应用tp2188_DY
  { WAKE_UP_PID: '840163801432188', LAND_PID: '840163801432188' },
  // 新版快应用tp6476_DZ
  { WAKE_UP_PID: '840163801956476', LAND_PID: '840163801956476' },
  // 新版快应用tp3490_EA
  { WAKE_UP_PID: '840163801433490', LAND_PID: '840163801433490' },
  // 新版快应用tp3242_EB
  { WAKE_UP_PID: '840163801433242', LAND_PID: '840163801433242' },
  // 新版快应用tp2994_EC
  { WAKE_UP_PID: '840163801432994', LAND_PID: '840163801432994' },
  // 新版快应用tp2746_ED
  { WAKE_UP_PID: '840163801432746', LAND_PID: '840163801432746' },
  // 新版快应用tp2498_EE
  { WAKE_UP_PID: '840163801432498', LAND_PID: '840163801432498' },
  // 新版快应用tp2250_EF
  { WAKE_UP_PID: '840163801432250', LAND_PID: '840163801432250' },
  // 新版快应用tp6538_EG
  { WAKE_UP_PID: '840163801956538', LAND_PID: '840163801956538' },
  // 新版快应用tp3304_EH
  { WAKE_UP_PID: '840163801433304', LAND_PID: '840163801433304' },
  // 新版快应用tp3056_EI
  { WAKE_UP_PID: '840163801433056', LAND_PID: '840163801433056' },
  // 新版快应用tp2808_EJ
  { WAKE_UP_PID: '840163801432808', LAND_PID: '840163801432808' },
  // 新版快应用tp2560_EK
  { WAKE_UP_PID: '840163801432560', LAND_PID: '840163801432560' },
  // 新版快应用tp2312_EL
  { WAKE_UP_PID: '840163801432312', LAND_PID: '840163801432312' },
  // 新版快应用tp6600_EM
  { WAKE_UP_PID: '840163801956600', LAND_PID: '840163801956600' },
  { WAKE_UP_PID: '840220359000064', LAND_PID: '840220359000064' },
  // 深圳畅想-支付宝扫码
  { WAKE_UP_PID: '839483841314816', LAND_PID: '839483841314816' },
  // VIVO_推广渠道
  { WAKE_UP_PID: '839081423011840', LAND_PID: '839081423011840' },
  // 上海圣魔方
  { WAKE_UP_PID: '838906124173312', LAND_PID: '838906124173312' },
  // 广州青莲
  { WAKE_UP_PID: '839218684231680', LAND_PID: '839218684231680' },
  // 百度快速优化0902
  { WAKE_UP_PID: '838354285887488', LAND_PID: '838354285887488' },
  // 微推0831
  { WAKE_UP_PID: '838262551740416', LAND_PID: '838262551740416' },
  // 华亚和讯0826
  { WAKE_UP_PID: '838034893832192', LAND_PID: '838034893832192' },
  // ====== 快应用分享后专用 ======
  { WAKE_UP_PID: '836810889494528', LAND_PID: '836810889494528' }
]

const DIRECT_WAKE_PID_BLACKLIST = [
  // 商品详情页分享
  '862993977442304',
  // 临时测试0814
  '837498341687296',
  // 测试-新首页
  '837449526804480',
  // APP专属渠道号
  '839081434021888',
  // 淘品福利社文章阅读测试
  '839612335915008',
  // 淘品优选商城文章阅读测试1009
  '840019270434816',
  // 公众号推广测试
  '839611769683968',
  // 淘品福利社文章阅读测试1009
  '840019197558784',
  // 公众号-淘品（新）
  '835805595041792',
  // 公众号-云仓（新）
  '835805406298112',
  // 公众号-云仓
  '834472073232384',
  // 测试-勿用
  '834086335676416',
  // 公众号福利社-淘品
  '832114248384512',
  // 测试环境专用渠道号
  '839081423011840',
  // vivo巡查问题渠道，加黑处理
  '840163801432808',
  // v5主推黑名单
  '832655194587136',
  '832655293677568',
  '832655309930496',
  '832746127622144',
  '835921281810432',
  '833704283340800',
  // v5.7.0主推黑名单
  '836724578582528',
  '839947678908478',
  '836041534078976',
  '836554647928840',
  '836554647928832',
  '836599765008384',
  // v5.8.0主推黑名单 201127
  '835693784334336',
  '835693810024448',
  '835693829947392',
  '835738086670336'
]

const BOTTOM_BANNER_PID_MAPPING = [
  // R
  { WAKE_UP_PID: '836724169113600', LAND_PID: '836724192706560' },
  // 快应用分享后专用
  { WAKE_UP_PID: '836810889494528', LAND_PID: '836810889494528' }
]

const PID_EXPIRE_IN_3DAYS_LIST = [
  '840259375464448',
  '840350669209600',
  '840475846639616',
  '840475847163904',
  '840475846639676',
  '840475847163964',
  '840475847164024',
  '840677512445952',
  '840677512446220',
  '840677512446019',
  '840677512446086',
  '840677512446153'
]

const isNeedDirectWakeQuickApp = (pId) => {
  // 只有指定的 pid 才拉起快应用，防止影响其他渠道的统计数据。
  // return _.find(DIRECT_WAKE_PID_MAPPING, { WAKE_UP_PID: pId })

  // 2020-10-14 改为黑名单
  return !_.includes(DIRECT_WAKE_PID_BLACKLIST, pId)
}

const isNeedBottomWakeQuickApp = (pId) => {
  // 只有指定的 pid 才拉起快应用，防止影响其他渠道的统计数据。
  return _.find(BOTTOM_BANNER_PID_MAPPING, { WAKE_UP_PID: pId })
}

const jumpToQuickApp = async ({
  packageName = PKG_NAME,
  path = PATH,
  params,
  confirm = false,
  silence = false,
  isVivo = false
}) => {
  if (!brand.renderBrandElement({ id: 'global.enableQuick' })) {
    console.log('禁用快应用')
    return
  }

  if (!silence) {
    Toast.success('请稍后')
  }

  // 如果没有带渠道号，则从params剔除。不能带 null 或者 undefined，否则会被字符串化。
  if (!params.h5PId) {
    delete params.h5PId
  } else {
    // 默认 7 天
    params.pidExpireIn = params.pidExpireIn || 7 * 24 * 60 * 60 * 1000
    if (_.includes(PID_EXPIRE_IN_3DAYS_LIST, params.h5PId)) {
      params.pidExpireIn = 3 * 24 * 60 * 60 * 1000
    }
  }
  try {
    if (!isVivo) {
      // 其实不用 router 跳转了，直接跳 hap://就行。这种链接并不会导致路由跳转，用户即使拒绝，仍然可以浏览 H5。
      window.appRouter(packageName, path, params, confirm)
    } else {
      // VIVO的默认会被拦截。
      const paramsStr = _.chain(params)
        .toPairs()
        .map((pair) => `${pair[0]}=${pair[1]}`)
        .join('&')
        .value()
      location.href = `hap://app/${packageName}/${path}?${paramsStr}`
    }
  } catch (e) {
    console.log(e)
  }

  if (!silence) {
    await utils.waitFor(1000)
    Toast.fail('请允许添加到桌面')
  }
}

async function openQuickAppAndAddToDesktop(eventName, h5UserId, h5PId) {
  const { isAudited, manufacturer } = store.state.appState
  trackEvent({
    category: eventName,
    action: '添加到桌面',
    opt_label: `总数`
    // opt_value: good.price
  })

  if (!isAudited) {
    trackEvent({
      category: eventName,
      action: '添加到桌面',
      opt_label: `未过审`
      // opt_value: good.price
    })
    Toast.fail('抱歉，您的设备不支持快应用')
    return
  }

  // 其实可以全部强拉，不用判断是否支持，这样量更大。
  // 不支持强拉，也不会跳 url，不会导致 H5 的流失。
  // if (!supportQuickApp) {
  //   trackEvent({
  //     category : eventName,
  //     action: '添加到桌面',
  //     opt_label: `不支持快应用`,
  //     // opt_value: good.price
  //   });
  //   Toast.fail('抱歉，您的设备不支持快应用');
  //   return
  // }

  trackEvent({
    category: eventName,
    action: '添加到桌面',
    opt_label: `尝试拉起`
    // opt_value: good.price
  })

  // 尝试拉起，同时跳转快应用。
  try {
    await jumpToQuickApp({
      params: {
        h5UserId,
        createShortcut: true,
        h5PId
      },
      isVivo: manufacturer === 'vivo'
    })
  } catch (e) {
    Toast.fail(String(e))
  }
}

export default {
  isNeedDirectWakeQuickApp,
  isNeedBottomWakeQuickApp,
  jumpToQuickApp,
  openQuickAppAndAddToDesktop
}
