<template>
  <div class="com-goods-purchase-form">
    <van-form @submit="saveOrderSubmit">
      <ul class="I_form">
        <li>
          <label class="bdxx"> <em>*</em>收货姓名 </label>
          <input type="text" name="receiverName" v-model="orderForm.receiverName" value placeholder="点此输入姓名" />
        </li>
        <li class="Phone">
          <label class="bdxx"> <em>*</em>手机号码 </label>
          <input
            type="number"
            name="phone"
            v-model="orderForm.phone"
            value
            placeholder="点此输入手机号，请确保填写正确"
          />
        </li>
        <li>
          <label class="bdxx"> <em>*</em>省市区县 </label>
          <div class="flex justify-between" style="width: 100%">
            <select name="addressProvince" id="addressProvince"></select>
            <select name="addressCity" id="addressCity"></select>
            <select name="addressArea" id="addressArea"></select>
          </div>
        </li>
        <li>
          <label class="bdxx"> <em>*</em>详细地址 </label>
          <input
            type="text"
            name="addressDetail"
            v-model="orderForm.addressDetail"
            value
            placeholder="点此输入收货的详细地址"
          />
        </li>

        <li id="youhuijuan02" class="mt3 flex items-center">
          应付：
          <p style="font-size: 0.7rem">
            <b v-if="!hideScore" class="bred">{{ goods.goodsDetailEx.costPoints }}{{ globalPointName }}&nbsp;+</b>
            <b class="bred" style="font-size: 0.6rem">￥{{ goods.goodsDetailEx.price }}</b>
          </p>
        </li>

        <li style="margin-bottom: 0px">
          <van-button
            class="bg-bred rounded"
            block
            type="danger"
            size="large"
            native-type="submit"
            :loading="isOrderFormLoading"
            loading-text="提交中..."
            >{{ submitText }}
          </van-button>
          <div class="explainbox deep_black">
            <p class="my2">
              <IconFont size="14px" icon="iconanquan" color="#36AB60"></IconFont>
              <span class="ml1">以上信息将加密发送</span>
            </p>
            <p class="my2" style="color: #0021e4">
              <IconFont size="14px" icon="iconjiage" color="#D1307A"></IconFont>
              <span class="ml1" @click="priceRuleVisible = true">商城价格说明</span>
            </p>
            <p class="mb2">
              <IconFont size="14px" icon="iconpinpai1" color="#1296DB"></IconFont>
              <span class="ml1">
                本商品由品牌商提供,
                <a class="telhone" href="javascript:;" @click="to('/customService1')">点此咨询官方客服</a>
              </span>
            </p>
          </div>
        </li>
      </ul>
    </van-form>

    <van-popup v-model="areaPopup" position="bottom">
      <van-area
        title="选择省市区县"
        :value="orderForm.addressCode"
        :area-list="areaList"
        @confirm="areaConfirmFn"
        @cancel="areaPopup = false"
      />
    </van-popup>

    <van-dialog v-model="priceRuleVisible" title="价格说明" class="price-rule-dialog" confirm-button-text="我知道了">
      <div class="panel">
        <p>
          1. 划线价格：
          商品展示的划线价格是商品的专柜价、吊牌价、零售价、指导价、曾经展示过的销售价等，并非原价，由于地区、时间的差异性和市场的行情波动，该价格仅供您参考。
        </p>
        <p>
          2. 未划线价格： 未划线的价格是商品的实时标价，并不一定为您最终实际支付的价格，具体的成交价格可能会因为您使用{{
            globalPointName
          }}、活动、优惠券等发生变化，请以订单结算价格为准。
        </p>
        <p>
          3.
          如您对商品的标题、价格、详情等信息内容有疑问，请在购买前咨询商城在线客服，客服工作时间为周一至周日早9:00至晚21:00。
        </p>
        <p>
          4.
          我们网站上的产品和定价信息在公布前已经过核实。但是，在极少数情形下可能有误。如果我们发现定价错误，我们将通知您，取消您的订单，并对订单金额全额退款。
        </p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import utils from 'base/utils'
import { areaList, initArea } from 'base/utils/area'
import xEnum from 'base/utils/enum'
import { IconFont } from 'components'
import { saveOrder } from 'base/api'
import { Toast, Dialog } from 'vant'
import { trackEvent } from 'base/utils/rp'

export default {
  name: 'PurchaseForm',
  components: { IconFont },
  props: {
    goods: {
      type: Object,
      default: () => {}
    },

    groupType: {
      type: Number,
      default: () => null
    },

    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    submitText: {
      type: String,
      default: () => '立即兑换'
    }
  },

  data() {
    return {
      orderForm: {},
      isOrderFormLoading: false,
      areaVlues: [],
      areaPopup: false,
      areaList: areaList,
      initOrderArea: false,
      priceRuleVisible: false
    }
  },

  computed: {
    hideScore() {
      const hideScoreList = [
        xEnum.ENUM_GOODS_GROUP_TYPE.RETURN_POINTS_GROUP.value,
        xEnum.ENUM_GOODS_GROUP_TYPE.TIME_LIMIT_GROUP.value
      ]
      return _.includes(hideScoreList, Number(this.groupType))
    },

    ...mapState({
      refundPoint: (state) => state.user.refundPoint,
      userId: (state) => state.user.userId,
      points: (state) => state.user.points,
      pId: (state) => state.appState.pId,
      appType: (state) => state.appState.appType
    }),

    ...mapGetters({
      appState: 'appState'
    })
  },

  created() {
    // 由于需要操作dom，使用nextTick等待组件渲染再执行
    this.$nextTick(() => {
      initArea('addressProvince', 'addressCity', 'addressArea')
      this.initOrderArea = true
      if (this.orderForm.addressProvince) {
        document.getElementById('addressProvince').value = this.orderForm.addressProvince
        document.getElementById('addressProvince').onchange()
      }
      if (this.orderForm.addressCity) {
        document.getElementById('addressCity').value = this.orderForm.addressCity
        document.getElementById('addressCity').onchange()
      }
      if (this.orderForm.addressArea) {
        document.getElementById('addressArea').value = this.orderForm.addressArea
      }
    })

    if (this.cookie.get('addressArea') == 'undefined') {
      this.cookie.remove('addressProvince')
      this.cookie.remove('addressCity')
      this.cookie.remove('addressArea')
      this.cookie.remove('addressCode')
    }
    const address = {
      receiverName: this.cookie.get('receiverName'),
      phone: this.cookie.get('phone'),
      addressProvince: this.cookie.get('addressProvince'),
      addressCity: this.cookie.get('addressCity'),
      addressDetail: this.cookie.get('addressDetail'),
      addressArea: this.cookie.get('addressArea'),
      addressCode: this.cookie.get('addressCode')
    }
    this.orderForm = address
    /*
    //  造成组件校验卡顿
    this.orderForm.phone = this.cookie.get("phone");
    this.orderForm.addressProvince = this.cookie.get("addressProvince");
    this.orderForm.addressCity = this.cookie.get("addressCity");
    this.orderForm.addressArea = this.cookie.get("addressArea");
    this.orderForm.addressDetail = this.cookie.get("addressDetail");
    this.orderForm.addressCode = this.cookie.get("addressCode");
    */
    if (this.orderForm.addressProvince) {
      this.orderForm.address =
        this.orderForm.addressProvince +
        ' ' +
        (this.orderForm.addressCity || '') +
        ' ' +
        (this.orderForm.addressArea || '')
    }
  },

  destroyed() {},

  methods: {
    areaConfirmFn(values) {
      this.areaPopup = false
      this.areaVlues = values
      this.orderForm.address = values.map((item) => item.name).join(' ')
      this.orderForm.addressProvince = values[0].name
      this.orderForm.addressCity = values[1].name
      this.orderForm.addressArea = values[2].name
      this.orderForm.addressCode = values[2].code
    },

    validateFormData(data) {
      if (!data.receiverName || data.receiverName == '') {
        Toast.fail('收货人姓名填写错误，不能为空，也不可包含特殊字符')
        return false
      }

      if (data.receiverName.length > 20) {
        Toast.fail('收货人姓名不能超过20个字符')
        return false
      }

      if (!data.phone || data.phone == '') {
        Toast.fail('手机号码不能为空')
        return false
      }

      const re = /^1[0|1|2|3|4|5|6|7|8|9][0-9]\d{8}$/
      if (data.phone.length != 11 || !re.test(data.phone)) {
        Toast.fail('请填写有效的手机号码')
        return false
      }

      if (!data.addressProvince || data.addressProvince == '' || data.addressProvince == '所属省份') {
        Toast.fail('请选择所属省份')
        return false
      }

      if (!data.addressCity || data.addressCity == '' || data.addressCity == '所属地级市') {
        Toast.fail('请选择所属地级市')
        return false
      }

      if (!data.addressArea || data.addressArea == '' || data.addressArea == '所属区县') {
        Toast.fail('请选择所属区县')
        return false
      }

      if (!data.addressDetail || data.addressDetail == '') {
        Toast.fail('您填写详细地址无效，不可为空，也不可包含特殊字符')
        return false
      }

      if (data.addressDetail.length > 200) {
        Toast.fail('详细地址过长不能超过200个字符')
        return false
      }

      return true
    },

    async saveOrderSubmit() {
      if (this.isOrderFormLoading) {
        return
      }
      this.isOrderFormLoading = true

      trackEvent({
        category: `下单-提交流程`,
        action: '总数'
      })

      if (!this.userId) {
        trackEvent({
          category: `下单-提交流程`,
          action: '登录失效'
        })
        Toast.fail('登录失效,重新登录')
        location.reload()
        return
      }

      if (!this.checkGoods(this.goods.goodsDetailEx)) {
        this.isOrderFormLoading = false
        return
      }

      const postData = {
        addressProvince: document.getElementById('addressProvince').value,
        addressCity: document.getElementById('addressCity').value,
        addressArea: document.getElementById('addressArea').value,
        addressDetail: utils.noEmoji(this.orderForm.addressDetail).trim(),
        receiverName: utils.noEmoji(this.orderForm.receiverName).trim(),
        phone: this.orderForm.phone,
        goodsId: this.goods.goodsDetailEx.goodsId,
        projectId: this.pId,
        goodGroupType: this.groupType,
        userId: this.userId,
        appType: this.appType
        // pageSource方案变更，先去掉该特性，后面可能又会加上
        // pageSource: this.pageSource
      }

      if (!this.validateFormData(postData)) {
        this.isOrderFormLoading = false
        return
      }

      trackEvent({
        category: `下单-提交流程`,
        action: 'saveOrder前'
      })

      try {
        const response = await saveOrder(postData)

        if (response.code === 200) {
          this.saveContactInfoToCookie(postData)

          trackEvent({
            category: `下单-提交流程`,
            action: 'saveOrder成功'
          })

          // 只有肖鹏的信息流，才上报给 vivo
          if (this.appState.pId === '839081423011840' && window.VAD_EVENT) {
            // 辣鸡 vivo，两处 submit，都必须写死。
            const actProp = { act: 'submit', name: '立即兑换-提交订单' }
            window.VAD_EVENT.sendAction('submit', actProp)
          }

          this.$router.push({
            path: '/payindex',
            query: { orderId: response.data.orderId }
          })
        } else {
          Toast.fail('下单失败：' + response.message)

          trackEvent({
            category: `下单-提交流程`,
            action: 'saveOrder失败',
            opt_label: response.message
            // opt_value: good.price
          })
        }
      } catch (err) {
        Toast.fail('网络超时，请稍后再试')
        trackEvent({
          category: `下单-提交流程`,
          action: 'saveOrder失败',
          opt_label: `用户ID${this.userId}`
        })
      }

      this.isOrderFormLoading = false
    },

    saveContactInfoToCookie(data) {
      this.cookie.set('receiverName', data.receiverName, { expires: 30 })
      this.cookie.set('phone', data.phone, { expires: 30 })
      this.cookie.set('addressProvince', data.addressProvince, { expires: 30 })
      this.cookie.set('addressCity', data.addressCity, { expires: 30 })
      this.cookie.set('addressArea', data.addressArea, { expires: 30 })
      this.cookie.set('addressDetail', data.addressDetail, { expires: 30 })
      this.cookie.set('addressCode', data.addressCode, { expires: 30 })
    },

    checkGoods(good) {
      if (this.activityType === 'point') {
        if (this.points.activePoints < good.costPoints) {
          Dialog.alert({
            message: `${this.globalPointName}不足兑换本商品，您可以选择超值兑换更多其他优质商品`,
            confirmButtonText: '好的'
          }).then(() => {
            this.toHome()
          })

          trackEvent({
            category: `${this.name}-${this.globalPointName}不足`,
            action: `需${good.costPoints}`,
            opt_label: good.goodsName
            // opt_value: good.price
          })
          return false
        }
        if (good.stockNum < 1) {
          // Toast.fail('该商品已兑完');
          Dialog.alert({
            message: '非常遗憾，本商品已兑完，您可以选择超值兑换更多其他优质商品',
            confirmButtonText: '好的'
          }).then(() => {
            this.toHome()
          })

          trackEvent({
            category: `${this.name}-已兑完`,
            action: good.goodsName
            // opt_label: good.goodsName,
            // opt_value: good.price
          })
          return false
        }
      } else if (this.activityType === 'returnScore') {
        if (this.refundPoint >= 8000) {
          Dialog.alert({
            message: `您已在全额返活动中获得了超过8000以上的${this.globalPointName}，根据活动规则，将无法继续参与全额返活动，感谢您的支持与理解！`,
            confirmButtonText: '好的'
          }).then(() => {
            this.toHome()
          })

          trackEvent({
            category: `${this.name}-全额返超过8000${this.globalPointName}`,
            action: good.goodsName
            // opt_label: good.goodsName,
            // opt_value: good.price
          })

          return false
        }
      }

      return true
    },

    toHome() {
      this.$router.push({ path: '/primary' })
    },

    to(path) {
      this.$router.push({
        path: path,
        query: {
          location: window.location.href
        }
      })
    }
  }
}
</script>
<style lang="less">
.price-rule-dialog {
  .van-dialog__header {
    padding: 15px !important;
    font-size: 20px;
    font-weight: 500;
    color: #c22b2d;
  }
  .van-dialog__content {
    padding-bottom: 15px;
  }
  .panel {
    width: 85%;
    margin: 0 auto;
    overflow: scroll;
    font-size: 13px;
    .p {
      margin-bottom: 5px;
    }
  }
}

.com-goods-purchase-form {
  .I_form {
    width: 100%;
    margin: 0 auto;
  }
  .I_form li {
    margin: 0 auto 15px;
  }
  .I_li_p2 {
    display: inline-block;
    vertical-align: middle;
    width: 49%;
    font-size: 0.55rem;
    color: #bbb;
  }
  .I_li_p3 {
    font-size: 0.6rem;
  }
  .I_li_p3 b {
    color: rgb(229, 3, 15);
    font-size: 0.8rem;
  }
  .I_form_but {
    width: 100%;
    height: 40px;
    line-height: 32px;
    font-size: 0.7rem;
    color: #fff;
    background-color: #e5040f;
    border-radius: 3px;
    padding: 0;
    border: 4px solid #e5040f;
  }
  .I_form_but {
    height: 50px;
    border-radius: 35px;
  }

  .I_form li input {
    width: 95%;
    padding: 2% 2%;
    font-size: 0.6rem;
    border: 1px solid #b5b5b5;
  }
  .I_form li input:focus {
    border: 1px solid #b5b5b5;
    outline-color: #b5b5b5;
  }
  .I_form li select {
    display: inline-block;
    vertical-align: middle;
    width: 32%;
    margin: 0;
    font-size: 0.6rem;
    height: 40px;
    text-align: left;
    text-align-last: left;
    background-color: #fff;
    border: 1px #d1d1d1 solid;
    height: 1.8rem;
    color: #828282;
    padding-left: 14px;
    /*appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;*/
    /* background: url(../../images/san.png) no-repeat scroll 80% 50% transparent;
      background-size: 20px 20px;*/

    /* 复写默认的 select 样式。 https://www.cnblogs.com/dongkx/p/7163261.html */
    /*Chrome和Firefox里面的边框是不一样的，所以复写了一下*/
    /* border: solid 1px #000;   */
    /*很关键：将默认的select选择框样式清除*/
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    /*在选择框的最右侧中间显示小箭头图片*/
    background: url('http://ourjs.github.io/static/2015/arrow.png') no-repeat scroll right center transparent;
    /*为下拉小箭头留出一点位置，避免被文字覆盖*/
    padding-right: 14px;
  }

  .bdxx em {
    color: #e00000;
    padding: 2px;
  }

  .I_form li input {
    outline: none;
    width: 92%;
    padding: 3% 4%;
    border-radius: 3px; /* 35px */
    font-size: 0.6rem;
    border: 1px solid #d1d1d1;
  }
  .I_form li input::-ms-input-placeholder {
    color: #b7b7b7;
  }
  .I_form li input::-moz-input-placeholder {
    color: #b7b7b7;
  }
  .I_form li input::-webkit-input-placeholder {
    color: #b7b7b7;
  }
  .bdxx {
    display: block;
    float: left;
    width: 100%;
    height: 34px;
    font-size: 0.7rem;
    line-height: 34px;
  }

  .I_form li select {
    width: 32%;
    border-radius: 35px;
  }

  .I_form li select {
    width: 31%;
    border-radius: 3px;
    /* margin-right: 2%; */
  }
}
</style>
