// vm.$mount(el) => document.body.appendChild();
// Vue.extend() 继承 【子类继承父类的方法】
// 父类 Vue--Vue.extend--子类 定制化的 Vue 构造函数
import Vue from 'vue'
import CenterWineDialog from './centerWineDialog'
import store from '@/store'

const Customized = Vue.extend(CenterWineDialog)

export const centerWineDialog = (option = {}) =>
  new Promise((resolve, reject) => {
    const res = new Customized({
      data() {
        return {
          onClick: typeof option.onClick === 'function' ? option.onClick : () => {}
        }
      },
      methods: {
        resolve,
        reject
      },
      store
    }).$mount()
    // console.log(res.$el);
    document.body.appendChild(res.$el)
  })
