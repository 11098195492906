import { render, staticRenderFns } from "./HomeCapsuleThemed.vue?vue&type=template&id=641c4a03&scoped=true&"
import script from "./HomeCapsuleThemed.vue?vue&type=script&lang=js&"
export * from "./HomeCapsuleThemed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641c4a03",
  null
  
)

export default component.exports