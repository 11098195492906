<template>
  <div>
    <div class="flex flex-col items-center gap-[15px] dialog-box" v-if="backInterceptImage">
      <van-image class="" :src="backInterceptImage" @click="popupImageClick" />
      <van-image class="w-[30px]" src="https://img.bj0yx.com/887095397187584" @click="dialogClose" />
    </div>
    <div class="dialog-box h-[330px]" v-else>
      <div class="dialog-container">
        <div class="above flex flex-column items-center">
          <div class="above-item">
            <div class="desc flex flex-column items-center">
              <span style="color: #027aff; font-size: 20px">{{ showCoupon }}</span>
              <span style="color: #808080; font-size: 15px; padding: 10px">离开后您将失去领取资格</span>
            </div>
            <div class="flex justify-between">
              <van-button class="button" type="info" :plain="true" @click="dialogClose">离开</van-button>
              <van-button class="button" type="info" @click="confirmClick">立即使用</van-button>
            </div>
          </div>
        </div>
        <div class="below flex flex-column items-center justify-center">
          <div class="below-item flex justify-start">
            <div class="title">有效期</div>
            <div>{{ time }}</div>
          </div>
          <div class="below-item flex justify-start">
            <div class="title">重要说明</div>
            <div>本次购物红包可用于活动商品购买时立减使用</div>
          </div>
        </div>
      </div>
      <van-image class="cancel-button" src="https://img.bj0yx.com/887095397187584" @click="dialogClose" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import utils from 'base/utils'
export default {
  name: 'RedPacketDialog',
  components: {},

  props: {
    showCoupon: {
      type: String,
      default: ''
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      backInterceptImage: (state) => state.appState.backInterceptImage,
      backInterceptNavigatePath: (state) => state.appState.backInterceptNavigatePath
    }),

    time() {
      return (
        dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss') +
        ' - ' +
        dayjs().add(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss')
      )
    }
  },

  methods: {
    confirmClick() {
      this.$router.push({ path: '/home' })
    },

    dialogClose() {
      this.$emit('close-red-packet-dialog')
    },

    popupImageClick() {
      const shortLinkReg = new RegExp(/^([0-9a-zA-Z-]{1,}\.)+([a-zA-Z]{2,})$/)
      if (!this.backInterceptNavigatePath) {
        this.$router.push({ path: '/home' })
      } else if (shortLinkReg.test(this.backInterceptNavigatePath) || utils.isWebUrl(this.backInterceptNavigatePath)) {
        let path = this.backInterceptNavigatePath
        if (shortLinkReg.test(this.backInterceptNavigatePath)) {
          path = window.location.protocol + '//' + this.backInterceptNavigatePath
        }
        window.location.href = path
      } else {
        this.$router.push({ path: this.backInterceptNavigatePath })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-box {
  width: 343px;
  overflow: hidden;
  // background-clip: content-box;

  .dialog-container {
    width: 100%;
    height: 284px;
    border-radius: 10px;
    position: relative;
    background-color: #ffffff;
    overflow: hidden;
    .above {
      height: 194px;
      border-bottom: 1px #cccccc dashed;
      .above-item {
        height: 124px;
        width: 303px;
        margin-top: 40px;
        .desc {
          height: 83px;
        }
        .button {
          height: 41px;
          width: 140px;
          border-radius: 5px;
          font-size: 15px;
          border-width: 1.5px;
          line-height: 38px;
        }
      }
    }
    .below {
      height: 90px;
      color: #999999;
      font-size: 12px;
      .below-item {
        width: 100%;
        box-sizing: border-box;
        padding: 4px;
        padding-left: 10px;
        padding-right: 10px;
        .title {
          width: 48px;
          text-align: left;
          margin-right: 8px;
        }
      }
    }
    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 186px;
      left: -8px;
    }
    &::after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      bottom: 82px;
      right: -8px;
    }
  }
  .cancel-button {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 5px;
    transform: translateX(-50%);
    margin-left: 50%;
    overflow: hidden;
    border: none;
  }
}
</style>
