<template>
  <div class="slide-grid-bar flex flex-column items-center">
    <div class="slide-grid flex" @scroll="handleScroll">
      <div
        class="grid-item py2 flex flex-column items-center justify-center"
        v-for="(item, index) in gridList"
        :key="index"
        @click="itemClick(item, index)"
      >
        <img :src="item.img" alt="" />
        <div class="fs14 color_text center">{{ item.businessKey.title }}</div>
        <div class="fs12 color_tips center">{{ item.businessKey.subTitle }}</div>
      </div>
    </div>
    <div class="scroll-bar mb1">
      <div class="thumb relative" :style="{ width: thumbWidth + 'px', left: thumbLeft + 'px' }"></div>
    </div>
  </div>
</template>

<script>
import { trackEvent } from 'base/utils/rp'
import xEnum from 'base/utils/enum'
import utils from 'base/utils'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'SlideGridBar',
  props: {
    gridList: {
      type: Array,
      default: () => {}
    }
  },

  data() {
    return {
      thumbLeft: 0
    }
  },

  computed: {
    thumbWidth() {
      return (4 / this.gridList.length) * 12
    }
  },

  methods: {
    handleScroll(e) {
      this.thumbLeft = (e.target.scrollLeft / this.gridList.length / 89) * 12
    },

    async itemClick(source, index) {
      let placeType = ''
      let placeDetail = ''
      if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.value) {
        const mpScheme = await this.$store.dispatch('getMpUrlScheme', source.businessKey)
        location.href = mpScheme
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.getText()
        placeDetail = source.businessKey
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.value) {
        this.$router.push({ path: '/activity/brand', query: { pageId: source.businessKey.pageId } })
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.getText()
        placeDetail = source.businessKey.pageId
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.value) {
        this.$router.push({
          path: `/goodsProfile/${source.businessKey.goodsId}`,
          query: { type: source.businessKey.type }
        })
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.getText()
        placeDetail = source.businessKey.goodsId
      } else {
        const isWebUrl = utils.isWebUrl(source.businessKey.path)
        if (isWebUrl) {
          window.location.href = source.businessKey.path
        } else {
          this.$router.push({ path: source.businessKey.path })
        }
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.LINK.getText()
        placeDetail = source.businessKey.path
      }
      trackEvent({
        category: `点击-${source.desc}-个人中心`,
        action: '总数'
      })

      // 个人中心页资源位-金刚区
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_personal_place_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_personal_page',
        params: {
          place_type: placeType,
          place_name: '个人中心页金刚位位置',
          place_addr: index,
          place_detail: placeDetail,
          module_name: source.businessKey?.title
        }
      })
    }

    // getEventInfo(addr) {
    //   return {
    //     eventType: xEnum.ENUM_RP_EVENT_TYPE.MINE_PAGE_SOURCE_PLACE_EP.value,
    //     eventInfo: {
    //       place_type: '无',
    //       place_name: addr,
    //       place_addr: '无'
    //     }
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.slide-grid {
  overflow-x: auto;
  display: -webkit-box;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  .grid-item {
    width: 89px;
    img {
      width: 44px;
      height: 44px;
    }
  }
}
.slide-grid::-webkit-scrollbar {
  display: none;
}
.scroll-bar {
  width: 12px;
  height: 3px;
  background: #d5d5d5;
  border-radius: 2px 2px 2px 2px;
  .thumb {
    height: 100%;
    background-color: #f36f68;
  }
}
</style>
