<template>
  <div @click="onClick" class="capsule-area px2">
    <img class="fit" :src="renderBrandElement({ id: 'homeCapsule.banner' })" alt="" />
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'HomeCapsuleThemed',
  props: {
    navigatePath: {
      type: String,
      default: () => ''
    }
  },

  data() {
    return {}
  },

  methods: {
    onClick() {
      if (_.startsWith(this.navigatePath, 'http')) {
        window.location.href = this.navigatePath
      } else {
        this.$router.push({ path: this.navigatePath })
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
