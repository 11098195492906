<template>
  <div class="container fixed flex items-center justify-center" :class="`${shadow && 'shadow'}`">
    <div class="bg_primary white h6 button" :style="styleWrap" @click="$emit('click')">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomButton',
  props: {
    title: {
      type: String,
      default: ''
    },

    styleWrap: {
      type: Object,
      default: () => {}
    },

    shadow: {
      type: Boolean,
      default: false
    }
  },

  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.container {
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 0;
  background-color: white;

  &.shadow {
    box-shadow: 6px 6px 10px rgba(0, 0, 0);
    -webkit-box-shadow: 6px 6px 10px rgba(0, 0, 0);
    -moz-box-shadow: 6px 6px 10px rgba(0, 0, 0);
  }

  .button {
    width: 90%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
  }
}
</style>
