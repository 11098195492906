<template>
  <transition name="fade">
    <div v-if="visible" class="mask">
      <div
        class="dialog-content flex flex-column"
        :style="{
          width: `${imageWidth}px`,
          height: `${imageHeight}px`
        }"
      >
        <div class="relative" :style="{ height: `${imageHeight}px` }">
          <img :width="imageWidth" :height="imageHeight" src="~base/assets/img/centerWineDialogImg.png" />
          <div @click="onClickBtn" class="to-cod-btn absolute" style="height: 40px"></div>
        </div>

        <div id="close" class="close-btn" @click="closeDialog">
          <van-icon size="30" name="close" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { IconFont } from 'components'

const screenWidth = window.screen.width
const widthHeightRatio = 5 / 6

export default {
  name: 'CenterWineDialog',
  // components: { IconFont },
  props: {},
  data() {
    return {
      visible: true,
      imageWidth: screenWidth * (250 / 320),
      imageHeight: (screenWidth * (250 / 320)) / widthHeightRatio
    }
  },

  computed: {},
  created() {},
  methods: {
    closeDialog() {
      this.visible = false
      window.localStorage.setItem('centerWineDialogIsShowed', true)
    },

    onClickBtn() {
      this.closeDialog()

      this.onClick()
    }
  }
}
</script>

<style lang="less" scoped>
.mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  padding-top: 135px;

  .dialog-content {
    // width: 250px;
    // height: 300px;
    position: relative;

    .point {
      position: absolute;
      // top: 165px;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      color: #fefbe9;
    }

    .close-btn {
      text-align: center;
      margin: auto;
      margin-top: 10px;
      color: white;
      opacity: 0.5;
    }

    .close-dialog {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 5px;
    }
    .to-cod-btn {
      width: 200px;
      bottom: 20px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.5s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
}

.fade-leave {
  opacity: 1;
}
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}
.fade-leave-to {
  opacity: 0;
}
</style>
