<template>
  <div class="activity-area">
    <div class="base-info flex items-center">
      <div class="left-part flex pl2 items-center justify-center">
        <div class="fs16 pr3">{{ good.goodsDetailEx.saleDesc | sails }}</div>
      </div>
      <div class="right-part relative flex flex-column justify-center items-center pr1">
        <div class="triangle"></div>
        <div class="flex items-center">
          <span class="ml1 color_primary">距离结束还剩</span>
        </div>
        <CountDown
          :end-time="countDownEnd"
          :millisecond="true"
          :ms-two-letter="true"
          bg-color="#E54535"
          colon-color="#E54535"
        >
        </CountDown>
      </div>
    </div>
  </div>
</template>
<script>
import { trackEvent } from 'base/utils/rp'
import { CountDown } from 'components'

export default {
  components: { CountDown },
  filters: {
    sails: function (value) {
      return _.replace(value, '兑换', '领取')
    }
  },

  props: {
    good: { type: Object, default: new Object() }
  },

  data() {
    return {
      countDownTime: 0,
      countDownEnd: dayjs()
    }
  },

  created() {
    this.countDownEnd = dayjs().endOf('d')
  },

  mounted() {},
  beforeDestroy() {},

  methods: {
    onClickArea(name, action) {
      trackEvent({
        category: `点击-详情页-${name}`,
        action: action
      })
    }
  }
}
</script>
<style></style>
<style lang="less" scoped>
.activity-area {
  background-size: no-repeat;
  background-size: 100% 100%;
  background-image: url('~base/assets/img/goodsDetail/priceBanner_payOnDeliveryFreeReceive.png');
}
.base-info {
  height: 60px;
  color: white;
  font-size: 13px;
  .left-part {
    height: 100%;
    flex-basis: 70%;
    .original-price {
      height: 30px;
      font-size: 26px;
    }
    .price {
      .final-price {
        padding: 0 6px;
        text-decoration: line-through;
      }
    }
  }
}
.desc {
  font-size: 16px;
  padding: 9px;
  .hint {
    font-size: 14px;
    border-radius: 3px;
    padding: 1px 3px;
  }
}
.stock {
  padding: 9px;
  font-size: 13px;
  .title {
    margin-right: 15px;
    color: #929292;
  }
  .buy-limit {
    padding: 1px 3px;
  }
}
</style>
