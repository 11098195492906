<template>
  <div class="com-deal-bubble">
    <div class="trade-carousel" :class="fixed ? 'fixed' : ''" :style="containerStyle" id="trade-carousel">
      <div class="trade-carousel__swipe">
        <div class="trade-carousel__swipe-item move-up" id="trade-carousel-item">
          <span class="trade-carousel__swipe-text">{{ qipao.msg }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from 'base/utils'
import mock from 'base/utils/mock'

export default {
  name: 'DealBubble',
  props: {
    fixed: {
      // 是否绝对定位
      type: Boolean,
      default: () => true
    },

    fixOffset: {
      type: Object,
      default: () => {
        return { top: '50px' }
      }
    },

    activityType: {
      type: String,
      default: () => 'point'
    },

    mode: {
      type: String,
      default: () => 'purchase' // 购买-purchase, 省钱-save
    },

    haveGoodsName: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      qipao: {
        msg: ''
      }
    }
  },

  computed: {
    containerStyle() {
      if (this.fixed) {
        return this.fixOffset
      }

      return {}
    },

    purchaseWord() {
      if (this.activityType === 'point') {
        return this.globalExchangeWord
      } else {
        return '签收'
      }
    }
  },

  created() {},
  async mounted() {
    await utils.waitFor(200)
    this.timer = utils.setImmediateInterval(async () => {
      const familyName = mock.familyNames[_.random(mock.familyNames.length - 1)]
      const goodsName = mock.goodsName[_.random(mock.goodsName.length - 1)]
      const second = _.random(1, 12)
      if (this.mode === 'purchase') {
        this.qipao = {
          msg: `${familyName}** 在${second}分钟前${this.purchaseWord}了${this.haveGoodsName ? goodsName : '该商品'}`
        }
      } else if (this.mode === 'save') {
        const money = _.random(100, 1000)
        this.qipao = {
          msg: `${familyName}** 在${second}分钟前使用补贴省了${money}元`
        }
      }
    }, 3000)
  },

  destroyed() {
    clearInterval(this.timer)
  },

  methods: {}
}
</script>
<style lang="less" scoped>
.com-deal-bubble {
  .trade-carousel {
    min-width: 180px;
    height: 26px;
    width: 50%;
    text-align: center;
    overflow: hidden;

    &.fixed {
      position: fixed;
      left: 10px;
      z-index: 22;
    }
    &.absolute {
      position: absolute;
      left: 10px;
      bottom: 20px;
      z-index: 22;
    }
  }
  .move-up {
    -webkit-animation: move-in-out 3s 1s linear infinite normal;
    -moz-animation: move-in-out 3s 1s linear infinite normal;
    animation: move-in-out 3s 1s linear infinite normal;
  }
  @keyframes move-in-out {
    0% {
      -webkit-transform: translateY(64px);
      transform: translateY(64px);
    }
    15% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    65% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    80% {
      -webkit-transform: translateY(-64px);
      transform: translateY(-64px);
    }
    100% {
      -webkit-transform: translateY(-64px);
      transform: translateY(-64px);
    }
  }
  .trade-carousel__swipe {
    height: 26px;
    color: #fff;
    font-size: 12px;
    line-height: 26px;
  }
  .trade-carousel__swipe-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 26px;
    background-color: rgba(0, 0, 0, 0.39);
    border-radius: 13px;
    padding: 0 5px;
  }
  .trade-carousel__swipe-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .trade-carousel__swipe-text {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    flex: 1;
    margin-right: 1px;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
