<template>
  <div class="com-goods-gallery">
    <van-swipe :autoplay="-1" indicator-color="#f44" :show-indicators="false" :loop="false" :width="146">
      <van-swipe-item v-for="(good, index) in goodsList" :key="index" @click="goodClick(good, index)">
        <div class="P_ul_li box sbImg">
          <!-- <img class="In_c_r_Img1" :src="require('base/assets/img/goodsDetail/jf'+(index+1)+'.png')" /> -->
          <div style="width: 100%; background: whitesmoke; position: relative">
            <div style="position: relative">
              <!-- lazy-load设为false，强行占位图片高度 -->
              <van-image class="phImg" :src="good.imgUrl" :lazy-load="false">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <img v-if="good.stockNum <= 0" class="dw_img" src="~base/assets/img/dw.png" />
              <!-- <van-loading type="spinner" color="#1989fa" /> -->
            </div>
          </div>

          <p class="In_c_r_p">{{ good.goodsName }}</p>
          <div class="In_c_r_p1 flex items-center">
            <span class="In_c_r_p2 mr2">￥{{ good.price }}</span>
            <span class="In_c_r_p3 fs13">￥{{ good.originalPrice }}元</span>
          </div>
          <p class="In_c_r_p4">{{ good.saleDesc }}</p>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { getPagingGoodsList } from 'base/api'
import xEnum from 'base/utils/enum'

export default {
  props: {
    goodsGroupType: {
      type: Number,
      default: xEnum.ENUM_GOODS_GROUP_TYPE.RATE_GROUP.getValue()
    },

    activityType: {
      type: String,
      default: () => ''
    }
    // excludeGoodsIds: {
    //   type: Array,
    //   default: () => []
    // }
  },

  data() {
    return {
      goodsList: [],
      itemType: this.renderBrandElement({ id: 'goodsGallery.itemType' })
    }
  },

  computed: {
    pId() {
      return this.$store.state.appState.pId
    },

    userId() {
      return this.$store.state.user.userId
    }
  },

  created() {
    // this.reload()
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    goodClick(goods, index) {
      // this.$store.dispatch('enterGoodsProfile', {
      //   module: '详情页精品推荐',
      //   index,
      //   type: this.goodsGroupType,
      //   goods
      // })

      this.$router.push({
        path: `/goodsProfile/${goods.id}`,
        query: {
          type: this.goodsGroupType,
          activityType: this.activityType
        }
      })
    },

    reload(excludeGoodsIds = []) {
      const params = {
        pId: this.pId,
        type: this.goodsGroupType,
        pageIndex: 1,
        pageSize: 20,
        userId: this.userId
      }
      getPagingGoodsList(params).then((response) => {
        if (response.code === 200) {
          this.goodsList = _.chain(response.data.list)
            .filter((ele) => {
              return !_.includes(excludeGoodsIds, ele.id)
            })
            .map((ele) => {
              if (this.itemType === 'seckill') {
                ele.saleDesc = _.replace(ele.saleDesc, '兑换', '抢购')
              } else {
                ele.saleDesc = _.replace(ele.saleDesc, '兑换', '抢购')
              }
              return ele
            })
            .slice(0, 9) // 最多支持展示9个商品
            .value()
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.com-goods-gallery {
  /*排行榜*/

  .P_ul_li {
    width: 93.5%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    margin: 10px auto 0;
    border-radius: 5px;
    font-size: 0;
    padding-bottom: 8px;
    background-color: #fff;
  }

  .In_c_r_Img1 {
    width: 20%;
    position: absolute;
    top: 0;
    left: 5px;
    z-index: 1;
  }

  .In_c_r_p {
    width: 90%;
    margin: 8px 0 2px 6%;
    font-size: 0.7rem;
    color: #555;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .In_c_r_p1 {
    width: 90%;
    margin: 3px 0 0px 6%;
    color: #ff3600;
  }
  .In_c_r_p2 {
    font-size: 0.6rem;
  }
  .In_c_r_p3 {
    color: #888888;
    text-decoration: line-through;
  }
  .In_c_r_p4 {
    width: 90%;
    margin: 0px 0 6px 6%;
    color: #666666;
    font-size: 0.47rem;
  }
}
</style>
