<template>
  <div class="notice-bar" :style="{ ...noticeBarContain }">
    <div class="" v-if="!enableVerticalScroll">
      <van-notice-bar
        :left-icon="leftIcon"
        :speed="speed"
        :scrollable="scrollable"
        :text="noticeList"
        :style="{ ...noticeBarStyle }"
      />
    </div>
    <div class="" v-else>
      <van-notice-bar :left-icon="leftIcon" :scrollable="false" :speed="speed" :style="{ ...noticeBarStyle }">
        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="item in noticeList" :key="item">{{ item }}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
  </div>
</template>

<script>
import { NoticeBar } from 'vant'
export default {
  name: 'NoticeBar',
  components: {
    [NoticeBar.name]: NoticeBar
  },

  props: {
    enableVerticalScroll: {
      type: Boolean,
      default: () => false
    },

    speed: {
      type: Number,
      default: () => 60
    },

    scrollable: {
      type: Boolean,
      default: () => false
    },

    leftIcon: {
      type: String,
      default: () => 'volume-o'
    },

    noticeList: {
      type: [String, Array],
      default: () => '' || []
    },

    noticeBarStyle: {
      type: Object,
      default: () => {}
    },

    noticeBarContain: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  }
}
</script>

<style lang="less">
.notice-bar {
  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
}
</style>
<style lang="less" scoped></style>
