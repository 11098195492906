<template>
  <div class="com-goods-price-banner">
    <PointArea v-if="activityType === 'point'" :good="goods"></PointArea>
    <TimeLimitArea v-if="activityType === 'timeLimit'" :good="goods"></TimeLimitArea>
    <ReturnScoreArea v-if="activityType === 'returnScore'" :good="goods"></ReturnScoreArea>
    <Seckill v-if="activityType === 'seckill'" :good="goods"></Seckill>
    <DrawPrize v-if="activityType === 'drawPrize'" :good="goods"></DrawPrize>
    <PayOnDelivery
      v-if="activityType === 'payOnDelivery' || activityType === 'singleProduct'"
      :good="goods"
      :activity-type="activityType"
    ></PayOnDelivery>
    <PayOnDeliveryFreeReceive
      v-if="activityType === 'payOnDeliveryFreeReceive'"
      :good="goods"
    ></PayOnDeliveryFreeReceive>
    <TencentAd v-if="activityType === 'tencentAd'" :good="goods"></TencentAd>
  </div>
</template>
<script>
import PointArea from './Point'
import TimeLimitArea from './TimeLimit'
import ReturnScoreArea from './ReturnScore'
import Seckill from './Seckill'
import DrawPrize from './DrawPrize'
import PayOnDelivery from './PayOnDelivery'
import PayOnDeliveryFreeReceive from './PayOnDeliveryFreeReceive'
import TencentAd from './TencentAd'

export default {
  components: {
    PointArea,
    TimeLimitArea,
    ReturnScoreArea,
    Seckill,
    DrawPrize,
    PayOnDelivery,
    PayOnDeliveryFreeReceive,
    TencentAd
  },

  props: {
    goods: {
      type: Object,
      default: () => {}
    },

    activityType: {
      type: String,
      default: () => 'point'
    }
  },

  data() {
    return {}
  },

  created() {},
  mounted() {},
  beforeDestroy() {},

  methods: {}
}
</script>
<style></style>
<style scoped></style>
