<template>
  <div class="com-goods-service-info" :style="{ ...styleWrap }">
    <div v-if="showPriceIntro && activityType === 'point'">
      <div class="section-title" v-track:exposure :track-params="JSON.stringify(eventInfo('滑动到底部'))">
        <!-- <IconFont size="14px" icon="iconjiage" color="#D1307A"></IconFont> -->
        <div class="fs16 bold pt2" style="color: #222222">价格说明</div>
      </div>
      <div class="price-intro mt2">
        <p class="bold mb1" style="color: #222">1. 划线价格：</p>
        <p class="fs12" style="color: #4d4d4d">
          商品展示的划线价格是商品的专柜价、吊牌价、零售价、指导价、曾经展示过的销售价等，并非原价，由于地区、时间的差异性和市场的行情波动，该价格仅供您参考。
        </p>
        <p class="mt2 bold mb1" style="color: #222">2. 未划线价格：</p>
        <p class="fs12" style="color: #4d4d4d">
          未划线的价格是商品的实时标价，并不一定为您最终实际支付的价格，具体的成交价格可能会因为您使用{{
            globalPointName
          }}、活动、优惠券等发生变化，请以订单结算价格为准。
        </p>
        <p class="mt2 bold mb1" style="color: #222">3. 其他说明：</p>
        <p class="fs12" style="color: #4d4d4d">
          如您对商品的标题、价格、详情等信息内容有疑问，请在购买前咨询商城在线客服，客服工作时间为周一至周日早9:00至晚21:00。
        </p>
        <p class="fs12" style="color: #4d4d4d">
          我们网站上的产品和定价信息在公布前已经过核实。但是，在极少数情形下可能有误。如果我们发现定价错误，我们将通知您，取消您的订单，并对订单金额全额退款。
        </p>
      </div>
    </div>
    <div v-else-if="showPriceIntro && activityType === 'payOnDelivery'" class="">
      <!-- <a
        class="telhone"
        style="display: inline-block; margin-bottom: 20px; color: blue"
        href="javascript:;"
        data-clipboard-text="lhp284220"
        @click="toCustomService"
      >
        如有疑问，点击咨询官方客服>>>
      </a> -->
      <van-collapse v-model="activeNames">
        <van-collapse-item name="1">
          <template #title>
            <div
              class="fs16 bold"
              style="color: #222222"
              v-track:exposure
              :track-params="JSON.stringify(eventInfo('滑动到底部'))"
            >
              价格说明
            </div>
          </template>
          <div class="price-intro">
            <p class="bold mb1" style="color: #222">1. 划线价格：</p>
            <p class="fs12" style="color: #4d4d4d">
              商品展示的划线价格是商品的专柜价、吊牌价、零售价、指导价、曾经展示过的销售价等，并非原价，由于地区、时间的差异性和市场的行情波动，该价格仅供您参考。
            </p>
            <p class="mt2 bold mb1" style="color: #222">2. 未划线价格：</p>
            <p class="fs12" style="color: #4d4d4d">
              未划线的价格是商品的实时标价，并不一定为您最终实际支付的价格，具体的成交价格可能会因为您使用{{
                globalPointName
              }}、活动、优惠券等发生变化，请以订单结算价格为准。
            </p>
            <p class="mt2 bold mb1" style="color: #222">3. 其他说明：</p>
            <p class="fs12" style="color: #4d4d4d">
              如您对商品的标题、价格、详情等信息内容有疑问，请在购买前咨询商城在线客服，客服工作时间为周一至周日早9:00至晚21:00。
            </p>
            <p class="fs12" style="color: #4d4d4d">
              我们网站上的产品和定价信息在公布前已经过核实。但是，在极少数情形下可能有误。如果我们发现定价错误，我们将通知您，取消您的订单，并对订单金额全额退款。
            </p>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>

    <div class="mt2 mb2">
      <!-- <IconFont size="14px" icon="iconpinpai1" color="#1296DB"></IconFont> -->
      <span class="section-title" v-if="activityType === 'point'">
        本商品由品牌商提供,
        <a
          class="telhone"
          style="color: blue"
          href="javascript:;"
          data-clipboard-text="li1723531428"
          @click="toCustomService"
        >
          点此咨询官方客服
        </a>
      </span>
      <span class="section-title" v-else>本商品由品牌商提供，详细内容查看价格说明</span>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
// import { IconFont, SectionTitle } from 'components'
import { Toast, Collapse, CollapseItem } from 'vant'
import xEnum from 'base/utils/enum'

export default {
  name: 'ServiceInfo',
  components: { [Collapse.name]: Collapse, [CollapseItem.name]: CollapseItem },
  props: {
    directPullCustomService: {
      type: Boolean,
      default: () => false
    },

    activityType: {
      type: String,
      default: () => 'point'
    },

    showPriceIntro: {
      type: Boolean,
      default: () => true
    },

    styleWrap: {
      type: Object,
      default: () => {}
    },

    goodsDetail: {
      type: Object,
      default: () => {}
    },

    groupType: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      activeNames: ['']
    }
  },

  created() {},
  destroyed() {},

  methods: {
    to(path) {
      this.$router.push({
        path: path,
        query: {
          location: window.location.href
        }
      })
    },

    copyText(o) {
      const clipboard = new Clipboard(o)
      clipboard.on('success', () => {
        Toast({
          message: '客服微信号已复制\n请添加客服微信咨询',
          duration: 3000
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        // 不支持复制
        Toast.fail('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    },

    toCustomService() {
      if (this.directPullCustomService) {
        this.copyText('.telhone')
        // window.ysf('open', {
        //   templateId: this.renderBrandElement({id: 'ysf.templateId'})
        // })
      } else {
        this.to('/customService1')
      }
    },

    eventInfo() {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_PROFILE_ARRIVE_BOTTOM_EP.value,
        eventInfo: {
          goodsId: this.goodsDetail.goodsId,
          goodsPrice: this.goodsDetail.price,
          goodsGroupId: this.groupType,
          webUrl: window.location.href,
          goodsName: this.goodsDetail.goodsName,
          goodsOriginalPrice: this.goodsDetail.originalPrice,
          ifBottom: true,
          goodsCategoryId: this.goodsDetail.categoryId
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.com-goods-service-info {
  padding-bottom: 50px;
  background: #fff;
  .section-title {
    color: #555;
  }

  .price-intro {
    color: #9c9c9c;
  }
}
</style>
