import Vue from 'vue'
import GoodsProfilePointDialog from './goodsProfilePointDialog'

const Customized = Vue.extend(GoodsProfilePointDialog)

export const goodsProfilePointDialog = (option) => {
  const instance = new Customized({
    data() {
      return {
        onClick: typeof option.onClick === 'function' ? option.onClick : () => {}
      }
    }
  }).$mount()
  // console.log(instance.$el);
  document.body.appendChild(instance.$el)
}
