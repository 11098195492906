<template>
  <div class="flex items-center justify-center">
    <svg
      class="icon"
      aria-hidden="true"
      :style="{
        width: width || iconSize,
        height: height || iconSize,
        // ios 上面，color 只有此处才生效。安卓是下面的 use 上加，就可以生效。
        color
      }"
    >
      <use :xlink:href="`#${icon}`" :style="{ color }"></use>
    </svg>
    <div :style="{ color: color, fontSize: size, flexBasis: flexBasis, textAlign: textAlign }">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'IconTag',
  props: {
    text: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: () => 'black'
    },

    icon: {
      type: String,
      default: ''
    },

    width: {
      type: String,
      default: ''
    },

    height: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: '14px'
    },

    iconSize: {
      type: String,
      default: '14px'
    },

    flexBasis: {
      type: String,
      default: '100%'
    },

    textAlign: {
      type: String,
      default: 'center'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.icon {
  margin-right: 3px;
}
</style>
