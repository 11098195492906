<template>
  <div class="base64-avatar-container" :style="{ width: width + 'px', height: height + 'px' }">
    <van-image
      v-if="src && src !== ''"
      :width="width"
      :height="height"
      :round="round"
      :style="imgStyle"
      :src="`data:image/jpeg;base64, ${src}`"
    />
    <img
      v-else
      :width="width"
      :height="height"
      :class="{ round: round }"
      :style="imgStyle"
      src="~base/assets/img/my/userPhoto.png"
    />
  </div>
</template>

<script>
/**
 * 组件用于底部占位，当页面底部使用了fixed元素时，使用该占位组件，而不要直接使用padding属性。
 * 否则在华为浏览器中，当浏览器地址栏隐藏时，会导致底部点击区域失效
 */
export default {
  name: 'Base64Image',
  props: {
    src: {
      type: String,
      default: () => null
    },

    width: {
      type: Number,
      default: () => 60
    },

    height: {
      type: Number,
      default: () => 60
    },

    round: {
      type: Boolean,
      default: () => false
    },

    radius: {
      type: Number,
      default: () => 0
    }
  },

  computed: {
    imgStyle() {
      if (this.radius > 0) {
        return {
          borderRadius: `${this.radius}px`
        }
      }

      return {}
    }
  },

  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.base64-avatar-container {
  .round {
    border-radius: 50%;
  }
}
</style>
