<template>
  <div
    class="com-container px3 py2 flex items-center justify-between deep_black my2"
    v-track:exposure
    :track-params="JSON.stringify(getEventInfo('首页金刚区位置'))"
  >
    <div
      v-for="(item, index) in kingKongData"
      :key="index"
      @click="kingKongClick(item, index)"
      class="flex flex-column items-center"
    >
      <img class="entry-icon" :src="item.img" />
      <div>{{ item.businessKey?.title }}</div>
    </div>
  </div>
</template>

<script>
/**
 * 首页-金刚位
 */
import { mapState, mapGetters } from 'vuex'
import xEnum from 'base/utils/enum'
import utils from 'base/utils'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'HomeKingKongThemed',
  props: {
    kingKongData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      entries: []
    }
  },

  computed: {
    ...mapState({
      isInWechat: (state) => state.appState.isInWechat
    }),

    ...mapGetters({
      appState: 'appState'
    }),

    wxPubName() {
      return this.renderBrandElement({ id: 'weChat.publicAccount' })
    }
  },

  methods: {
    async kingKongClick(source, index) {
      let placeType = ''
      let placeDetail = ''
      if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.value) {
        const mpScheme = await this.$store.dispatch('getMpUrlScheme', source.businessKey)
        location.href = mpScheme
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.MINI_PROGRAM.getText()
        placeDetail = source.businessKey
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.value) {
        this.$router.push({ path: '/activity/brand', query: { pageId: source.businessKey.pageId } })
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.ACTIVITY.getText()
        placeDetail = source.businessKey.pageId
      } else if (source.urlType === xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.value) {
        this.$router.push({
          path: `/goodsProfile/${source.businessKey.goodsId}`,
          query: { type: source.businessKey.type }
        })
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.PROFILE.getText()
        placeDetail = source.businessKey.goodsId
      } else {
        const isWebUrl = utils.isWebUrl(source.businessKey.path)
        if (isWebUrl) {
          window.location.href = source.businessKey.path
        } else {
          this.$router.push({ path: source.businessKey.path })
        }
        placeType = xEnum.ENUM_ADVERTISE_URL_TYPE.LINK.getText()
        placeDetail = source.businessKey.path
      }
      // 首页资源位-金刚区
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hp_place_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_hp_page',
        params: {
          place_type: placeType,
          place_name: '首页金刚位位置',
          place_addr: index,
          place_detail: placeDetail,
          module_name: source.businessKey?.title
        }
      })
    },

    getEventInfo(addr) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.HOME_PAGE_SOURCE_PLACE_EP.value,
        eventInfo: {
          place_type: '无',
          place_name: addr,
          place_addr: '无'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.com-container {
  // margin-top: 30px;
  // background-color: #fff;
  border-radius: 10px;
  font-size: 12px;
  // color: ;
  .entry-icon {
    width: 45px;
  }
}
</style>
