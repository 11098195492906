import store from '@/store'
import { KEEP_POINTS_ROUTES } from '@/utils/xConfig'

function updatePidWhenRouteChange(path) {
  if (KEEP_POINTS_ROUTES.includes(path)) return
  try {
    const pId = store.state.appState.pId
    store.dispatch('GetPoints', pId)
  } catch (err) {
    console.log('get points while route change failed', err)
  }
}
export { updatePidWhenRouteChange }
