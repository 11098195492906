<template>
  <div
    @click="onClick"
    class="container"
    :style="{ ...itemStyle }"
    v-track:exposure
    :track-params="JSON.stringify(eventInfo)"
  >
    <van-image class="waterfall-img In_c_Img" :src="source.imgUrl || null">
      <template v-slot:loading>
        <ImageThemed :width="100" image-id="goodsNormalItem.loading"></ImageThemed>
      </template>
    </van-image>
    <div v-if="rankTagIndex < 11" class="rank absolute flex items-center justify-center text-[14px] text-white">
      Top{{ rankTagIndex }}
    </div>
    <div class="absolute img-tag text-[12px] text-white flex items-center justify-center">极速退款 | 包邮</div>
    <!-- <img :src="source.cornerImg" alt="" class="cornerImg" /> -->
    <img v-if="source.stockNum <= 0" class="dw_img" src="~base/assets/img/dw.png" />
    <div class="flex flex-column gap-[3px] px-[8px] py-[10px]">
      <div class="name flex items-center text-14 text-[#4D4D4D] line-clamp-2 p-[1px]">
        <span class="tag px-[2px] py-[2px]">秒杀</span>{{ source.goodsDesc }}
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-baseline text-[#D4372E]">
          <span class="text-[12px]">补贴价 ￥</span>
          <span class="text-[24px] font-bold">{{ source.price }}</span>
        </div>
        <div class="text-[14px] text-[#999999] line-through">￥{{ source.originalPrice }}</div>
      </div>
      <div class="flex justify-between">
        <div
          v-if="!marketingBlockAreaFlag"
          class="flex items-center text-[12px] text-white rounded-[2px] bg-[#E95549] px-[4px] py-[1px] font-medium"
        >
          {{ virtualCoupon.value + virtualCoupon.name }}
        </div>
        <div class="text-[12px] text-[#D4372E] border-[1px] border-solid border-[#D4372E] px-[2px] py-[1px]">
          {{ paySaleDesc }}
        </div>
      </div>
    </div>
    <!-- <div class="goods-info flex flex-column">
      <div class=" h-[30px] bold van-ellipsis">{{ source.goodsName }}</div>
      <div class="recommend-intro fs12 van-ellipsis" v-if="source.recommendReasonList?.length">
        <span v-for="item in source.recommendReasonList" :key="item" class="item px1">{{ item }}</span>
      </div>
      <div class="goods-tag flex flex-wrap fs11 van-ellipsis" v-if="source.styleTags?.length">
        <span
          v-for="item in source.styleTags"
          :key="item.text"
          class="item px1 mr1 mt1 fs11"
          :style="{ ...item.styleWrap }"
        >
          {{ item.text }}
        </span>
      </div>
      <div class="goods-bottom">
        <div class="color_primary bold mr2 flex items-end">
          <span class="point fs16">{{ source.costPoints }}{{ globalPointName }}</span>
          <span class="price fs14">+{{ source.price }}元</span>
        </div>
      </div>
      <div class="flex fs12 goods-desc justify-between">
        <span class="sale-desc color_primary">{{ saleDesc }}</span>
        <span class="original-price color_tips">{{ source.originalPrice }}元</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { trackUserEvent, webfunnyTrack } from 'base/utils/rp'
// import { ImageThemed } from 'components'
import ImageThemed from 'components/ImageThemed.vue'
import xEnum from 'base/utils/enum'
import { Dialog } from 'vant'
import { mapState } from 'vuex'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'GoodsNormalItem',
  components: { ImageThemed },
  props: {
    itemStyle: {
      type: Object,
      default: () => {}
    },

    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: () => 0
    },

    type: {
      type: Number,
      default: () => 1
    },

    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    showRankTag: {
      type: Boolean,
      default: () => false
    },

    onClickItem: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId,
      phone: (state) => state.user.phone,
      openid: (state) => state.user.openid,
      unionId: (state) => state.user.unionId,
      webFingerPrint: (state) => state.user.webFingerPrint,
      marketingBlockAreaFlag: (state) => state.appState.marketingBlockAreaFlag
    }),

    saleDesc() {
      return _.replace(this.source.saleDesc, '兑换热度', '兑换热度')
    },

    paySaleDesc() {
      const str = this.source.saleDesc.replace('已兑换', '')
      const num = str.replace('件', '')
      return num + '人购买'

      // return this.source.saleDesc.replace('兑换热度', '')
    },

    rankTagIndex() {
      return this.source.j * 2 + this.source.i + 1
    },

    eventInfo() {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_CARD_EP.value,
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_EXPOSE.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: this.source.id,
          goodsPrice: this.source.price,
          score: this.source.score,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          index: (this.source.i + 1) * (this.source.j + 1) - 1,
          goodsName: this.source.goodsName,
          goodsIndex: `${this.source.j}`,
          goodsIndexVertical: `${this.source.i}`,
          goodsCategoryId: this.source.categoryId
        }
      }
    }
  },

  methods: {
    onClick() {
      const { source, index } = this
      if (source.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        })
        return
      }
      let count = Number(sessionStorage.getItem('enterProfileCount'))
      if (count && count < 6) {
        count++
        sessionStorage.setItem('enterProfileCount', count)
      } else {
        sessionStorage.setItem('enterProfileCount', 1)
      }
      this.$router.push({
        path: `/goodsProfile/${source.id}`,
        query: {
          type: this.type,
          pageSource: this.pageSource
        }
      })

      this.onClickItem(source, index)

      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        goodsName: source.goodsName,
        goodsPrice: this.source.price,
        score: this.source.score,
        goodsIndex: index,
        goodsGroupId: this.type,
        webUrl: window.location.href
      }
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_CARD_CLICK.value,
        eventInfo
      })

      webfunnyTrack({
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_CLICK.value,
        eventType: 'goodsClick',
        eventInfo
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: source?.score,
          goods_group_type: this.type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: `${this.source.j}`,
          goods_index_vertical: `${this.source.i}`,
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_source_waterfall: '商品瀑布流',
          goods_category_id: source.categoryId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';

.line-clamp-2 {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.btn {
  width: 100%;
  height: 30px;
  border-radius: 6px;
  color: white;
  background: linear-gradient(to right, rgb(255, 36, 0), rgb(254, 160, 5));
  font-family: 'PingFangSC-Medium';
}
.container {
  width: 100%;
  display: inline-block;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  box-shadow: 0px 0px 6px rgba(128, 128, 128, 0.2);
  background: #fff;
  overflow: hidden;

  .cornerImg {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 0.72rem;
    width: auto;
  }

  .In_c_Img {
    display: block;
    width: 100%;
    .rem(height, 175px);
  }
  .dw_img {
    .rem(width, 95px);
    .rem(height, 95px);
  }
  .name {
    // padding-top: 0.32rem;
    max-width: 160px;
    text-align: left;
  }
  .recommend-intro {
    max-width: 160px;
    color: #999999;
    .item {
      border-right: 1px solid;
    }
    .item:last-child {
      border-right: 0;
      padding-right: 0;
    }
    .item:first-child {
      padding-left: 0;
    }
  }

  .in_c_I_p {
    display: inline-block;
    vertical-align: middle;
  }

  .in_c_I_p1 {
    width: 100%;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
  .goods-info {
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .goods-desc {
      margin-top: 0.16rem;
      color: #808080;
      flex-direction: row-reverse;
      .sale-desc {
        margin-right: 0.16rem;
      }
      .original-price {
        margin-left: 0.16rem;
        text-decoration: line-through;
      }
    }
    .point {
      font-size: 16px;
    }
    .goods-tag {
      max-width: 165px;
      .item {
        height: 18px;
        line-height: 18px;
        box-sizing: border-box;
      }
      .item:last-child {
        margin-right: 0;
      }
      .tag1 {
        padding: 1px 4px 0 4px;
        background: rgba(229, 70, 53);
        margin-right: 4px;
      }
      .tag2 {
        padding: 0 4px;
        border: 1px solid rgba(229, 70, 53, 0.5);
      }
    }
    .goods-bottom {
      margin-top: 4px;
    }
  }
}

.tag {
  color: #d4372e;
  border: 1px solid #d4372e;
  font-size: 12px;
  border-radius: 4px;
}

.rank {
  top: 0;
  left: 0;
  padding: 5px 7px;
  background: linear-gradient(90deg, #f4bd46 0%, #e19235 100%);
  border-radius: 0 16px 16px 0;
}
.img-tag {
  height: 20px;
  border-radius: 10px 0 0 0;
  background: linear-gradient(90deg, #ed742d 0%, #e6ba3f 100%);
  right: 0;
  // top: 175px;
  .rem(top, 155px);
  padding: 1px 5px;
}
</style>
