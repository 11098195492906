<template>
  <div class="waterfall-wrapper" :style="{ 'column-gap': gap[1] }">
    <div class="col-wrapper" ref="cols" v-for="(col, i) in colData" :key="i" :style="{ 'row-gap': gap[0] }">
      <div class="row-wrapper flex" :class="[animation]" v-for="(row, j) in col" :key="j">
        <slot :source="{ ...row, i, j }" />
      </div>
    </div>
  </div>
</template>

<script>
import { checkIntersectionObserver } from 'base/utils'

export default {
  props: {
    // 瀑布流数据
    goodsList: {
      type: Array,
      default: () => []
    },

    // 列数
    col: {
      type: Number,
      default: 2
    },

    finished: {
      type: Boolean,
      default: () => false
    },

    // 行、列间距
    gap: {
      type: Array,
      default: () => ['0px', '0px']
    },

    // 首屏开启并行渲染，减少白屏时间
    firstPageCount: {
      type: Number,
      default: 6
    },

    animation: {
      type: String,
      default: 'hapi' // hapi|osiris
    },

    // 扩展intersectionRect交叉区域，可以提前加载部分数据，优化用户浏览体验
    rootMargin: {
      type: String,
      default: '0px 0px 400px 0px'
    }
  },

  data() {
    return {
      colData: [[], []],
      observerObj: null,
      minCol: 0, // 最小列索引
      innerData: [], // 瀑布流数据队列
      counts: 0 // 已经渲染的数量
    }
  },

  watch: {
    col: {
      // eslint-disable-next-line no-unused-vars
      handler: function (v) {
        // console.log('col: ', v)
        // 初始化各列数据
        // for (let i = 0; i < v; i++) {
        //   this.$set(this.colData, i, [])
        // }
      },

      immediate: true
    },

    goodsList: {
      // eslint-disable-next-line no-unused-vars
      handler: function (nVal, oVal) {
        this.innerData = [...this.innerData, ...nVal]
        // console.log('goodsList: ', nVal, oVal)
        // console.log('innerData: ', this.innerData)
        this.waterfall()
      },

      immediate: true
    }
  },

  created() {
    // 不支持IntersectionObserver的场景下，动态引入polyfill
    const ioPromise = checkIntersectionObserver() ? Promise.resolve() : import('intersection-observer')
    // console.log('ioPromise: ', checkIntersectionObserver())

    ioPromise.then(() => {
      // 瀑布流布局：取出数据源中最靠前的一个并添加到瀑布流高度最小的那一列，等图片完全加载后重复该循环
      this.observerObj = new IntersectionObserver(
        (entries) => {
          for (const entry of entries) {
            const { target, isIntersecting } = entry
            if (isIntersecting) {
              const done = () => {
                // console.log('innerData: ', innerData)
                if (this.innerData.length) {
                  this.waterfall()
                } else if (!this.finished) {
                  // console.log('loadMore ')
                  this.$emit('load-more')
                }
                // 停止观察，防止回拉时二次触发监听逻辑
                this.observerObj.unobserve(target)
              }

              // console.info('target.complete: ', target)
              if (target.complete) {
                done()
              } else {
                target.onload = done
                target.onerror = done
              }
            }
          }
        },
        {
          rootMargin: this.rootMargin
        }
      )
    })
  },

  methods: {
    restRenderCounts() {
      this.counts = 0
    },

    updateMinCol() {
      // console.log('this.firstPageCount: ', this.firstPageCount)
      // console.log('counts: ', this.counts)
      // 并行渲染时，无法获取最小列，只能按列依次渲染
      if (this.counts <= this.firstPageCount) {
        this.minCol = this.counts % this.col
        return
      }
      // console.log("this.$refs['cols']: ", this.$refs['cols'])
      const heightList = this.$refs['cols'].map((item) => item.offsetHeight)
      const minHeight = Math.min(...heightList)
      this.minCol = heightList.indexOf(minHeight)
    },

    appendColData() {
      const colItem = this.innerData.shift()
      // console.log('colItem: ', colItem)
      this.colData[this.minCol].push(colItem)
    },

    startObserver() {
      // 开始监测新增加的瀑布流元素
      const nodes = this.$refs['cols'][this.minCol].querySelectorAll('.van-image__img')
      const lastNode = nodes[nodes.length - 1]
      this.observerObj.observe(lastNode)
    },

    waterfall() {
      // 更新瀑布流高度最小列
      this.updateMinCol()
      // 取出数据源中最靠前的一个并添加到瀑布流高度最小的那一列
      this.appendColData()
      // 首屏采用并行渲染，非首屏采用串行渲染
      if (++this.counts < this.firstPageCount) {
        this.$nextTick(() => this.waterfall())
      } else {
        this.$nextTick(() => this.startObserver())
      }
    }
  }
}
</script>
<style lang="less" scoped>
.waterfall-wrapper {
  display: flex;
  align-items: flex-start;
}

.col-wrapper {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  &:first-child {
    div {
      justify-content: flex-start;
    }
  }
  &:last-child {
    div {
      justify-content: flex-end;
    }
  }
}
</style>
<style lang="less">
.waterfall-wrapper {
  .hapi {
    .waterfall-card {
      animation: 0.4s hapi-animation linear;
      animation-fill-mode: forwards;
    }

    @keyframes hapi-animation {
      from {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(0);
      }

      to {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(1);
      }
    }
  }

  .osiris {
    .waterfall-card {
      animation: 0.4s osiris-animation linear;
      animation-fill-mode: forwards;
    }

    @keyframes osiris-animation {
      from {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: translateZ(-3000px) rotateY(-1turn);
      }

      to {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: translateZ(0) rotateY(0turn);
      }
    }
  }
}
</style>
