<template>
  <div @click="toSearchPage" :style="{ ...barStyle }" class="con">
    <div class="search-bar flex">
      <div class="input-area relative">
        <IconFont size="20px" class="icon" icon="iconchaxun" color="#7d7d7d"></IconFont>
        <input
          @focus="inputFocus"
          type="text"
          maxlength="15"
          v-model="keyWords"
          @blur="inputBlur"
          :placeholder="recommendWord"
        />
      </div>
      <div @click="searchGoods" class="btn-area bg_primary">搜索</div>
    </div>
  </div>
</template>

<script>
import { IconFont } from 'components'
// 这个文件的下面这个备注的引入方式 在dev环境正常 在生产环境 会导致 vue render函数报错
// import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'
import utils from 'base/utils'

export default {
  name: 'SearchBar',
  components: { IconFont },
  props: {
    recommendWord: {
      type: String,
      default: ''
    },

    focusSearchBar: {
      type: Boolean,
      default: false
    },

    barStyle: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      keyWords: ''
    }
  },

  computed: {},

  methods: {
    toSearchPage() {
      this.$emit('to-search-page')
    },

    searchGoods() {
      this.$emit('click-search', this.keyWords)
    },

    inputFocus() {
      this.$emit('focus')

      utils.sendQuickTrackingEvent({
        eventName: 'ducuecmc_sp_search_icon_clk',
        eventType: utils.EventType.Click,
        pageName: 'dacuecmc_search_page',
        params: {}
      })
    },

    inputBlur() {
      this.$emit('blur')
    },

    setKeyword(keyword) {
      this.keyWords = keyword
    }
  }
}
</script>

<style lang="less" scoped>
::-webkit-input-placeholder {
  color: #9e9d9d;
}
::-moz-placeholder {
  color: #9e9d9d;
}
:-moz-placeholder {
  color: #9e9d9d;
}
:-ms-input-placeholder {
  color: #9e9d9d;
}
.con {
  width: 100%;
  padding: 0px 8px;
  box-sizing: border-box;
  .search-bar {
    width: 100%;
    height: 45px;
    padding: 0px 10px;
    box-sizing: border-box;
    border-radius: 30px;
    background-color: #fff;
    border: 1px solid #eaeaea;
    align-items: center;
    .input-area {
      width: 80%;
      position: relative;
      .icon {
        position: absolute;
        top: 3px;
      }
      input {
        width: 89%;
        margin-left: 25px;
        margin-top: 3px;
        border: none;
        outline: none;
        // margin-bottom: 5px;
        background-color: #fff;
      }
    }
    .btn-area {
      width: 20%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      border-radius: 15px;
      font-size: 14px;
    }
  }
}
</style>
