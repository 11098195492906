<template>
  <div v-if="pId" class="mt2 guess-your-favor" :class="className">
    <div class="fs14 flex items-center justify-center my2" :class="iconColor" style="width: 100%">— {{ title }} —</div>
    <GoodsWaterfallList
      ref="waterfallGoodsList"
      name="猜你喜欢"
      :p-id="pId"
      :type="groupTypeIds[activeTabIndex].groupTypeId"
    />
  </div>
</template>

<script>
import { GoodsWaterfallList, GoodsNormalItem } from 'widgets'
import xEnum from 'base/utils/enum'

const itemMap = {
  point: GoodsNormalItem
}

export default {
  components: { GoodsWaterfallList },
  props: {
    name: {
      type: String,
      default: '未知'
    },

    iconName: {
      type: String,
      default: 'icongouwu'
    },

    iconColor: {
      type: String,
      default: 'color_title'
    },

    title: {
      type: String,
      default: '您可能也喜欢'
    },

    className: {
      type: String,
      default: ''
    },

    groupTypeIds: {
      type: Array,
      default: () => [
        { groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_XIHUAN_GROUP.value },
        { groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_JINGXUAN_GROUP.value },
        { groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_YANXUAN_GROUP.value },
        { groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_YOUXUAN_GROUP.value }
      ]
    },

    sticky: {
      type: Boolean,
      default: false
    },

    enableAutoScroll: {
      type: Boolean,
      default: true
    },

    tabFilterBgColor: {
      type: String,
      default: 'white'
    },

    tabFilterTitleColor: {
      type: String,
      default: null
    },

    inActiveStyle: {
      type: Object,
      default: () => {}
    },

    styleActiveSubtitle: {
      type: Object,
      default: () => {}
    },

    styleLoadingText: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      goodsList: [],
      loading: false,
      tabs: this.renderBrandElement({ id: 'guessYourFavor.tabs', type: 'object' }),
      activeTabIndex: 0,
      // 猜你喜欢改版，只显示一个类，暂时保留老逻辑
      // groupTypeId: xEnum.ENUM_GOODS_GROUP_TYPE.GUESS_YOUR_FAVOR_DISTRIBUTE_XIHUAN_GROUP.value,
      isStickyFixed: false,
      goodsItem: itemMap[this.renderBrandElement({ id: 'guessYourFavor.listItemType' }) || 'point']
    }
  },

  computed: {
    pId() {
      return this.$store.state.appState.pId
    },

    mergedTabs() {
      return _.merge(this.tabs, this.groupTypeIds)
    },

    stickyId() {
      return `stickyZone-${this.name}`
    }
  },

  created() {
    if (this.pId) {
      this.onTabChange(0, false)
    }
  },

  methods: {
    onClickGoodsListMore() {
      this.$emit('click-more')
    },

    // eslint-disable-next-line no-unused-vars
    onTabChange(index, pageIndex = 1, scrollToTop = true) {
      // 每次切换tab都要将finished和pageIndex重置
      this.goodsList = []
      this.loading = true
      if (this.$refs.waterfallGoodsList) {
        this.$refs.waterfallGoodsList.resetOnloadSetting()
      }
      this.loading = false
      this.activeTabIndex = index
    },

    loadGoodsList(dataList) {
      dataList.forEach((item) => {
        this.goodsList.push(item)
      })
    },

    // eslint-disable-next-line no-unused-vars
    onStickyScroll({ scrollTop, isFixed }) {
      this.isStickyFixed = isFixed
    },

    resetOnloadSetting() {
      this.$refs.waterfallGoodsList.resetOnloadSetting()
    }
  }
}
</script>
<style lang="less" scoped>
.guess-your-favor {
  background-color: #f2f2f2;
}
.com-point-notice-mask {
  .t_Img {
    display: inline-block;
    vertical-align: middle;
    width: 0.6rem;
    margin-left: 0.5rem;
    margin-right: 0.3rem;
  }
  .t_txt {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 2rem);
    font-size: 0.5rem;
    color: #ffffff;
  }

  &.top {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
</style>
