<template>
  <div
    class="com-goods-price-banner-point flex justify-between"
    :style="{
      'background-image': `url(${renderBrandElement({ id: 'goodsProfile.seckillPriceBannerBg', type: 'image' })})`
    }"
  >
    <div class="price-info flex flex-column justify-evenly items-start">
      <p class="" style="color: #fff">
        <span class="point">补贴价￥</span>
        <span class="price">
          <span class="fs24">{{ good.goodsDetailEx.price }}元</span>
        </span>
      </p>
      <div class="hot original-price">
        原价￥{{ good.goodsDetailEx.originalPrice }} | {{ good.goodsDetailEx.saleDesc | sails }}
      </div>
    </div>
  </div>
</template>

<script>
// import { trackEvent } from 'base/utils/rp'
// import { ImageThemed, IconFont } from 'components'

export default {
  filters: {
    sails: function (value) {
      return _.replace(value, '兑换', '兑换')
    }
  },

  // components: { IconFont, ImageThemed },

  props: {
    good: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  created() {},
  beforeDestroy() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.com-goods-price-banner-point {
  height: 60px;
  line-height: 1;
  background-color: #e93829;
  padding: 0 12px;
  box-sizing: border-box;
  position: relative;
  background-size: no-repeat;
  background-size: 100% 100%;
  .price-info {
    width: 70%;
  }
  .hot {
    color: #fff;
  }
  .point {
    color: #fff;
  }
  .price-reduce {
    width: 35%;
    color: #fff;
    text-align: right;
    font-size: 12px;
  }
  .price {
    font-size: 15px;
    color: #fff;
  }

  .tag {
    background-color: #fff;
    font-size: 12px;
    color: #e93829;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    position: absolute;
    top: 13px;
  }

  .original-price {
    color: #fff;
    font-weight: 400;
  }
}
</style>
