<template>
  <transition name="fade">
    <div v-if="visible" class="mask">
      <div class="dialog-content">
        <span @click="closeDialog" class="close-dialog">
          <IconFont size="15px" icon="iconqingchu" color="#cdcdcd"></IconFont>
        </span>
        <div v-if="!hidePoint" class="title flex items-center center">
          <div class="points-icon mr1 flex">
            <IconFont size="40px" icon="iconjifen1" color="#efb336"></IconFont>
          </div>
          <div class="title-con">
            <div class="txt" v-if="shareHint">{{ shareHint }}</div>
            <div v-else>
              <div class="txt">分享给好友，送你500{{ globalPointName }}</div>
            </div>
          </div>
        </div>
        <div v-if="shareImage" class="center">
          <img width="200" :src="shareImage" />
        </div>
        <div class="copy-desc mt2">
          <div class="">分享口令已复制</div>
          <div class="mt1">{{ shareCopyDesc }}</div>
        </div>
        <div @click="clickCopyLink" class="share-btn">
          <IconFont size="20px" icon="iconweixin" color="#fff"></IconFont>
          <span class="btn-desc ml1">{{ shareBtnDesc }}</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { IconFont } from 'components'
import { shareAddPoints } from 'base/api'
import store from '@/store'

export default {
  name: 'ShareDialog',
  components: { IconFont },
  props: {},
  data() {
    return {
      visible: true
    }
  },

  computed: {},
  created() {},
  methods: {
    closeDialog() {
      this.visible = false
    },

    hide() {
      this.visible = false
    },

    clickCopyLink() {
      // 此dialog中没有注入store,不能使用map state，所以需要使用store来获取
      shareAddPoints(store.state.user.userId, store.state.appState.pId)
      this.hide()
      this.resolve()
    }
  }
}
</script>

<style lang="less" scoped>
.mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  .dialog-content {
    padding: 20px 10px;
    box-sizing: border-box;
    width: 80%;
    position: absolute;
    top: 30%;
    left: 10%;
    z-index: 12;
    background-color: #fff;
    border-radius: 10px;
    .close-dialog {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 10px;
      top: 5px;
    }
    .title {
      margin: 0 auto;
      margin-top: 20px;
      width: 95%;
      box-sizing: border-box;
      display: flex;
      background-color: #f2f2f2;
      border-radius: 25px;
      padding: 5px 15px;
      box-sizing: border-box;
      .points-icon {
        width: 20%;
        align-items: center;
      }
      .points-con {
        width: 80%;
      }
    }
    .copy-desc {
      div {
        text-align: center;
        font-weight: bold;
        font-size: 18px;
      }
    }
    .share-btn {
      margin: 0 auto;
      width: 90%;
      line-height: 18px;
      font-size: 18px;
      background-color: #06c160;
      color: #fff;
      padding: 10px;
      box-sizing: border-box;
      text-align: center;
      margin-top: 15px;
      border-radius: 5px;
      .btn-desc {
        font-weight: bold;
      }
    }
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 0.5s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
}

.fade-leave {
  opacity: 1;
}
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}
.fade-leave-to {
  opacity: 0;
}
</style>
