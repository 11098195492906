// vm.$mount(el) => document.body.appendChild();
// Vue.extend() 继承 【子类继承父类的方法】
// 父类 Vue--Vue.extend--子类 定制化的 Vue 构造函数
import Vue from 'vue'
import ShareDialog from './ShareDialog'

const Customized = Vue.extend(ShareDialog)

export const shareDialog = (option = {}) =>
  new Promise((resolve, reject) => {
    const res = new Customized({
      data() {
        return {
          shareCopyDesc: option.shareCopyDesc || '快去粘贴给好友吧', // 默认为微信下分享文案
          shareBtnDesc: option.shareBtnDesc || '点击复制分享给好友',
          shareHint: option.shareHint,
          shareImage: option.shareImage,
          hidePoint: option.hidePoint
        }
      },
      methods: {
        resolve,
        reject
      }
    }).$mount()
    // console.log(res.$el);
    document.body.appendChild(res.$el)
  })
