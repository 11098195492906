<template>
  <div class="com-section-title flex items-center">
    <div class="flex-auto flex bold">
      <div class="dot"></div>
      <div class="title fs16">{{ title }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'IconFont',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.com-section-title {
  /* .dot {
    background-color: #fb5074;
    width: 5px;
    height: 20px;
    border-radius: 6px;
    margin-right: 10px;
  } */

  .title {
  }
}
</style>
