<template>
  <div v-if="isShow" class="mask-rule">
    <div class="dialog-content flex flex-column">
      <div>
        <div class="flex justify-between">
          <div
            class="cards flex items-center justify-center service-card"
            :class="{ active: showContent == true }"
            @click="changeContent($event)"
          >
            服务
          </div>
          <div
            class="cards flex items-center justify-center rule-card"
            :class="{ active: showContent == false }"
            @click="changeContent($event)"
          >
            规则
          </div>
        </div>
        <div v-if="showContent">
          <div class="service-content flex items-center background-quality" style="padding-left: 50px">
            <div class="service-icon">
              <img src="~/base/assets/img/icon/icon-quality.png" alt="" />
            </div>
            <div class="service-text">
              <p class="text-top">正品保障</p>
              <p class="text-bottom">平台承诺商品为官方正品</p>
            </div>
          </div>
          <div class="service-content flex items-center background-week" style="padding-left: 50px">
            <div class="service-icon">
              <img src="~/base/assets/img/icon/icon-week.png" alt="" />
            </div>
            <div class="service-text">
              <p class="text-top">七天无理由</p>
              <p class="text-bottom">平台承诺七天无理由退换货</p>
            </div>
          </div>
          <div class="service-content flex items-center background-postage" style="padding-left: 50px">
            <div class="service-icon">
              <img src="~/base/assets/img/icon/icon-postage.png" alt="" />
            </div>
            <div class="service-text">
              <p class="text-top">全国包邮</p>
              <p class="text-bottom">新疆、西藏等偏远地区除外</p>
            </div>
          </div>
        </div>
        <div v-else class="rule-box px3 py3">
          <div class="rule-text">
            <p v-html="message"></p>
          </div>
        </div>
      </div>
      <div id="close" class="close-btn" @click="closeRulePopup">
        <van-icon size="24" color="#cccccc" name="close" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

//解决遮罩层滚动穿透问题，分别在遮罩层弹出后和关闭前调用
const MaskProblem = ((bodyCls) => {
  let scrollTop
  return {
    afterOpen: function () {
      scrollTop = document.scrollingElement.scrollTop
      document.body.classList.add(bodyCls)
      document.body.style.top = -scrollTop + 'px'
    },
    beforeClose: function () {
      document.body.classList.remove(bodyCls)
      // scrollTop lost after set position:fixed, restore it back.
      document.scrollingElement.scrollTop = scrollTop
    }
  }
})('mongolia_open')

export default {
  name: 'ServiceRuleDialog',
  data() {
    return {
      showContent: true,
      isShow: true,
      message: ''
    }
  },

  computed: {
    ...mapState({
      points: (state) => state.user.points
    }),

    activityRules() {
      return this.renderBrandElement({ id: 'global.activityRules' })
    }
  },

  created() {
    this.showRule()
    MaskProblem.afterOpen()
  },

  methods: {
    showRule() {
      const pointName = this.globalPointName
      const exchangeWord = this.globalExchangeWord
      const defaultRules =
        `一、${pointName}获取方式` +
        `<br />1.新用户新用户可免费获赠一定的随机专属体验${pointName}。新用户是指没有在本商城提交过订单的用户，同一账号、同一手机号、同一终端设备号或其它合理显示为同一用户的情形，均视为同一用户。` +
        `<br />2.分享送好礼活动分享送好礼活动,将一次性赠送500${pointName}，再次分享不再增加${pointName}。` +
        `<br />` +
        `<br />二、${pointName}有效期` +
        `<br />新用户获赠的体验${pointName}有效期为自获取之日起计算的2天内，有效期内未使用的体验${pointName}到期将自动作废。` +
        `<br />` +
        `<br />三、${pointName}扣减规则` +
        `<br />通过下单购买${pointName}${exchangeWord}活动页面内的所有商品，会扣减相应${pointName}。` +
        `<br />` +
        `<br />四、${pointName}退回规则` +
        `<br />在${pointName}商城使用${pointName}${exchangeWord}的商品，订单产生退款时，不会退回${pointName}。` +
        `<br />` +
        `<br />五、价格说明` +
        `<br />1. 划线价格： 商品展示的划线价格是商品的专柜价、吊牌价、零售价、指导价、曾经展示过的销售价等，并非原价，由于地区、时间的差异性和市场的行情波动，该价格仅供您参考。` +
        `<br />2. 未划线价格： 未划线的价格是商品的实时标价，并不一定为您最终实际支付的价格，具体的成交价格可能会因为您使用${pointName}、活动、优惠券等发生变化，请以订单结算价格为准。` +
        `<br />3. 如您对商品的标题、价格、详情等信息内容有疑问，请在购买前咨询商城在线客服，客服工作时间为周一至周日早9:00至晚21:00。` +
        `<br />4. 我们网站上的产品和定价信息在公布前已经过核实。但是，在极少数情形下可能有误。如果我们发现定价错误，我们将通知您，取消您的订单，并对订单金额全额退款。` +
        `<br />` +
        `<br />六、其他说明` +
        `<br />1. 商品的${exchangeWord}热度是指该商品一定期间内访问人数、下单人数、实际支付人数、平台推荐力度等的综合指标，并非商品的销量，由于地区、时间或网络波动的差异，${exchangeWord}热度仅供您参考。` +
        `<br />2. 如用户存在违规盗刷${pointName}行为，我们有权取消该违规用户非法获取的${pointName}资格，已领取的${pointName}将被扣回，并要求其对已发放的活动所得进行返还，承担相应法律责任。` +
        `<br />3. 如遇不可抗力因素，本商城有权取消、修改或暂停${pointName}${exchangeWord}等活动。` +
        `<br />4. 为了提升用户体验，我们将不定时更新${pointName}服务内容，${pointName}规则也可能会随之变更，更新后的内容于公布之日起生效。如您选择继续使用${pointName}服务，产生购物行为即表示您默认更新后的${pointName}规则。若不同意变更后的${pointName}规则，您有权停止使用${pointName}服务。双方协商一致的，也可另行变更相关服务和对应内容条款。如更新后涉及您的主要权利或责任，我们会以公告、客户端通知或短信等方式为您提示，您也可以随时在本页面查阅${pointName}规则的最新版本。` +
        `<br />5. 下单时请仔细核对收件信息，商品一经发出便交由快递公司配送，无法再为您进行修改，因填写失误所产生的配送相关问题将由您自行承担责任。` +
        `<br />6. 下单后一般在48小时内根据订单顺序为您发货，如遇节假日或周末可能由于订单较多导致发货时间延长至72小时。如您一次购买多个商品，可能由于商品存放的仓库位置不同，分多个包裹为您发货，还请您耐心等待。` +
        `<br />7. 不支持指定快递，发货后物流需要3-5日为您送达，偏远地区7-10日，商品由仓库合作的快递公司进行配送，无法保证送达时效，不便之处敬请谅解。` +
        `<br />8. 护肤品请先在耳后肌肤测试，确认无过敏反应再用于面部，由于特殊商品（如护肤品、食品等）开封后将影响二次销售，故不支持七天无理由退换货。` +
        `<br />9. 根据我国法律规定，快递收寄须实名制，请您在填写收件人时使用真实姓名，避免使用“X先生、X女士”及昵称等收件信息，导致出现快递无法签收。` +
        `<br />10. 签收快递时请当面与快递员确认商品是否完好无损，产品配件是否齐全。若查看时发现有损坏、漏发、划痕等情况，请您直接拒收。一旦签收，即代表您已经确认商品的完整性，不再作为商品售后依据。` +
        `<br />11. 七天无理由退货须保证商品不影响二次销售，配件、说明书及赠品等齐全，若有破损、缺失等可能会导致退货失败。建议您自产品签收之日起，将原包装保留至少15天。凡商品产生人为损坏的均不支持退换货，七天无理由退货运费由您自行承担，商品质量问题导致的退换货及维修，运费由您先行垫付，寄回后请联系在线客服报销运费，拒收任何到付件。` +
        `<br />12. 根据我国《新广告法》出台实施的极限化“违禁词”规定，本商城已尽量规避，即日起商城所有商品标题、图片、详情等内含极限化“违禁词”介绍的文字说明一律失效，不作为商品描述依据。凡是您进行支付即视为认同，如无法认可商品描述信息，请勿购买。` +
        `<br />13. 在法律允许范围内，本商城拥有最终解释权。如有任何疑问，请在第一时间联系商城在线客服或拨打400-000-9353咨询热线，在线客服工作时间为：周一至周日早9:00至晚21:00，感谢您对本商城的支持与信赖，祝您购物愉快！` +
        `<br />14. 近期有不法分子未经授权或许可擅自使用我司名义、盗用我司旗下网站页面风格从事非法活动，我司保留采取一切必要法律手段追究相关侵权方责任之权利，任何机构或个人如发现上述涉嫌对我司相关权益的侵害行为欢迎向我司反映或咨询，也请社会公众提高警惕，注意甄别，避免造成不必要的损失！`
      this.message = this.activityRules ? this.activityRules : defaultRules
    },

    changeContent(e) {
      if (e.target.innerHTML.trim() == '服务' && this.showContent == false) this.showContent = true
      if (e.target.innerHTML.trim() == '规则' && this.showContent == true) this.showContent = false
    },

    closeRulePopup() {
      MaskProblem.beforeClose()
      this.isShow = false
      this.showContent = true
    }
  }
}
</script>

<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';
.mask-rule {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  padding-top: 5.4rem;
  .dialog-content {
    width: 337px;
    position: relative;
    .cards {
      box-sizing: border-box;
      // width: 168px;
      flex: 1;
      height: 2.2rem;
      font-size: 18px;
      background-color: #f2f2f2;
    }
    .service-card {
      border-radius: 10px 0 0 0;
    }
    .rule-card {
      border-radius: 0 10px 0 0;
    }
    .active {
      font-weight: bold;
      color: #e54635;
      background-color: #fff;
    }
    .service-content {
      height: 125px;
      .service-icon {
        box-sizing: border-box;
        width: 36px;
        height: 36px;
        margin-right: 4px;
        // left: 63px;
        // top: 45px;
        .img {
          width: 100%;
          height: 100%;
        }
      }
      .service-text {
        .text-top {
          font-size: 18px;
          font-weight: bold;
        }
        .text-bottom {
          color: #4d4d4d;
          font-size: 14px;
        }
      }
    }
    .background-quality {
      background: linear-gradient(to right, #d0e6fa, #fff);
      .text-top {
        color: #3a7bc2;
      }
    }
    .background-week {
      background: linear-gradient(to right, #d5fdf2, #fff);
      .text-top {
        color: #53a08e;
      }
    }
    .background-postage {
      background: linear-gradient(to right, #ffead3, #fff);
      border-radius: 0 0 10px 10px;
      .text-top {
        color: #f1a661;
      }
    }
    .close-btn {
      text-align: center;
      margin: auto;
      margin-top: 20px;
    }
  }
}

.rule-box {
  height: 335px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  .rule-text {
    color: #333333;
    font-size: 14px;
  }
  .rule-text::-webkit-scrollbar {
    display: none;
  }
}
</style>
