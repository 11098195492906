<template>
  <div @click="onClick" class="container">
    <van-image class="In_c_Img" :src="source.imgUrl" :lazy-load="true">
      <template v-slot:loading>
        <ImageThemed :width="100" image-id="goodsNormalItem.loading"></ImageThemed>
      </template>
    </van-image>
    <img v-if="source.stockNum <= 0" class="dw_img" src="~base/assets/img/dw.png" />
    <img
      class="rank-tag"
      v-if="showRankTag && index < 9"
      :src="require('base/assets/img/goodsDetail/jf' + (index + 1) + '.png')"
      alt=""
    />
    <div class="goods-info flex flex-column pt2">
      <div class="name van-ellipsis fs16 bold">{{ source.goodsName }}</div>
      <div class="flex items-end justify-between mt2">
        <div class="color_primary bold mr2">
          <!-- <IconFont size="14px" icon="iconjifen" color="#FFA202"></IconFont> -->
          <div class="orginal-price fs16">{{ source.price }}元</div>
        </div>
        <div class="goods-info-price flex">
          <div class="price fs16">￥{{ source.originalPrice }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { trackUserEvent } from 'base/utils/rp'
import { ImageThemed } from 'components'
import xEnum from 'base/utils/enum'
import { Dialog } from 'vant'
import { mapState } from 'vuex'

export default {
  name: 'SingleProductGoodsItem',
  components: { ImageThemed },
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: 0
    },

    type: {
      type: Number,
      default: 1
    },

    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    showRankTag: {
      type: Boolean,
      default: () => false
    },

    onClickItem: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId
    }),

    saleDesc() {
      return _.replace(this.source.saleDesc, '兑换', this.globalExchangeWord)
    }
  },

  methods: {
    onClick() {
      const { source, index } = this

      if (source.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        })
        return
      }

      this.$router.push({
        path: `/goodsProfile/${source.id}`,
        query: {
          type: this.type,
          pageSource: this.pageSource
        }
      })

      this.onClickItem(source, index)

      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        goodsName: source.goodsName,
        goodsIndex: index,
        goodsGroupId: this.type
      }
      trackUserEvent({
        eventType: 'goodsClick',
        eventInfo
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';

.container {
  width: 100%;
  display: inline-block;
  // box-shadow: 0px 0px 4px 1px #e4e4e4;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  // padding-bottom: 10px;
  background: #fff;

  .In_c_Img {
    display: block;
    width: 100%;
  }

  .in_c_I_p {
    display: inline-block;
    vertical-align: middle;
  }

  .in_c_I_p1 {
    width: 100%;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
  .goods-info {
    height: 85px;
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-left: 7px;
    padding-right: 7px;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .point {
      font-size: 16px;
    }

    .orginal-price {
      // font-size: 13px;
    }
  }

  .goods-info-price {
    display: flex;
    justify-content: space-between;

    .price {
      text-decoration: line-through;
      // font-size: 12px;
      color: #888888;
    }

    .sale-desc {
      font-size: 0.5rem;
      color: #ff3600;
    }
  }
}
.rank-tag {
  .rem(width, 29px);
  .rem(height, 27px);
  position: absolute;
  top: 0px;
  .rem(left, 15px);
}
</style>
